import React from 'react'
import { useGetCart } from '../CartQuery/CartQuery'
import { Notification } from '@local/do-secundo-notification'

export const CartNotifications = () => {
  const { info } = useGetCart()
  return (
    <>
      {info &&
        info.map((info) => (
          <Notification key={info.code} isCloseable>
            {info.message}
          </Notification>
        ))}
    </>
  )
}
