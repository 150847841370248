import React from 'react'
import PropTypes from 'prop-types'
import Observer from '@researchgate/react-intersection-observer'

export class LazyLoad extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasIntersected: false }
  }

  handleChange({ isIntersecting }) {
    if (isIntersecting) {
      this.setState({ hasIntersected: true })
    }
  }

  render() {
    return (
      <Observer {...this.props} onChange={(e) => this.handleChange(e)}>
        {this.props.children(this.state.hasIntersected)}
      </Observer>
    )
  }
}

LazyLoad.propTypes = {
  children: PropTypes.func.isRequired
}
