import React from 'react'

const formatUSD = (n = 0, options = {}) => {
  if (typeof n !== 'number' || Number.isNaN(n)) {
    throw new TypeError(`Value "${n}" is not number`)
  }

  return n.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options
  })
}

interface Props {
  amount?: number
  showLeadingSign?: boolean
  renderWhenZero: React.ReactNode
  truncateZeroCents?: boolean
  shouldRenderSchema?: boolean
  typography?: string
  testId?: string
}

export const Dollars: React.FC<Props> = ({
  amount = 0,
  showLeadingSign = false,
  renderWhenZero,
  truncateZeroCents = false,
  shouldRenderSchema = false,
  typography = 'type-default',
  testId
}: Props) => {
  if (amount === 0 && renderWhenZero !== undefined) {
    return <>renderWhenZero</>
  }

  let leadingSign = ''
  if (showLeadingSign && amount >= 0) {
    leadingSign = '+'
  }

  let truncateOptions
  if (truncateZeroCents && amount % 1 === 0) {
    truncateOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  }

  return (
    <span className={typography} data-testid={testId}>
      {shouldRenderSchema && <meta itemProp='price' content={'' + amount} />}
      {shouldRenderSchema && <meta itemProp='priceCurrency' content='USD' />}
      {leadingSign}
      {formatUSD(amount, truncateOptions)}
    </span>
  )
}
