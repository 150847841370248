import React from 'react'
import { useCart } from '@local/do-secundo-cart-provider'
import { getCart } from '../../api/cart'
import { useQuery } from 'react-query'
import { Cart } from '../../types/cart'
import { getCartQueryKey } from '../../utils/cart-helpers'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'

export const useGetCart = () => {
  const context = useCart()
  const { cartGuid: guid, deleteCartGuid } = context

  const { fastLinkName } = useFulfillment()

  const {
    data: cart,
    isLoading,
    isError,
    error: networkError,
    refetch
  } = useQuery<Cart, Response>(
    getCartQueryKey(guid!!),
    () => getCart(guid || '', fastLinkName),
    {
      staleTime: 60 * 1000,
      enabled: !!guid,
      onError: () => {}
    }
  )

  let error = networkError
  if (networkError?.status === 404) {
    error = null
    deleteCartGuid(fastLinkName)
  }

  const info = cart?.info
  const warnings = cart?.warnings
  const cartGuid = cart?.guid
  if (!isLoading && !isError && !cartGuid) {
    deleteCartGuid(fastLinkName)
  }

  const check = cart?.order.checks[0]
  const numberOfSelections = check?.selections.length || 0
  const cartIsEmpty = numberOfSelections === 0
  const cartTotal = !cartIsEmpty ? check?.totalAmount : 0

  return {
    ...context,
    error,
    loading: isLoading,
    data: cart,
    refetch,
    cart,
    cartGuid,
    info,
    warnings,
    cartTotal,
    cartIsEmpty,
    numberOfSelections
  }
}

interface GetCartQueryProps {
  children: (context: ReturnType<typeof useGetCart>) => React.ReactElement
}

export const GetCartQuery: React.FC<GetCartQueryProps> = ({ children }) => {
  const context = useGetCart()
  return children(context)
}
