import React, { ReactNode } from 'react'
import { useDiningOptions } from '../../hooks/useDiningOptions'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { AvailabilityEntry } from '../../types/fulfillment'
import {
  formatDOWs,
  formatIntervals,
  renderNodes
} from '../../utils/availability-util'

const pickupMsg = 'Available for pickup orders'
const deliveryMsg = 'Available for delivery orders'
const deliveryPartialMsg = ' and for delivery order'
const bothMsg = 'Available for pickup and delivery orders'

const getFormattedDisplayAvailability = (
  displayAvailability: AvailabilityEntry | undefined,
  timeZoneId?: string
): ReactNode | undefined => {
  if (!displayAvailability) {
    return undefined
  }

  return renderNodes([
    formatDOWs(displayAvailability?.availableDaysOfWeek),
    formatIntervals(
      displayAvailability?.availableDates ?? [],
      timeZoneId ?? undefined
    )
  ])
}

const equals = (entry1: AvailabilityEntry, entry2: AvailabilityEntry) => {
  if (entry1.availableDaysOfWeek.length !== entry2.availableDaysOfWeek.length) {
    return false
  }

  const dowSet = new Set(entry1.availableDaysOfWeek)
  if (!entry2.availableDaysOfWeek.every((d) => dowSet.has(d))) {
    return false
  }

  if (entry1.availableDates.length !== entry2.availableDates.length) {
    return false
  }

  const dateSet = new Set(entry2.availableDates)
  return entry2.availableDates.every((d) => dateSet.has(d))
}

export const SpecialMenuFulfillmentDisplay = ({
  testId
}: {
  testId: string
}) => {
  const { displayName } = useDiningOptions()

  return (
    <>
      {displayName && (
        <div
          className={'bg-gray-0 text-center p-4'}
          data-testid={`specialMenuFulfillmentDisplay-${testId}`}
        >
          <div className={'type-headline-5 text-default font-semibold'}>
            {displayName}
          </div>
          <div>
            <SpecialMenuAvailability className={'text-secondary mt-2'} />
          </div>
        </div>
      )}
    </>
  )
}

export const SpecialMenuAvailability = ({
  className
}: {
  className: string
}) => {
  const { displayAvailability, displayName, data } = useDiningOptions()
  const { restaurantInfo } = useRestaurant()

  const takeout = displayAvailability && displayAvailability['TAKE_OUT']
  const formattedTakeout = getFormattedDisplayAvailability(
    takeout,
    restaurantInfo?.timeZoneId ?? undefined
  )

  const delivery = displayAvailability && displayAvailability['DELIVERY']
  const formattedDelivery = getFormattedDisplayAvailability(
    delivery,
    restaurantInfo?.timeZoneId ?? undefined
  )

  const displayBoth = takeout && delivery
  const displayBehaviorsTogether = displayBoth && equals(takeout, delivery)

  let availabilities: { prefix: string; node: ReactNode }[] = []
  if (displayBehaviorsTogether) {
    availabilities = [{ prefix: bothMsg, node: formattedDelivery }]
  } else if (displayBoth) {
    availabilities = [
      { prefix: pickupMsg, node: formattedTakeout },
      { prefix: deliveryPartialMsg, node: formattedDelivery }
    ]
  } else if (formattedTakeout) {
    availabilities = [{ prefix: pickupMsg, node: formattedTakeout }]
  } else if (formattedDelivery) {
    availabilities = [{ prefix: deliveryMsg, node: formattedDelivery }]
  }

  return (
    <>
      {availabilities.length > 0 ? (
        <p className={className}>
          {availabilities.map(({ prefix, node }) => (
            <span key={`availability-${prefix}`}>
              {prefix}
              {node}
            </span>
          ))}
          .
        </p>
      ) : (
        <>
          {!data?.length && (
            <p
              className={className}
            >{`Currently not accepting orders for ${displayName}. Have questions? Contact us!`}</p>
          )}
        </>
      )}
    </>
  )
}
