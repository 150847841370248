import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { Button, ButtonVariant } from '@local/do-secundo-button'
import React from 'react'
import { useCart } from '@local/do-secundo-cart-provider/src'

export const SharedCartWelcomeModal = ({
  onClose
}: {
  onClose: () => void
}) => {
  const { initialCartMetadata } = useCart()

  const name = initialCartMetadata?.metadata?.name
  if (!name) {
    return null
  }

  const description = initialCartMetadata?.metadata?.description

  return (
    <Modal isOpen onRequestClose={onClose} size={'xl'} hideCloseIcon>
      <ModalHeader>
        <div className={'text-center w-full'}>{name}</div>
      </ModalHeader>
      {description && <div className={'text-center w-full'}>{description}</div>}
      <ModalBody>
        <div className={'pt-4 pb-1'}>
          <Button variant={ButtonVariant.PRIMARY} onClick={onClose}>
            Add items
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
