import {
  RestaurantConfig,
  Location,
  ApplicableConfigs,
  ApplicableConfigsRequest
} from '../types/config'
import { MenuFulfillmentResponse } from '../types/fulfillment'
import { domain, get, getQueryParams, post } from './util'

export const getRestaurantConfig = (): Promise<RestaurantConfig> => {
  return get(`https://${domain}/catering/v1/public/restaurant`).then(
    (response) => response.json()
  )
}

export const getApplicableConfigs = (
  request: ApplicableConfigsRequest
): Promise<ApplicableConfigs> => {
  return post(
    `https://${domain}/catering/v1/public/restaurant/configs`,
    request
  ).then((response) => response.json())
}

export const getFulfillmentTimes = (
  startDate: string,
  endDate: string,
  fastLinkName: string | undefined
): Promise<MenuFulfillmentResponse> => {
  const params = {
    fastLinkName: fastLinkName,
    startDate: startDate,
    endDate: endDate
  }

  const path = `https://${domain}/catering/v2/public/restaurant/fulfillmentTimes${getQueryParams(
    params
  )}`

  return get(path)
    .then((response) => response.json())
    .catch((error) => {
      if (fastLinkName && error.status === 404) {
        return {
          fastLinkNotFound: true
        }
      }

      throw error
    })
}

export const getRestaurantGroupLocations = (params: {
  latitude?: number
  longitude?: number
  radius?: number
}): Promise<{
  locations: Location[]
}> => {
  return get(
    `https://${domain}/catering/v1/public/locations${getQueryParams(params)}`
  ).then((response) => response.json())
}

export const getReplateLink = (
  restaurantGuid: string,
  orderGuid: string
): string => {
  return `https://${domain}/catering/v1/public/restaurant/replate?rxGuid=${restaurantGuid}&orderGuid=${orderGuid}`
}
