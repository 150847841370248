import { useMemo } from 'react'

import {
  ApprovalRuleType,
  Cart,
  CartOrderApprovalRuleResult
} from '../../../types/cart'

const IMPLEMENTED_RULE_TYPES = [
  ApprovalRuleType.ORDER_LT,
  ApprovalRuleType.ORDER_GT
]

/**
 * This hook uses the results of the server-side evaluation of the configured approval
 * rules to determine whether they are pertinent given the current client-side state
 * of the cart/UI.
 */
export const useApprovalRulesValidator = ({ cart }: { cart?: Cart }) => {
  const approvalRules = useMemo(() => {
    return cart?.approvalRuleResults || []
  }, [cart])

  const { ruleMap, valid } = useMemo(() => {
    const ruleMap = approvalRules.reduce((acc, rule) => {
      const { ruleType } = rule
      const checkApplicable = IMPLEMENTED_RULE_TYPES.includes(ruleType)

      if (!checkApplicable) {
        return acc
      }

      return {
        ...acc,
        [ruleType]: { ...rule }
      }
    }, {} as Record<ApprovalRuleType, CartOrderApprovalRuleResult>)

    const valid = Object.keys(ruleMap).length === 0
    return { ruleMap, valid }
  }, [approvalRules, cart])

  return {
    ruleMap,
    valid
  }
}
