import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from '@apollo/client/react/components'
import { adopt } from 'react-adopt'

import { CREDIT_CARDS, DELETE_CARD, MAKE_PRIMARY } from './CreditCard.graphql'

const CreditCardContext = React.createContext({})

// eslint-disable-next-line react/prop-types
const getCardMutation =
  (props) =>
  ({ render }) =>
    (
      <Mutation
        {...props}
        refetchQueries={[{ query: CREDIT_CARDS }]}
        awaitRefetchQueries
      >
        {(mutate, result) => render({ mutate, result })}
      </Mutation>
    )

const CreditCardContainer = adopt({
  deleteCard: getCardMutation({ mutation: DELETE_CARD }),
  makePrimary: getCardMutation({ mutation: MAKE_PRIMARY })
})

export const CreditCardProvider = ({ children }) => {
  return (
    <CreditCardContainer>
      {({ deleteCard, makePrimary }) => {
        const context = {
          deleteCard,
          makePrimary
        }
        return (
          <CreditCardContext.Provider value={context}>
            {children}
          </CreditCardContext.Provider>
        )
      }}
    </CreditCardContainer>
  )
}

CreditCardProvider.propTypes = {
  children: PropTypes.node
}

export const CreditCardConsumer = ({ children }) => {
  return (
    <CreditCardContext.Consumer>
      {(context) => children(context)}
    </CreditCardContext.Consumer>
  )
}

CreditCardConsumer.propTypes = {
  children: PropTypes.func
}

export const useCreditCard = () => React.useContext(CreditCardContext)
