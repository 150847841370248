import React from 'react'
import TwitterImg from '../../../assets/twitter.svg'
import FacebookImg from '../../../assets/facebook.svg'
import { getTwitterRestaurantReference } from '../../../utils/social-utils'
import styles from './SocialBanner.module.css'
import { SocialMediaLinks } from '../../../types/config'

interface Props {
  socialMediaLinks: SocialMediaLinks
  whiteLabelName: string
}

export const SocialBanner = ({
  socialMediaLinks: { twitterUser },
  whiteLabelName
}: Props) => {
  const ooLink = window.location.href.slice(
    0,
    window.location.href.indexOf('/confirm')
  )
  const message = `Every order makes a difference. Order from ${getTwitterRestaurantReference(
    twitterUser,
    whiteLabelName
  )} to show your support today.`
  const twitterShareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    ooLink
  )}&text=${encodeURIComponent(message)}`
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${ooLink}`

  return (
    <div className={styles.banner}>
      <div className='mb-4 font-bold text-center type-headline-5 text-default'>
        Share your support
      </div>
      <p className='text-center type-subhead'>
        Every order makes a difference. Share and encourage others to support
        our restaurant.
      </p>
      <br />
      <div className={styles.linksContainer}>
        <a
          href={facebookShareLink}
          rel='noopener noreferrer'
          target='_blank'
          data-testid='social-share-facebook'
          className='facebookButton'
          title='Share on Facebook'
          aria-label='Share on Facebook'
        >
          <FacebookImg className={styles.socialMediaImg} />
        </a>
        <a
          href={twitterShareLink}
          rel='noopener noreferrer'
          target='_blank'
          data-testid='social-share-twitter'
          className='twitterButton'
          title='Share on Twitter'
          aria-label='Share on Twitter'
        >
          <TwitterImg className={styles.socialMediaImg} />
        </a>
      </div>
    </div>
  )
}
