import React from 'react'
import PropTypes from 'prop-types'

import styles from './ErrorMessage.module.css'

import { ErrorMessage as FormikErrorMessage } from 'formik'

const Error = ({ children }) => (
  <p data-testid='error-message-required' className={styles.error}>
    {children}
  </p>
)

Error.propTypes = {
  children: PropTypes.node.isRequired
}

export const ErrorMessage = (props) => (
  <FormikErrorMessage component={Error} {...props} />
)

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired
}
