import React from 'react'
import cx from 'classnames'

export const LegalCopy = ({ className }: { className?: string }) => {
  return (
    <p
      className={cx('type-caption mx-1 mt-5', className)}
      data-testid='legal-links'
      role='group'
      aria-label='legal information'
    >
      By completing your order you agree to the Toast{' '}
      <a
        href={'https://pos.toasttab.com/terms-of-service'}
        rel='noopener noreferrer'
        target='_blank'
        data-testid='link-terms-of-service'
        className='type-caption'
      >
        Guest Terms of Service
      </a>{' '}
      and acknowledge that you have read{' '}
      <a
        href={'https://pos.toasttab.com/privacy'}
        rel='noopener noreferrer'
        target='_blank'
        data-testid='link-privacy'
        className='type-caption'
      >
        Toast’s Privacy Statement
      </a>
      . Additional info for CA residents available{' '}
      <a
        href={'https://pos.toasttab.com/privacy#addendum-a'}
        rel='noopener noreferrer'
        target='_blank'
        data-testid='link-ca-addendum'
        className='type-caption'
      >
        here
      </a>
      . The contact information shared above may be used by Toast and this
      restaurant or restaurant group to send you digital receipts, order
      updates, and marketing materials.
    </p>
  )
}
