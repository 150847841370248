import React, { ReactNode, useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { useLocation } from 'react-router-dom'

export const RumRoute = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  useEffect(() => {
    datadogRum.startView('/catering/?' + location.pathname)
  }, [location.pathname])
  return <>{children}</>
}
