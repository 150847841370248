import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import { MenuItemPrice } from '@local/do-secundo-menu-item-price'
import { MenuItemImage } from './MenuItemImage/MenuItemImage'
import styles from './MenuItems.module.css'
import { CooMenuItem } from '../../../types/menu'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'

interface Props {
  items: CooMenuItem[]
  itemGroupGuid: string
  menuDisabled: boolean
  orderingAvailable: boolean
  fulfillmentSelected: boolean
}

const MenuItems: React.FC<Props> = ({
  items,
  itemGroupGuid,
  menuDisabled,
  orderingAvailable,
  fulfillmentSelected
}) => {
  const { getRestaurantPath } = useRestaurant()

  return (
    <ul data-testid='menu-items' className={styles.menuItems}>
      {items.map((item) => {
        const { name, guid, price, outOfStock, description, unitOfMeasure } =
          item
        const imageUrl = item.imageLink
        const menuItemInner = (
          <div data-testid='menu-item-content' className={styles.content}>
            <span
              data-testid='menu-item-name'
              className={cx(styles.name, 'font-semibold type-default')}
              itemProp={'name'}
            >
              {name}
            </span>
            <p
              data-testid='menu-item-description'
              className={cx(styles.description, 'type-subhead')}
              itemProp={'description'}
            >
              {description}
            </p>
            <div className='space-x-2'>
              {price !== undefined && price > 0 && (
                <MenuItemPrice
                  price={price}
                  usesFractionalQuantity={false}
                  unitOfMeasure={unitOfMeasure}
                  shouldRenderSchema={true}
                  disabled={menuDisabled || outOfStock}
                />
              )}
              {outOfStock && (
                <span
                  data-testid='menu-item-out-of-stock-label'
                  className={styles.outOfStockLabel}
                >
                  –Out of stock–
                </span>
              )}
            </div>
          </div>
        )
        return (
          <li
            data-testid='menu-item'
            key={`${guid}${itemGroupGuid || ''}`}
            className={cx(styles.menuItem, 'type-default', {
              [styles.outOfStock || '']: outOfStock || menuDisabled
            })}
          >
            <Link
              data-testid='menu-item-link'
              className={styles.menuItemLink}
              to={{
                pathname: getRestaurantPath(
                  orderingAvailable && !fulfillmentSelected
                    ? ''
                    : `items/add/${guid}/${itemGroupGuid}`
                ),
                search:
                  orderingAvailable && !fulfillmentSelected
                    ? '?mode=fulfillment'
                    : undefined
              }}
              itemProp='hasMenuItem'
              itemType={'https://schema.org/MenuItem'}
            >
              {imageUrl && <meta itemProp='image' content={imageUrl} />}
              <MenuItemImage
                imageUrl={imageUrl}
                disabled={outOfStock || menuDisabled}
              />
              {menuItemInner}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export { MenuItems }
