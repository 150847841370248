import { DiningOptionBehavior } from './cart'
import { LoyaltyVendor } from './loyalty'
import { DeliveryInfo } from './orders'

export interface RestaurantConfig {
  onlineOrderingConfig: OnlineOrderingConfig
  restaurantInfo: RestaurantInfo
}

export interface ApplicableConfigsRequest {
  behavior?: DiningOptionBehavior
  promiseDate?: string
  name?: string
  deliveryInfo?: DeliveryInfo
}

export interface ApplicableConfigs {
  configItems: PublicConfigItem[]
}

interface PublicConfigItem {
  type: ConfigItemType
  value: any
}

export enum ConfigItemType {
  BRANDING = 'BRANDING',
  SPECIAL_FULFILLMENT_INSTRUCTIONS = 'SPECIAL_FULFILLMENT_INSTRUCTIONS',
  CUSTOM_LOCATIONS = 'CUSTOM_LOCATIONS',
  SPOTLIGHT = 'SPOTLIGHT'
}

export interface BrandingConfig {
  primaryColor?: string
  backgroundColor?: string
  textColor?: string
  fontFamily?: { name: string; url?: string } | string
  headerFontFamily?: { name: string; url?: string } | string
  logo?: string
  bannerImage?: Image
  favicon?: string
  restaurantName?: string
  locationName?: string
  phone?: string
  removeCateringName?: boolean
}

export interface SpecialInstructionsConfig {
  instructions: string
}

export interface CustomLocationsConfig {
  locations: CustomLocation[]
  standardDelivery: boolean
  standardTakeout: boolean
}

export interface CustomLocation {
  address: Address | undefined
  behavior: DiningOptionBehavior
  name: string
}

export interface SpotlightConfig {
  enabled: boolean
  headerText?: string
  bodyText?: string
  url?: string
  modalOpenByDefault?: boolean
}

interface Image {
  src: string
  fit?: string
  link?: string
  altText?: string
  modifications?: any
  displaySrc?: string
}

export interface Location {
  restaurantGuid: string
  restaurantName: string
  locationName?: string
  address: Address
  deliveryEnabled: boolean
  inRangeForDelivery: boolean
  deliveryOrderType?: string
  takeoutEnabled: boolean
  takeoutOrderType?: string
  distanceMiles?: number
  shortUrl: string
}

export interface Address {
  address1: string
  address2?: string
  city: string
  state: string
  country: string
  zip: string
  zipCode?: string
  latitude?: number
  longitude?: number
  phone?: string
}

export interface RestaurantInfo {
  imagePath?: string
  bannerImagePath?: string
  name: string
  locationName?: string
  address: Address
  timeZoneId: string
  socialMediaLinks: SocialMediaLinks
  amexAccepted: Boolean
  description?: string
  managementSetGuid: string
}

export interface SocialMediaLinks {
  facebookUrl?: string
  twitterUser?: string
  instagramHandle?: string
}

export interface OnlineOrderingConfig {
  guid: string
  restaurantGuid: string
  createdDate: string
  modifiedDate: string
  enabled: boolean
  takeoutDiningOption?: string
  deliveryDiningOption?: string
  scheduledOrderMaxDays?: number
  deliveryEnabled: boolean
  takeoutEnabled: boolean
  itemQuantityRules: ItemQuantityRuleView[]
  taxExemptEnabled: boolean
  promoCodesEnabled: boolean
  payLaterEnabled: boolean
  minRuleAtCartLevel: boolean
  loyaltyVendor: LoyaltyVendor
  allowSpecialRequests: boolean
  specialRequestsMessage: string
}

export interface ScheduleInterval {
  start: string
  end: string
}

export enum DaysOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDESNDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export interface ItemQuantityRuleView {
  guid: string
  restaurantGuid: string
  name: string
  itemGuid: string
  range: QuantityRange
  applicableBehaviors: DiningOptionBehavior[]
}

export interface QuantityRange {
  minimum?: number
  maximum?: number
}
