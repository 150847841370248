import { GET_ACTIVE_MENU } from './menus-client.graphql'

const setActiveMenu = (cache, id) =>
  cache.writeQuery({ query: GET_ACTIVE_MENU, data: { activeMenu: id } })

export const menuResolvers = {
  MenusResponse: {
    menus: ({ menus }, _, context) => {
      const cache = context.cache
      const { activeMenu } = cache.readQuery({ query: GET_ACTIVE_MENU })
      if (!menus.length) return menus
      if (!activeMenu || !activeMenu.length) {
        setActiveMenu(cache, menus[0].id)
        return menus
      }
      const foundMenu =
        menus.find((menu) => activeMenu.includes(menu.id)) || menus[0]
      setActiveMenu(cache, foundMenu.id)
      return menus
    }
  },
  Menu: {
    isActive: ({ id }, _, { cache }) => {
      const { activeMenu } = cache.readQuery({ query: GET_ACTIVE_MENU })
      return activeMenu.includes(id)
    },
    groups: ({ id, groups }) => {
      return groups.map((group) => {
        group.id = `d${id}d${group.guid}`
        return group
      })
    }
  },
  Mutation: {
    setActiveMenu: (_, { id }, { cache }) => {
      cache.writeQuery({ query: GET_ACTIVE_MENU, data: { activeMenu: id } })
      return { id, __typename: 'ActiveMenu' }
    }
  }
}
