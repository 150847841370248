import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

export const Anchor = ({
  allowOpener = false,
  allowFollow = false,
  rel,
  ...props
}) => {
  const relProp = useMemo(() => {
    const relAttributes = new Set(rel ? rel.split(/\s+/) : [])

    if (props.target === '_blank' && !allowOpener) {
      relAttributes.add('noopener')
    }
    if (!allowFollow) {
      relAttributes.add('nofollow')
    }

    return Array.from(relAttributes).join(' ')
  }, [rel, props.target, allowOpener, allowFollow])

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a {...props} rel={relProp} />
  )
}

Anchor.propTypes = {
  sendEvent: PropTypes.func,
  children: PropTypes.node.isRequired,
  // override the safety mechanism that prevents access to window from other tabs
  allowOpener: PropTypes.bool,
  allowFollow: PropTypes.bool,
  rel: PropTypes.string
}
