import React, { useState, useCallback } from 'react'
import { getMessage, getRetryMessage, getError } from './error-utils'
import { Notification } from '@local/do-secundo-notification'
import { Button } from '@local/do-secundo-button'
import { Modal } from '@local/do-secundo-modal'
import styles from './Error.module.css'

const DEFAULT_ACTION_CONTENT = 'Click here to retry'

interface Props {
  error?: any
  retry?: () => void
  isActionable: boolean
}

export const ErrorComponent: React.FC<Props> = ({
  error,
  retry,
  isActionable,
  ...props
}) => {
  const [isShowingMoreInfo, setShowMoreInfo] = useState(false)
  isActionable = Boolean(retry || isActionable)
  const showMoreInfo = useCallback(() => {
    setShowMoreInfo(true)
  }, [])
  const hideMoreInfo = useCallback(() => {
    setShowMoreInfo(false)
  }, [])
  const errObj = getError(error)
  return (
    <>
      <div className='p-4'>
        <Notification
          severity='error'
          isActionable={isActionable}
          actionContent={DEFAULT_ACTION_CONTENT}
          onAction={retry}
          className='mb-0'
          {...props}
        >
          <span data-testid='error-message'>
            {getMessage(errObj)}
            {errObj.moreInfo && (
              <div className='mt-2'>
                <Button
                  onClick={showMoreInfo}
                  data-testid='error-more-info-button'
                >
                  Learn more
                </Button>
              </div>
            )}
            {!isActionable && getRetryMessage(errObj) && (
              <div className='mt-2'>{getRetryMessage(errObj)}</div>
            )}
          </span>
        </Notification>
      </div>
      {isShowingMoreInfo && errObj.moreInfo && (
        <Modal responsive={false} onClose={hideMoreInfo}>
          <div className={styles.modal}>
            <h1
              className={styles.header}
              data-testid='error-more-info-modal-header'
            >
              {errObj.moreInfo.header}
            </h1>
            <p
              className={styles.text}
              data-testid='error-more-info-modal-message'
            >
              {errObj.moreInfo.message}
            </p>
          </div>
        </Modal>
      )}
    </>
  )
}
