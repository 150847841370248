import * as Yup from 'yup'
import { Modifier, ModifierGroup } from '@local/do-secundo-model'
import {
  DetailsByItemGuid,
  ModifierFormValueTypes,
  ModifierGroupValue,
  ModifierValue
} from './types'
import { QuantityRange } from '../../../../client/types/config'
import { getPriceToRender } from '@local/do-secundo-modifier-price-helpers'

export const inputTypes: {
  [key: string]: {
    APIToFormValue: (modifiers: Modifier[]) => ModifierValue[] | string
    getSchema: (modifierGroup: ModifierGroup) => Yup.BaseSchema
    formValueToApi: (
      value: ModifierValue[] | string,
      itemGroupGuid: string,
      quantity: number
    ) => {
      itemGuid: string
      itemGroupGuid: string
      quantity: number
    }[]
    defaultInitialValue?: string
  }
} = {}

export const getQuantity = ({ quantity, selected }: ModifierValue): number =>
  quantity !== undefined ? quantity : Number(selected || 0) || 0

export const isSelected = (modifier: { quantity: number }) =>
  modifier.quantity > 0

/**
 * Input type that supports modifier quantities
 */
inputTypes.modifierBoxInput = {
  APIToFormValue: (modifiers) => {
    return modifiers.map((o) => {
      return {
        itemGuid: o.guid,
        quantity: o.quantity,
        selected: isSelected(o)
      }
    })
  },
  getSchema: (modifierGroup) => {
    const { minSelections, maxSelections } = modifierGroup
    const valueSchema = Yup.array()
      .of(
        Yup.object().shape({
          quantity: Yup.number()
        })
      )
      .test(
        'minMaxSelected',
        'missing min/max required selections',
        (values) => {
          // need to do this bc of the duplicate mods so that we can programatically add up quantity to see if they meet our min/max selections allowed
          const totalSelections =
            values?.reduce(
              (acc, modifier) => acc + (modifier?.quantity ?? 0),
              0
            ) ?? 0
          if (maxSelections && minSelections) {
            return (
              totalSelections >= minSelections &&
              totalSelections <= maxSelections
            )
          } else if (minSelections && !maxSelections) {
            return totalSelections >= minSelections
          } else if (maxSelections && !minSelections) {
            return totalSelections <= maxSelections
          }
          return true
        }
      )

    const schema = Yup.object().shape({
      value: valueSchema
    })

    return schema
  },
  //@ts-ignore - The below code works fine, I'm stumped by the ts error
  formValueToApi: (value, itemGroupGuid, quantity) => {
    if (!Array.isArray(value)) {
      throw Error('Unexpected type for box input type')
    }
    //@ts-ignore
    return value.reduce((acc, { itemGuid, quantity: modifierQuantity }) => {
      const newItem = {
        itemGuid,
        itemGroupGuid,
        quantity
      }
      const hasDuplicateModifiers = modifierQuantity > 0

      if (hasDuplicateModifiers) {
        const valueForApi = Array.from(
          { length: modifierQuantity },
          () => newItem
        )
        return [...acc, ...valueForApi]
      }

      return acc
    }, [])
  }
}

inputTypes.radio = {
  APIToFormValue: (modifiers) => {
    const found = modifiers.find((modifier) => isSelected(modifier))
    return found ? found.guid : ''
  },
  getSchema: () =>
    Yup.object().shape({ value: Yup.string().required('Required') }),
  formValueToApi: (itemGuid, itemGroupGuid, quantity) => {
    if (typeof itemGuid !== 'string') {
      throw Error('Unexpected type for radio input type')
    }
    return [
      {
        itemGuid,
        itemGroupGuid,
        quantity
      }
    ]
  }
}

const isSingleSelect = (min, max) => min === 1 && max === 1

export const getNumSelectedModifiers = (modifierGroup: ModifierValue[]) =>
  modifierGroup.reduce((acc, mod) => acc + getQuantity(mod), 0)

export const getNumSelectedModifiersByGroupGuid = (form, groupGuid) =>
  getNumSelectedModifiers(form.values.modifiers[groupGuid].value)

export const maxCharacters = 200

export const modifierFormHelpers = ({
  modifierGroups,
  itemGroupGuid,
  quantity,
  specialInstructions = '',
  itemQuantityRange,
  enforceMinRequirement
}: {
  modifierGroups: ModifierGroup[]
  itemGroupGuid: string
  quantity?: number
  specialInstructions?: string
  itemQuantityRange?: QuantityRange
  enforceMinRequirement?: boolean
}) => {
  const typeByGuid: Record<string, string> = {}

  const getType = (guid: string) => typeByGuid[guid]
  const initTypeByGuid = ({
    guid,
    minSelections,
    maxSelections
  }: ModifierGroup) => {
    if (isSingleSelect(minSelections, maxSelections)) {
      typeByGuid[guid] = 'radio'
    } else {
      typeByGuid[guid] = 'modifierBoxInput'
    }
  }

  const getInitialModifiers = (
    modifierGroups: ModifierGroup[]
  ): Record<string, ModifierGroupValue> => {
    /** While also composing initialValues,
     * recursively loop through modifierGroups
     * to initModifierGroupByGuid */
    return modifierGroups.reduce((acc, group) => {
      initTypeByGuid(group)

      const { guid: groupGuid, modifiers, defaultOptionsChargePrice } = group
      const { APIToFormValue } = inputTypes[getType(groupGuid)!!]!!

      const detailsByItemGuid: DetailsByItemGuid = modifiers.reduce(
        (ac, mod) => {
          const { guid, modifierGroups = [], price, name, isDefault } = mod
          return {
            ...ac,
            [guid]: {
              price,
              name,
              isDefault,
              modifiers: getInitialModifiers(modifierGroups)
            }
          }
        },
        {} as DetailsByItemGuid
      )

      return {
        ...acc,
        [groupGuid]: {
          detailsByItemGuid,
          value: APIToFormValue(modifiers),
          defaultOptionsChargePrice
        }
      }
    }, {})
  }

  // get initial values (deep)
  const initialValues: ModifierFormValueTypes = {
    modifiers: getInitialModifiers(modifierGroups),
    quantity: quantity ?? itemQuantityRange?.minimum ?? 1,
    specialInstructions
  }

  // get schema shape (shallow)
  const schemaShape = modifierGroups.reduce((acc, group) => {
    const { guid: groupGuid } = group
    const { getSchema } = inputTypes[getType(groupGuid)]

    return { ...acc, [groupGuid]: getSchema(group) }
  }, {})

  // add special instructions to schema shape
  let schema = Yup.object().shape({
    modifiers: Yup.object().shape(schemaShape),
    quantity: Yup.number()
      .min(
        enforceMinRequirement && itemQuantityRange?.minimum
          ? itemQuantityRange.minimum
          : 1
      )
      .max(itemQuantityRange?.maximum ?? Infinity),
    specialInstructions: Yup.string().max(
      maxCharacters,
      // eslint-disable-next-line no-template-curly-in-string
      'max ${max} characters'
    )
  })

  // calls corresponding formValueToApi function for radio/checkbox recursively
  const getModifierGroups = (values: ModifierFormValueTypes, quantity = 1) =>
    Object.entries(values.modifiers).map(
      ([guid, { value, detailsByItemGuid, defaultOptionsChargePrice }]) => {
        const { formValueToApi } = inputTypes[getType(guid)]

        const getModifiersParameter = formValueToApi(
          value,
          itemGroupGuid,
          quantity
        )

        const getModifiers = (vals) => {
          return vals.map(({ itemGuid, ...rest }) => {
            const nestedValues = detailsByItemGuid[itemGuid]
            return {
              itemGuid,
              ...rest,
              // nestedValues will be undefined if a radio group's value is not selected (i.e. itemGuid === '')
              modifierGroups: nestedValues
                ? getModifierGroups(nestedValues, quantity)
                : []
            }
          })
        }

        const modifiers = getModifiers(getModifiersParameter)

        return { guid, modifiers, defaultOptionsChargePrice }
      }
    )

  // get calculated price from formik values recursively
  const getComputedPrice = (values: ModifierFormValueTypes) => {
    let res = 0
    getModifierGroups(values).forEach(
      ({ guid: groupGuid, modifiers, defaultOptionsChargePrice }) => {
        modifiers.forEach(({ itemGuid }) => {
          // itemGuid can be an empty string when radio is unselected
          if (itemGuid) {
            const details =
              values.modifiers[groupGuid].detailsByItemGuid[itemGuid]
            const price = getPriceToRender({
              isDefault: details.isDefault,
              price: details.price,
              quantity: values.quantity,
              defaultOptionsChargePrice
            })
            res += (price ?? 0) + getComputedPrice(details)
          }
        })
      }
    )
    return res
  }

  // get an array of names of selected guids from formik values shallowly
  const getSelectedNames = (values: ModifierFormValueTypes): string[] => {
    const res: string[] = []
    getModifierGroups(values).forEach(({ guid: groupGuid, modifiers }) => {
      modifiers.forEach(({ itemGuid }) => {
        // itemGuid can be an empty string when radio is unselected
        if (itemGuid) {
          const details =
            values.modifiers[groupGuid].detailsByItemGuid[itemGuid]
          res.push(details.name)
        }
      })
    })

    return res
  }

  return {
    initialValues,
    schema,
    getModifierGroups,
    getComputedPrice,
    maxCharacters,
    getSelectedNames
  }
}
