import React, { useState, useCallback, ReactElement } from 'react'
import Observer from '@researchgate/react-intersection-observer'

interface Props {
  children: (isIntersecting: boolean) => ReactElement
  root?: string | Element | null
  rootMargin?: string
  threshold?: number | number[]
  disabled?: boolean
  onChange?: (entry: IntersectionObserverEntry, unobserve: () => void) => void
}

export const ViewableMonitor = ({ children, ...rest }: Props) => {
  const [isIntersecting, setIntersecting] = useState(false)
  const handleChange = useCallback(
    ({ isIntersecting }) => setIntersecting(isIntersecting),
    [setIntersecting]
  )

  return (
    <>
      {/* @ts-ignore */}
      <Observer {...rest} onChange={handleChange}>
        <div>{children(isIntersecting)}</div>
      </Observer>
    </>
  )
}
