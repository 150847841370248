import { DiscountEntity } from './orders'

export enum RewardType {
  LOYALTY_POINTS_AMOUNT = 'LOYALTY_POINTS_AMOUNT',
  LOYALTY_DOLLARS_AMOUNTS = 'LOYALTY_DOLLARS_AMOUNTS',
  BIRTHDAY_REWARD = 'BIRTHDAY_REWARD',
  BIRTHDAY_REWARDS = 'BIRTHDAY_REWARDS',
  ITEM_REWARDS = 'ITEM_REWARDS'
}

export interface AvailableRedemption {
  availability: string
  itemId: string
  name: string
  percent: any
  quantity: number
  referenceId: string
  unit: string
  integrationData?: IntegrationData
}

interface IntegrationData {
  amount: number
  selectionType: SelectionType
  appliedDiscountGuid: string
}

export enum SelectionType {
  ITEM = 'ITEM',
  CHECK = 'CHECK',
  MULTI_ITEM = 'MULTI_ITEM' // not supporting for now
}

export interface InquiryResponse {
  availableRedemptions: AvailableRedemption[]
  discounts: DiscountEntity[]
  vendor: LoyaltyVendor
  effectiveConversionRate: number
}

export enum LoyaltyVendor {
  TOAST = 'TOAST',
  NONE = 'NONE',
  INTEGRATION = 'INTEGRATION',
  PAYTRONIX = 'PAYTRONIX',
  PUNCHH = 'PUNCHH',
  PUNCHH2 = 'PUNCHH2',
  APPFRONT = 'APPFRONT',
  MOCK = 'MOCK'
}
