import { CooMenu } from '../types/menu'
import { domain, get, getQueryParams } from './util'
import { DiningOptionBehavior } from '../types/cart'

interface CooMenuResponse {
  menus: CooMenu[]
}

export const getMenus = (
  diningOptionBehavior: DiningOptionBehavior | undefined,
  time: string | undefined,
  fastLinkName: string | undefined
): Promise<CooMenuResponse> => {
  const useDateAndTime = time && diningOptionBehavior

  const params = {
    diningOptionBehavior: useDateAndTime ? diningOptionBehavior : undefined,
    time: useDateAndTime ? time : undefined,
    fastLinkName: fastLinkName
  }

  const path = `https://${domain}/catering/v1/public/menus${getQueryParams(
    params
  )}`

  return get(path).then((response) => response.json())
}
