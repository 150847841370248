import { Button } from '@local/do-secundo-button'
import React, { useState } from 'react'
import { useGetCart } from '../CartQuery/CartQuery'
import { ShareCartModal } from './ShareCartModal'
import TooltipWrapper from '../Tooltip/TooltipWrapper'
import cx from 'classnames'

export const ShareCartButton = () => {
  const { cartIsEmpty } = useGetCart()
  const [showShareCartModal, setShowShareCartModal] = useState(false)

  return (
    <>
      <TooltipWrapper
        enableTooltip={cartIsEmpty}
        message={'Add an item to share cart'}
        placement={'top'}
      >
        <Button
          className={cx({ 'no-underline': cartIsEmpty })}
          disabled={cartIsEmpty}
          data-testid='share-cart-button'
          onClick={() => setShowShareCartModal(true)}
        >
          Share cart
        </Button>
      </TooltipWrapper>
      {showShareCartModal && (
        <ShareCartModal onClose={() => setShowShareCartModal(false)} />
      )}
    </>
  )
}
