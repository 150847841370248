import React from 'react'
import Checkmark from '../../../assets/checkmark.svg'
import styles from './ToastNotification.module.css'

interface Props {
  checkmark?: boolean
}

export const ToastNotification: React.FC<Props> = ({ children, checkmark }) => (
  <span className={styles.toast}>
    {checkmark && <Checkmark className={styles.checkmark} />}
    {children}
  </span>
)
