import forOwn from 'lodash/forOwn'
import find from 'lodash/find'

export class Model {
  constructor(data) {
    forOwn(
      data,
      function (value, key) {
        // Store the initial state for resets
        this[key] = value
      }.bind(this)
    )
  }

  resetDefaults() {
    // noop - implemented by Modifier and ModifierGroup
  }

  /**
   * Walks the tree and removes any of the formik data
   */
  resetSubTreeDefaults() {
    this.resetDefaults()
    this.getNextValues().forEach((model) => {
      model.resetSubTreeDefaults()
    })
  }

  getNext(guid) {
    return find(this.getNextValues(), { guid })
  }

  getNextValues() {
    throw new Error('getNextValues must be overriden in the child class')
  }

  hasChildren() {
    return Boolean(this.getNextValues())
  }
}
