import React from 'react'
import cx from 'classnames'
import styles from './CartFooter.module.css'
import { Cart } from '../../../types/cart'
import { LineItems } from './LineItems'
import { LoyaltyAccrue } from './LoyaltyAccrue'
import { Customer } from '../../CheckoutForm/CustomerInfo/CustomerInfo'

interface Props {
  cart: Cart
  customer?: Customer
  showTotal?: boolean
  showTip?: boolean
  tip?: number
  isCheckoutForm?: boolean
  paymentType?: string
}

export const CartFooter: React.FC<Props> = ({
  cart,
  customer,
  showTotal = false,
  showTip = false,
  tip,
  isCheckoutForm = false,
  paymentType
}) => {
  return (
    <div
      className={cx(styles.footer, 'type-default')}
      data-testid='total-amounts'
      role='group'
      aria-label='cart totals'
    >
      <table>
        <tfoot>
          <LineItems
            cart={cart}
            showTotal={showTotal}
            showTip={showTip}
            tip={tip}
          />
        </tfoot>
      </table>
      {isCheckoutForm && customer && (
        <LoyaltyAccrue
          cart={cart}
          customer={customer}
          paymentType={paymentType}
        />
      )}
    </div>
  )
}
