import React, { useState, useEffect } from 'react'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useDiningOptions } from '@local/do-secundo-use-dining-options'
import { useHasMode } from '../ModeRouter/ModeRouter'
import { useCart } from '@local/do-secundo-cart-provider/src'
import { CartNotFoundModal } from '../ShareCart/CartNotFoundModal'
import { SharedCartWelcomeModal } from '../ShareCart/SharedCartWelcomeModal'
import { FastLinkNotFoundModal } from '../FulfillmentSelectorModal/FastLink/FastLinkNotFoundModal'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useRedirectToFulfillment } from '../../hooks/useRedirectToFullfilment'

export const FulfillmentRedirect: React.FC = () => {
  const { loading: availabilityLoading } = useAvailability()
  const { loading: fulfillmentLoading } = useFulfillment()
  const { isLoading: diningOptionsLoading, fastLinkNotFound } =
    useDiningOptions()
  const {
    applicableConfigs: { spotlightConfig }
  } = useRestaurant()
  const { shouldShowFulfillmentModal, redirectToFulfillment } =
    useRedirectToFulfillment()

  const [sharedCartWelcomeModalOpen, setSharedCartWelcomeModalOpen] =
    useState(false)
  const [fastLinkNotFoundModalOpen, setFastLinkNotFoundModalOpen] =
    useState(false)
  const [cartNotFoundModalOpen, setCartNotFoundModalOpen] = useState(false)
  const [redirectToFulfillmentOnClose, setRedirectToFulfillmentOnClose] =
    useState(false)

  const { initialCartNotFound, initialCartMetadata, setInitialCartNotFound } =
    useCart()

  const loading =
    diningOptionsLoading || availabilityLoading || fulfillmentLoading
  const isFulfillment = useHasMode('fulfillment')

  // Will open modal prior to having fulfillment selected e.g. on first menu load
  useEffect(() => {
    if (!loading) {
      const fulfillmentModalShouldShow =
        shouldShowFulfillmentModal && !spotlightConfig.modalOpenByDefault

      if (initialCartNotFound) {
        setCartNotFoundModalOpen(true)
        setRedirectToFulfillmentOnClose(fulfillmentModalShouldShow)
      } else if (initialCartMetadata?.metadata?.name) {
        setSharedCartWelcomeModalOpen(true)
      } else if (fastLinkNotFound) {
        setFastLinkNotFoundModalOpen(true)
      } else if (fulfillmentModalShouldShow && !isFulfillment) {
        redirectToFulfillment()
      }
    }
  }, [
    loading,
    shouldShowFulfillmentModal,
    initialCartNotFound,
    fastLinkNotFound,
    spotlightConfig
  ])

  return (
    <>
      {cartNotFoundModalOpen && (
        <CartNotFoundModal
          onClose={() => {
            setInitialCartNotFound(false)
            setCartNotFoundModalOpen(false)
            if (redirectToFulfillmentOnClose) {
              redirectToFulfillment()
            }
          }}
        />
      )}
      {sharedCartWelcomeModalOpen && (
        <SharedCartWelcomeModal
          onClose={() => {
            setSharedCartWelcomeModalOpen(false)
          }}
        />
      )}
      {fastLinkNotFoundModalOpen && (
        <FastLinkNotFoundModal
          onClose={() => setFastLinkNotFoundModalOpen(false)}
        />
      )}
    </>
  )
}
