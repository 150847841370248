import { Customer } from '../components/CheckoutForm/CustomerInfo/CustomerInfo'
import { updateCustomer } from './cart'
import * as Sentry from '@sentry/react'
import { useDebounce } from '../hooks/util'
import { Cart } from '../types/cart'
import { useLoyalty } from './loyalty'
import { useCart } from '@local/do-secundo-cart-provider'

export const useCustomer = (cart: Cart, customer: Customer) => {
  const { refetchRewards } = useLoyalty(customer, cart)
  const { setOptedInTo3pAccountLookup, updateCartCache } = useCart()

  const doUpdateCustomer = async (
    update: Customer,
    cart: Cart,
    companyName: string | undefined
  ) => {
    setOptedInTo3pAccountLookup(false)
    await updateCustomer(cart.guid, { ...update, companyName: companyName })
      .then(async (response) => {
        // loyalty discounts could get cleared as a result of changing the customer
        // this can make the customer form a little glitchy so only update the cart if we need to
        if (
          response.order.checks[0].appliedDiscounts.length !==
          cart.order.checks[0].appliedDiscounts.length
        ) {
          updateCartCache(response)
        }
        await refetchRewards()
      })
      .catch((e) =>
        Sentry.withScope((scope) => {
          scope.setLevel('error')
          scope.setExtra('error', `${e}`)
          Sentry.captureMessage('failed to update customer on cart')
        })
      )
  }

  const debouncedCustomerUpdate = useDebounce(doUpdateCustomer, 1000)

  return {
    onChange: (
      customer: Customer,
      cart: Cart,
      companyName: string | undefined
    ) => {
      debouncedCustomerUpdate(customer, cart, companyName)
    }
  }
}
