import { DeliveryInfo } from '../types/orders'
import { domain, post } from './util'

export const getDeliveryAddressValid = (
  restaurantGuid: string,
  request: { address: DeliveryInfo }
): Promise<{ valid: boolean }> => {
  return post(
    `https://${domain}/catering/v1/public/validation/${restaurantGuid}/delivery`,
    request
  ).then((response) => response.json())
}
