import * as React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import { CloseButton } from '@local/do-secundo-closebutton'

import styles from './Notification.module.css'

export interface NotificationProps {
  children: React.ReactNode
  className?: string
  isCloseable?: boolean // If true, will render a close button that will dismiss this notification.
  onClose?(): void // Will be called after this notification is dismissed, if `isCloseable` is true.
  isActionable?: boolean // If true, will render a React Router `Link` or a button (depending on the other props) to serve as a call to action for the user.
  actionContent?: React.ReactNode // The content to render within the action Link or button, if `isActionable` is true. Can be just text.
  actionLink?: string | null // Will cause a React Router `Link` to be rendered with this as the `to` prop, if `isActionable` is true.
  onAction?: () => void // Will cause a button to be rendered with this as the `onClick` prop, if `isActionable` is true. `actionLink` takes priority if both are specified.
  severity?: string // error or warning
}

export const Notification = ({
  children,
  className,
  isCloseable = false,
  onClose = () => {},
  isActionable = false,
  actionContent = null,
  actionLink = null,
  onAction,
  severity = ''
}: NotificationProps) => {
  const [isVisible, toggleVisibility] = React.useState(true)

  const handleClose = React.useCallback(() => {
    toggleVisibility(false)
    onClose()
  }, [onClose])
  const handleAction = React.useCallback(() => {
    if (onAction) {
      onAction()
    }
  }, [onAction])

  return isVisible ? (
    <div
      data-testid='notification'
      className={cx(
        styles.notification,
        styles[severity as keyof {}],
        className
      )}
    >
      <div className={styles.notificationBody}>
        <div
          data-testid='notification-text'
          className={styles.notificationText}
        >
          {children}
        </div>
        {isCloseable && (
          <div className={styles.closeButton}>
            <CloseButton onClick={handleClose} />
          </div>
        )}
      </div>
      {isActionable && (
        <span className={styles.notificationAction}>
          {(actionLink && (
            <Link
              data-testid='notification-action'
              className={styles.action}
              to={actionLink}
            >
              {actionContent}
            </Link>
          )) ||
            (onAction && (
              <button
                data-testid='notification-action'
                className={styles.action}
                onClick={handleAction}
              >
                {actionContent}
              </button>
            ))}
        </span>
      )}
    </div>
  ) : null
}
