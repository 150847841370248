const PUSH_MODIFICATION_ERRORS = 'PUSH_MODIFICATION_ERRORS'
const CLEAR_MODIFICATION_ERRORS = 'CLEAR_MODIFICATION_ERRORS'

type CartActionType =
  | typeof PUSH_MODIFICATION_ERRORS
  | typeof CLEAR_MODIFICATION_ERRORS

export interface ModifiedCartItem {
  name?: string
  guid?: string
  type?: string
}

export interface ModificationError {
  id?: string
  items: ModifiedCartItem[]
  message?: string
}

interface CartAction {
  type: CartActionType
  modificationErrors?: ModificationError[]
}

export {
  PUSH_MODIFICATION_ERRORS,
  CLEAR_MODIFICATION_ERRORS,
  CartAction,
  CartActionType
}
