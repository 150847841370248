import {
  NamespacedStorage,
  namespacedStorage,
  StorageKey
} from './namespaced-storage'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useMemo } from 'react'

export const useRestaurantStorage = (
  storagePlace = localStorage
): NamespacedStorage => {
  const { restaurantGuid } = useRestaurant()

  const restaurantStorage = useMemo(() => {
    const namespace = 'toast-catering-oo-restaurant'
    const storage = namespacedStorage(namespace, storagePlace)
    const makeKey = (key: StorageKey): string[] =>
      typeof key === 'string' ? [restaurantGuid, key] : [restaurantGuid, ...key]

    return {
      get: (key: StorageKey) => storage.get(makeKey(key)),
      set: (key: StorageKey, value: any) => storage.set(makeKey(key), value),
      remove: (key: StorageKey) => storage.remove(makeKey(key))
    }
  }, [restaurantGuid, storagePlace])

  if (!restaurantGuid) throw new Error('Outside of Restaurant Context')
  return restaurantStorage
}
