import React, { useEffect, useMemo, useState } from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Cart } from '../../../types/cart'
import { TailSpin } from 'react-loader-spinner'
import { getAppliedPromoCode, usePromoCode } from './usePromoCode'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { hasAnyCheckDiscounts } from '../../../utils/cart-helpers'

export const PromoCodeInput = ({ cart }: { cart: Cart }) => {
  const { ooConfig } = useRestaurant()
  const {
    applyPromoCode,
    clearPromoCode,
    isLoading,
    errorMessage,
    setErrorMessage,
    showSuccessMessage
  } = usePromoCode(cart.guid)

  const appliedPromoCode = useMemo(
    () => getAppliedPromoCode(cart.order.checks[0]),
    [cart.order.checks]
  )
  const hasAppliedPromoCode = Boolean(appliedPromoCode)

  const [showPromoCode, setShowPromoCode] = useState(hasAppliedPromoCode)
  const [promoCodeInput, setPromoCodeInput] = useState<string>(
    appliedPromoCode ?? ''
  )

  useEffect(() => {
    setPromoCodeInput(appliedPromoCode ?? '')
    setShowPromoCode(hasAppliedPromoCode)
  }, [appliedPromoCode, hasAppliedPromoCode])

  if (!ooConfig?.promoCodesEnabled && !hasAppliedPromoCode) return null

  if (!showPromoCode) {
    return (
      <Button
        testId='add-promo-code-button'
        variant='link'
        className='-ml-4'
        onClick={() => setShowPromoCode(true)}
        disabled={hasAnyCheckDiscounts(cart)}
      >
        Add a promo code
      </Button>
    )
  }

  return (
    <div className='pb-4'>
      <TextInput
        testId='promo-code-text-field'
        label='Promo code'
        value={promoCodeInput}
        onChange={(e) => {
          if (errorMessage) setErrorMessage(undefined)
          setPromoCodeInput(e.target.value)
        }}
        maxLength={100}
        readOnly={hasAppliedPromoCode || isLoading}
        suffix={
          isLoading ? (
            <TailSpin
              wrapperClass='pr-8'
              color='#2b4fb9'
              width='24px'
              height='24px'
            />
          ) : showSuccessMessage ? (
            <div className='pr-4 text-caption text-color-success'>Success!</div>
          ) : hasAppliedPromoCode ? (
            <Button
              testId='remove-promo-code-button'
              variant='link'
              onClick={clearPromoCode}
            >
              Remove
            </Button>
          ) : (
            <Button
              testId='apply-promo-code-button'
              variant='link'
              onClick={() => applyPromoCode(promoCodeInput.trim()!!)}
              disabled={!promoCodeInput.trim()}
            >
              Apply
            </Button>
          )
        }
        suffixVariant='iconButton'
      />
      {errorMessage && (
        <div className='pt-2 text-caption text-color-error'>{errorMessage}</div>
      )}
    </div>
  )
}
