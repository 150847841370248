import React from 'react'
import PropTypes from 'prop-types'

import { OOGlobalsConsumer } from '@local/do-secundo-oo-globals-provider'

import { CreditCardIframe } from './CreditCardIframe'

const getIframeSrc = ({ amexAccepted, toastwebBaseUri, shortUrl }) => {
  const iframeSrc =
    process.env.CREDIT_CARD_IFRAME_URI ||
    `${toastwebBaseUri}/${shortUrl}/v3/ccform?color=%23002182`
  const iframeUrl = new URL(iframeSrc)

  if (amexAccepted) {
    iframeUrl.searchParams.set('amexAccepted', true)
  }

  return iframeUrl.toString()
}

export const CreditCard = ({ amexAccepted, field, form }) => {
  const onChange = (data) => {
    form.setFieldValue(field.name, data)
    form.setFieldTouched(field.name, true)
  }

  return (
    <OOGlobalsConsumer>
      {({ toastwebBaseUri, shortUrl }) => {
        const iframeSrc = getIframeSrc({
          amexAccepted,
          toastwebBaseUri,
          shortUrl
        })

        return (
          <CreditCardIframe
            src={iframeSrc}
            onChange={(data) => onChange(data)}
          />
        )
      }}
    </OOGlobalsConsumer>
  )
}

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired
})

const FormShape = PropTypes.shape({
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired
})

CreditCard.defaultValues = {
  amexAccepted: false
}

CreditCard.propTypes = {
  amexAccepted: PropTypes.bool,
  field: FieldShape.isRequired,
  form: FormShape.isRequired
}
