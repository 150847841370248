import React from 'react'
import cx from 'classnames'
import { LazyLoad } from '../../../LazyLoad/LazyLoad'
import styles from './MenuItemImage.module.css'

interface Props {
  imageUrl?: string
  disabled?: boolean
}

export const MenuItemImage = ({ imageUrl, disabled }: Props) => {
  if (!imageUrl) return <div className={styles.spacer} />

  return (
    <LazyLoad rootMargin={'0px 0px 100% 0px'}>
      {(loaded) => (
        <div
          data-testid='menu-item-image'
          style={{ backgroundImage: loaded && `url(${imageUrl})` }}
          className={cx(styles.image, { [styles.outOfStock]: disabled })}
        />
      )}
    </LazyLoad>
  )
}
