import React, { ReactNode } from 'react'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { CartButton } from '@local/do-secundo-cart-button'
import { Dollars } from '@local/do-secundo-dollars'
import { useNestedModifiers } from '../NestedModifiersProvider/NestedModifiersProvider'
import { useAvailability } from '@local/do-secundo-availability-provider'
import styles from './ModifiersFooter.module.css'
import { useFormikContext } from 'formik'
import { useFulfillment } from '../../../../client/components/FulfillmentProvider/FulfillmentProvider'
import { QuantityRange } from '../../../../client/types/config'
import { Alert } from '@toasttab/buffet-pui-alerts'

interface Props {
  getDisplayPrice: (values: any) => number | undefined
  onClose: () => void
  isEditModal: boolean
  quantityRange?: QuantityRange
  itemName: string
  quantityInputField: ReactNode
  validationSchema: any
}

export const ModifiersFooter: React.FC<Props> = ({
  getDisplayPrice,
  onClose,
  isEditModal = false,
  quantityRange,
  itemName,
  quantityInputField,
  validationSchema
}: Props) => {
  const {
    nestedModifier,
    nestedModifierIsValid,
    confirmNestedModifierSelections,
    modalLoading
  } = useNestedModifiers()
  // default to an object in case component is called outside of the formik context
  // allows us to hit a safety check on line 68
  const { values, isSubmitting } = useFormikContext() || {}
  const { loading: availabilityLoading, orderingAvailable } = useAvailability()
  const { selected } = useFulfillment()

  if (nestedModifier) {
    return (
      <Button
        type={ButtonType.BUTTON}
        variant={ButtonVariant.PRIMARY}
        disabled={!nestedModifierIsValid}
        onClick={confirmNestedModifierSelections}
        data-testid='nested-modifier-add-button'
        className='rounded-none md:rounded-b-2xl'
      >
        Continue
      </Button>
    )
  }

  if (!orderingAvailable || !selected) {
    return (
      <Button
        data-testid='close-modifiers-button'
        variant={ButtonVariant.PRIMARY}
        onClick={onClose}
        className='rounded-none md:rounded-b-2xl'
      >
        Close
      </Button>
    )
  }

  if (!values) {
    return null
  }

  const isLoading = isSubmitting || modalLoading || availabilityLoading
  const displayPrice = getDisplayPrice(values)
  const isPriceValid = displayPrice !== undefined && displayPrice >= 0

  const buttonText = isEditModal ? 'Update cart' : 'Add to cart'

  const min = quantityRange?.minimum
  const max = quantityRange?.maximum
  const minMaxText =
    min || max
      ? `'${itemName}' has a ${
          min && max && min === max
            ? `required quantity of ${min}`
            : min && max
            ? `min (${min}) and max (${max})`
            : min
            ? `min of ${min}`
            : `max of ${max}`
        } per order.`
      : undefined

  return (
    <div>
      <div className={styles.wrapper}>
        {quantityInputField}
        <div className={styles.buttonWrapper}>
          <CartButton
            data-testid='add-to-cart-button'
            type='submit'
            loading={isLoading}
            disabled={!isPriceValid || !validationSchema.isValidSync(values)}
            left={<span>{buttonText}</span>}
            right={isPriceValid && <Dollars amount={displayPrice} />}
          />
        </div>
      </div>
      {minMaxText && (
        <div className='px-2 pb-2 text-left'>
          <Alert className={'w-full flex'} showIcon={true}>
            {minMaxText}
          </Alert>
        </div>
      )}
    </div>
  )
}
