import React, { useCallback } from 'react'
import cx from 'classnames'

import PlusIcon from './PlusIcon.svg'
import MinusIcon from './MinusIcon.svg'

import styles from './Stepper.module.css'
import { NumberInput } from '@toasttab/buffet-pui-text-input'
import { NumberFormatValues } from 'react-number-format'

interface Props {
  onChange: (newValue: number | undefined) => void
  value: number
  incrementDisabled?: boolean
  decrementDisabled?: boolean
  modifierQuantities?: boolean
  dataTestId?: string
}

export const Stepper: React.FC<Props> = ({
  onChange,
  value,
  incrementDisabled,
  decrementDisabled,
  modifierQuantities,
  dataTestId
}) => {
  const { modQty = '', stepper, button, modQtyIcon = '', quantity } = styles

  const onDecrement = useCallback(() => onChange(value - 1), [value, onChange])
  const onIncrement = useCallback(() => onChange(value + 1), [value, onChange])
  const onValueChange = useCallback(
    (values: NumberFormatValues) => {
      onChange(values.floatValue)
    },
    [onChange]
  )

  return (
    <div
      data-testid={`${dataTestId}-stepper`}
      className={cx(stepper, { [modQty]: modifierQuantities })}
    >
      <button
        className={button}
        disabled={decrementDisabled}
        type='button'
        onClick={onDecrement}
        data-testid='decrement'
        aria-label='Decrease quantity'
      >
        <MinusIcon className={cx({ [modQtyIcon]: modifierQuantities })} />
      </button>
      {modifierQuantities ? (
        <span data-testid='item-quantity' className={quantity}>
          {value}
        </span>
      ) : (
        <NumberInput
          containerClassName='mx-2 w-12'
          className='text-center'
          data-testid='item-quantity'
          value={value}
          decimalScale={0}
          maxLength={3}
          onChange={onValueChange}
        />
      )}
      <button
        disabled={incrementDisabled}
        className={button}
        type='button'
        onClick={onIncrement}
        data-testid='increment'
        aria-label='Increase quantity'
      >
        <PlusIcon className={cx({ [modQtyIcon]: modifierQuantities })} />
      </button>
    </div>
  )
}
