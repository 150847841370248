import { ItemSize } from '@local/do-secundo-pricingutility'
import * as React from 'react'
const { createContext, useContext, useState } = React

const ItemContext = createContext<{
  itemSize?: ItemSize
  setItemSize: (size: ItemSize) => void
  //@ts-ignore
}>({})

export const ItemProvider: React.FC = ({ children }) => {
  const [itemSize, setItemSize] = useState<ItemSize>()

  return (
    <ItemContext.Provider value={{ itemSize, setItemSize }}>
      {children}
    </ItemContext.Provider>
  )
}

export const useItem = () => {
  return useContext(ItemContext)
}
