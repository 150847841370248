import { Cart } from '../../../types/cart'
import { useCart } from '@local/do-secundo-cart-provider/src'
import { useDebounce } from '../../../hooks/util'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { setTaxExempt } from '../../../api/cart'

export const useTaxExempt = (cartGuid: string) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const debouncedReset = useDebounce(() => setShowSuccessMessage(false), 2000)

  const { updateCartCache } = useCart()

  const mutationOptions = {
    onMutate: () => {
      setErrorMessage(undefined)
    },
    onSuccess: async (cartResponse: Cart) => {
      updateCartCache(cartResponse)
    },
    onError: async (error: Response) => {
      setErrorMessage((await error.json()).message)
    }
  }

  const applyTaxExemptMutation = useMutation(
    (request: { taxExemptId?: string; taxExemptState?: string }) =>
      setTaxExempt(cartGuid, request),
    {
      ...mutationOptions,
      onSuccess: async (cartResponse: Cart) => {
        mutationOptions.onSuccess(cartResponse)
        setShowSuccessMessage(true)
        debouncedReset()
      }
    }
  )

  const applyTaxExempt = (request: {
    taxExemptId?: string
    taxExemptState?: string
  }) => {
    applyTaxExemptMutation.mutate(request)
  }

  return {
    errorMessage,
    showSuccessMessage,
    isLoading: applyTaxExemptMutation.isLoading,
    applyTaxExempt
  }
}
