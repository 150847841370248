import React from 'react'
import { MenuItem } from '@local/do-secundo-modifiers'
import {
  ModifierFormValueTypes,
  maxCharacters
} from '@local/do-secundo-modifier-form-helpers'
import { ModifierGroup } from '../ModifierGroup/ModifierGroup'
import { MenuItemDetails, Modifier } from '@local/do-secundo-model'
import { SpecialInstructions } from './SpecialInstructions'

interface Props {
  itemDetails: MenuItemDetails | Modifier
  isNested?: boolean
  specialRequestsConfig?: { enabled?: boolean; placeholderMessage?: string }
  deleteButton?: React.ReactNode
  quantityInputField?: React.ReactNode
  onRemoveModifier?: () => void
  getSelectedNames: (values: ModifierFormValueTypes) => string[]
  disabled?: boolean
}

export const ModifierBody: React.FC<Props> = ({
  itemDetails,
  isNested = false,
  specialRequestsConfig = { enabled: false },
  deleteButton = null,
  quantityInputField = null,
  onRemoveModifier,
  getSelectedNames = () => [],
  disabled = false
}) => {
  return (
    <>
      <MenuItem
        nestedModifierName={isNested ? itemDetails.name : undefined}
        calories={(itemDetails as MenuItemDetails).calories}
        description={(itemDetails as MenuItemDetails).description}
        onRemoveModifier={onRemoveModifier}
      />
      {itemDetails.modifierGroups.map((modifierGroup) => (
        <ModifierGroup
          key={modifierGroup.guid}
          modifierGroup={modifierGroup}
          getSelectedNames={getSelectedNames}
          defaultOptionsChargePrice={modifierGroup.defaultOptionsChargePrice}
          readOnly={disabled}
        />
      ))}
      {specialRequestsConfig.enabled && (
        <SpecialInstructions
          label='Special instructions'
          name='specialInstructions'
          maxLength={maxCharacters}
          rows={4}
          placeholder={specialRequestsConfig?.placeholderMessage ?? ''}
          disabled={disabled}
        />
      )}
      {quantityInputField}
      {deleteButton}
    </>
  )
}
