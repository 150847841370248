import React, { useState } from 'react'
import { Field } from 'formik'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { FulfillmentSelectorModal } from '../../FulfillmentSelectorModal/FulfillmentSelectorModal'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import { DeliveryInfo } from '../../../types/orders'
import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'

export const NewAddress = () => {
  return (
    <>
      <Field
        id='customer_delivery_info_address'
        name='customerDeliveryInfoAddress'
        component={ReadOnlyAddressField}
      />
      <TextInputField
        containerClassName='mb-2'
        data-testid='customer_delivery_info_notes'
        id='customer_delivery_info_notes'
        label='Delivery instructions'
        name='customerDeliveryInfoNotes'
        type='text'
      />
    </>
  )
}

const ReadOnlyAddressField = ({ field }) => {
  const [showFulfillmentModal, setShowFulfillmentModal] = useState(false)
  return (
    <>
      <ReadonlyText
        testId='ReadOnlyAddressField'
        containerClassName='mb-3 text-color-default'
        label={
          <div className='flex items-center -mb-1'>
            <span className='text-error mr-0.5'>*</span>Address
            <IconButton
              className='ml-1'
              icon={<EditIcon size='xs' />}
              onClick={() => setShowFulfillmentModal(true)}
            />
          </div>
        }
        value={<FormattedAddressTwoLines address={field.value} />}
      />
      {showFulfillmentModal && (
        <FulfillmentSelectorModal
          onClose={() => setShowFulfillmentModal(false)}
        />
      )}
    </>
  )
}

const FormattedAddressTwoLines = ({ address }: { address: DeliveryInfo }) => {
  const { customLocationName } = useFulfillment()

  if (!address) return <></>
  const { address1, address2, city, state, zipCode } = address
  if (!(address1 && city && state && zipCode)) return <></>

  return (
    <>
      {customLocationName && (
        <>
          {customLocationName}
          <br />
        </>
      )}
      {address1} {address2}
      <br />
      {city}, {state} {zipCode}
    </>
  )
}
