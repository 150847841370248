import React from 'react'
import ContentLoader from 'react-content-loader'
import cx from 'classnames'
import styles from './RestaurantHeader.module.css'

export const RestaurantHeaderLoader = () => (
  <>
    <div className={styles.header}>
      <div className={styles.banner}>
        <div className={cx(styles.information, styles.fakeInfo)}>
          <ContentLoader className={styles.desktop} height={100} width={600}>
            <rect x='0' y='10' rx='4' ry='4' width='500' height='31' />
            <rect x='0' y='50' rx='4' ry='4' width='260' height='20' />
            <rect x='0' y='80' rx='4' ry='4' width='220' height='20' />
          </ContentLoader>
        </div>
      </div>
    </div>
    <div style={{ height: '55px' }} />
  </>
)
