import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { CopyableLink } from './CopyableLink'
import { useCart } from '@local/do-secundo-cart-provider/src'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import cx from 'classnames'
import { useGetWindowSize } from '@local/do-secundo-use-get-window-size'
import { Button, ButtonVariant } from '@local/do-secundo-button'
import { Cart, CartMetadata } from '../../types/cart'
import { useGetCart } from '../CartQuery/CartQuery'
import { updateCartMeta } from '../../api/cart'
import { Formik } from 'formik'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { getRestaurantShortUrl } from '../../api/util'

export const ShareCartModal = ({ onClose }: { onClose: () => void }) => {
  const { cartGuid } = useCart()
  const { getRestaurantPath } = useRestaurant()
  const { cart } = useGetCart()
  const { isMobile } = useGetWindowSize()

  const [editMode, setEditMode] = useState(false)

  if (!cartGuid || !cart) {
    return null
  }

  return (
    <Modal isOpen onRequestClose={onClose} size={'xxl'}>
      <ModalHeader>Invite others to add to this cart!</ModalHeader>
      <ModalBody className={'px-2'}>
        Allow others to add items to your cart by sharing this link. Once
        everything is added, one person should checkout to place the order.
        <div className={'pt-4 pb-2 w-full'}>
          <CopyableLink
            url={`${
              window.location.origin
            }/catering/${getRestaurantShortUrl()}${getRestaurantPath()}?cart=${cartGuid}`}
          />
          {editMode ? (
            <EditMode
              cart={cart}
              setEditMode={setEditMode}
              cartGuid={cartGuid}
            />
          ) : (
            <div
              className={cx('pt-4', {
                'flex w-full': !isMobile,
                'space-y-2': isMobile
              })}
            >
              <div className={cx('flex-1', { flex: isMobile })}>
                <div className={'flex-1'}>
                  <div className={'font-semibold text-default'}>Name</div>
                  <div>
                    {cart?.metadata?.name ? cart.metadata.name : 'None'}
                  </div>
                </div>
                {isMobile && <EditButton setEditMode={setEditMode} />}
              </div>
              <div className={'flex-1'}>
                <div className={'font-semibold text-default'}>Description</div>
                <div>
                  {cart?.metadata?.description
                    ? cart.metadata.description
                    : 'None'}
                </div>
              </div>
              {!isMobile && <EditButton setEditMode={setEditMode} />}
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

const EditButton = ({
  setEditMode
}: {
  setEditMode: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <div>
      <IconButton
        icon={<EditIcon />}
        onClick={() => {
          setEditMode(true)
        }}
      />
    </div>
  )
}

const EditMode = ({
  setEditMode,
  cartGuid,
  cart
}: {
  setEditMode: Dispatch<SetStateAction<boolean>>
  cartGuid: string
  cart: Cart
}) => {
  const [isError, setIsError] = useState(false)

  const { updateCartCache } = useCart()

  const initialValue: CartMetadata = cart.metadata || {}

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={(values) => {
        setIsError(false)
        updateCartMeta(cartGuid, values)
          .then((cart: Cart) => {
            updateCartCache(cart)
            setEditMode(false)
          })
          .catch(() => setIsError(true))
      }}
    >
      {({ handleSubmit, dirty }) => (
        <div className={'pt-4'}>
          <hr className={'bg-darken-12'} />
          <div className={'font-semibold type-default pt-4'}>Name</div>
          <TextInputField name={'name'} />
          <div className={'font-semibold type-default pt-2'}>Description</div>
          <TextInputField name={'description'} />

          <div className={'flex w-full space-x-2 pt-6'}>
            <Button
              variant={ButtonVariant.SECONDARY}
              onClick={() => setEditMode(false)}
              className={'flex-1'}
            >
              Cancel
            </Button>
            <Button
              disabled={!dirty}
              variant={ButtonVariant.PRIMARY}
              onClick={() => handleSubmit()}
              className={'flex-1'}
            >
              Save
            </Button>
          </div>
          {isError && (
            <div className={'text-error text-right pt-1'}>
              Failed to save. Please try again
            </div>
          )}
        </div>
      )}
    </Formik>
  )
}
