export const managementGroupResolvers = {
  Query: {
    // TODO: Remove this client side resolver once
    // OO-2649 is completed. This temporary response
    // is just to enable development
    async restaurantsForManagementGroup(_, { input }) {
      // Simulate server lag time
      await new Promise((resolve) => setTimeout(resolve, 1000))
      return {
        guid: '00000019-debd-01d3-0000-00e8990bc8ab',
        imageUrls: {
          medium: 'http://placekitten.com/300/300',
          __typename: 'ImageSet'
        },
        restaurants: [
          {
            guid: 'ed352e13-8256-4374-a68a-71dde8aa4c95',
            whiteLabelName: 'nathálie',
            name: 'nathálie',
            shortUrl: 'nathalie',
            location: {
              latitude: 42.343875,
              longitude: -71.101817,
              address1: '186 Brookline Avenue',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02215',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: 'b1d955c7-32fc-437e-89ba-f0883ed4469e',
            whiteLabelName: 'Saloniki Fenway',
            name: 'Saloniki - Fenway',
            shortUrl: 'saloniki-fenway',
            location: {
              latitude: 42.3446222,
              longitude: -71.10039909999999,
              address1: '4 Kilmarnock st.',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02215',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: 'a7e30519-eafd-41f0-ba42-e370d86d55b9',
            whiteLabelName: 'Sushi Kappo 86 Peterborough St, Boston MA 02215',
            name: 'Sushi Kappo - Fenway',
            shortUrl: 'sushi-kappo',
            location: {
              latitude: 42.3431366,
              longitude: -71.1010823,
              address1: '86 Peterborough Street',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02215',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: '6fdcec00-de87-49ba-bc7f-e7942d19fabd',
            whiteLabelName: 'Tatte Bakery | Fenway Fenway',
            name: 'Tatte Bakery - Fenway',
            shortUrl: 'tatte-bakery-fenway',
            location: {
              latitude: 42.3441299,
              longitude: -71.09941189999999,
              address1: '1352 Boylston Street',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02215',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: '22187e88-e593-40e3-acea-1a1463ca3b31',
            whiteLabelName: 'Eventide Fenway',
            name: 'Eventide - Fenway',
            shortUrl: 'eventide-fenway',
            location: {
              latitude: 42.34429,
              longitude: -71.0988342,
              address1: '1321 Boylston Street',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02215',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: 'cd1bd2bc-c887-428a-a0d4-66382766b4f6',
            whiteLabelName: 'Neighborhoods Coffee and Crepes',
            name: 'Neighborhoods Coffee & Crepes',
            shortUrl: 'neighborhoods-coffee-and-crepes',
            location: {
              latitude: 42.343038,
              longitude: -71.099231,
              address1: '96 Peterborough St',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02215',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: '7f813f7c-3791-40bb-af70-3491425224a8',
            whiteLabelName: "Bennett's Sandwich Shop Boston",
            name: "Bennett's Sandwich Shop",
            shortUrl: 'bennet2',
            location: {
              latitude: 42.343095,
              longitude: -71.099111,
              address1: '84 Peterborough St',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02215',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: 'eb9c0151-8b98-4130-98d0-2720e9403ff2',
            whiteLabelName: "Sal's Pizza Brookline Ave.",
            name: "Sal's Pizza- Fenway",
            shortUrl: 'sals',
            location: {
              latitude: 42.3470223,
              longitude: -71.0988941,
              address1: '51 Brookline Ave',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02115',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: 'dc735b1c-e6fa-454b-a24a-aa719da4926a',
            whiteLabelName: 'Cafe Landwer 900 Beacon',
            name: 'Cafe Landwer - Beacon',
            shortUrl: 'dc735b1c-e6fa-454b-a24a-aa719da4926a',
            location: {
              latitude: 42.346807,
              longitude: -71.105731,
              address1: '900 Beacon Street',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02215',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: '60637b7b-2682-45cd-beb0-64dc70ee3f01',
            whiteLabelName: "Taberna de Haro St. Mary's Street T stop",
            name: 'Taberna de Haro',
            shortUrl: 'taberna-de-haro',
            location: {
              latitude: 42.3457918,
              longitude: -71.1067071,
              address1: '999 Beacon St',
              address2: null,
              city: 'Brookline',
              state: 'MA',
              zip: '02446',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: 'b8bc8300-8453-4298-b47c-cf1d7751fc4b',
            whiteLabelName: 'Tatte Bakery | Brookline Brookline',
            name: 'Tatte Bakery - Brookline',
            shortUrl: 'tatte-bakery-beacon-street',
            location: {
              latitude: 42.3457352,
              longitude: -71.1068508,
              address1: '1003 Beacon Street',
              address2: null,
              city: 'Brookline',
              state: 'MA',
              zip: '02446',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: '11b48133-8933-489d-93b1-b5897b6b7a90',
            whiteLabelName:
              'Mei Mei Restaurant 506 Park Drive, Boston MA 02215',
            name: 'Mei Mei',
            shortUrl: 'mei-mei-restaurant',
            location: {
              latitude: 42.34753449999999,
              longitude: -71.10556989999999,
              address1: '506 Park Drive',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02215',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: '93e36f7d-d820-4b7d-93e8-d2922339b826',
            whiteLabelName: 'Giggling Rice Thai Togo',
            name: 'Giggling Rice Thai To Go',
            shortUrl: 'gigglingrice',
            location: {
              latitude: 42.3457024,
              longitude: -71.1070828,
              address1: '1009 Beacon Street',
              address2: null,
              city: 'Brookline',
              state: 'MA',
              zip: '02446',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          },
          {
            guid: 'd31960f8-461b-4e05-aa79-78c1ffedf4ef',
            whiteLabelName: 'b.good Longwood',
            name: 'B.GOOD - Longwood',
            shortUrl: 'b-good-longwood',
            location: {
              latitude: 42.3390162,
              longitude: -71.1080246,
              address1: '360 Longwood Ave.',
              address2: null,
              city: 'Boston',
              state: 'MA',
              zip: '02215',
              __typename: 'Location'
            },
            __typename: 'Restaurant'
          }
        ],
        __typename: 'RestaurantsForManagementGroupResponse'
      }
    }
  }
}
