import React from 'react'
import { FutureAvailability } from '../../types/menu'
import styles from './MenuGroups/MenuGroups.module.css'
import { MoreInfo } from '@local/do-secundo-more-info/MoreInfo'
import { MenuFutureAvailability } from './MenuFutureAvailability'

interface Props {
  name: string
  futureAvailability: FutureAvailability[]
  description?: string
}

export const MenuHeader = React.forwardRef<HTMLDivElement, Props>(
  ({ name, futureAvailability, description }, ref) => {
    return (
      <>
        <h2
          ref={ref}
          className='mt-6 type-headline-5 bold text-coo-primary-text'
        >
          {name}
        </h2>
        <div className={'mt-2'}>
          {description && (
            <div className={styles.description}>
              <MoreInfo text={description} />
            </div>
          )}
          <MenuFutureAvailability futureAvailability={futureAvailability} />
        </div>
      </>
    )
  }
)
