/* eslint-disable */
/* Generated by GraphQL Code Generator against pre-production environment */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { Currency } from '@toasttab/buffet-pui-number-utilities'
import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `Upload` scalar type represents a file upload. */
  Upload: any
}

export type AddItemToCartInput = {
  cartGuid: Scalars['ID']
  selection: SelectionInput
}

export type AddItemToCartInputV2 = {
  /** The GUID of the cart to add the **selection** to. Not including it indicates that a new cart will be created. */
  cartGuid?: Maybe<Scalars['ID']>
  /**
   * Data required for creating a new cart.
   * If **cartGuid** is null, then this is required.
   * If **cartGuid** is not null, then this is ignored.
   */
  createCartInput?: Maybe<CreateCartInputV2>
  /** The item to be added to the cart indicated by **cartGuid**, or the new cart that will be created. */
  selection: SelectionInput
}

export type AddItemToCartResponse = {
  __typename?: 'AddItemToCartResponse'
  cart?: Maybe<Cart>
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type AddLoyaltyRedemptionInput = {
  cartGuid: Scalars['ID']
  /** Guid of the redemption to be applied, obtained from the **loyaltyInquiry** mutation */
  redemptionGuid: Scalars['ID']
}

export type AddLoyaltyRedemptionV2Input = {
  cartGuid: Scalars['ID']
  /** Redemption to be applied, obtained from the **loyaltyInquiry** mutation */
  redemption: LoyaltyRedemptionInput
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type AddPreauthToPartyInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID']
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID']
  /** Member's auth token. */
  memberAuthToken: Scalars['String']
  /** RestaurantGuid for the new preauth tab. */
  restaurantGuid: Scalars['ID']
  /** A new credit card with encrypted data. */
  newCardInput?: Maybe<NewCardInput>
  /** A customer's saved credit card. This is for future use. For now, preauth tabs can only use new cards */
  savedCardInput?: Maybe<SavedCardInput>
  /** Customer's information. */
  customer?: Maybe<OrderAtTableCustomerInput>
}

export type AddPushNotificationTargetToCustomerInput = {
  targetIdentifier: Scalars['String']
  targetType: PushNotificationTargetType
}

export type AddPushNotificationTargetToCustomerResponse = {
  __typename?: 'AddPushNotificationTargetToCustomerResponse'
  success: Scalars['Boolean']
}

export type AddToOptTabResponse = {
  __typename?: 'AddToOPTTabResponse'
  order: OptTabOrder
  tabCheckGuid: Scalars['ID']
  tabOrderGuid: Scalars['ID']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type AddToTabInput = {
  cartGuid?: Maybe<Scalars['ID']>
  tabCheckGuid?: Maybe<Scalars['ID']>
  tabOrderGuid?: Maybe<Scalars['ID']>
}

export type AndroidVersionInput = {
  versionCode: Scalars['Int']
  versionName: Scalars['String']
}

export type ApplicableCustomerRewardInput = {
  /** The customer reward to be applied to the order being placed. */
  reward: ApplyCustomerRewardInput
  /** The order amount that the customer is seeing while placing the order, accounting for the reward. */
  customerExpectedAmount: Scalars['Float']
}

export type AppliedCustomerReward = {
  __typename?: 'AppliedCustomerReward'
  amount?: Maybe<Scalars['Float']>
  name?: Maybe<Scalars['String']>
  rewardType?: Maybe<RewardType>
  beforeTax?: Maybe<Scalars['Boolean']>
  tax?: Maybe<Scalars['Float']>
  total?: Maybe<Scalars['Float']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type AppliedPreauthInfo = {
  __typename?: 'AppliedPreauthInfo'
  guid: Scalars['ID']
  token: Scalars['String']
  last4CardDigits: Scalars['String']
  cardHolderFirstName: Scalars['String']
  cardHolderLastName: Scalars['String']
  cardHolderExpirationMonth: Scalars['String']
  cardHolderExpirationYear: Scalars['String']
  cardType: CardType
}

export type ApplyCustomerRewardInput = {
  name?: Maybe<Scalars['String']>
  type?: Maybe<RewardType>
  amount?: Maybe<Scalars['Float']>
  beforeTax?: Maybe<Scalars['Boolean']>
}

/**
 * Promo code error type and message. The message should be the same regardless of the code,
 * as we do not want to expose any additional information that could help a malicious user abuse
 * promo codes.
 */
export type ApplyPromoCodeError = Error & {
  __typename?: 'ApplyPromoCodeError'
  code: ApplyPromoCodeErrorCode
  message: Scalars['String']
}

export enum ApplyPromoCodeErrorCode {
  /** Indicates that the code the user has entered does not exist. */
  CodeDoesNotExist = 'CODE_DOES_NOT_EXIST',
  /** Indicates that the code is a correct code but the specific user cannot use it. */
  CodeCannotBeRedeemed = 'CODE_CANNOT_BE_REDEEMED'
}

/**  Input for when a customer attempts to apply a promo code for their cart.  */
export type ApplyPromoCodeInput = {
  /** The guid of the cart that the user wishes to apply the promo code reward to. */
  cartGuid: Scalars['ID']
  /** A generic string that is used to look up rewards. */
  promoCode: Scalars['String']
}

/**  Input for when a customer attempts to apply a promo code to their order.  */
export type ApplyPromoCodeInputV2 = {
  /** The guid of the cart in the order the user wishes to apply the promo code reward to. */
  cartGuid: Scalars['ID']
  /** A generic string that is used to look up rewards. */
  promoCode: Scalars['String']
  /** A string representing the type of the promo code being applied. */
  discountType: DiscountType
}

/**  Input for when a customer attempts to apply a promo code to their order.  */
export type ApplyPromoCodeInputV3 = {
  /** The guid of the cart in the order the user wishes to apply the promo code reward to. */
  cartGuid: Scalars['ID']
  /** A generic string that is used to look up rewards. */
  promoCode: Scalars['String']
}

/** When a user applies a promo code, on success, they should receive the cart with the reward applied. */
export type ApplyPromoCodeResponse = {
  __typename?: 'ApplyPromoCodeResponse'
  cart: Cart
}

export type ApplyPromoCodeResponseOrError =
  | ApplyPromoCodeResponse
  | ApplyPromoCodeError

export type ApplyPromoCodeResponseOrErrorV2 =
  | CartResponse
  | CartModificationError
  | CartOutOfStockError
  | ApplyPromoCodeError

export type ApplyPromoInProgressOrderInput = {
  /** The restaurant for the order to apply the promo code */
  restaurantGuid: Scalars['ID']
  /** The guid of the check to apply the promo code */
  checkGuid: Scalars['ID']
  /** The guid of the order to apply the promo code */
  orderGuid: Scalars['ID']
  /** The promo code */
  promoCode: Scalars['String']
}

export type ApprovalRule = {
  __typename?: 'ApprovalRule'
  ruleType: OrderTriggerMatchType
  requiredAdjustment: Scalars['Float']
  thresholdAmount: Scalars['Float']
}

export type AsapSchedule = {
  __typename?: 'AsapSchedule'
  /** Whether **ASAP** option is available at the moment the request is made. */
  availableNow: Scalars['Boolean']
  /**
   * The ISO 8601 date/time string that represents the earliest time, in the future, that an **ASAP** order can be placed.
   * A null value indicates that no such time can be found.
   */
  availableAt?: Maybe<Scalars['String']>
}

/** Tokens provided from successful login or refresh response */
export type AuthenticationResponse = {
  __typename?: 'AuthenticationResponse'
  /** The customerGuid for the account that's been logged in */
  customerGuid: Scalars['ID']
  /**
   * Access token in the form of JWT ( JSON Web Token ). Use the access token in the header of all authenticated requests to this service with the key "toast-customer-access".
   * The access token has a short lifespan of 20 minutes. Use the ***refreshToken*** to obtain a new access token using the ***refresh*** mutation.
   */
  accessToken: Scalars['String']
  /**
   * Refresh token in the form of JWT ( JSON Web Token ). Use the refresh token for obtaining a new access token once the access token has expired.
   * The refresh token expires after 30 days.
   */
  refreshToken: Scalars['String']
}

export type AuthorizePaymentError = Error & {
  __typename?: 'AuthorizePaymentError'
  message: Scalars['String']
}

export type AuthorizePaymentInput = {
  /** The guid of the restaurant which has the order being paid for */
  restaurantGuid: Scalars['ID']
  /** A new credit card with encrypted data.  Either newCardInput, savedCardInput or pkPaymentToken must be supplied. */
  newCardInput?: Maybe<NewCardInput>
  /** Check amount to authorize the card for */
  amount: Scalars['Float']
  /** The amount that the consumer selected to tip */
  tipAmount: Scalars['Float']
  /** customer email will be used at guest identifier for cc fraud */
  email: string
  /** reCAPTCHA token will be required from the client to mitigate fraud attempts */
  reCaptchaToken?: string
  /** cart guid to associate with the payment request */
  cartGuid?: string
  /** some orders on COO might come from a special menu, this is that name */
  fastLinkName?: string
}

export type AuthorizePaymentResponse = {
  __typename?: 'AuthorizePaymentResponse'
  paymentGuid?: Maybe<Scalars['ID']>
  /** The state of the payment. Toast may introduce enum values at any time, please future-proof your implementation */
  paymentState?: Maybe<PaymentState>
  /** The amount of this payment not including tip. */
  amount: Scalars['Float']
  /** The tip amount of this payment. */
  tipAmount: Scalars['Float']
  /** The reason a payment was denied. This must be displayed to the user. */
  denialReason?: Maybe<Scalars['String']>
}

export type AuthorizePaymentResponseOrError =
  | AuthorizePaymentResponse
  | AuthorizePaymentError

/**
 * Fields that can be changed as part of updating the basic info on an account. All are required, but can be left the same as the
 * current values stored in the database.
 */
export type BasicInfoInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  phone: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type BucketSelectionRequest = {
  /** Id of the bucket you are moving items out of. */
  fromBucketGuid: Scalars['ID']
  /** List of item ids to move. */
  selectionIds: Array<Maybe<Scalars['String']>>
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export enum CardStatusType {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum CardType {
  Visa = 'VISA',
  Mastercard = 'MASTERCARD',
  Amex = 'AMEX',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Diners = 'DINERS',
  Cit = 'CIT',
  Maestro = 'MAESTRO',
  Laser = 'LASER',
  Solo = 'SOLO',
  Unknown = 'UNKNOWN'
}

export type Cart = {
  __typename?: 'Cart'
  guid: Scalars['ID']
  restaurant?: Maybe<Restaurant>
  order?: Maybe<Order>
  expiredDate: Scalars['Float']
  paymentOptions: CartPaymentOptions
  preComputedTips: Array<Maybe<PreComputedTip>>
  approvalRules: Array<ApprovalRule>
  /** @deprecated Inaccurate and causes client errors */
  isPurchasable: Scalars['Boolean']
  /** **DELIVERY** or **TAKE_OUT**. Note this does not yet support **OUTPOST**. */
  diningOptionBehavior: DiningOptionBehavior
  /** ASAP or FUTURE */
  fulfillmentType: FulfillmentType
  /**
   * The duration that represents how long, from the current time, it would take for the order to be fulfilled.
   * This reflects the current state of the cart and typically varies based on the current **diningOptionBehavior**.
   * Only relevant for a **fulfillmentType** of **ASAP**.
   * If **fulfillmentType** is not **ASAP**, this value will be null.
   */
  quoteTime?: Maybe<Scalars['Int']>
  /**
   * The duration that represents how long, from the current time, it would take for the order to be fulfilled
   * if the order were made to be an ASAP takeout order, regardless of what **fulfillmentType** and
   * **diningOptionBehavior** currently are.
   * Typically this value will not be null unless the restaurant is misconfigured.
   * A non-null value does not necessarily indicate takeout is currently available.
   */
  takeoutQuoteTime?: Maybe<Scalars['Int']>
  /**
   * The duration that represents how long, from the current time, it would take for the order to be fulfilled
   * if the order were made to be an ASAP delivery order, regardless of what **fulfillmentType** and
   * **diningOptionBehavior** currently are.
   * Typically this value will not be null unless the restaurant is misconfigured.
   * A non-null value does not necessarily indicate delivery is currently available.
   */
  deliveryQuoteTime?: Maybe<Scalars['Int']>
  /**
   * An ISO 8601 date+time string that represents when the order is expected to be fullfilled at, set by the diner.
   * Only relevant for a **fulfillmentType** of **FUTURE**.
   * If **fulfillmentType** is not **FUTURE**, this value will be null.
   */
  fulfillmentDateTime?: Maybe<Scalars['String']>
  /** Cart Upsell Info */
  cartUpsellInfo?: Maybe<CartUpsellInfo>
  /**
   * Given that the cart has **diningOptionBehavior** set to **DELIVERY**, this field provides information regarding the delivery provider.
   * Null if the cart does not have **diningOptionBehavior** set to **DELIVERY**.
   */
  deliveryProviderInfo?: Maybe<DeliveryProviderInfo>
}

/** The available APIs for retrieving cart data */
export enum CartApiType {
  /** The online ordering api for cart */
  Oo = 'OO',
  /** The order and pay at the table api for cart */
  Opt = 'OPT'
}

export type CartError = Error & {
  __typename?: 'CartError'
  code: CartErrorCode
  message: Scalars['String']
}

export enum CartErrorCode {
  /** The requested cart is no longer available */
  CartNotFound = 'CART_NOT_FOUND'
}

export type CartFulfillmentInput = {
  /**
   * TBD Open Questions:
   * - should probably be required when behavior = DELIVERY
   * - should validate this is in the delivery area
   * - if behavior != DELIVERY should we clear out what was saved on the server
   *   and ignore this field on the input
   * - should we strictly require this field to be null when behavior != DELIVERY
   */
  deliveryInfo?: Maybe<DeliveryInfoInput>
  /** DELIVERY or TAKE_OUT, defaults to TAKE_OUT */
  diningOptionBehavior?: Maybe<DiningOptionBehavior>
  /**
   * The expected time at which the order on the cart would be fulfilled.
   * 1. A **fulfillmentType** of **ASAP** requires this value to not be set.
   * 2. A **fulfillmentType** of **FUTURE** requires this value to be set, in ISO 8601 format.
   */
  fulfillmentDateTime?: Maybe<Scalars['String']>
  /** ASAP or FUTURE */
  fulfillmentType: FulfillmentType
}

export type CartInfo = Info & {
  __typename?: 'CartInfo'
  code: CartInfoCode
  message: Scalars['String']
}

export enum CartInfoCode {
  /**
   * If a cart is scheduled for a particular fulfillment time, Price-based Prep can push the fulfillment time out
   * if the prep time increases due to cart modification. Placing the order on the cart is still possible.
   */
  FulfillmentDateChanged = 'FULFILLMENT_DATE_CHANGED'
}

export type CartMessage = {
  __typename?: 'CartMessage'
  messageType?: Maybe<Scalars['String']>
  userFacingMessage?: Maybe<Scalars['String']>
}

export type CartModificationError = Error & {
  __typename?: 'CartModificationError'
  code: CartModificationErrorCode
  message: Scalars['String']
}

export enum CartModificationErrorCode {
  /** Either the requested cart or the requested cart item is no longer available. */
  CartContentNotFound = 'CART_CONTENT_NOT_FOUND',
  /**
   * Either the selection in the request (only for **addItemToCartV2** and **editItemInCartV2**)
   * or an existing selection on the cart (for **addItemToCartV2**, **editItemInCartV2**, and **deleteItemFromCartV2**)
   * does not meet the modifier configuration.
   */
  ModifierConfigurationMismatch = 'MODIFIER_CONFIGURATION_MISMATCH'
}

export type CartModificationResponseOrError =
  | CartResponse
  | CartModificationError
  | CartOutOfStockError

export type CartOutOfStockError = Error & {
  __typename?: 'CartOutOfStockError'
  message: Scalars['String']
  cart: Cart
  items: Array<CartOutOfStockItem>
}

export type CartOutOfStockItem = {
  __typename?: 'CartOutOfStockItem'
  name?: Maybe<Scalars['String']>
  guid?: Maybe<Scalars['ID']>
}

export type CartPaymentOption = {
  __typename?: 'CartPaymentOption'
  paymentType: PaymentType
}

export type CartPaymentOptions = {
  __typename?: 'CartPaymentOptions'
  atCheckout: Array<Maybe<CartPaymentOption>>
  uponReceipt: Array<Maybe<CartPaymentOption>>
}

export type CartResponse = {
  __typename?: 'CartResponse'
  cart: Cart
  /** Sides effects that would prevent placing the order represented by **cart**. */
  warnings: Array<CartWarning>
  /** Sides effects that wouldn't prevent placing the order represented by **cart**. */
  info: Array<CartInfo>
}

export type CartResponseOrError = CartResponse | CartError

/** Upsell data returned from DataScience Upsell Service */
export type CartUpsellInfo = {
  __typename?: 'CartUpsellInfo'
  /** List of Longs returned from the upsell service */
  upsellItems?: Maybe<Array<Scalars['String']>>
}

export type CartWarning = Warning & {
  __typename?: 'CartWarning'
  code: CartWarningCode
  message: Scalars['String']
}

export enum CartWarningCode {
  /**
   * Placing the order on the cart is not possible due to either:
   * 1. Cart is ASAP but restaurant does not accept ASAP orders currently.
   * 2. Cart has a set fulfillment time (scheduled order) but restaurant cannot fulfill an order at that time.
   * Reason #2 can occur as a result of Price-based Prep pushing the fulfillment time outside of the restaurant's
   * configured schedule.
   */
  CartUnfulfillable = 'CART_UNFULFILLABLE',
  /**
   * Placing the order on the cart is not possible due to the current delivery info
   * being outside the restaurant's delivery area.
   *
   * This is only applicable if the cart's dining option behavior is DELIVERY.
   */
  NotInDeliveryArea = 'NOT_IN_DELIVERY_AREA',
  /**
   * Placing the order on the cart is not possible due to the delivery provider
   * being disabled or otherwise unavailable.
   *
   * This is only applicable if the cart is a DaaS order.
   */
  ProviderUnavailable = 'PROVIDER_UNAVAILABLE'
}

export enum Client {
  OnlineOrdering = 'ONLINE_ORDERING',
  ConsumerAppIos = 'CONSUMER_APP_IOS'
}

export type CompleteIdentityProfileError = Error & {
  __typename?: 'CompleteIdentityProfileError'
  code: CompleteIdentityProfileErrorCode
  message: Scalars['String']
}

export enum CompleteIdentityProfileErrorCode {
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidName = 'INVALID_NAME'
}

export type CompleteIdentityProfileInput = {
  /** The guest's provided first name */
  firstName: Scalars['String']
  /** The guest's provided last name */
  lastName: Scalars['String']
  /** Email for the account. Cannot be changed once the account is created. */
  email: Scalars['String']
  /** The source for the account creation (Toast App vs web Online Ordering vs Order and Pay) */
  source: RegistrationSource
}

export type CompleteIdentityProfileResponse = {
  __typename?: 'CompleteIdentityProfileResponse'
  guestGuid: Scalars['ID']
}

export type CompleteIdentityProfileResponseOrError =
  | CompleteIdentityProfileResponse
  | CompleteIdentityProfileError

export type CompleteProfileCreationError = Error & {
  __typename?: 'CompleteProfileCreationError'
  code: CompleteProfileCreationErrorCode
  message: Scalars['String']
}

export enum CompleteProfileCreationErrorCode {
  ServiceUnavailable = 'SERVICE_UNAVAILABLE'
}

export type CompleteProfileCreationInput = {
  /** The source for the account creation (Toast App vs web Online Ordering vs Order and Pay) */
  source: RegistrationSource
}

export type CompleteProfileCreationResponse = {
  __typename?: 'CompleteProfileCreationResponse'
  guestGuid: Scalars['ID']
}

export type CompleteProfileCreationResponseOrError =
  | CompleteProfileCreationResponse
  | CompleteProfileCreationError

/** An order that has already been placed. Contains fields useful for displaying past orders or for an order confirmation screen. */
export type CompletedOrder = {
  __typename?: 'CompletedOrder'
  /** The guid of the order object in the database */
  guid: Scalars['ID']
  /** The display number assigned to this order's check (only one check per order for digital orders) */
  checkNumber?: Maybe<Scalars['Int']>
  /** Guid of the check on this order, used primarily for creating a loyalty account to connect the order to the new account */
  checkGuid: Scalars['ID']
  /**
   * Provided personal information, either from inputs for guest orders or retrieved from customermgmt for orders with a saved account
   * @deprecated use customerV2
   */
  customer: CustomerInfo
  /**
   * Provided personal information, either from inputs for guest orders or retrieved from customermgmt for orders with a saved account.
   * Will only be present if returned from PlaceOrder or if the user is authenticated.
   */
  customerV2?: Maybe<CustomerInfo>
  /**
   * The provided delivery info used to calculate delivery fees, assuming this was a
   * delivery order. Will only be present if returned from PlaceOrder or if the user is authenticated.
   */
  deliveryInfo?: Maybe<DeliveryInfo>
  /** Timestamp of the estimated fulfillment (current time + quote time) */
  estimatedFulfillmentDate?: Maybe<Scalars['Float']>
  /** The message set by the restaurant in the OO-config for takeout orders, null if unset by the restaurant */
  guestCommunication?: Maybe<Scalars['String']>
  /** Number of items present on this order */
  numberOfSelections: Scalars['Int']
  /** Timestamp of when the order was placed */
  openedDateTime: Scalars['Float']
  /** Enum defining which kind of payment was used to place this order */
  paymentType: PaymentType
  /** identifier for the order payment */
  orderPaymentGuid?: Maybe<Scalars['ID']>
  /** Restaurant the order was placed at, which contains all relevant information (name, address, etc.) */
  restaurant: Restaurant
  /** List of items present on this order */
  selections: Array<CompletedOrderSelection>
  /**
   * The total amount of delivery charges on this order based on **deliveryInfo**,
   * included in the **total** below, assuming this was a delivery order.
   * @deprecated service charges are separated out into different categories such as processingServiceCharges and deliveryServiceCharges
   */
  deliveryChargeTotal: Scalars['Float']
  /**
   * The total amount of service charges on this order, included in the **total** below.
   * Does not include delivery charges.
   * @deprecated service charges are separated out into different categories such as processingServiceCharges and deliveryServiceCharges
   */
  serviceChargeTotal: Scalars['Float']
  /** The sum of the cost of all items, after discounts, in the cart except for outpost fees */
  itemsSubtotal: Scalars['Float']
  /** The sum of the cost of all items, before discounts, in the cart except for outpost fees */
  preDiscountItemsSubtotal: Scalars['Float']
  /** The sum of the service charges identified as UBP (user based pricing) */
  processingServiceCharges: Scalars['Float']
  /** The sum of the service charges that are not UBP and only for delivery orders */
  deliveryServiceCharges: Scalars['Float']
  /** The sum of the service charges that are not UBP, not only for delivery orders, and identified as gratuity  */
  gratuityServiceCharges: Scalars['Float']
  /** The sum of the service charges that are not UBP, not only for delivery orders, and not identified as gratuity */
  nonDeliveryNonGratuityNonUbpServiceCharges: Scalars['Float']
  /** The total amount of Toast For Good specific service charges on this order, included in the **total** below. */
  tfgRoundUpTotal?: Maybe<Scalars['Float']>
  /**
   * The subtotal of the order (price without tax/tip/service charges), included in the **total** below
   * @deprecated use itemsSubtotal
   */
  subtotal: Scalars['Float']
  /**
   * The total amount of taxes on this order, included in the **total** below
   * @deprecated Use taxV2 field
   */
  tax: Scalars['Float']
  /** The total amount of taxes on this order after applying global promos, included in the **totalV2** below */
  taxV2: Scalars['Float']
  /** The tip for the order, included in the **total** below */
  tip: Scalars['Float']
  /**
   * The final price of the order, including **subtotal**, **tax**, **tip**, and **serviceChargeTotal**
   * @deprecated Use totalV2 field
   */
  total: Scalars['Float']
  /** The final price of the order, including **subtotal**, **tax**, **tip**, **serviceChargeTotal** and **discounts** */
  totalV2: Scalars['Float']
  /**
   * The optional applied reward, if any for the order.  If present, use the **tax** and **total** for this object for the values after the reward has been applied.
   * @deprecated Use discounts field
   */
  appliedCustomerReward?: Maybe<AppliedCustomerReward>
  /** Optional discounts, if any, for the order. */
  discounts: Discounts
  /** Total of all the discounts applied to the order. */
  discountsTotal: Scalars['Float']
  /** The current state the order is in. Order's not submitted to a KDS will return null */
  orderStatus?: Maybe<OrderStatusType>
  /**
   * Inferred dining option of the order, based on the presence of delivery info. Will be 'TAKEOUT' for both takeout and outpost orders;
   * use **outpostOrder** field to know if an order is an outpost order.
   */
  diningOptionBehavior: DiningOptionBehavior
  /** The fee the user has paid for using outpost. Only not null if the user actually order from outpost. */
  outpostFee?: Maybe<Scalars['Float']>
  /** Whether or not the order is being delivered to Outpost.  False (or null) is a standard takeout order. */
  outpostOrder?: Maybe<Scalars['Boolean']>
  /** Whether the order has loyalty info attached, meaning a loyalty account was found with the same email at this restaurant */
  hasLoyaltyAttached: Scalars['Boolean']
  /** Gift card applied as payment */
  giftCard?: Maybe<GiftCardPayment>
  /** (Deprecated) The curbside pickup options for an order. Can be null if curbside pickup indication is not needed. */
  curbsidePickup?: Maybe<CurbsidePickup>
  /** The curbside pickup information for an order. Can be null if curbside pickup indication is not needed. */
  curbsidePickupV2?: Maybe<CurbsidePickupV2>
  /**
   * Whether or not an order is considered reorderable
   *
   * Business logic currently primarily considers whether a restaurant
   * has off-prem services available.
   *
   * The removeDuplicates argument will determine whether we take into consideration
   * if an order is a duplicate in a list of orders within a single query response.
   *
   * Null response means that the reorderability status has not been computed.
   */
  reorderable?: Maybe<Scalars['Boolean']>
  optPayments?: Maybe<Array<Maybe<OptPayment>>>
}

/** An order that has already been placed. Contains fields useful for displaying past orders or for an order confirmation screen. */
export type CompletedOrderReorderableArgs = {
  removeDuplicates?: Maybe<Scalars['Boolean']>
}

export type CompletedOrderInput = {
  orderGuid: Scalars['ID']
  restaurantGuid: Scalars['ID']
  tfgServiceChargeGuid?: Maybe<Scalars['ID']>
}

/** A modifier on an item for an order that has already been placed. */
export type CompletedOrderModifierSelection = {
  __typename?: 'CompletedOrderModifierSelection'
  /** Display name of the modifier */
  name: Scalars['String']
  /** List of nested modifier selections */
  modifiers: Array<CompletedOrderModifierSelection>
  /** Indicates whether this modifier was voided, only relevant for OPT open tab orders. */
  voided?: Maybe<Scalars['Boolean']>
}

/** An item on an order that has already been placed. */
export type CompletedOrderSelection = {
  __typename?: 'CompletedOrderSelection'
  /** the ID of the item this selection represents */
  itemGuid?: Maybe<Scalars['String']>
  /** the ID of the item group this selection's item is a part of */
  itemGroupGuid?: Maybe<Scalars['String']>
  /** External ID that matches with the user's selection when placing the order */
  externalId?: Maybe<Scalars['String']>
  /** List of all modifiers included on this item */
  modifiers: Array<CompletedOrderModifierSelection>
  /** The item's display name */
  name: Scalars['String']
  /** The item's price (including the price of any modifiers) */
  price: Scalars['Float']
  /** The cost of the Selection before any discounts are applied to the order */
  preDiscountPrice?: Maybe<Scalars['Float']>
  /** Quantity of this item (including modifiers) */
  quantity: Scalars['Int']
  usesFractionalQuantity: Scalars['Boolean']
  /** Includes the unit of measure for the item as well as the quantity to be measured. */
  fractionalQuantity: FractionalQuantity
  /** Indicates whether this selection was voided, only relevant for OPT open tab orders. */
  voided?: Maybe<Scalars['Boolean']>
}

export type CreateAccountError = Error & {
  __typename?: 'CreateAccountError'
  code: CreateAccountErrorCode
  message: Scalars['String']
}

export enum CreateAccountErrorCode {
  EmailInUse = 'EMAIL_IN_USE',
  InvalidAccountInfo = 'INVALID_ACCOUNT_INFO',
  PhoneNumberNotFound = 'PHONE_NUMBER_NOT_FOUND',
  PhoneInUse = 'PHONE_IN_USE'
}

export type CreateAccountInput = {
  /**
   * Email for the new account. Must not be in use by another account, and cannot be changed once the account is created.
   * A verification email will be sent to this address which contains a link that must be clicked to finish creating the account
   */
  email: Scalars['String']
  /** Password, used in combination with the email to login to the account */
  password: Scalars['String']
  /**
   * Restaurant guid, which can be optionally supplied to fetch the restaurant name from the API based
   * on the guid provided. If provided, will override the default name of 'Toast' with the server response.
   */
  restaurantGuid?: Maybe<Scalars['ID']>
  /**
   * Url link sent in the email, which is appended with the verification token as a query parameter. Should be used
   * to redirect the user from their email to a page with logic able to handle the the final step of creating an
   * account (i.e. the **verifyEmail** mutation)
   */
  redirectUrl: Scalars['String']
  /** The source for the account creation (iOS app vs web Online Ordering) */
  registrationClient: Client
  /** Optional basic info can be provided to create the account with these fields (first name, last name, phone number) already set */
  basicInfo?: Maybe<BasicInfoInput>
  /**
   * Optional input to create a loyalty account at the same time as the OO account is created. **basicInfo** is required for the
   * loyalty account to be successfully created.
   */
  loyaltyAccountCreationInput?: Maybe<LoyaltyAccountCreationInput>
}

export type CreateAccountResponse = {
  __typename?: 'CreateAccountResponse'
  customerGuid: Scalars['ID']
}

export type CreateAccountResponseOrError =
  | CreateAccountResponse
  | CreateAccountError

export type CreateCartInput = {
  restaurantGuid: Scalars['ID']
  orderSource?: Maybe<OrderSource>
}

export type CreateCartInputV2 = {
  /** The GUID of the restaurant that the cart will be created in. */
  restaurantGuid: Scalars['ID']
  /** The product that the cart is created on. */
  orderSource: OrderSource
  /** Indicates how the order on the cart should be fulfilled. */
  cartFulfillmentInput: CartFulfillmentInput
}

export type CreateCartResponse = {
  __typename?: 'CreateCartResponse'
  cart?: Maybe<Cart>
}

export type CreateConfirmedAccountInput = {
  /**
   * Email for the new account. Must not be in use by another account, and cannot be changed once the account is created.
   * A verification email will be sent to this address which contains a link that must be clicked to finish creating the account
   */
  email: Scalars['String']
  /** Password, used in combination with the email to login to the account */
  password: Scalars['String']
  /** The source for the account creation (iOS app vs web Online Ordering) */
  registrationClient: Client
  /** Basic info can be provided to create the account with these fields (first name, last name, phone number) already set */
  basicInfo: BasicInfoInput
}

export type CreateConfirmedAccountResponseOrError =
  | AuthenticationResponse
  | CreateAccountError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type CreateTabInput = {
  /** GUID for the cart that has the order currently being placed. */
  cartGuid: Scalars['ID']
  /** RestaurantGuid for the cart that has the order currently being placed. */
  restaurantGuid: Scalars['ID']
  /** A new credit card with encrypted data. */
  newCardInput?: Maybe<NewCardInput>
  /** A customer's saved credit card. */
  savedCardInput?: Maybe<SavedCardInput>
  /** A boolean that indicates whether (newCardInput || savedCardInput) is required to start a tab */
  requireCardToCreateTab: Scalars['Boolean']
  /**
   * Customer's information, required for unauthenticated **placeCcOrder**,
   * if absent for authenticated **placeCcOrder** it will be looked up based
   * on the customerGuid.
   */
  customer?: Maybe<OrderAtTableCustomerInput>
  /**
   * Customer guid, should only be provided for an unconfirmed account - authenticated orders placed for a confirmed
   * account will use the customer guid from the access token (JWT)
   */
  customerGuid?: Maybe<Scalars['ID']>
  /** The amount of tip the customer intends to give. */
  tipAmount?: Maybe<Scalars['Float']>
}

/** An object representing curbside pickup options */
export type CurbsidePickup = {
  __typename?: 'CurbsidePickup'
  selected: Scalars['Boolean']
}

/**
 * Provides details about the restaurant's curbside pickup configuration
 *
 * Note **enabled** and **enabledV2** should be mutally exclusive, though
 * **enabledV2** would take precedence.
 */
export type CurbsidePickupConfig = {
  __typename?: 'CurbsidePickupConfig'
  /**
   * If true, indicates the client can prompt the user to request curbside pickup
   * for their takeout order (legacy version that cannot accept transport details)
   *
   * **curbsidePickup** should be included on the corresponding Place*OrderInput
   * when calling the place*Order mutation
   */
  enabled: Scalars['Boolean']
  /**
   * If true, indicates the client can prompt the user to request curbside pickup
   * and must also prompt for transport details
   *
   * **curbsidePickupV2** should be included on the corresponding Place*OrderInput
   * when calling the place*Order mutation
   */
  enabledV2: Scalars['Boolean']
}

export type CurbsidePickupInput = {
  selected: Scalars['Boolean']
}

export type CurbsidePickupInputV2 = {
  transportDescription: Scalars['String']
  transportColor?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
}

/** An object representing curbside pickup information */
export type CurbsidePickupV2 = {
  __typename?: 'CurbsidePickupV2'
  transportDescription: Scalars['String']
  transportColor?: Maybe<Scalars['String']>
  notes?: Maybe<Scalars['String']>
}

/** The stored data for a customer account that is visible to the customer */
export type Customer = {
  __typename?: 'Customer'
  /** Customer guid */
  guid: Scalars['ID']
  /** First name, from basic info - not a required field when creating an account on OO so can be nullable */
  firstName?: Maybe<Scalars['String']>
  /** Last name, from basic info - not a required field when creating an account on OO so can be nullable */
  lastName?: Maybe<Scalars['String']>
  /** Phone number, from basic info - not a required field when creating an account on OO so can be nullable */
  phone?: Maybe<Scalars['String']>
  /** Email (primary identifier for the customer account) */
  email: Scalars['String']
  addresses: Array<SavedAddress>
  /** List of saved credit cards for this customer, sorted with the primary card first */
  creditCards: Array<SavedCreditCard>
}

export type CustomerInfo = {
  __typename?: 'CustomerInfo'
  firstName: Scalars['String']
  lastName: Scalars['String']
  phone: Scalars['String']
  email: Scalars['String']
}

export type CustomerInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  phone: Scalars['String']
  email: Scalars['String']
}

export type CustomerLoyaltyError = Error & {
  __typename?: 'CustomerLoyaltyError'
  code: LoyaltyErrorCode
  message: Scalars['String']
}

export type CustomerLoyaltyInfoInput = {
  restaurantGuid: Scalars['ID']
}

export type CustomerLoyaltyInfoResponseOrError =
  | LoyaltyInquiryResponse
  | CustomerLoyaltyError

export type DailySchedule = {
  __typename?: 'DailySchedule'
  /** ISO 8601 local date string. */
  date: Scalars['String']
  /** May be set if this schedule was overridden from the weekly schedule. */
  overrideDescription?: Maybe<Scalars['String']>
  servicePeriods: Array<ServicePeriod>
}

export type DateRangeInput = {
  /** ISO 8601 local date string (time zone is not part of the definition) */
  start: Scalars['String']
  /** ISO 8601 local date string (time zone is not part of the definition) */
  end: Scalars['String']
}

export type DeleteAccountError = Error & {
  __typename?: 'DeleteAccountError'
  code: DeleteAccountErrorCode
  message: Scalars['String']
}

export enum DeleteAccountErrorCode {
  ServiceUnavailable = 'SERVICE_UNAVAILABLE'
}

export type DeleteAccountResponse = {
  __typename?: 'DeleteAccountResponse'
  success: Scalars['Boolean']
}

export type DeleteAccountResponseOrError =
  | DeleteAccountResponse
  | DeleteAccountError

export type DeleteAddressError = Error & {
  __typename?: 'DeleteAddressError'
  code: DeleteAddressErrorCode
  message: Scalars['String']
}

export enum DeleteAddressErrorCode {
  AddressNotFound = 'ADDRESS_NOT_FOUND'
}

export type DeleteAddressInput = {
  addressGuid: Scalars['ID']
}

export type DeleteAddressResponse = {
  __typename?: 'DeleteAddressResponse'
  customer: Customer
}

export type DeleteAddressResponseOrError =
  | DeleteAddressResponse
  | DeleteAddressError

export type DeleteCreditCardError = Error & {
  __typename?: 'DeleteCreditCardError'
  code: DeleteCreditCardErrorCode
  message: Scalars['String']
}

export enum DeleteCreditCardErrorCode {
  CardNotFound = 'CARD_NOT_FOUND'
}

/** Guid of the desired card to be deleted from the account */
export type DeleteCreditCardInput = {
  cardGuid: Scalars['ID']
}

export type DeleteCreditCardResponse = {
  __typename?: 'DeleteCreditCardResponse'
  customer: Customer
}

export type DeleteCreditCardResponseOrError =
  | DeleteCreditCardResponse
  | DeleteCreditCardError

export type DeleteCustomerPushNotificationTargetInput = {
  targetIdentifier: Scalars['String']
}

export type DeleteCustomerPushNotificationTargetResponse = {
  __typename?: 'DeleteCustomerPushNotificationTargetResponse'
  success: Scalars['Boolean']
}

export type DeleteItemFromCartInput = {
  /** The GUID of the cart that the cart item referenced by **selectionGuid** would be removed from. */
  cartGuid: Scalars['ID']
  /** Indicates the cart item to be removed from the cart referenced by **cartGuid**. */
  selectionGuid: Scalars['ID']
}

export type DeleteItemFromCartResponse = {
  __typename?: 'DeleteItemFromCartResponse'
  cart?: Maybe<Cart>
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type DeliveryBanner = PromoBannerBase & {
  __typename?: 'DeliveryBanner'
  /** The GUID of the banner */
  bannerGuid: Scalars['String']
  /** The banner text */
  bannerText: Scalars['String']
  /** The delivery banner type */
  type: Scalars['String']
}

export type DeliveryInfo = {
  __typename?: 'DeliveryInfo'
  address1: Scalars['String']
  address2?: Maybe<Scalars['String']>
  city: Scalars['String']
  state: Scalars['String']
  zipCode: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  /** Notes for the delivery driver */
  notes?: Maybe<Scalars['String']>
}

export type DeliveryInfoInput = {
  address1: Scalars['String']
  address2?: Maybe<Scalars['String']>
  city: Scalars['String']
  state: Scalars['String']
  zipCode: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  /** Notes for the delivery driver */
  notes?: Maybe<Scalars['String']>
}

export enum DeliveryProvider {
  /** The order is being delivered by a first party delivery driver, i.e. a driver that the restaurant supplied themselves. */
  Toast = 'TOAST',
  /** The order is to be delivered to by a DoorDash driver. */
  Doordash = 'DOORDASH'
}

export type DeliveryProviderConfig = {
  __typename?: 'DeliveryProviderConfig'
  /** The configured provider */
  provider: DeliveryProvider
  /** Whether this provider is enabled or not */
  enabled: Scalars['Boolean']
  /** Whether this provider is pending or not */
  pending: Scalars['Boolean']
  /** The dining option GUID represented by this provider */
  diningOptionGuid: Scalars['ID']
}

export type DeliveryProviderInfo = {
  __typename?: 'DeliveryProviderInfo'
  /** The provider fulfilling the delivery. */
  provider: DeliveryProvider
  /** Indicates whether this provider requires consent from the diner for delivery-related communication from the provider to the diner. */
  needsDeliveryCommunicationConsent: Scalars['Boolean']
}

export type DeliveryUnavailableReason = Warning & {
  __typename?: 'DeliveryUnavailableReason'
  code: DeliveryUnavailableReasonCode
  message: Scalars['String']
}

export enum DeliveryUnavailableReasonCode {
  /** The request is not in the delivery service area */
  NotInDeliveryArea = 'NOT_IN_DELIVERY_AREA',
  /** The provider is unavailable, either due to us turning it off, or some type of outage. */
  ProviderUnavailable = 'PROVIDER_UNAVAILABLE'
}

export type DiningOption = {
  __typename?: 'DiningOption'
  /** The unique identifier for the **DiningOption** */
  guid: Scalars['ID']
  /** The type of the dining option */
  behavior: DiningOptionBehavior
  /** A description of the **DiningOption** */
  description: Scalars['String']
  /**
   * The ability for this **DiningOption** to accept **ASAP** order.
   * A null value indicates that **ASAP** orders cannot be accepted due to restaurant configuration.
   */
  asapSchedule?: Maybe<AsapSchedule>
  /**
   * The ability for this **DiningOption** to accept **FUTURE** order.
   * A null value indicates that **FUTURE** orders cannot be accepted due to restaurant configuration.
   */
  futureSchedule?: Maybe<FutureSchedule>
  /**
   * The delivery provider that this dining option is used for, whether that's Toast, or a third party.
   * Is null for non delivery dining options.
   */
  deliveryProvider?: Maybe<DeliveryProviderInfo>
}

export enum DiningOptionBehavior {
  /** The order is to be delivered to the diner. */
  Delivery = 'DELIVERY',
  /** The order is to be picked up at an outpost. */
  Outpost = 'OUTPOST',
  /** The order is to be picked up at the restaurant. */
  TakeOut = 'TAKE_OUT'
}

export type DiningOptionsInput = {
  /**
   * The dining option behaviors used to filter the dining options.
   * An empty **includeBehaviors** will return an empty list.
   */
  includeBehaviors: Array<DiningOptionBehavior>
  /**
   * The date range used to filter **FutureFulfillmentDate**s. If not provided, will default
   * to start with today and end with a date based on the max days ahead the restaurant accepts
   * scheduled orders.
   */
  futureFulfillmentDatesBetween?: Maybe<DateRangeInput>
  /**
   * The number of days used to filter **FutureFulfillmentDate**s. Starts with today and
   * ends with a date futureFulfillmentDaysAhead in the future, capped
   * at the restaurant's configured maximum.
   */
  futureFulfillmentDaysAhead?: Maybe<Scalars['Int']>
  /**
   * Only one of **restaurantGuid** and **cartGuid** can be non-null.
   * If **restaurantGuid** is supplied, then the dining options will be resolved according to the restaurant configuration only.
   */
  restaurantGuid?: Maybe<Scalars['ID']>
  /**
   * Only one of **restaurantGuid** and **cartGuid** can be non-null.
   * If **cartGuid** is supplied, then the dining options will be resolved according to the restaurant configuration and the cart content.
   */
  cartGuid?: Maybe<Scalars['ID']>
}

export enum DiscountType {
  /** A global discount that is not tied to a specific restaurant, e.x. sign up and get $5 off */
  Global = 'GLOBAL',
  /** A discount that is tied to a specific restaurant, e.x. Get 20% off as part of a promotion */
  Restaurant = 'RESTAURANT',
  /** A restaurant specific loyalty discount, tied to a customermgmt/loyalty account */
  Loyalty = 'LOYALTY'
}

/**
 * An object represeting the various discounts that can be applied to an order. Each field will contain an object with
 * each discount's associated information, such as type, name, and amount.
 */
export type Discounts = {
  __typename?: 'Discounts'
  globalReward?: Maybe<AppliedCustomerReward>
  restaurantDiscount?: Maybe<RestaurantDiscount>
  loyaltyDiscount?: Maybe<LoyaltyDiscount>
  loyaltyDiscounts?: Maybe<Array<Maybe<LoyaltyDiscount>>>
}

export type DoMenus_FindMenuItemError = {
  __typename?: 'DoMenus_FindMenuItemError'
  message: Scalars['String']
}

export type DoMenus_FindMenuItemInput = {
  restaurantGuid: Scalars['String']
  menuGroupGuid: Scalars['String']
  menuItemGuid: Scalars['String']
  visibility: Menus_VisibilityEnum
  /**
   * The instant at which to evaluate time-specific menu features, such as
   * availability and pricing. The current time will be used if omitted.
   *
   * Should be in ISO 8601 format and include date and time.
   * Example: 2019-08-07T13:00:00-04:00
   */
  dateTime?: Maybe<Scalars['String']>
}

export type DoMenus_FindMenuItemResponse = {
  __typename?: 'DoMenus_FindMenuItemResponse'
  /** The parent menu of the found item. */
  menu: Menus_MenuV2
  /**
   * The path of ancestor menu groups of the found item within `menu` up to and
   * including `menuGroup`.
   */
  menuGroups: Array<Menus_MenuGroupV2>
  /** The immediate parent menu group of the found item. */
  menuGroup: Menus_MenuGroupV2
  /** The found item. */
  menuItem: Menus_MenuItemV2
  /**
   * The contents of this menu response will be stripped to only contain data
   * relevant to the found menu item.
   */
  menuResponse: Menus_MenuResponseV2
}

export type DoMenus_FindMenuItemResult =
  | DoMenus_FindMenuItemError
  | DoMenus_FindMenuItemResponse

export type DoMenus_FindMenuItemsError = {
  __typename?: 'DoMenus_FindMenuItemsError'
  message: Scalars['String']
}

export type DoMenus_FindMenuItemsInput = {
  restaurantGuid: Scalars['String']
  items: Array<DoMenus_FindMenuItemsItemInput>
  visibility: Menus_VisibilityEnum
  /**
   * The instant at which to evaluate time-specific menu features, such as
   * availability and pricing. The current time will be used if omitted.
   *
   * Should be in ISO 8601 format and include date and time.
   * Example: 2019-08-07T13:00:00-04:00
   */
  dateTime?: Maybe<Scalars['String']>
}

export type DoMenus_FindMenuItemsItem = {
  __typename?: 'DoMenus_FindMenuItemsItem'
  menuGroup: Menus_MenuGroupV2
  menuItem: Menus_MenuItemV2
}

export type DoMenus_FindMenuItemsItemInput = {
  itemGuid: Scalars['String']
  groupGuid: Scalars['String']
}

/**
 * Will return an array equal in length to the input array, even if items
 * are not found within the menu. The order of items in the response will
 * also be in the same order as the input.
 *
 * For example, if the input provided is [item1, item2, item3] and item2 was not
 * found, then the result would be [item1Info, null, item2Info].
 *
 * Will return an empty array when the oo-find-menu-items feature flag is disabled
 */
export type DoMenus_FindMenuItemsResponse = {
  __typename?: 'DoMenus_FindMenuItemsResponse'
  items: Array<Maybe<DoMenus_FindMenuItemsItem>>
}

export type DoMenus_FindMenuItemsResult =
  | DoMenus_FindMenuItemsError
  | DoMenus_FindMenuItemsResponse

export type DoMenus_PopularItem = {
  __typename?: 'DoMenus_PopularItem'
  menuItem: Menus_MenuItemV2
  menuGroup: Menus_MenuGroupV2
}

export type DoMenus_PopularItemsError = {
  __typename?: 'DoMenus_PopularItemsError'
  message: Scalars['String']
}

export type DoMenus_PopularItemsInput = {
  restaurantGuid: Scalars['String']
  visibility: Menus_VisibilityEnum
  dateTime?: Maybe<Scalars['String']>
}

export type DoMenus_PopularItemsResponse = {
  __typename?: 'DoMenus_PopularItemsResponse'
  /** Ordered list of popular items that are currently visible based on the input parameters. */
  popularItems: Array<DoMenus_PopularItem>
}

export type DoMenus_PopularItemsResult =
  | DoMenus_PopularItemsError
  | DoMenus_PopularItemsResponse

export type EditItemInCartInput = {
  /** The GUID of the cart that the cart item referenced by **selectionGuid** belongs to. */
  cartGuid: Scalars['ID']
  /** Indicates the cart item to be modified. */
  selectionGuid: Scalars['ID']
  /** Indicates what the cart item referenced by **selectionGuid** would look like. */
  selection: SelectionInput
}

export type EditItemInCartResponse = {
  __typename?: 'EditItemInCartResponse'
  cart?: Maybe<Cart>
  warnings?: Maybe<Array<Maybe<Scalars['String']>>>
}

/** Indicates the failure to achieve the intended result of a query or mutation. */
export type Error = {
  message: Scalars['String']
}

export type FavoriteRestaurantsInput = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export enum FeedbackRating {
  Like = 'LIKE',
  Dislike = 'DISLIKE'
}

export enum FeedbackRatingV2 {
  Positive = 'POSITIVE',
  Passive = 'PASSIVE',
  Negative = 'NEGATIVE'
}

export enum FeedbackRawRatingV2 {
  Thumbs = 'THUMBS',
  Faces = 'FACES',
  Stars = 'STARS'
}

export type FractionalQuantity = {
  __typename?: 'FractionalQuantity'
  unitOfMeasure: UnitOfMeasure
  quantity: Scalars['Float']
}

/** Data representing the unit of measure to use when weighing out an item and the amount to weigh. */
export type FractionalQuantityInput = {
  unitOfMeasure: UnitOfMeasure
  quantity: Scalars['Float']
}

export enum FulfillmentType {
  /** The order on the cart is intended to be fulfilled as soon as possible, within the current service period. */
  Asap = 'ASAP',
  /** The order on the cart is intended to be fulfilled at a later time indicated by the client. */
  Future = 'FUTURE'
}

export type FutureFulfillmentDate = {
  __typename?: 'FutureFulfillmentDate'
  /** ISO 8601 local date string. */
  date: Scalars['String']
  /**
   * A **FutureFulfillmentTime** represents a time that can be selected for placing a **FUTURE** order.
   *
   * A **FutureFulfillmentServiceGap** represents a gap between two service periods.
   */
  timesAndGaps: Array<FutureFulfillmentTimeSlot>
  /** A list of fulfillment times without any service gap. */
  times: Array<FutureFulfillmentTime>
}

export type FutureFulfillmentServiceGap = {
  __typename?: 'FutureFulfillmentServiceGap'
  /** The label for a service gap. */
  description: Scalars['String']
}

export type FutureFulfillmentTime = {
  __typename?: 'FutureFulfillmentTime'
  /**
   * ISO 8601 date/time string.
   * This is the full date/time string for clients' ease to select and store a timestamp as opposed to having the need to stitch together a local date and a local time.
   */
  time: Scalars['String']
}

export type FutureFulfillmentTimeSlot =
  | FutureFulfillmentTime
  | FutureFulfillmentServiceGap

export type FutureSchedule = {
  __typename?: 'FutureSchedule'
  /** The dates that can be selected for placing a **FUTURE** order. */
  dates: Array<FutureFulfillmentDate>
}

/** General error resulted from temporary unavailability or failure of critical upstream services or tripped circuit breakers. */
export type GeneralError = Error & {
  __typename?: 'GeneralError'
  code: GeneralErrorCode
  message: Scalars['String']
}

export enum GeneralErrorCode {
  /** Temporary unavailability or failure of upstream services. */
  Retryable = 'RETRYABLE',
  /** Tripped circuit breakers within BFF or critical upstream services. */
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  /** Invalid Input */
  InvalidInput = 'INVALID_INPUT'
}

export type GenerateSmsVerificationCodeInput = {
  phoneNumber: Scalars['String']
}

export type GenerateSmsVerificationCodeResponse = {
  __typename?: 'GenerateSmsVerificationCodeResponse'
  success: Scalars['Boolean']
}

export type GiftCardBalanceInquiryError = Error & {
  __typename?: 'GiftCardBalanceInquiryError'
  code: GiftCardBalanceInquiryErrorCode
  message: Scalars['String']
}

export enum GiftCardBalanceInquiryErrorCode {
  /**
   * Indicates no available balance on the gift card
   * This is used instead of just applying a balance of 0 because we don't want to
   * expose errors for invalid cards to the front-end and we don't want to apply
   * a balance (even if zero) to an invalid card
   */
  NoBalance = 'NO_BALANCE'
}

export type GiftCardBalanceInquiryInput = {
  /** References Cart Guid */
  cartGuid: Scalars['ID']
  /** Gift Card Number to lookup */
  cardNumber: Scalars['String']
}

export type GiftCardBalanceInquiryResponse = {
  __typename?: 'GiftCardBalanceInquiryResponse'
  /** The balance available on the gift card */
  availableBalance: Scalars['Float']
  /** The requested card number */
  cardNumber: Scalars['String']
}

export type GiftCardBalanceInquiryResponseOrError =
  | GiftCardBalanceInquiryResponse
  | GiftCardBalanceInquiryError

/** Gift Card Config */
export type GiftCardConfig = {
  __typename?: 'GiftCardConfig'
  /** Can add value */
  addValueAllowed: Scalars['Boolean']
  /** Can lookup value */
  lookupAllowed: Scalars['Boolean']
  /** Can Purchase Gift Cards */
  purchaseAllowed: Scalars['Boolean']
  /** Can Redeem Gift Cards */
  redemptionAllowed: Scalars['Boolean']
}

/** Gift Card Links are so clients can direct guests to Toastweb URLs to purchase or manage gift cards */
export type GiftCardLinks = {
  __typename?: 'GiftCardLinks'
  /**
   * The link in Toastweb for guests to purchase gift cards
   * if null, the client should not display the link
   */
  purchaseLink?: Maybe<Scalars['String']>
  /**
   * The link in Toastweb for guests to check a gift card value or add value if addValueEnabled === true
   * if null, the client should not display the link
   */
  checkValueLink?: Maybe<Scalars['String']>
  /**
   * Allows the client to set guest expectations for the checkValueLink link
   * if true, indicate that value can be added
   * if false, do no indicate value can be added
   */
  addValueEnabled: Scalars['Boolean']
}

export type GiftCardPayment = {
  __typename?: 'GiftCardPayment'
  /** The balance of the gift card used as payment on the order */
  appliedBalance: Scalars['Float']
}

export type GiftCardPaymentInput = {
  /** The full numeric card number, no spaces */
  cardNumber: Scalars['String']
  /**
   * The amount that is expected to be used during the place order action
   * If check amount > expectedAvailableBalance and expectedAvailableBalance < the actual balance
   * of the gift card at the time of placing the order and the alternative payment is Credit Card
   * then the order will error because the amount charged to the card would be more than expected
   * by the client
   */
  expectedAvailableBalance: Scalars['Float']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type GuestFeedbackInput = {
  /** ID of the order relating to this feedback */
  orderGuid: Scalars['ID']
  /** ID of the restaurant relating to this feedback */
  restaurantGuid: Scalars['ID']
  /** either LIKE or DISLIKE */
  feedbackRating: FeedbackRating
  /** array of string tags the diner associated with their experience */
  feedbackTags?: Maybe<Array<Maybe<Scalars['String']>>>
  /** optional additional feedback text */
  feedbackText?: Maybe<Scalars['String']>
  /** additional metadata in the form of String: String */
  feedbackMeta?: Maybe<Array<Maybe<StringKeyValInput>>>
  /** optional bool indicating the diner gave permission to be contacted */
  feedbackContactPermission?: Maybe<Scalars['Boolean']>
  /** guid of the feedback record in the g2 feedback service */
  feedbackGuid?: Maybe<Scalars['String']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type GuestFeedbackResponse = {
  __typename?: 'GuestFeedbackResponse'
  /** ID of the order relating to this feedback */
  orderGuid: Scalars['ID']
  /** ID of the restaurant relating to this feedback */
  restaurantGuid: Scalars['ID']
  /** either LIKE or DISLIKE */
  feedbackRating: FeedbackRating
  /** array of string tags the diner associated with their experience */
  feedbackTags?: Maybe<Array<Maybe<Scalars['String']>>>
  /** optional additional feedback text */
  feedbackText?: Maybe<Scalars['String']>
  /** additional metadata in the form of String: String */
  feedbackMeta?: Maybe<Array<Maybe<StringKeyVal>>>
  /** optional bool indicating the diner gave permission to be contacted */
  feedbackContactPermission?: Maybe<Scalars['Boolean']>
  /** guid of the feedback record in the g2 feedback service */
  feedbackGuid?: Maybe<Scalars['String']>
}

export type GuestFeedbackV2Error = Error & {
  __typename?: 'GuestFeedbackV2Error'
  code: GuestFeedbackV2ErrorCode
  message: Scalars['String']
}

export enum GuestFeedbackV2ErrorCode {
  FeedbackNotSubmitted = 'FEEDBACK_NOT_SUBMITTED',
  FeedbackAlreadySubmitted = 'FEEDBACK_ALREADY_SUBMITTED',
  UnknownError = 'UNKNOWN_ERROR'
}

export type GuestFeedbackV2SuccessResponse = {
  __typename?: 'GuestFeedbackV2SuccessResponse'
  guestFeedbackEventGuid: Scalars['ID']
}

export type IosVersionInput = {
  cfBundleVersion: Scalars['Int']
  cfBundleShortVersionString: Scalars['String']
}

/**
 * Contains a mapping of size to image paths.
 * The sizes other than **raw** are resized from the original image, which is represented by **raw**.
 * Resizing is done by the toast-image-lambda Lambda function and is cached on CloudFront.
 * For details, refer to the [toast-image-lambda documentation](https://github.com/toasttab/system-docs/blob/master/consumer/image-processing/cached-resizing/Readme.md).
 */
export type ImageSet = {
  __typename?: 'ImageSet'
  /** the raw image that has not gone through resizing */
  raw: Scalars['String']
  /** 1920p */
  xxl: Scalars['String']
  /** 1280p */
  xl: Scalars['String']
  /** 1080p */
  large: Scalars['String']
  /** 720p */
  medium: Scalars['String']
  /** 360p */
  small: Scalars['String']
  /** 180p */
  xs: Scalars['String']
}

export type ImportAccountError = Error & {
  __typename?: 'ImportAccountError'
  code: ImportAccountErrorCode
  message: Scalars['String']
}

export enum ImportAccountErrorCode {
  /** The customerGuid account has been exported into too many accounts. */
  MaxExports = 'MAX_EXPORTS',
  /** The guestGuid account has imported too many accounts into it. */
  MaxImports = 'MAX_IMPORTS',
  /** The guestGuid account already has an import running. */
  ImportInProgress = 'IMPORT_IN_PROGRESS'
}

export type ImportAccountResponse = {
  __typename?: 'ImportAccountResponse'
  customerGuid: Scalars['ID']
  guestGuid: Scalars['ID']
}

export type ImportAccountResponseOrError =
  | ImportAccountResponse
  | ImportAccountError

export type ImportStatus = {
  __typename?: 'ImportStatus'
  canImport: Scalars['Boolean']
  importCount: Scalars['Int']
}

/** An order with at least one, but potentially more, checks which are open. */
export type InProgressOrder = {
  __typename?: 'InProgressOrder'
  guid: Scalars['ID']
  /**
   * The check requested. Only the check matching the client's requested check will be returned.
   * Will return null if no check matches the requested number or guid.
   */
  check?: Maybe<InProgressOrderCheck>
  /**
   * The check requested. Only the check matching the client's requested check will be returned.
   * Will return null if no check matches the requested number or guid.
   * @deprecated use check
   */
  checks: Array<InProgressOrderCheck>
  /** The discounts associated with the order. */
  discounts: Discounts
  /** The restaurant associated with the order. Used so that the client can know if amex is accepted. */
  restaurant: Restaurant
}

/** An open check associated with an InProgressOrder. */
export type InProgressOrderCheck = {
  __typename?: 'InProgressOrderCheck'
  guid: Scalars['ID']
  /** Order guid corresponding to a check */
  orderGuid: Scalars['ID']
  /** All selections that have not been voided associated with the InProgressOrderCheck */
  selections: Array<InProgressOrderSelection>
  /** The amount, before tax, on the check */
  subTotal: Scalars['Float']
  /** The amount, before tax and discounts, on the check */
  preDiscountSubTotal: Scalars['Float']
  /** The amount of tax on the check */
  taxAmount: Scalars['Float']
  /** The total amount associated with the check */
  totalAmount: Scalars['Float']
  /** The total amount unpaid on the check */
  amountRemaining: Scalars['Float']
  /** The sum of all surcharges applied to the check, most commonly an auto gratuity or less commonly a kitchen appreciation fee. */
  surchargeAmount: Scalars['Float']
  /**
   * Suggested tip for a check based on restaurant tipping preferences.
   * Null indicates failure in obtaining suggested tip values
   */
  suggestedTips?: Maybe<SuggestedTip>
}

/** Fetch an order with at least one open check. The order can be identified by its table name. */
export type InProgressOrderForTableInput = {
  /** The guid of the restaurant which has the order being paid for */
  restaurantGuid: Scalars['ID']
  /** Table name at which to search for an order */
  tableName: Scalars['String']
}

/** Fetch an order with at least one open check. The order can be identified by the number of a check it contains, or by the table name the order is located at. */
export type InProgressOrderInput = {
  /** The guid of the restaurant which has the order being paid for */
  restaurantGuid: Scalars['ID']
  /**
   * Only one of **checkGuid** and **checkNumber** can be non-null.
   * If **checkGuid** is supplied, then the InProgressOrder returned will be an order with an open check with that check guid or null
   */
  checkGuid?: Maybe<Scalars['String']>
  /**
   * Only one of **tableName** and **checkNumber** can be non-null.
   * If **checkNumber** is supplied, then the InProgressOrder returned will be an order with an open check with that check number or null
   */
  checkNumber?: Maybe<Scalars['String']>
  /** Removing support. Will return an error if requested. Please only use checkguid or checknumber. */
  tableName?: Maybe<Scalars['String']>
  /**
   * If orderGuid is present, checkGuid must also be sent. This is more consistent and speeds up the search by using the indexed orders APIs
   * instead of getting a bulk list. This also solves the missed checks after midnight and only being able to query for a check within the
   * past calendar day.
   */
  orderGuid?: Maybe<Scalars['String']>
}

/** A selection associated with an InProgressOrderCheck. */
export type InProgressOrderSelection = {
  __typename?: 'InProgressOrderSelection'
  /** The name of the selection */
  name: Scalars['String']
  /** The net price, after tax and discounts, for the selection */
  price: Scalars['Float']
  /** The price before tax and discounts, for the selection */
  preDiscountPrice: Scalars['Float']
  /**
   * The number of selections this object represents
   * @deprecated Quantity Fraction
   */
  quantity: Scalars['Int']
  /** The number of selections this object represents, as a float. */
  quantityFraction: Scalars['Float']
}

/**
 * Indicates that although the intended result of a query or mutation was achieved,
 * there are side effects that occurred that wouldn't affect downstream actions.
 */
export type Info = {
  message: Scalars['String']
}

export type InitiateEmailPasswordResetInput = {
  /** Email address to send the password reset email to */
  email: Scalars['String']
  /**
   * Restaurant guid, which is used to fetch the restaurant name used in the password reset email to give context to the user
   * where their account is from. This is more important for white label sources where the user may not know what "Toast" is.
   * If not provided, will send the email with "Toast" as the restaurant name.
   */
  restaurantGuid?: Maybe<Scalars['ID']>
  /**
   * Url link sent in the email, which is appended with the reset token as a query parameter. Should be used
   * to redirect the user from their email to a page with logic able to handle the the final step of resetting
   * the password (i.e. the **resetPassword** mutation)
   */
  redirectUrl: Scalars['String']
}

export type InitiateEmailPasswordResetResponse = {
  __typename?: 'InitiateEmailPasswordResetResponse'
  success: Scalars['Boolean']
}

export type InvalidOffsetError = {
  __typename?: 'InvalidOffsetError'
  message?: Maybe<Scalars['String']>
  invalidOffsetValue?: Maybe<Scalars['Int']>
  code?: Maybe<GeneralErrorCode>
}

export type ItemDetailOfferBadge = {
  __typename?: 'ItemDetailOfferBadge'
  /** The menu item guid that should show this badge */
  itemGuid: Scalars['String']
  bannerText: Scalars['String']
  promoCode: Scalars['String']
  bannerGuid: Scalars['String']
}

export type ItemDetailOfferBadgeInput = {
  /** The guid of the restaurant */
  restaurantGuid: Scalars['ID']
  /** The guid of the item which we are requesting offers for */
  itemGuid: Scalars['ID']
}

export type ItemDetailOfferBadgeResponse = {
  __typename?: 'ItemDetailOfferBadgeResponse'
  itemOfferBadges: Array<ItemDetailOfferBadge>
}

export type ItemDetailOfferBadgeResponseOrError =
  | ItemDetailOfferBadgeResponse
  | GeneralError

export type ItemOrderHistoryInput = {
  /** Guid of the restaurant */
  restaurantGuid: Scalars['ID']
  /** Menus information to query to get the item from. */
  menusInput: MenusInput
  /** Count of how many orders to query for items */
  pageSize?: Maybe<Scalars['Int']>
  /** Identifier for which page of orders to return items of */
  pageToken?: Maybe<Scalars['String']>
  /** Index to start getting orders from */
  offset?: Maybe<Scalars['Int']>
}

export type ItemOrderHistoryResponse = {
  __typename?: 'ItemOrderHistoryResponse'
  /** The guest's last ordered items sorted via openedDate newest -> oldest */
  items: Array<MenuItem>
  /**
   * Page token used to retrieve the current page of orders when querying guest profiles. Passing this value through as the pageToken in
   * subsequent requests will result in the same orders being retrieved.
   */
  currentPageToken?: Maybe<Scalars['String']>
  /**
   * Page token used to retrieve the next page of orders when querying guest profiles
   * When this field is null that indicates that [currentPageToken] was the last page in the result set.
   */
  nextPageToken?: Maybe<Scalars['String']>
  /** Offset used to get orders from a specific point when querying customer mgmt. */
  offset?: Maybe<Scalars['Int']>
}

export type ItemOrderHistoryResponseOrError =
  | ItemOrderHistoryResponse
  | MissingCustomerOrGuestGuidError
  | InvalidOffsetError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type ItemTag = {
  __typename?: 'ItemTag'
  name?: Maybe<Scalars['String']>
  guid: Scalars['ID']
}

export type Location = {
  __typename?: 'Location'
  address1: Scalars['String']
  address2?: Maybe<Scalars['String']>
  city: Scalars['String']
  state: Scalars['String']
  zip: Scalars['String']
  phone: Scalars['String']
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  distanceFromCustomer?: Maybe<Scalars['Float']>
}

/** Response type returned when a login request fails (e.g. due to an incorrect email/password combination) */
export type LoginError = Error & {
  __typename?: 'LoginError'
  /** Type of error encountered - use this field to differentiate between errors, for example an account being locked versus the wrong login information used */
  code: LoginErrorCode
  /** Additional information about the error encountered */
  message: Scalars['String']
}

export enum LoginErrorCode {
  IncorrectCredentials = 'INCORRECT_CREDENTIALS',
  EmailLocked = 'EMAIL_LOCKED',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE'
}

/** Email/password combination for signing in */
export type LoginInput = {
  /** Account email */
  email: Scalars['String']
  /** Account password */
  password: Scalars['String']
}

export type LoginResponse =
  | AuthenticationResponse
  | MfaChallengeGeneratedResponse
  | LoginError

export type LoyaltyAccountCreationInput = {
  /** Guid of the restaurant that a loyalty account is being created at */
  restaurantGuid: Scalars['ID']
  /** Guid generated by the front-end client that is constant per session (i.e. retries use the same guid). */
  transactionGuid: Scalars['ID']
  /** Guid of the check from the placed order. Used to accrue points for that order despite the account being created afterwards. */
  checkIdentifier: Scalars['ID']
}

export type LoyaltyBanner = PromoBannerBase & {
  __typename?: 'LoyaltyBanner'
  /** The GUID of the banner */
  bannerGuid: Scalars['String']
  /** The banner text */
  bannerText: Scalars['String']
  /** The banner subtitle */
  subtitle?: Maybe<Scalars['String']>
  /**
   * The time constraints on a loyalty offer
   * Example: a double points offer available Mon-Fri between 4-6PM
   */
  availability?: Maybe<PromoBannerAvailability>
  /** The loyalty banner type */
  type: Scalars['String']
}

export type LoyaltyConfig = {
  __typename?: 'LoyaltyConfig'
  /** The one time point bonus for creating a new loyalty account */
  loyaltySignupBonus?: Maybe<Scalars['Int']>
  /** Whether loyalty signups are enabled at this restaurant or not. Will be false if the restaurant does not have a loyalty program. */
  loyaltySignupEnabled: Scalars['Boolean']
  /** Whether loyalty redemptions are enabled at this restaurant or not. Will be false if the restaurant does not have loyalty program. */
  loyaltyRedemptionEnabled: Scalars['Boolean']
  /** The rate at which points are accrued for loyalty. Example: 2.0 means "1 point is earned for every 2 dollars" */
  accrualRate?: Maybe<Scalars['Float']>
  /** The number of points that a diner needs to reach before they are allowed to redeem */
  accrualTarget?: Maybe<Scalars['Int']>
  /** The amount each point is worth. Example: 0.2 means "0.2 dollars for every point" */
  conversionRate?: Maybe<Scalars['Float']>
  /** The signup method. It can be "EMAIL" or "PHONE_NUMBER". Will be null if the restaurant does not have loyalty program. */
  signupMethod?: Maybe<Scalars['String']>
  /** Whether loyalty program uses a custom description or not. Will be false if the restaurant does not have loyalty program. */
  useCustomDescription: Scalars['Boolean']
  /** The custom description text, in case the loyalty program uses one. Will be null if the restaurant does not have loyalty program. */
  customDescription?: Maybe<Scalars['String']>
  /** The loyalty program name. Will be null if the restaurant does not have loyalty program. Clients usually display "{Restaurant Name} Rewards Program" as a fallback. */
  programName?: Maybe<Scalars['String']>
}

/** An object representing a (restaurant-specific) loyalty redemption, which is tied to a customermgmt/loyalty account */
export type LoyaltyDiscount = {
  __typename?: 'LoyaltyDiscount'
  guid: Scalars['ID']
  amount: Scalars['Float']
  type: LoyaltyRedemptionType
  referenceId?: Maybe<Scalars['String']>
}

export enum LoyaltyErrorCode {
  /** If the restaurant does not have loyalty enabled. */
  RestaurantLoyaltyNotEnabled = 'RESTAURANT_LOYALTY_NOT_ENABLED',
  /** If the diner does not have a loyalty account. */
  NoLoyaltyAccount = 'NO_LOYALTY_ACCOUNT'
}

export type LoyaltyInquiryInput = {
  cartGuid: Scalars['ID']
}

/**
 * Only will be present if a loyalty account was successfully found with the email in the diner's customermgmt account
 * (also will be null if the diner is unauthenticated). Will trigger a loyalty inquiry from the Online Ordering API, which
 * in rare cases may cause the cart to change (i.e. the customer guid has changed after applying the redemption so the redemption is removed)
 */
export type LoyaltyInquiryResponse = {
  __typename?: 'LoyaltyInquiryResponse'
  /** The number of loyalty points the user has at this restaurant. */
  pointsBalance: Scalars['Int']
  /**
   * Available redemptions for this cart/customer. Will only have redemptions that can be applied to the current cart and with a value
   * dependent on the customer's earned loyalty points.
   */
  redemptions: Array<LoyaltyRedemption>
  /**
   * **The Masked** Identifier for the customer's loyalty account (the equivalent of customerGuid for customermgmt accounts, but for the loyalty account)
   * Will be null if no loyalty account was found for the customer.  ex: An identifier of 12345678 might have a masked identifier of 345678
   */
  loyaltyAccountIdentifier?: Maybe<Scalars['ID']>
  /**
   * Identifier for the customer's loyalty account (the equivalent of customerGuid for customermgmt accounts, but for the loyalty account)
   * Will be null if no loyalty account was found for the customer.
   */
  unmaskedLoyaltyAccountIdentifier?: Maybe<Scalars['String']>
  /** A hashed, secure token that can be used to access the card data for a loyalty account. */
  secureToken?: Maybe<Scalars['String']>
}

export type LoyaltyInquiryResponseOrError =
  | LoyaltyInquiryResponse
  | CartOutOfStockError
  | CustomerLoyaltyError

export type LoyaltyRedemption = {
  __typename?: 'LoyaltyRedemption'
  /** Type of this redemption discount, for displaying reward as birthday or cash. */
  type: LoyaltyRedemptionType
  /** Maximum redemption amount (may be greater than the total value of the cart, in which case only the cart's value is deducted) */
  redemptionAmount: Scalars['Float']
  /** Identifier for this redemption discount, for attaching to the cart if the user chooses. */
  redemptionGuid: Scalars['ID']
  /**
   * For 1st party this is the same string as type.
   * For 3rd party type will === LoyaltyRedemptionType.INTEGRATION and this will be the
   * id to use for applying or removing a discount.
   */
  referenceId?: Maybe<Scalars['String']>
  /** 3rd Party has a name */
  name?: Maybe<Scalars['String']>
  /**
   * 1st party uses quantity to populate redemptionAmount but 3rd party uses quantity to
   * denote how many times a reward may be used. Disambiguate by renaming to rewardQuantity
   * and only populating for 3rd party loyalty rewards.
   */
  rewardQuantity?: Maybe<Scalars['Int']>
}

/**
 * The guid refers to the guid the reward applies to. For check level rewards this is the same GUID.
 * To disambiguate for first party type is used. For third party we use referenceId and set the type
 * to INTEGRATION.
 */
export type LoyaltyRedemptionInput = {
  type: LoyaltyRedemptionType
  guid: Scalars['ID']
  /** If set prefer this over type for adding and removing a reward. */
  referenceId?: Maybe<Scalars['String']>
}

export enum LoyaltyRedemptionType {
  BirthdayRewards = 'BIRTHDAY_REWARDS',
  LoyaltyDollarsAmounts = 'LOYALTY_DOLLARS_AMOUNTS',
  /** 3rd party integration reward */
  Integration = 'INTEGRATION'
}

export type LoyaltySignupError = Error & {
  __typename?: 'LoyaltySignupError'
  code: LoyaltySignupErrorCode
  message: Scalars['String']
}

export enum LoyaltySignupErrorCode {
  SignupFailed = 'SIGNUP_FAILED'
}

export type LoyaltySignupInput = {
  loyaltyAccountCreationInput: LoyaltyAccountCreationInput
  customerGuid: Scalars['ID']
}

export type LoyaltySignupResponse = {
  __typename?: 'LoyaltySignupResponse'
  customer?: Maybe<Customer>
}

export type LoyaltySignupResponseOrError =
  | LoyaltySignupResponse
  | LoyaltySignupError

export type MakeCreditCardPrimaryError = Error & {
  __typename?: 'MakeCreditCardPrimaryError'
  code: MakeCreditCardPrimaryErrorCode
  message: Scalars['String']
}

export enum MakeCreditCardPrimaryErrorCode {
  CardNotFound = 'CARD_NOT_FOUND'
}

/** Guid of the desired card to be made the primary card for the account */
export type MakeCreditCardPrimaryInput = {
  cardGuid: Scalars['ID']
}

export type MakeCreditCardPrimaryResponse = {
  __typename?: 'MakeCreditCardPrimaryResponse'
  customer: Customer
}

export type MakeCreditCardPrimaryResponseOrError =
  | MakeCreditCardPrimaryResponse
  | MakeCreditCardPrimaryError

export type Menu = {
  __typename?: 'Menu'
  groups?: Maybe<Array<Maybe<MenuGroup>>>
  /**
   * This will be a unique identifier for this menu within the scope of the
   * restaurant but should be treated as an opaque string. It may be the numeric
   * database id or the guid, depending which API was used to retrieve the menu
   * but that should not be relied upon by the client.
   */
  id: Scalars['ID']
  isActive: Scalars['Boolean']
  /** Will only be populated if retrieved from DO Menus */
  masterId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

/** The available APIs for retrieving menu and stock data */
export enum MenuApiType {
  /** The legacy menu and stock endpoints in toastweb, requires a shortUrl to be provided */
  Toastweb = 'TOASTWEB',
  /** The endpoints in toast-do-menus, requires a restaurantGuid to be provided */
  Do = 'DO'
}

export type MenuGroup = {
  __typename?: 'MenuGroup'
  description?: Maybe<Scalars['String']>
  dietaryItemTags: Array<Menus_MenuItemTag>
  /**
   * Top-level menu group guid.
   * Do not use this for item related queries.
   * Instead, use MenuItem.itemGroupGuid.
   */
  guid: Scalars['ID']
  id?: Maybe<Scalars['String']>
  itemTags: Array<Menus_MenuItemTag>
  items?: Maybe<Array<Maybe<MenuItem>>>
  /** Will only be populated if retrieved from DO Menus */
  masterId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type MenuGroupOfferBadge = OfferBadge & {
  __typename?: 'MenuGroupOfferBadge'
  /** The menu group guid that should show this badge */
  groupGuid: Scalars['String']
  badgeText: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type MenuGroupV2 = {
  __typename?: 'MenuGroupV2'
  name: Scalars['String']
  guid: Scalars['ID']
  masterID?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  groups: Array<MenuGroupV2>
  items: Array<MenuItemV2>
}

/** An item in the menu */
export type MenuItem = {
  __typename?: 'MenuItem'
  description?: Maybe<Scalars['String']>
  /** Will only be populated if retrieved from DO Menus */
  masterId?: Maybe<Scalars['String']>
  guid: Scalars['ID']
  /**
   * This is the sub-group guid if the item is part of a subgroup.
   * Else, this is the top-level menu group guid.
   * Use this for item related queries.
   */
  itemGroupGuid: Scalars['ID']
  /** @deprecated use imageUrls */
  imageUrl?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  outOfStock?: Maybe<Scalars['Boolean']>
  /** @deprecated Use prices instead */
  price?: Maybe<Scalars['Float']>
  /**
   * prices contains either an array of a single price which is the
   * actual price of the menu item, or it contains a list of prices,
   * which are required size option modifiers that determine the menu item's price.
   * Clients can display these in whichever way is most appropriate
   * whether that's a range or list of the potential prices.
   */
  prices: Array<Scalars['Float']>
  /**
   * This optional argument allows the query to specify the level of
   * nesting in the modifiers. Any top level modifier with greater than
   * the specified level of nesting is removed, and empty modifier
   * groups are removed as well. A level of 1 will return mods
   * without nesting. A level of 2 will return mods with at most one
   * level of nesting, and so on.
   */
  modifierGroups: Array<ModifierGroup>
  calories?: Maybe<Scalars['Int']>
  unitOfMeasure: UnitOfMeasure
  usesFractionalQuantity?: Maybe<Scalars['Boolean']>
  itemTags: Array<Menus_MenuItemTag>
  dietaryItemTags: Array<Menus_MenuItemTag>
  contentAdvisories: Menus_ContentAdvisories
  imageUrls?: Maybe<ImageSet>
}

/** An item in the menu */
export type MenuItemModifierGroupsArgs = {
  nestingLevel?: Maybe<Scalars['Int']>
}

/** Note that shortUrl will remain required throughout the transition to the DO menu API */
export type MenuItemDetailsInput = {
  shortUrl: Scalars['String']
  /** Determine which menu API should be used for retrieving menu and stock data */
  menuApi?: Maybe<MenuApiType>
  /** Required if menuApi is DO */
  restaurantGuid?: Maybe<Scalars['ID']>
  itemGuid: Scalars['ID']
  itemGroupGuid: Scalars['ID']
  /**
   * Date should be in ISO 8601 format and include date and time.
   * Example: 2019-08-07T13:00:00-04:00
   * This is necessary if fetching menu items that are not currently available.
   * If the dateTime is not provided, the current time is used.
   */
  dateTime?: Maybe<Scalars['String']>
  /** Determines the visibility to filter menu items by. Defaults to TOAST_ONLINE_ORDERING. */
  visibility?: Maybe<Menus_VisibilityEnum>
}

export type MenuItemFromCart = {
  __typename?: 'MenuItemFromCart'
  modifierGroups: Array<ModifierGroup>
  specialRequest?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

/** Note that shortUrl will remain required throughout the transition to the DO menu API */
export type MenuItemFromCartInput = {
  shortUrl: Scalars['String']
  /** Determine which menu API should be used for retrieving menu and stock data */
  menuApi?: Maybe<MenuApiType>
  /** Required if menuApi is DO */
  restaurantGuid?: Maybe<Scalars['ID']>
  itemGuid: Scalars['ID']
  itemGroupGuid: Scalars['ID']
  cartGuid: Scalars['ID']
  selectionGuid: Scalars['ID']
}

export type MenuItemOfferBadge = OfferBadge & {
  __typename?: 'MenuItemOfferBadge'
  /** The menu item guid that should show this badge */
  itemGuid: Scalars['String']
  badgeText: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type MenuItemV2 = {
  __typename?: 'MenuItemV2'
  name: Scalars['String']
  guid: Scalars['ID']
  itemGroupGuid: Scalars['ID']
  description?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  pricingStrategy: PricingStrategy
  pricingRules?: Maybe<PricingRules>
  isDiscountable: Scalars['Boolean']
  taxInfo: Array<Scalars['String']>
  calories?: Maybe<Scalars['Int']>
  unitOfMeasure: UnitOfMeasure
  usesFractionalQuantity: Scalars['Boolean']
  outOfStock: Scalars['Boolean']
  stockQuantity?: Maybe<Scalars['Float']>
  modifierGroups: Array<ModifierGroupV2>
  imageUrl?: Maybe<Scalars['String']>
  itemTags: Array<Maybe<ItemTag>>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type MenuItemV2ModifierGroupsArgs = {
  nestingLevel?: Maybe<Scalars['Int']>
}

export type MenuOfferBadge = OfferBadge & {
  __typename?: 'MenuOfferBadge'
  /** The menu guid that should show this badge */
  menuGuid: Scalars['String']
  badgeText: Scalars['String']
}

export type MenuOfferBadgesInput = {
  /** The guid of the restaurant which has the order being paid for */
  restaurantGuid: Scalars['ID']
}

export type MenuOfferBadgesResponse = {
  __typename?: 'MenuOfferBadgesResponse'
  items: Array<MenuItemOfferBadge>
  groups: Array<MenuGroupOfferBadge>
  menus: Array<MenuOfferBadge>
}

export type MenuOfferBadgesResponseOrError =
  | MenuOfferBadgesResponse
  | GeneralError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type MenuV2 = {
  __typename?: 'MenuV2'
  name: Scalars['String']
  id: Scalars['ID']
  description?: Maybe<Scalars['String']>
  groups: Array<MenuGroupV2>
}

/** Note that shortUrl will remain required throughout the transition to the DO menu API */
export type MenusInput = {
  shortUrl: Scalars['ID']
  /** Determine which menu API should be used for retrieving menu and stock data */
  menuApi?: Maybe<MenuApiType>
  /** Required if menuApi is DO */
  restaurantGuid?: Maybe<Scalars['ID']>
  /**
   * Date should be in ISO 8601 format and include date and time.
   * Example: 2019-08-07T13:00:00-04:00
   */
  dateTime?: Maybe<Scalars['String']>
  /** Determines the visibility to filter menu items by. Defaults to TOAST_ONLINE_ORDERING. */
  visibility?: Maybe<Menus_VisibilityEnum>
}

export type MenusResponse = {
  __typename?: 'MenusResponse'
  menus: Array<Menu>
}

export type MenusResponseOrError = MenusResponse | GeneralError

/** A representation of com.toasttab.service.menus.api.Alcohol */
export type Menus_Alcohol = {
  __typename?: 'Menus_Alcohol'
  containsAlcohol?: Maybe<Menus_ContainsAlcohol>
}

/** A 1:1 representation of com.toasttab.service.menus.api.Availability */
export type Menus_Availability = {
  __typename?: 'Menus_Availability'
  alwaysAvailable: Scalars['Boolean']
  schedule?: Maybe<Array<Menus_Schedule>>
}

/**
 * A representation of com.toasttab.models.ContainsAlcohol
 *
 * Note this is being treated as logically belonging to toast-menus
 */
export enum Menus_ContainsAlcohol {
  Yes = 'YES',
  No = 'NO'
}

/** A representation of com.toasttab.service.menus.ContentAdvisories */
export type Menus_ContentAdvisories = {
  __typename?: 'Menus_ContentAdvisories'
  alcohol?: Maybe<Menus_Alcohol>
}

/** A 1:1 representation of com.toasttab.service.menus.api.MappablePricingStrategy */
export enum Menus_MappablePricingStrategy {
  Default = 'DEFAULT',
  SizePrice = 'SIZE_PRICE',
  MenuSpecificPrice = 'MENU_SPECIFIC_PRICE',
  TimeSpecificPrice = 'TIME_SPECIFIC_PRICE',
  OpenPrice = 'OPEN_PRICE',
  LocationSpecificPrice = 'LOCATION_SPECIFIC_PRICE',
  None = 'NONE',
  BasePrice = 'BASE_PRICE',
  SequencePrice = 'SEQUENCE_PRICE',
  SizeSequencePrice = 'SIZE_SEQUENCE_PRICE',
  GroupPrice = 'GROUP_PRICE'
}

/**
 * A 1:1 representation of com.toasttab.service.menus.api.v2.MenuGroupV2
 *
 * Menu groups are globally unique by `guid`.
 */
export type Menus_MenuGroupV2 = {
  __typename?: 'Menus_MenuGroupV2'
  guid: Scalars['String']
  name: Scalars['String']
  masterId?: Maybe<Scalars['String']>
  visibility: Array<Menus_VisibilityEnum>
  image?: Maybe<Scalars['String']>
  itemTags: Array<Menus_MenuItemTag>
  description?: Maybe<Scalars['String']>
  menuGroups: Array<Menus_MenuGroupV2>
  menuItems: Array<Menus_MenuItemV2>
}

/** A 1:1 representation of com.toasttab.service.menus.api.MenuItemTag */
export type Menus_MenuItemTag = {
  __typename?: 'Menus_MenuItemTag'
  guid?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

/**
 * A 1:1 representation of com.toasttab.service.menus.api.v2.MenuItemV2
 *
 * In general, menu items are *not* globally unique by `guid`. They are unique
 * in conjunction with their parent `Menus_MenuGroupV2`. Items with the same
 * `guid` may have different property values (e.g. `name`, `price`)
 * depending on their location within the menu structure.
 *
 * Note however that all menu items with the same `guid` share the same state
 * with regard to the `toast-stock` API.
 */
export type Menus_MenuItemV2 = {
  __typename?: 'Menus_MenuItemV2'
  guid: Scalars['String']
  name: Scalars['String']
  masterId?: Maybe<Scalars['String']>
  visibility: Array<Menus_VisibilityEnum>
  image?: Maybe<Scalars['String']>
  itemTags: Array<Menus_MenuItemTag>
  dietaryItemTags: Array<Menus_MenuItemTag>
  modifierGroupReferences: Array<Scalars['Int']>
  portions: Array<Menus_PortionV2>
  calories?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['Float']>
  pricingStrategy: Menus_MappablePricingStrategy
  pricingRules?: Maybe<Menus_PricingRules>
  salesCategory?: Maybe<Menus_SalesCategory>
  taxInfo: Array<Scalars['String']>
  isDiscountable: Scalars['Boolean']
  plu: Scalars['String']
  sku: Scalars['String']
  description: Scalars['String']
  unitOfMeasure: Menus_UnitOfMeasure
  contentAdvisories: Menus_ContentAdvisories
}

/**
 * A 1:1 representation of com.toasttab.service.menus.api.v2.MenuOptionGroupV2
 *
 * In general, menu option groups are *not* globally unique by `guid`. They are
 * unique in conjunction with their `referenceId`. Option groups with the same
 * `guid` may have different property values (e.g. `name`, `pricingRules`)
 * depending on their location within the menu structure.
 */
export type Menus_MenuOptionGroupV2 = {
  __typename?: 'Menus_MenuOptionGroupV2'
  guid: Scalars['String']
  name: Scalars['String']
  masterId?: Maybe<Scalars['String']>
  visibility: Array<Menus_VisibilityEnum>
  /** Note this is a "YES"/"NO" string, not a boolean */
  defaultOptionsChargePrice?: Maybe<Scalars['String']>
  requiredMode: Menus_RequiredMode
  minSelections: Scalars['Int']
  /** Note this is a "YES"/"NO" string, not a boolean */
  defaultOptionsSubstitutionPricing?: Maybe<Scalars['String']>
  modifierOptionReferences: Array<Scalars['Int']>
  isMultiSelect?: Maybe<Scalars['Boolean']>
  maxSelections?: Maybe<Scalars['Int']>
  preModifierGroupReference?: Maybe<Scalars['Int']>
  pricingStrategy: Menus_MappablePricingStrategy
  pricingRules?: Maybe<Menus_PricingRules>
  referenceId: Scalars['Int']
}

/** A 1:1 representation of com.toasttab.service.menus.api.v2.MenuResponseV2 */
export type Menus_MenuResponseV2 = {
  __typename?: 'Menus_MenuResponseV2'
  menus: Array<Menus_MenuV2>
  restaurantGuid: Scalars['String']
  lastUpdated?: Maybe<Scalars['String']>
  restaurantTimeZone?: Maybe<Scalars['String']>
  /** List of entries that should be associated by referenceId to build a lookup map */
  modifierGroupReferences: Array<Menus_MenuOptionGroupV2>
  /** List of entries that should be associated by referenceId to build a lookup map */
  modifierOptionReferences: Array<Menus_ModifierOptionV2>
  /** List of entries that should be associated by referenceId to build a lookup map */
  preModifierGroupReferences: Array<Menus_PreModifierGroupV2>
}

/**
 * A 1:1 representation of com.toasttab.service.menus.api.v2.MenuV2
 *
 * Menus are globally unique by `guid`.
 */
export type Menus_MenuV2 = {
  __typename?: 'Menus_MenuV2'
  guid: Scalars['String']
  name: Scalars['String']
  masterId?: Maybe<Scalars['String']>
  visibility: Array<Menus_VisibilityEnum>
  highResImage?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  availability: Menus_Availability
  menuGroups: Array<Menus_MenuGroupV2>
  description: Scalars['String']
}

/** A 1:1 representation of com.toasttab.service.menus.api.ModifierOptionTaxInfo */
export type Menus_ModifierOptionTaxInfo = {
  __typename?: 'Menus_ModifierOptionTaxInfo'
  taxRateGuids: Array<Scalars['String']>
  overrideItemTaxRates: Scalars['Boolean']
}

/**
 * A 1:1 representation of com.toasttab.service.menus.api.v2.ModifierOptionV2
 *
 * In general, modifier options are *not* globally unique by `guid`. They are
 * unique in conjunction with their `referenceId`. Option groups with the same
 * `guid` may have different property values (e.g. `name`, `pricingRules`)
 * depending on their location within the menu structure.
 *
 * Note however that all modifier options with the same `guid` share the same
 * state with regard to the `toast-stock` API.
 */
export type Menus_ModifierOptionV2 = {
  __typename?: 'Menus_ModifierOptionV2'
  guid: Scalars['String']
  name: Scalars['String']
  masterId?: Maybe<Scalars['String']>
  modifierOptionTaxInfo: Menus_ModifierOptionTaxInfo
  visibility: Array<Menus_VisibilityEnum>
  referenceId: Scalars['Int']
  image?: Maybe<Scalars['String']>
  itemTags: Array<Menus_MenuItemTag>
  isDefault?: Maybe<Scalars['Boolean']>
  allowsDuplicates?: Maybe<Scalars['Boolean']>
  price?: Maybe<Scalars['Float']>
  modifierGroupReferences: Array<Scalars['Int']>
  portions: Array<Maybe<Menus_PortionV2>>
  calories?: Maybe<Scalars['Int']>
  pricingStrategy: Menus_MappablePricingStrategy
  pricingRules?: Maybe<Menus_PricingRules>
  salesCategory?: Maybe<Menus_SalesCategory>
  taxInfo: Array<Scalars['String']>
  plu: Scalars['String']
  sku: Scalars['String']
  description: Scalars['String']
  unitOfMeasure: Menus_UnitOfMeasure
}

/** A 1:1 representation of com.toasttab.service.menus.api.v2.PortionV2 */
export type Menus_PortionV2 = {
  __typename?: 'Menus_PortionV2'
  name: Scalars['String']
  guid: Scalars['String']
  modifierGroupReferences: Array<Scalars['Int']>
}

/** A 1:1 representation of com.toasttab.service.menus.api.v2.PreModifierGroupV2 */
export type Menus_PreModifierGroupV2 = {
  __typename?: 'Menus_PreModifierGroupV2'
  referenceId: Scalars['Int']
  guid: Scalars['String']
  name: Scalars['String']
  preModifiers: Array<Menus_PreModifierV2>
}

/** A 1:1 representation of com.toasttab.service.menus.api.v2.PreModifierV2 */
export type Menus_PreModifierV2 = {
  __typename?: 'Menus_PreModifierV2'
  guid: Scalars['String']
  name: Scalars['String']
  fixedPrice?: Maybe<Scalars['Float']>
  multiplicationFactor?: Maybe<Scalars['Float']>
  displayMode: Menus_PreModifierV2DisplayMode
}

/** A 1:1 representation of com.toasttab.service.menus.api.v2.PreModifierV2.DisplayMode */
export enum Menus_PreModifierV2DisplayMode {
  Prefix = 'PREFIX',
  Suffix = 'SUFFIX',
  Other = 'OTHER'
}

/** A 1:1 representation of com.toasttab.service.menus.api.PricingRules */
export type Menus_PricingRules = {
  __typename?: 'Menus_PricingRules'
  timeSpecificPricingRules: Array<Menus_TimeSpecificPricingRule>
  sizeSpecificPricingGuid?: Maybe<Scalars['String']>
  sizeSequencePricingRules: Array<Menus_SizeSequencePricingRule>
}

/** A 1:1 representation of com.toasttab.service.menus.api.RequiredMode */
export enum Menus_RequiredMode {
  Required = 'REQUIRED',
  OptionalForceShow = 'OPTIONAL_FORCE_SHOW',
  Optional = 'OPTIONAL'
}

/** A 1:1 representation of com.toasttab.service.menus.api.SalesCategory */
export type Menus_SalesCategory = {
  __typename?: 'Menus_SalesCategory'
  name?: Maybe<Scalars['String']>
  guid?: Maybe<Scalars['String']>
}

/** A 1:1 representation of com.toasttab.service.menus.api.Schedule */
export type Menus_Schedule = {
  __typename?: 'Menus_Schedule'
  days: Array<Scalars['String']>
  timeRanges: Array<Menus_TimeRange>
}

/** A 1:1 representation of com.toasttab.service.menus.api.SequencePrice */
export type Menus_SequencePrice = {
  __typename?: 'Menus_SequencePrice'
  sequence: Scalars['Int']
  price: Scalars['Float']
}

/** A 1:1 representation of com.toasttab.service.menus.api.SizeSequencePricingRule */
export type Menus_SizeSequencePricingRule = {
  __typename?: 'Menus_SizeSequencePricingRule'
  sizeName?: Maybe<Scalars['String']>
  sizeGuid?: Maybe<Scalars['String']>
  sequencePrices: Array<Menus_SequencePrice>
}

/** A 1:1 representation of com.toasttab.service.menus.api.TimeRange */
export type Menus_TimeRange = {
  __typename?: 'Menus_TimeRange'
  start: Scalars['String']
  end: Scalars['String']
}

/** A 1:1 representation of com.toasttab.service.menus.api.TimeSpecificPricingRule */
export type Menus_TimeSpecificPricingRule = {
  __typename?: 'Menus_TimeSpecificPricingRule'
  timeSpecificPrice: Scalars['Float']
  basePrice: Scalars['Float']
  schedule: Array<Menus_Schedule>
}

/** A 1:1 representation of com.toasttab.service.menus.api.UnitOfMeasure */
export enum Menus_UnitOfMeasure {
  None = 'NONE',
  Lb = 'LB',
  Oz = 'OZ',
  Kg = 'KG',
  G = 'G'
}

/** A 1:1 representation of com.toasttab.service.menus.api.VisibilityEnum */
export enum Menus_VisibilityEnum {
  Grubhub = 'GRUBHUB',
  Kiosk = 'KIOSK',
  OrderingPartners = 'ORDERING_PARTNERS',
  Pos = 'POS',
  ToastOnlineOrdering = 'TOAST_ONLINE_ORDERING',
  Unknown = 'UNKNOWN'
}

/** Response to indicate that a Multi-Factor Auth challenge is required */
export type MfaChallengeGeneratedResponse = {
  __typename?: 'MfaChallengeGeneratedResponse'
  /** MFA Challenge Token - required on verification */
  challengeToken: Scalars['String']
  /** Method used to send the MFA code */
  mfaType: MfaType
  /** Masked email address/phone number to which the MFA code was sent */
  maskedRecipient?: Maybe<Scalars['String']>
}

/** MFA Challenge input */
export type MfaLoginInput = {
  /** Challenge token sent in the **login** response */
  challengeToken: Scalars['String']
  /** MFA Code sent via email/sms */
  code: Scalars['String']
  /** Email used to look up user account */
  email: Scalars['String']
}

export enum MfaType {
  EmailChallenge = 'EMAIL_CHALLENGE',
  SmsChallenge = 'SMS_CHALLENGE'
}

export type MissingCustomerOrGuestGuidError = {
  __typename?: 'MissingCustomerOrGuestGuidError'
  message?: Maybe<Scalars['String']>
  code?: Maybe<GeneralErrorCode>
}

export type Modifier = {
  __typename?: 'Modifier'
  itemGuid: Scalars['ID']
  itemGroupGuid?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  isDefault?: Maybe<Scalars['Boolean']>
  selected?: Maybe<Scalars['Boolean']>
  modifierGroups: Array<ModifierGroup>
  outOfStock?: Maybe<Scalars['Boolean']>
  /**
   * Required for modifier quantity implementation.
   * Will only be populated if retrieved from DO Menus.
   */
  allowsDuplicates?: Maybe<Scalars['Boolean']>
}

export type ModifierGroup = {
  __typename?: 'ModifierGroup'
  guid: Scalars['ID']
  maxSelections?: Maybe<Scalars['Int']>
  minSelections?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  pricingMode?: Maybe<Scalars['String']>
  modifiers: Array<Modifier>
}

export type ModifierGroupInput = {
  guid: Scalars['ID']
  modifiers: Array<SelectionInput>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type ModifierGroupV2 = {
  __typename?: 'ModifierGroupV2'
  name: Scalars['String']
  guid: Scalars['ID']
  description?: Maybe<Scalars['String']>
  pricingStrategy: PricingStrategy
  pricingRules?: Maybe<PricingRules>
  defaultOptionsChargePrice: Scalars['Boolean']
  defaultOptionsSubstitutionPricing: Scalars['Boolean']
  minSelections: Scalars['Int']
  maxSelections?: Maybe<Scalars['Int']>
  requiredMode: RequiredMode
  isMultiSelect: Scalars['Boolean']
  modifiers: Array<ModifierOptionV2>
}

/** Note that shortUrl will remain required throughout the transition to the DO menu API */
export type ModifierGroupsInput = {
  shortUrl: Scalars['String']
  /** Determine which menu API should be used for retrieving menu and stock data */
  menuApi?: Maybe<MenuApiType>
  /** Required if menuApi is DO */
  restaurantGuid?: Maybe<Scalars['ID']>
  itemGuid: Scalars['ID']
  itemGroupGuid: Scalars['ID']
}

export type ModifierOptionV2 = {
  __typename?: 'ModifierOptionV2'
  name: Scalars['String']
  guid: Scalars['ID']
  itemGuid: Scalars['ID']
  description?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  pricingStrategy: PricingStrategy
  pricingRules?: Maybe<PricingRules>
  calories?: Maybe<Scalars['Int']>
  isDefault: Scalars['Boolean']
  allowsDuplicates: Scalars['Boolean']
  unitOfMeasure: UnitOfMeasure
  modifierGroups: Array<ModifierGroupV2>
  outOfStock: Scalars['Boolean']
  stockQuantity?: Maybe<Scalars['Float']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** @deprecated use addItemToCartV2 */
  addItemToCart?: Maybe<AddItemToCartResponse>
  /** @deprecated use deleteItemFromCartV2 */
  deleteItemFromCart?: Maybe<DeleteItemFromCartResponse>
  /** @deprecated use addItemToCartV2 */
  createCart?: Maybe<CreateCartResponse>
  /** @deprecated use editItemInCartV2 */
  editItemInCart?: Maybe<EditItemInCartResponse>
  /**
   * Adds the given **selection** to the cart represented by **cartGuid**, if **cartGuid** is given.
   * Otherwise, creates a cart and add the given **selection** to the newly created cart.
   */
  addItemToCartV2: CartModificationResponseOrError
  /** Modifies the cart item represented by **selectionGuid** in the cart represented by **cartGuid** to be the same as the given **selection**. */
  editItemInCartV2: CartModificationResponseOrError
  /** Deletes the cart item represented by **selectionGuid** in the cart represented by **cartGuid**. */
  deleteItemFromCartV2: CartModificationResponseOrError
  /**
   * Modifies the fulfillment information for the cart represented by **cartGuid**.
   * All items in the cart will be removed *if and only if* the fulfillment type or time changed.
   * This is a workaround because we cannot currently determine if existing items in the cart are
   * available at the newly selected time.
   *
   * The following are considered fulfillment information for the purpose of this mutation:
   * - fulfillment type (**ASAP** / **FUTURE**)
   * - fulfillment date+time
   * - dining option behavior (**TAKE_OUT** / **DELIVERY**)
   * - delivery info
   */
  updateFulfillmentAndClearCart: CartModificationResponseOrError
  /**
   * Modifies the fulfillment information for the cart represented by **cartGuid**.
   * Items in the cart will be validated using online-ordering validation endpoint.
   *
   * The following are considered fulfillment information for the purpose of this mutation:
   * - fulfillment type (**ASAP** / **FUTURE**)
   * - fulfillment date+time
   * - dining option behavior (**TAKE_OUT** / **DELIVERY**)
   * - delivery info
   */
  updateFulfillmentAndValidate: CartModificationResponseOrError
  /** Updates the dining type of the cart. Currently this is controlled by the dining option, which is either takeout or outpost. */
  updateDiningOption: CartModificationResponseOrError
  /** Removes items from the cart if they are out of stock */
  validateCartPreCheckout: CartModificationResponseOrError
  /**
   * Adds a loyalty redemption discount to the cart. Only one redemption can be present on a cart at a time,
   * but it can be combined with a promo code discount. Loyalty points will be adjusted after the order is placed,
   * not when the redemption is added. To get the required **redemptionGuid** field, call the **loyaltyInquiry** mutation.
   */
  addLoyaltyRedemption: CartModificationResponseOrError
  /**
   * Adds a loyalty redemption discount to the cart. Differs from ***addLoyaltyRedemption*** as it takes a
   * ***AddLoyaltyRedemptionV2Input***.
   * ***AddLoyaltyRedemptionV2Input*** includes the guid of the redemption and the type of the redemption in order to
   * add a specific type of loyalty redemption.
   * This allows for redemption of multiple types of loyalty rewards.
   * Loyalty points will be adjusted after the order is placed, not when the redemption is added.
   * To get the required **guid** and **type** field, call the **loyaltyInquiry** mutation.
   */
  addLoyaltyRedemptionV2: CartModificationResponseOrError
  /**
   * Removes a loyalty redemption discount from the cart. Does not remove the attached loyalty info on the cart, so
   * accruals will still take place for the given customer email when the order is placed.
   */
  removeLoyaltyRedemption: CartModificationResponseOrError
  /**
   * Removes a loyalty redemption discount from the cart. Does not remove the attached loyalty info on the cart, so
   * accruals will still take place for the given customer email when the order is placed.
   * Differs from ***removeLoyaltyRedemption*** as it takes a ***RemoveLoyaltyRedemptionV2Input***.
   * ***RemoveLoyaltyRedemptionV2Input*** includes the guid of the redemption and the type of the redemption in order to
   * remove a specific type of loyalty redemption.
   * This allows for redemption of multiple types of loyalty rewards.
   */
  removeLoyaltyRedemptionV2: CartModificationResponseOrError
  /**
   * Performs a loyalty inquiry, which attempts to find a loyalty account for the given customer guid and attaches it
   * to the cart. If an account was found, the cart will be repriced, which may trigger an out of stock cart adjustment,
   * but otherwise the cart should be unchanged.
   */
  loyaltyInquiry: LoyaltyInquiryResponseOrError
  /**
   * Performs a loyalty inquiry, which attempts to find a loyalty account for the given customer guid and attaches it
   * to the cart. If an account was found, the cart will be repriced, which may trigger an out of stock cart adjustment,
   * but otherwise the cart should be unchanged.
   * Differs from ***loyaltyInquiry*** as it includes birthday rewards in the response.
   * It also returns the type of the redemption - to allow for redemption of multiple types of loyalty rewards.
   */
  loyaltyInquiryV2: LoyaltyInquiryResponseOrError
  /**
   * Places the order using the cart referenced by **cartGuid** using a cash payment (in store), and sends an email receipt
   * to the email in the customer object.
   * If the cart was modified by the server, e.g. an item on the cart is out of stock or order time has been adjusted,
   * the cart will be returned in the **PlaceOrderCartUpdatedError** response since it was modified;
   * otherwise either a successful **PlaceOrderResponse** will be returned (with a warning if afterPlaceOrder failed)
   * or a generic **PlaceOrderError** with a code specifying the error if the request was unsuccessful.
   */
  placeCashOrder: PlaceCashOrderResponse
  /**
   * Places the order using the cart referenced by **cartGuid** using an Apple Pay payment token, and sends an email receipt
   * to the email in the customer object.
   * If the cart was modified by the server, e.g. an item on the cart is out of stock or order time has been adjusted,
   * the cart will be returned in the **PlaceOrderCartUpdatedError** response since it was modified;
   * otherwise either a successful **PlaceOrderResponse** will be returned (with a warning if afterPlaceOrder failed)
   * or a generic **PlaceOrderError** with a code specifying the error if the request was unsuccessful.
   */
  placeApplePayOrder: PlaceApplePayOrderResponse
  /**
   * Places the order using the cart referenced by **cartGuid** using a credit card payment, and sends an email receipt
   * to the email in the customer object.
   * If the cart was modified by the server, e.g. an item on the cart is out of stock or order time has been adjusted,
   * the cart will be returned in the **PlaceOrderCartUpdatedError** response since it was modified;
   * otherwise either a successful **PlaceOrderResponse** will be returned (with a warning if afterPlaceOrder failed)
   * or a generic **PlaceOrderError** with a code specifying the error if the request was unsuccessful.
   */
  placeCcOrder: PlaceCcOrderResponse
  /**
   * accepts the validationURL generated by Apple Pay JS on the client and passes it through to the
   * Apple Pay server’s Payment Session endpoint
   */
  validateApplePayMerchant: ValidateApplePayMerchantResponse
  /**
   * Attempts to redeem the promo code to the cart referenced in the input.
   * On success,  will return the cart with the reward applied. On failure, it will return an error.
   * The response is optional because the user needs to be authenticated to actually apply a reward.
   * @deprecated use applyPromoCodeV3
   */
  applyPromoCode?: Maybe<ApplyPromoCodeResponseOrError>
  /**
   * Attempts to redeem the promo code of given type (currently only supports Restaurant) in the input to the cart referenced
   * in the input. On success,  will return the cart with the reward applied. On failure, it will return an error.
   * @deprecated use applyPromoCodeV3
   */
  applyPromoCodeV2: ApplyPromoCodeResponseOrErrorV2
  /**
   * Attempts to redeem the promo code for any supported reward types for the cart.
   * Only one reward type is supported on a cart at a time.  If a promo code reward already exists, a ApplyPromoCodeError
   * will be returned.
   * On success,  will return the cart with the reward applied. On failure, it will return an error.
   */
  applyPromoCodeV3: ApplyPromoCodeResponseOrErrorV2
  /**
   * Attempts to remove the promo code of given type (currently only supports Restaurant) in the input with the guid in the input
   * from the cart in the input. On success, will return the cart with the removed discount. On failure, it will return an error.
   * @deprecated use removePromoCodeV2
   */
  removePromoCode: RemovePromoCodeResponseOrError
  /**
   * Attempts to remove all rewards for the the promo code. On success, will return the cart with the removed discount.
   * On failure, it will return an error.
   */
  removePromoCodeV2: RemovePromoCodeResponseOrError
  /**
   * Creates a new cart in the restaurant referenced by **restaurantGuid**,
   * Attempts to add each item in the order referenced by **orderGuid** to the newly created cart and return the cart.
   * If any of the items is unavailable, they'll be added to the warning message in **warnings**.
   * @deprecated use reorderV2
   */
  reorder?: Maybe<ReorderResponse>
  /**
   * Creates a new cart in the restaurant referenced by **restaurantGuid**,
   * Attempts to add each item in the order referenced by **orderGuid** to the newly created cart and return the cart.
   * If any of the items is unavailable, but at least one item is available, the unavailable items will be added to a warning in **warningsV2**.
   * If an error is encountered, a **ReorderError** is returned. See **ReorderErrorCode** for the possible errors.
   * A null return value indicates that the customer is unauthenticated.
   */
  reorderV2?: Maybe<ReorderResponseOrError>
  /**
   * Allows guests to submit feedback and tag said feedback with specific tags. Also allows
   * for open text feedback
   */
  submitGuestFeedbackV2: SubmitGuestFeedbackV2Response
  /** Create a customer account, which can be used to store basic info, past orders, and credit cards */
  createAccount: CreateAccountResponseOrError
  /** Start profile creation (process) to start passwordless auth flow */
  startProfileCreation: StartProfileCreationResponseOrError
  /** Complete profile creation after user has confirmed their phone number with Auth0 thus verifying the users account */
  completeProfileCreation: CompleteProfileCreationResponseOrError
  /**
   * Start identity and profile creation (process) to start passwordless auth flow for unified login
   *
   * For clients that are creating accounts using unified login, they must call this API
   * before passwordlessStart in order to ensure an Auth0 account exists for the guest
   *
   * Successful response: null
   * Error response: StartIdentityProfileError
   */
  startIdentityProfile?: Maybe<StartIdentityProfileError>
  /** For unified login flows, this API is called to create a verified profile for the guest */
  completeIdentityProfile: CompleteIdentityProfileResponseOrError
  /**
   * Create a customer account, which can be used to store basic info, past orders, and credit cards
   * This account has already been verified through sms verification and will return an authenticated response.
   */
  createConfirmedAccount: CreateConfirmedAccountResponseOrError
  /**
   * Verifies the email for a new account, which finishes account creation allowing the account
   * to be logged into and used
   */
  verifyEmail: VerifyEmailResponse
  /** Update the firstName, lastName, and/or phone number on the account */
  updateBasicInfo: UpdateBasicInfoResponseOrError
  /** Updates an existing address on the account */
  updateAddress: UpdateAddressResponseOrError
  /** Deletes an existing address on the account */
  deleteAddress: DeleteAddressResponseOrError
  /** Sets the credit card as the primary (used by default) card on the account */
  makeCreditCardPrimary: MakeCreditCardPrimaryResponseOrError
  /** Removes the credit card from the account (and the associated secure token for the card from ccpartner) */
  deleteCreditCard: DeleteCreditCardResponseOrError
  /** Change the password for the account, requires the old password to match in order to update successfully */
  updatePassword: UpdatePasswordResponseOrError
  /**
   * Sends an email to the given address which contains a confirmation link with a token that will allow a new password
   * to be entered for the account.
   */
  initiateEmailPasswordReset: InitiateEmailPasswordResetResponse
  /**
   * Uses the **resetToken** (obtained from the email sent by **initiateEmailPasswordReset**) to change the old password to
   * the specified new password.
   */
  resetPassword: ResetPasswordResponse
  /**
   * Create a new loyalty account using the same email as the customermgmt account. This shared email is the only link between
   * the two accounts. Requires the customer account to already have a saved basic info (first name, last name, phone).
   */
  loyaltySignup: LoyaltySignupResponseOrError
  /**
   * Will attempt to import and merge data from the given customermgmt account to the guest-profiles account.
   * Request must be sent along with valid access tokens for both the customermgmt and guest-profiles accounts
   * in the 'toast-customer-access' and 'authorization' headers, respectively. The customerGuid and guestGuid will
   * be extracted from these tokens and used for the import.
   */
  importAccount: ImportAccountResponseOrError
  /**
   * Will attempt to delete a guest with a guest-profiles account.
   * Request must be sent along with a valid access token for guest-profiles in the 'authorization' header.
   * The guestGuid will be extracted from the token and used for account deletion.
   */
  deleteAccount: DeleteAccountResponseOrError
  /**
   * Create new access and refresh tokens using an email and password login for the account.
   * These tokens are used for requests that require a user to be logged in, such as getting the list of a user's saved credit cards.
   * See **AuthenticationResponse** for more information on token usage.
   */
  login: LoginResponse
  /**
   * Create new access and refresh tokens using a previously obtained refresh token (from ***login*** or from ***refresh***).
   * These tokens are used for requests that require a user to be logged in, such as getting the list of a user's saved credit cards.
   * See **AuthenticationResponse** for more information on token usage.
   */
  refresh: RefreshResponse
  /** MFA Challenge Login Mutation - creates new access and refresh tokens when code is correct. */
  mfaLogin: LoginResponse
  /**
   * Attempts to create a new account with the given parameters. Will create the account in guest-profiles and then call toast-authentication
   * to send a code to the guest for account verification/login.
   */
  passwordlessSignup: PasswordlessSignupResponseOrError
  /**
   * Begins the process to login. Will call toast-authentication to send a code to the guest for account verification/login.
   * Used for PW- Auth V1 Workflows
   * @deprecated use passwordlessLoginUnified
   */
  passwordlessLogin: PasswordlessLoginResponseOrError
  /**
   * Begins the process to login. Will call toast-authentication to send a code to the guest for account verification/login and will create
   * user account in toast-users if it doesnt already exist.
   * Used for PW- Auth V2 Workflows where login/create account flows are unified
   */
  passwordlessLoginUnified: PasswordlessLoginUnifiedResponseOrError
  /**
   * Validates that the given code was the one sent to the guest. Will call toast-authentication to verify the code and if successful will return an
   * accessToken and refreshToken. Will also call guest-profiles to verify the account.
   * Used for PW- Auth V1 Workflows
   * @deprecated use passwordlessConfirmCodeUnified
   */
  passwordlessConfirmCode: PasswordlessTokenResponseOrError
  /**
   * Validates that the given code was the one sent to the guest. Will call toast-authentication to verify the code and if successful will return an
   * accessToken and refreshToken
   * Used for PW- Auth V2 Workflows where login/create account flows are unified
   */
  passwordlessConfirmCodeUnified: PasswordlessTokenUnifiedResponseOrError
  /** Retrieves a new accessToken and refreshToken. */
  passwordlessRefreshToken: PasswordlessTokenResponseOrError
  /** Revokes the given refresh token. Effectly rendering the token useless. The guest will need to login in order to be issued a new valid refresh token. */
  passwordlessLogout: PasswordlessLogoutResponseOrError
  /** Adds a new push notification target to the customer referenced by **customerGuid**. */
  addPushNotificationTargetToCustomer?: Maybe<AddPushNotificationTargetToCustomerResponse>
  /** Deletes a customer's push notification target referenced by **customerGuid**. */
  deleteCustomerPushNotificationTarget?: Maybe<DeleteCustomerPushNotificationTargetResponse>
  /** Allows clients to pay for an open check */
  payForCheck: PayForCheckResponseOrError
  /** Allows clients to sign-up to restaurant's rewards program */
  signUpToLoyalty: SignUpToLoyaltyResponseOrError
  /** Allows clients to apply promos to the current in progress order */
  applyPromoInProgressOrder?: Maybe<InProgressOrder>
  /** Allows clients to send a pay at a distance link */
  sendPaadLink: SendPayAtADistanceResponse
  /** Add an item to an existing order at table cart or create + add an item to a new order at table cart */
  addItemToOrderAtTableCart: OrderAtTableCart
  /** Creates a tab and adds items from current cart to tab, creates payment and returns an order with associated payment (Hack TODO remove this) */
  createAndAddToOrderAtTableTab?: Maybe<OptAddToTabResponseOrError>
  /** Creates a preauth tab with an order containing the saved preauth info and no selections */
  optAddPreauthToParty?: Maybe<OptPartyRefreshResponse>
  /** Add items from current cart to existing tab */
  addToOrderAtTableTab?: Maybe<OptAddToTabResponseOrError>
  /** Place an order for an order at table cart */
  placeOrderAtTableOrder?: Maybe<PlaceOptOrderResponse>
  /** Place an order for an order at table cart */
  placeOrderAtTableOrderV2?: Maybe<PlaceOptOrderResponse>
  /** Edit an item in an existing order at table cart */
  editItemInOrderAtTableCart: OrderAtTableCart
  /** Delete an item in an existing order at table cart */
  deleteItemInOrderAtTableCart: OrderAtTableCart
  /** Fetches the table guid for a given table name at a restaurant and updates the cart with that table */
  getTableGuidAndUpdateCart: Table
  /** Submit feedback from a guest */
  submitGuestFeedback: GuestFeedbackResponse
  /** Apply a promo code to an order at table cart */
  optApplyPromoCode: OrderAtTableCart
  /** Apply a promo code to an order at table tab */
  optTabApplyPromoCode: OptTabOrder
  /** Remove promo code associated with an order at table cart */
  optRemovePromoCode: OrderAtTableCart
  /** Remove promo code associated with a table tab */
  optTabRemovePromoCode: OptTabOrder
  /** GROUP ORDERING: Creates a Party */
  optCreateParty: OptPartyResponse
  /** GROUP ORDERING: Creates a Party with Refresh Return */
  optCreatePartyRefresh: OptPartyRefreshResponse
  /** GROUP ORDERING: Joins a Party */
  optJoinParty: OptPartyRefreshResponse
  /** GROUP ORDERING: Joins a Party */
  optJoinPartySecure: OptPartyRefreshResponse
  /** GROUP ORDERING: Adds an item to a party member's cart */
  optAddItemToPartyMemberCart: OptPartyRefreshResponse
  /** GROUP ORDERING: Edits an item in a party member's cart */
  optEditItemInPartyMemberCart: OptPartyRefreshResponse
  /** GROUP ORDERING: Edits an item in a party member's cart */
  optDeleteItemInPartyMemberCart: OptPartyRefreshResponse
  /** GROUP ORDERING: Fires items in member's carts to an order */
  optFireTabRound: OptPartyRefreshResponse
  /** SPLIT PAYMENT: Creates a new shared bucket to place items in */
  optCreateSplitPaymentBucket: OptPartyRefreshResponse
  /** SPLIT PAYMENT: Moves items between buckets to map items to the party members who are paying for them */
  optMoveItemsInSplitPaymentBucket: OptPartyRefreshResponse
  /** SPLIT PAYMENT: Adds party members to a shared bucket who will participate in paying for the items in the given bucket */
  optAddParticipantsToSplitPaymentBucket: OptPartyRefreshResponse
  /** SPLIT PAYMENT: Removes party members from a shared bucket */
  optRemoveParticipantsFromSplitPaymentBucket: OptPartyRefreshResponse
  generateSmsVerificationCode?: Maybe<GenerateSmsVerificationCodeResponse>
  verifySmsCode?: Maybe<VerifySmsCodeResponse>
  /** Allows clients to authorize a card for an amount */
  authorizePayment: AuthorizePaymentResponseOrError
}

export type MutationAddItemToCartArgs = {
  input: AddItemToCartInput
}

export type MutationDeleteItemFromCartArgs = {
  input: DeleteItemFromCartInput
}

export type MutationCreateCartArgs = {
  input: CreateCartInput
}

export type MutationEditItemInCartArgs = {
  input: EditItemInCartInput
}

export type MutationAddItemToCartV2Args = {
  input: AddItemToCartInputV2
}

export type MutationEditItemInCartV2Args = {
  input: EditItemInCartInput
}

export type MutationDeleteItemFromCartV2Args = {
  input: DeleteItemFromCartInput
}

export type MutationUpdateFulfillmentAndClearCartArgs = {
  input: UpdateFulfillmentAndClearCartInput
}

export type MutationUpdateFulfillmentAndValidateArgs = {
  input: UpdateFulfillmentAndValidateInput
}

export type MutationUpdateDiningOptionArgs = {
  input: UpdateDiningOptionInput
}

export type MutationValidateCartPreCheckoutArgs = {
  input: ValidateCartPreCheckoutInput
}

export type MutationAddLoyaltyRedemptionArgs = {
  input: AddLoyaltyRedemptionInput
}

export type MutationAddLoyaltyRedemptionV2Args = {
  input: AddLoyaltyRedemptionV2Input
}

export type MutationRemoveLoyaltyRedemptionArgs = {
  input: RemoveLoyaltyRedemptionInput
}

export type MutationRemoveLoyaltyRedemptionV2Args = {
  input: RemoveLoyaltyRedemptionV2Input
}

export type MutationLoyaltyInquiryArgs = {
  input: LoyaltyInquiryInput
}

export type MutationLoyaltyInquiryV2Args = {
  input: LoyaltyInquiryInput
}

export type MutationPlaceCashOrderArgs = {
  input: PlaceCashOrderInput
}

export type MutationPlaceApplePayOrderArgs = {
  input: PlaceApplePayOrderInput
}

export type MutationPlaceCcOrderArgs = {
  input: PlaceCcOrderInput
}

export type MutationValidateApplePayMerchantArgs = {
  input: ValidateApplePayMerchantInput
}

export type MutationApplyPromoCodeArgs = {
  input: ApplyPromoCodeInput
}

export type MutationApplyPromoCodeV2Args = {
  input: ApplyPromoCodeInputV2
}

export type MutationApplyPromoCodeV3Args = {
  input: ApplyPromoCodeInputV3
}

export type MutationRemovePromoCodeArgs = {
  input: RemovePromoCodeInput
}

export type MutationRemovePromoCodeV2Args = {
  input: RemovePromoCodeInputV2
}

export type MutationReorderArgs = {
  input: ReorderInput
}

export type MutationReorderV2Args = {
  input: ReorderInput
}

export type MutationSubmitGuestFeedbackV2Args = {
  input: SubmitGuestFeedbackV2Input
}

export type MutationCreateAccountArgs = {
  input: CreateAccountInput
}

export type MutationStartProfileCreationArgs = {
  input: StartProfileCreationInput
}

export type MutationCompleteProfileCreationArgs = {
  input: CompleteProfileCreationInput
}

export type MutationStartIdentityProfileArgs = {
  input: StartIdentityProfileInput
}

export type MutationCompleteIdentityProfileArgs = {
  input: CompleteIdentityProfileInput
}

export type MutationCreateConfirmedAccountArgs = {
  input: CreateConfirmedAccountInput
}

export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput
}

export type MutationUpdateBasicInfoArgs = {
  input: BasicInfoInput
}

export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput
}

export type MutationDeleteAddressArgs = {
  input: DeleteAddressInput
}

export type MutationMakeCreditCardPrimaryArgs = {
  input: MakeCreditCardPrimaryInput
}

export type MutationDeleteCreditCardArgs = {
  input: DeleteCreditCardInput
}

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput
}

export type MutationInitiateEmailPasswordResetArgs = {
  input: InitiateEmailPasswordResetInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationLoyaltySignupArgs = {
  input: LoyaltySignupInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationRefreshArgs = {
  input: RefreshInput
}

export type MutationMfaLoginArgs = {
  input: MfaLoginInput
}

export type MutationPasswordlessSignupArgs = {
  input: PasswordlessSignupInput
}

export type MutationPasswordlessLoginArgs = {
  input: PasswordlessLoginInput
}

export type MutationPasswordlessLoginUnifiedArgs = {
  input: PasswordlessLoginUnifiedInput
}

export type MutationPasswordlessConfirmCodeArgs = {
  input: PasswordlessConfirmCodeInput
}

export type MutationPasswordlessConfirmCodeUnifiedArgs = {
  input: PasswordlessConfirmCodeUnifiedInput
}

export type MutationPasswordlessRefreshTokenArgs = {
  input: PasswordlessRefreshTokenInput
}

export type MutationPasswordlessLogoutArgs = {
  input: PasswordlessLogoutInput
}

export type MutationAddPushNotificationTargetToCustomerArgs = {
  input: AddPushNotificationTargetToCustomerInput
}

export type MutationDeleteCustomerPushNotificationTargetArgs = {
  input: DeleteCustomerPushNotificationTargetInput
}

export type MutationPayForCheckArgs = {
  input: PayForCheckInput
}

export type MutationSignUpToLoyaltyArgs = {
  input: SignUpToLoyaltyInput
}

export type MutationApplyPromoInProgressOrderArgs = {
  input: ApplyPromoInProgressOrderInput
}

export type MutationSendPaadLinkArgs = {
  input: PayAtADistanceLinkInput
}

export type MutationAddItemToOrderAtTableCartArgs = {
  orderAtTableCartInput: OrderAtTableCartInput
}

export type MutationCreateAndAddToOrderAtTableTabArgs = {
  createTabInput: CreateTabInput
}

export type MutationOptAddPreauthToPartyArgs = {
  addPreauthToPartyInput: AddPreauthToPartyInput
}

export type MutationAddToOrderAtTableTabArgs = {
  addToTabInput: AddToTabInput
}

export type MutationPlaceOrderAtTableOrderArgs = {
  placeOrderAtTableOrderInput: PlaceOrderAtTableOrderInput
}

export type MutationPlaceOrderAtTableOrderV2Args = {
  placeOrderAtTableOrderInputV2: PlaceOrderAtTableOrderInputV2
}

export type MutationEditItemInOrderAtTableCartArgs = {
  editItemInCartInput: EditItemInCartInput
}

export type MutationDeleteItemInOrderAtTableCartArgs = {
  deleteItemInCartInput: DeleteItemFromCartInput
}

export type MutationGetTableGuidAndUpdateCartArgs = {
  input: OrderAtTableTableGuidInput
}

export type MutationSubmitGuestFeedbackArgs = {
  guestFeedbackInput: GuestFeedbackInput
}

export type MutationOptApplyPromoCodeArgs = {
  orderAtTablePromoCodeInput: OrderAtTablePromoCodeInput
}

export type MutationOptTabApplyPromoCodeArgs = {
  orderAtTableTabPromoCodeInput: OrderAtTableTabPromoCodeInput
}

export type MutationOptRemovePromoCodeArgs = {
  cartGuid: Scalars['ID']
}

export type MutationOptTabRemovePromoCodeArgs = {
  tabOrderGuid: Scalars['ID']
  tabCheckGuid: Scalars['ID']
  restaurantGuid: Scalars['ID']
}

export type MutationOptCreatePartyArgs = {
  optCreatePartyInput: OptCreatePartyInput
}

export type MutationOptCreatePartyRefreshArgs = {
  optCreatePartyInput: OptCreatePartyInput
}

export type MutationOptJoinPartyArgs = {
  optJoinPartyInput: OptJoinPartyInput
}

export type MutationOptJoinPartySecureArgs = {
  optJoinPartySecureInput: OptJoinPartySecureInput
}

export type MutationOptAddItemToPartyMemberCartArgs = {
  optAddItemToPartyMemberCartInput: OptAddItemToPartyMemberCartInput
}

export type MutationOptEditItemInPartyMemberCartArgs = {
  optEditItemInPartyMemberCartInput: OptEditOrDeleteItemInPartyMemberCartInput
}

export type MutationOptDeleteItemInPartyMemberCartArgs = {
  optDeleteItemInPartyMemberCartInput: OptEditOrDeleteItemInPartyMemberCartInput
}

export type MutationOptFireTabRoundArgs = {
  optFireTabRoundInput: OptFireTabRoundInput
}

export type MutationOptCreateSplitPaymentBucketArgs = {
  optCreateSplitPaymentBucketInput: OptCreateSplitPaymentBucketInput
}

export type MutationOptMoveItemsInSplitPaymentBucketArgs = {
  optMoveItemsInSplitPaymentBucketInput: OptMoveItemsInSplitPaymentBucketInput
}

export type MutationOptAddParticipantsToSplitPaymentBucketArgs = {
  optAddParticipantsToSplitPaymentBucketInput: OptAddParticipantsToSplitPaymentBucketInput
}

export type MutationOptRemoveParticipantsFromSplitPaymentBucketArgs = {
  optRemoveParticipantsFromSplitPaymentBucketInput: OptRemoveParticipantsFromSplitPaymentBucketInput
}

export type MutationGenerateSmsVerificationCodeArgs = {
  input: GenerateSmsVerificationCodeInput
}

export type MutationVerifySmsCodeArgs = {
  input: VerifySmsCodeInput
}

export type MutationAuthorizePaymentArgs = {
  input: AuthorizePaymentInput
}

export type NearbyRestaurantsInput = {
  diningOption: DiningOptionBehavior
  fulfillmentDateTime: Scalars['String']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  radius: Scalars['Float']
}

export type NewAddressInput = {
  deliveryInfo: DeliveryInfoInput
  /**
   * This field indicates whether or not the customer would like to save the new address.
   * If it is not found in the input, it will have **false** as a default value.
   *
   * For unauthenticated place order mutations, addresses will never be saved, but we may implement
   * that in the future.
   *
   * For authenticated place order mutations, the client should include this field as part of the input.
   */
  saveAddress?: Maybe<Scalars['Boolean']>
  /** If **saveAddress** is **true**, set the name/label for the address, e.g. "Home", "Work" */
  name?: Maybe<Scalars['String']>
}

export type NewCardInput = {
  /** The encrypted credit card information. */
  encryptedCardDataString: Scalars['String']
  /** The algorithm used to encrypt the credit card information. */
  encryptionKeyId: Scalars['String']
  /** First 6 digits (BIN) to be passed through to CCPartner for fraud prevention */
  cardFirst6?: Maybe<Scalars['String']>
  /** Last 4 digits to be passed through to CCPartner for fraud prevention */
  cardLast4?: Maybe<Scalars['String']>
  /** Billing zip code to be passed through to CCPartner for fraud prevention */
  zipCode?: Maybe<Scalars['String']>
  /** Two-digit expiration month to be passed through to CCPartner for OPT preauth */
  expMonth?: Maybe<Scalars['String']>
  /** Two-digit expiration year to be passed through to CCPartner for OPT preauth */
  expYear?: Maybe<Scalars['String']>
  /**
   * This field indicates whether or not the customer would like to save the new credit card.
   * If it is not found in the input, it will have **false** as a default value.
   * For unauthenticated **placeCcOrder**, the value of this field only matters if customer guid is also supplied
   * on the **PlaceCCOrderInput**, to allow saving a card on an unauthenticated account
   * For authenticated **placeCcOrder**, the client should include this field as part of the input.
   */
  saveCard?: Maybe<Scalars['Boolean']>
}

export type NvBrandsInput = {
  restaurantGuid: Scalars['String']
  defaultValue?: Maybe<Scalars['Boolean']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptAddItemToPartyMemberCartInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID']
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID']
  /** Member's auth token. */
  memberAuthToken: Scalars['String']
  /** Selection to add to cart. */
  selection: SelectionInput
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptAddParticipantsToSplitPaymentBucketInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID']
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID']
  /** Member's auth token. */
  memberAuthToken: Scalars['String']
  /** Id of bucket we are adding participants to. */
  bucketGuid: Scalars['ID']
  /** Ids of members that are participating in this bucket. */
  addMemberGuids: Array<Maybe<Scalars['ID']>>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptAddToTabResponseOrError = AddToOptTabResponse | OptTabError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptCheck = {
  __typename?: 'OPTCheck'
  guid: Scalars['ID']
  selections: Array<Maybe<CompletedOrderSelection>>
  payments: Array<Maybe<OptPayment>>
  amountAlreadyPaid: Scalars['Float']
  appliedPreauthInfo?: Maybe<AppliedPreauthInfo>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptConfig = {
  __typename?: 'OPTConfig'
  /** Whether or not a restaurant wishes to allow users to open tabs */
  allowTabs?: Maybe<Scalars['Boolean']>
  /** Whether or not a restaurant wishes to allow for joinable parties */
  allowJoinableParty?: Maybe<Scalars['Boolean']>
  /** Whether or not a restaurant wishes to enforce preauthorization for tabs */
  allowPreauth?: Maybe<Scalars['Boolean']>
  /** Whether or not a restaurant has KDS text fulfillment enabled */
  allowTextFulfillment?: Maybe<Scalars['Boolean']>
  /** Whether or not a restaurant has OPT view-only menus enabled */
  viewOnlyMenu?: Maybe<Scalars['Boolean']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptCreatePartyInput = {
  /** Used for disambiguating table names */
  restaurantGuid: Scalars['ID']
  /** Table the Party represents */
  tableName: Scalars['String']
  /** User's name. */
  name: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptCreateSplitPaymentBucketInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID']
  /** Member's unique identifier who will own this bucket. */
  ownerGuid: Scalars['ID']
  /** Member's auth token. */
  memberAuthToken: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptEditOrDeleteItemInPartyMemberCartInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID']
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID']
  /** Member's auth token. */
  memberAuthToken: Scalars['String']
  /** Guid of the selection to be updated. */
  selectionGuid: Scalars['String']
  /** Selection to add to cart. */
  selection?: Maybe<SelectionInput>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptFireTabRoundInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID']
  /** Customer's information. */
  customer?: Maybe<OrderAtTableCustomerInput>
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID']
  /** Member's auth token. */
  memberAuthToken: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptJoinPartyInput = {
  /** Party to join */
  partyGuid: Scalars['ID']
  /** User's name. */
  name: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptJoinPartySecureInput = {
  /** Party to join */
  partyGuid: Scalars['ID']
  /** User's name. */
  name: Scalars['String']
  /** PIN required to join party. */
  pin: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptMoveItemsInSplitPaymentBucketInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID']
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID']
  /** Member's auth token. */
  memberAuthToken: Scalars['String']
  /** List of selection ids to move along with the bucket you are moving from. */
  selections: Array<Maybe<BucketSelectionRequest>>
  /** Id of the bucket you are moving items to. */
  toBucketGuid: Scalars['ID']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptOrderError = Error & {
  __typename?: 'OPTOrderError'
  message: Scalars['String']
  code: OptPayForCheckErrorCode
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptParty = {
  __typename?: 'OPTParty'
  /** Identifier for the Party object itself. */
  guid: Scalars['ID']
  /** Identifier for the Order object corresponding to this Party. */
  orderGuid?: Maybe<Scalars['ID']>
  /** Security PIN to allow other diners to join this Party. */
  pin?: Maybe<Scalars['String']>
  /** List of members currently taking part in this Party. */
  members: Array<Maybe<OptPartyMember>>
  /** Auth token specific to party member who made the request. */
  memberAuthToken?: Maybe<Scalars['String']>
  /** Represented as a long - party's TTL value, when it was last updated. */
  lastUpdated?: Maybe<Scalars['String']>
  /** Represented as a long - when a party was created in seconds. */
  createdTime?: Maybe<Scalars['String']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptPartyCartResponse = OrderAtTableCart | OptPartyError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptPartyError = Error & {
  __typename?: 'OPTPartyError'
  message: Scalars['String']
  code?: Maybe<OptPartyErrorCode>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export enum OptPartyErrorCode {
  CreatePartyFailed = 'CREATE_PARTY_FAILED',
  LookupPartyFailed = 'LOOKUP_PARTY_FAILED',
  LookupPartyTableFailed = 'LOOKUP_PARTY_TABLE_FAILED',
  GetCartFailed = 'GET_CART_FAILED',
  JoinPartyFailed = 'JOIN_PARTY_FAILED',
  AddToCartFailed = 'ADD_TO_CART_FAILED',
  FireTabRoundFailed = 'FIRE_TAB_ROUND_FAILED',
  CreateSplitPaymentBucketFailed = 'CREATE_SPLIT_PAYMENT_BUCKET_FAILED',
  MoveItemInSplitPaymentBucketFailed = 'MOVE_ITEM_IN_SPLIT_PAYMENT_BUCKET_FAILED',
  AddParticipantsToSplitPaymentBucketFailed = 'ADD_PARTICIPANTS_TO_SPLIT_PAYMENT_BUCKET_FAILED',
  RemoveParticipantsFromSplitPaymentBucketFailed = 'REMOVE_PARTICIPANTS_FROM_SPLIT_PAYMENT_BUCKET_FAILED',
  MissingCardInput = 'MISSING_CARD_INPUT',
  AddPreauthFailed = 'ADD_PREAUTH_FAILED',
  PreauthAlreadyExists = 'PREAUTH_ALREADY_EXISTS',
  CalcSplitPaymentChargesFailed = 'CALC_SPLIT_PAYMENT_CHARGES_FAILED'
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptPartyMember = {
  __typename?: 'OPTPartyMember'
  /** Unique identifier for member. */
  partyMemberGuid: Scalars['String']
  /** Name of this party member. */
  name: Scalars['String']
  /** Guid corresponding to this member's cart. */
  cartGuid?: Maybe<Scalars['ID']>
  /** Guid corresponding to this member's preauthed card. Null if this party member did not preauth. */
  preauthCardGuid?: Maybe<Scalars['String']>
}

export type OptPartyMemberStub = {
  __typename?: 'OPTPartyMemberStub'
  name: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptPartyRefresh = {
  __typename?: 'OPTPartyRefresh'
  /** The data for the refreshed party. */
  party: OptParty
  /** The data for the split payment config. */
  splitPayment: OptSplitPayments
  /** The hydrated list of carts in the party. This allows us to show what others are ordering. */
  carts: Array<Maybe<OrderAtTableCart>>
  /** The hydrated order with all items in the cart. */
  order?: Maybe<OptTabOrder>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptPartyRefreshResponse = OptPartyRefresh | OptPartyError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptPartyResponse = OptParty | OptPartyError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptPartyStub = {
  __typename?: 'OPTPartyStub'
  guid?: Maybe<Scalars['ID']>
  members?: Maybe<Array<Maybe<OptPartyMemberStub>>>
  /** Represented as a long - when a party was created in seconds. */
  createdTime?: Maybe<Scalars['String']>
}

export type OptPartyStubResponse = OptPartyStub | OptPartyError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export enum OptPayForCheckErrorCode {
  InvalidPaymentRequest = 'INVALID_PAYMENT_REQUEST',
  PaymentConflict = 'PAYMENT_CONFLICT',
  MissingRequiredParameter = 'MISSING_REQUIRED_PARAMETER',
  InvalidCardInformation = 'INVALID_CARD_INFORMATION',
  TransDenied = 'TRANS_DENIED',
  CheckNotFound = 'CHECK_NOT_FOUND',
  ValidationFailed = 'VALIDATION_FAILED',
  PaymentDenied = 'PAYMENT_DENIED',
  CheckAlreadyPaidFor = 'CHECK_ALREADY_PAID_FOR'
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptPayForCheckResponse = {
  __typename?: 'OPTPayForCheckResponse'
  completedOrder: CompletedOrder
  warnings: Array<PlaceOrderWarning>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptPayment = {
  __typename?: 'OPTPayment'
  guid: Scalars['ID']
  type?: Maybe<PaymentType>
  amount: Scalars['Float']
  tipAmount: Scalars['Float']
  paymentStatus?: Maybe<PaymentStatus>
}

export type OptPaymentBucket = {
  __typename?: 'OPTPaymentBucket'
  guid: Scalars['ID']
  owner: Scalars['ID']
  participants: Array<Maybe<OptSplitPaymentParticipant>>
  orderSelections: Array<Maybe<Scalars['String']>>
  isShared: Scalars['Boolean']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptRemoveParticipantsFromSplitPaymentBucketInput = {
  /** Party member is a part of. */
  partyGuid: Scalars['ID']
  /** Member's unique identifier. */
  partyMemberGuid: Scalars['ID']
  /** Member's auth token. */
  memberAuthToken: Scalars['String']
  /** Id of bucket we are removing participants from. */
  bucketGuid: Scalars['ID']
  /** Ids of members that are no longer participating in this bucket. */
  removeMemberGuids: Array<Maybe<Scalars['ID']>>
}

export enum OptSplitPaymentAmountUnit {
  Percent = 'PERCENT',
  Currency = 'CURRENCY'
}

export type OptSplitPaymentCharge = {
  __typename?: 'OPTSplitPaymentCharge'
  member: OptPartyMember
  selections: Array<Maybe<CompletedOrderSelection>>
  subtotalCharge: Scalars['Float']
  subtotalTax: Scalars['Float']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP [GROUP ORDERING] */
export type OptSplitPaymentChargesResponse = {
  __typename?: 'OPTSplitPaymentChargesResponse'
  party: OptParty
  splitPayment: OptSplitPayments
  order?: Maybe<OptTabOrder>
  charges: Array<Maybe<OptSplitPaymentCharge>>
}

export type OptSplitPaymentChargesResponseOrError =
  | OptSplitPaymentChargesResponse
  | OptPartyError

export type OptSplitPaymentParticipant = {
  __typename?: 'OPTSplitPaymentParticipant'
  memberUuid: Scalars['ID']
  responsibleForAmount?: Maybe<Scalars['Float']>
  amountUnit: OptSplitPaymentAmountUnit
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptSplitPayments = {
  __typename?: 'OPTSplitPayments'
  isEnabled: Scalars['Boolean']
  paymentBuckets: Array<Maybe<OptPaymentBucket>>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptTabError = Error & {
  __typename?: 'OPTTabError'
  message: Scalars['String']
  code: OptTabErrorCode
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export enum OptTabErrorCode {
  OrderNotFound = 'ORDER_NOT_FOUND',
  AddToTabFailed = 'ADD_TO_TAB_FAILED',
  CreateTabFailed = 'CREATE_TAB_FAILED',
  MissingCardInput = 'MISSING_CARD_INPUT'
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptTabHistoryOrder = {
  __typename?: 'OPTTabHistoryOrder'
  /** @deprecated use tabOrder */
  order?: Maybe<Order>
  tabOrder?: Maybe<OptTabOrder>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptTabHistoryResponse = OptTabHistoryOrder | OptTabError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OptTabOrder = {
  __typename?: 'OPTTabOrder'
  /** Order guid */
  guid: Scalars['ID']
  /** Guid for the primary check on this order */
  checkGuid: Scalars['ID']
  numberOfSelections: Scalars['Int']
  checks: Array<Maybe<OptCheck>>
  selections: Array<Maybe<CompletedOrderSelection>>
  /** Does not include delivery charges. */
  serviceChargeTotal: Scalars['Float']
  subtotal: Scalars['Float']
  /** The order tax calculated after applying global promo codes if they exist. */
  taxV2: Scalars['Float']
  /** The order total calculated after applying global promo codes if they exist. */
  totalV2: Scalars['Float']
  /**
   * An object containing potential discounts applied to order, such as a global reward or
   * a restaurant specific reward.
   */
  discounts: Discounts
  isClosed: Scalars['Boolean']
}

export type OfferBadge = {
  /** The display text of a badge */
  badgeText: Scalars['String']
}

export type Order = {
  __typename?: 'Order'
  promisedDateTime?: Maybe<Scalars['Float']>
  numberOfSelections?: Maybe<Scalars['Int']>
  selections?: Maybe<Array<Maybe<Selection>>>
  /**
   * The delivery info to use to calculate delivery fees, provided this is a
   * delivery order.
   */
  deliveryInfo?: Maybe<DeliveryInfo>
  /**
   * The delivery fee based on the current delivery info, provided this is a
   * delivery order.
   * @deprecated service charges are separated out into different categories such as processingServiceCharges and deliveryServiceCharges
   */
  deliveryChargeTotal?: Maybe<Scalars['Float']>
  /**
   * Does not include delivery charges.
   * @deprecated service charges are separated out into different categories such as processingServiceCharges and deliveryServiceCharges
   */
  serviceChargeTotal?: Maybe<Scalars['Float']>
  /** The sum of the cost of all items in the cart except for outpost fees */
  itemsSubtotal: Scalars['Float']
  /** The sum of the cost of all items, before discounts, in the cart except for outpost fees */
  preDiscountItemsSubtotal: Scalars['Float']
  /** The sum of the service charges identified as UBP (user based pricing) */
  processingServiceCharges: Scalars['Float']
  /** The sum of the service charges that are not UBP and only for delivery orders */
  deliveryServiceCharges: Scalars['Float']
  /** The sum of the service charges that are not UBP, not only for delivery orders, and identified as gratuity  */
  gratuityServiceCharges: Scalars['Float']
  /** The sum of the service charges that are not UBP, not only for delivery orders, and not identified as gratuity */
  nonDeliveryNonGratuityNonUbpServiceCharges: Scalars['Float']
  /** @deprecated use itemsSubtotal */
  subtotal?: Maybe<Scalars['Float']>
  /** @deprecated use taxV2 field instead */
  tax?: Maybe<Scalars['Float']>
  /** @deprecated use totalV2 field instead */
  total?: Maybe<Scalars['Float']>
  /** The order tax calculated after applying global promo codes if they exist. */
  taxV2?: Maybe<Scalars['Float']>
  /** The order total calculated after applying global promo codes if they exist. */
  totalV2?: Maybe<Scalars['Float']>
  /** @deprecated use discounts field instead */
  appliedCustomerReward?: Maybe<AppliedCustomerReward>
  /**
   * An object containing potential discounts applied to order, such as a global reward or
   * a restaurant specific reward.
   */
  discounts: Discounts
  /** Total of all the discounts applied to the order. */
  discountsTotal: Scalars['Float']
  /**
   * A number that indicates how much to charge a user for placing an outpost order. This value will only be non null
   * if the order is an outpost order, otherwise it will be null.
   */
  outpostFee?: Maybe<Scalars['Float']>
}

export type OrderAppliedCustomerRewardArgs = {
  input?: Maybe<ApplyCustomerRewardInput>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTableCart = {
  __typename?: 'OrderAtTableCart'
  guid: Scalars['ID']
  lastUpdated?: Maybe<Scalars['String']>
  order: Order
  creditCardConfig: RestaurantCreditCardConfig
  paymentOptions: CartPaymentOptions
  preComputedTips: Array<Maybe<PreComputedTip>>
  cartMessages?: Maybe<Array<Maybe<CartMessage>>>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTableCartInput = {
  restaurantGuid: Scalars['ID']
  cartGuid?: Maybe<Scalars['ID']>
  selection: SelectionInput
  tableGuid: Scalars['ID']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTableCustomerInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  email: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTableGiftCardTabBalanceInquiryInput = {
  /** References Order Guid */
  tabOrderGuid: Scalars['ID']
  /** References Check Guid */
  tabCheckGuid: Scalars['ID']
  /** References Restaurant Guid */
  restaurantGuid: Scalars['ID']
  /** Gift Card Number to lookup */
  cardNumber: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTablePromoCodeInput = {
  /** ID of target order at table cart */
  cartGuid: Scalars['ID']
  /** Promo code to apply to cart */
  promoCode: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTableSelectionItemDetailsInput = {
  itemGuid: Scalars['ID']
  itemGroupGuid: Scalars['ID']
  cartGuid: Scalars['ID']
  selectionGuid: Scalars['ID']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTableServiceAreasInput = {
  restaurantGuid: Scalars['ID']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTableTabPromoCodeInput = {
  /** References Order Guid */
  tabOrderGuid: Scalars['ID']
  /** References Check Guid */
  tabCheckGuid: Scalars['ID']
  /** References Restaurant Guid */
  restaurantGuid: Scalars['ID']
  /** Promo code to apply to cart */
  promoCode: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTableTableGuidInput = {
  restaurantGuid: Scalars['ID']
  tableName: Scalars['String']
  cartGuid?: Maybe<Scalars['ID']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTableTablesInServiceAreasInput = {
  restaurantGuid: Scalars['ID']
  serviceAreaGuids: Array<Scalars['String']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTheTableGiftCardBalanceInquiryError = Error & {
  __typename?: 'OrderAtTheTableGiftCardBalanceInquiryError'
  code: GiftCardBalanceInquiryErrorCode
  message: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTheTableGiftCardBalanceInquiryInput = {
  /** References Cart Guid */
  cartGuid: Scalars['ID']
  /** Gift Card Number to lookup */
  cardNumber: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTheTableGiftCardBalanceInquiryResponse = {
  __typename?: 'OrderAtTheTableGiftCardBalanceInquiryResponse'
  /** The balance available on the gift card */
  availableBalance: Scalars['Float']
  /** The requested card number */
  cardNumber: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type OrderAtTheTableGiftCardBalanceInquiryResponseOrError =
  | OrderAtTheTableGiftCardBalanceInquiryResponse
  | OrderAtTheTableGiftCardBalanceInquiryError

/** The guestGuid will be extracted from the JWT */
export type OrderHistoryInput = {
  /** [Optional] Filter results to only include orders at the specified restaurant. */
  restaurantGuid?: Maybe<Scalars['ID']>
  /**
   * [Optional] Number of results to return for this given page.
   * Default: 20
   * Max: 100 (exclusive)
   */
  pageSize?: Maybe<Scalars['Int']>
  /**
   * The page token to retrieve the next page of results.
   * To retrieve the first page, set this to null. All subsequent request should have a non-null value for this.
   */
  nextPageToken?: Maybe<Scalars['String']>
}

export type OrderHistoryResponse = {
  __typename?: 'OrderHistoryResponse'
  /** The guest's orders sorted via openedDate newest -> oldest */
  orders: Array<CompletedOrder>
  /**
   * Page token used to retrieve the current page of orders. Passing this value through as the pageToken in
   * subsequent requests will result in the same orders being retrieved.
   */
  currentPageToken?: Maybe<Scalars['String']>
  /**
   * Page token used to retrieve the next page of orders.
   * When this field is null that indicates that [currentPageToken] was the last page in the result set.
   */
  nextPageToken?: Maybe<Scalars['String']>
}

export enum OrderSource {
  ConsumerApp = 'CONSUMER_APP',
  Online = 'ONLINE',
  Local = 'LOCAL',
  Boo = 'BOO'
}

/** The different states or an order tracked order. If **orderStatus** is null then the restaurant does not support order tracking currently */
export enum OrderStatusType {
  /** An order has been received and is present in the orders database. */
  Received = 'RECEIVED',
  /** An order is being prepared. */
  Preparing = 'PREPARING',
  /** The order has been marked as completed by the restaurant through the KDS. */
  AlmostReady = 'ALMOST_READY',
  /** The order is ready for pickup. Set 90 seconds after the order becomes **ALMOST_READY** to account for bagging and placing order on shelf etc. */
  ReadyForPickup = 'READY_FOR_PICKUP',
  /** When a RECEIVED state order reaches 15 minutes past its estimated completion date, it becomes unfulfilled. */
  Unfulfilled = 'UNFULFILLED'
}

export enum OrderTriggerMatchType {
  CreditLt = 'CREDIT_LT',
  DeliveryLt = 'DELIVERY_LT',
  CashGteq = 'CASH_GTEQ',
  TotalGteq = 'TOTAL_GTEQ'
}

export type Outpost = {
  __typename?: 'Outpost'
  /**
   * An outpost guid is generated at the time of the creation of the outpost and can be associated as the dining option
   * when ordering from a specific restaurant for outpost.
   */
  guid: Scalars['String']
  /** Name of the outpost which is the exact name of the dining option this outpost is associated with. */
  name: Scalars['String']
  /** The display name for an outpost location */
  outpostName: Scalars['String']
  /** URL for the image for the outpost. */
  image?: Maybe<Scalars['String']>
  /** Schedule of restaurants for the next 7 days with index 0 = today with respect to the outpost's time zone. */
  schedule: Array<OutpostDay>
}

export type OutpostDay = {
  __typename?: 'OutpostDay'
  /** A specific number that corresponds to the day of the week. Monday is 1 and Sunday is 7 [1-7] */
  dayOfWeek: Scalars['Int']
  /** List of restaurants that are available on a specific day. */
  outpostRestaurants: Array<OutpostRestaurant>
}

export type OutpostDaySchedule = {
  __typename?: 'OutpostDaySchedule'
  /** The latest acceptable time to place an outpost order, presented as an ISO local time string. */
  cutoffTime: Scalars['String']
  /** The time that an order will be delivered to the outpost by, presented as an ISO local time string. */
  deliveryTime: Scalars['String']
  /** The latest acceptable time to place an outpost order, presented as an ISO date+time string. */
  cutoffDateTime: Scalars['String']
  /** The time that an order will be delivered to the outpost by, presented as an ISO date+time string. */
  deliveryDateTime: Scalars['String']
}

export type OutpostInput = {
  /** The diner's current latitude for determining which outposts should be available (leaving optional will return all outposts) */
  latitude?: Maybe<Scalars['Float']>
  /** The diner's current longitude for determining which outposts should be available (leaving optional will return all outposts) */
  longitude?: Maybe<Scalars['Float']>
}

export type OutpostRestaurant = {
  __typename?: 'OutpostRestaurant'
  /** Outpost info for a specific outpost restaurant */
  outpost: Outpost
  /** Restaurant info for a specific outpost restaurant */
  restaurant: Restaurant
  /** The dining option GUID for this restaurant-outpost pair */
  diningOptionGuid: Scalars['ID']
  /**
   * The latest acceptable time to place an outpost order.
   * @deprecated replaced by daySchedule
   */
  cutOffTime: Scalars['String']
  /**
   * The time that an order will be delivered to the outpost by.
   * @deprecated replaced by daySchedule
   */
  deliveryTime: Scalars['String']
  /**
   * The cut off and delivery time for one day for this OutpostRestaurant.
   * When getting a single OutpostRestaurant, the schedule for today--with respect to the outpost's timezone--is returned.
   * When this as part of a schedule, the day of the week from the schedule is used.
   */
  daySchedule?: Maybe<OutpostDaySchedule>
  /** Whether or not a user can place an outpost order at this outpost-restaurant pair currently. */
  orderableNow: Scalars['Boolean']
}

export type OutpostRestaurantInput = {
  /** The guid of the dining option representing this outpost-restaurant pair. */
  diningOptionGuid: Scalars['ID']
}

export type OutpostScheduleInput = {
  /** The guid of the specific outpost we want to request the schedule for. */
  guid: Scalars['String']
}

export type PaginatedOrderResponse = {
  __typename?: 'PaginatedOrderResponse'
  orders: Array<CompletedOrder>
  /** Total number of orders, were the list to be not paginated */
  totalSize: Scalars['Int']
}

/**
 * Fetch past orders between two indices, for a paginated orders display (or to limit orders to display)
 * TODO: Replace with cursor based pagination, for more info see https://graphql.org/learn/pagination/ and https://facebook.github.io/relay/graphql/connections.htm
 */
export type PaginatedOrdersInput = {
  /** Maximimum number of orders to return */
  limit?: Maybe<Scalars['Int']>
  /** Number of orders to offset the returned list by, i.e. the starting index of the list */
  offset?: Maybe<Scalars['Int']>
  restaurantGuid: Scalars['ID']
}

export type PasswordlessAuthenticationError = Error & {
  __typename?: 'PasswordlessAuthenticationError'
  code: PasswordlessAuthenticationErrorCode
  message: Scalars['String']
}

export enum PasswordlessAuthenticationErrorCode {
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  IncorrectCode = 'INCORRECT_CODE',
  InvalidPhone = 'INVALID_PHONE',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidName = 'INVALID_NAME',
  UnknownOrInvalidRefresh = 'UNKNOWN_OR_INVALID_REFRESH'
}

export type PasswordlessConfirmCodeInput = {
  phone: Scalars['String']
  code: Scalars['String']
  source: Source
}

export type PasswordlessConfirmCodeUnifiedInput = {
  phone: Scalars['String']
  code: Scalars['String']
  source: Source
}

export type PasswordlessLoginInput = {
  phone: Scalars['String']
  source: Source
}

export type PasswordlessLoginResponse = {
  __typename?: 'PasswordlessLoginResponse'
  success: Scalars['Boolean']
}

export type PasswordlessLoginResponseOrError =
  | PasswordlessAuthenticationError
  | PasswordlessLoginResponse

export type PasswordlessLoginUnifiedInput = {
  phone: Scalars['String']
  source: Source
}

export type PasswordlessLoginUnifiedResponse = {
  __typename?: 'PasswordlessLoginUnifiedResponse'
  success: Scalars['Boolean']
}

export type PasswordlessLoginUnifiedResponseOrError =
  | PasswordlessAuthenticationError
  | PasswordlessLoginUnifiedResponse

export type PasswordlessLogoutInput = {
  refreshToken: Scalars['String']
  source: Source
}

export type PasswordlessLogoutResponse = {
  __typename?: 'PasswordlessLogoutResponse'
  success: Scalars['Boolean']
}

export type PasswordlessLogoutResponseOrError =
  | PasswordlessAuthenticationError
  | PasswordlessLogoutResponse

export type PasswordlessRefreshTokenInput = {
  refreshToken: Scalars['String']
  source: Source
}

export type PasswordlessSignupInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email: Scalars['String']
  phone: Scalars['String']
  source: Source
}

export type PasswordlessSignupResponse = {
  __typename?: 'PasswordlessSignupResponse'
  success: Scalars['Boolean']
}

export type PasswordlessSignupResponseOrError =
  | PasswordlessAuthenticationError
  | PasswordlessSignupResponse

export type PasswordlessTokenResponse = {
  __typename?: 'PasswordlessTokenResponse'
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
  /** Time in ISO8601 format, when the accessToken expires */
  expiresAtIso8601: Scalars['String']
  /** The guid associated with the access and refresh tokens */
  guestGuid: Scalars['ID']
}

export type PasswordlessTokenResponseOrError =
  | PasswordlessAuthenticationError
  | PasswordlessTokenResponse

export type PasswordlessTokenUnifiedResponse = {
  __typename?: 'PasswordlessTokenUnifiedResponse'
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
  /** Time in ISO8601 format, when the accessToken expires */
  expiresAtIso8601: Scalars['String']
  /** The guid associated with the access and refresh tokens */
  guestGuid: Scalars['ID']
}

export type PasswordlessTokenUnifiedResponseOrError =
  | PasswordlessAuthenticationError
  | PasswordlessTokenUnifiedResponse

export type PastOrdersInput = {
  /** The maximum of past orders to return, before applying the rules of **reorderableOnly**. */
  limit: Scalars['Int']
  /**
   * When set to true, orders that belongs to at least one of the following categories will be filtered out:
   * 1. The order is voided.
   * 2. The restaurant that the order belongs to is not available for ASAP takeout at the moment.
   * 3. The order has the same content (same restaurant, same items, same modifiers on the items, same quantity of contents) as one of the more recent orders.
   */
  reorderableOnly: Scalars['Boolean']
}

export type PayAtADistanceLinkInput = {
  /** The restaurant guid for this check */
  restaurantGuid: Scalars['ID']
  /** The guest's check guid that they will pay for */
  checkGuid: Scalars['ID']
  /** The guest's phone number that will receive the message */
  phoneNumber: Scalars['String']
}

export type PayForCheckError = Error & {
  __typename?: 'PayForCheckError'
  message: Scalars['String']
  code: PayForCheckErrorCode
  /**
   * To notify the frontend of any changes which occurred on the order,
   * especially in the case that the check total has changed.
   * May not exist on all error types.
   */
  inProgressOrder?: Maybe<InProgressOrder>
}

export enum PayForCheckErrorCode {
  InvalidPaymentRequest = 'INVALID_PAYMENT_REQUEST',
  PaymentConflict = 'PAYMENT_CONFLICT',
  ValidationFailed = 'VALIDATION_FAILED',
  CheckNotFound = 'CHECK_NOT_FOUND',
  CheckAlreadyPaidFor = 'CHECK_ALREADY_PAID_FOR',
  PaymentDenied = 'PAYMENT_DENIED',
  PaymentOutOfSync = 'PAYMENT_OUT_OF_SYNC'
}

export type PayForCheckInput = {
  /** The order guid which contains the check that is being paid for */
  orderGuid: Scalars['ID']
  /** The guid of the restaurant which has the order being paid for */
  restaurantGuid: Scalars['ID']
  /** A new credit card with encrypted data.  Either newCardInput, savedCardInput or pkPaymentToken must be supplied. */
  newCardInput?: Maybe<NewCardInput>
  /** A customer's saved credit card. Either savedCardInput, newCardInput or pkPaymentToken must be supplied. */
  savedCardInput?: Maybe<SavedCardInput>
  /**
   * For ApplePay.  A PKPayment is a payment object from apple that contains information such as the encrypted payment token and key secure diner information.
   * The pkPayment as well as the entiure PKPayment object including payment user metadata.
   * Either pkPayment, newCardInput or savedCardInput must be supplied.
   */
  pkPayment?: Maybe<Scalars['String']>
  /** The amount that the consumer selected to tip */
  tipAmount: Scalars['Float']
  /** The guid of the check to pay for */
  checkGuid: Scalars['ID']
  /** The guest's email to send a digital receipt to, also used for accrue loyalty points */
  email: Scalars['String']
  /** The guest's phone number to accrue loyalty points, can be null */
  phone?: Maybe<Scalars['String']>
  /** True if the guest opted into marketing, false if not. */
  marketable?: Maybe<Scalars['Boolean']>
  /**
   * Expected amount to be charged, not including tips.
   *
   * If the client sends an expected amount to be charged,
   * it expects this mutation to fail if the actual order balance
   * is more than 3 cents different.
   */
  expectedAmount?: Maybe<Scalars['Float']>
  /** The promo code to apply to this order. Only necessary for global promos */
  promoCode?: Maybe<Scalars['String']>
}

export type PayForCheckResponse = {
  __typename?: 'PayForCheckResponse'
  /**
   * An array of all checks associated with the order still open. Used to allow the client to
   * display any remaining balance, and the selections, to the guest.
   */
  remainingChecks: Array<InProgressOrderCheck>
  /** Indicates whether loyalty account was found and user accrued points for paid check. */
  hasLoyalty?: Maybe<Scalars['Boolean']>
}

export type PayForCheckResponseOrError = PayForCheckResponse | PayForCheckError

export enum PaymentState {
  Authorized = 'AUTHORIZED',
  Acknowledged = 'ACKNOWLEDGED',
  Cancelled = 'CANCELLED',
  Denied = 'DENIED',
  FraudReject = 'FRAUD_REJECT'
}

export enum PaymentStatus {
  Open = 'OPEN',
  Processing = 'PROCESSING',
  AuthorizedAtRisk = 'AUTHORIZED_AT_RISK',
  Authorized = 'AUTHORIZED',
  Error = 'ERROR',
  ErrorNetwork = 'ERROR_NETWORK',
  Denied = 'DENIED',
  ProcessingVoid = 'PROCESSING_VOID',
  VoidedAtRisk = 'VOIDED_AT_RISK',
  Cancelled = 'CANCELLED',
  CaptureInProgress = 'CAPTURE_IN_PROGRESS',
  Captured = 'CAPTURED',
  Voided = 'VOIDED'
}

/**
 * This is a subset of the values from toast-orders' com.toasttab.service.orders.api.Payment.TypeEnum
 *
 * See https://dev.toasttab.com/Ie5QH/apiOrdersApiReference.html#/definitions/Payment
 */
export enum PaymentType {
  Cash = 'CASH',
  Credit = 'CREDIT',
  Giftcard = 'GIFTCARD',
  HouseAccount = 'HOUSE_ACCOUNT',
  Rewardcard = 'REWARDCARD',
  Levelup = 'LEVELUP',
  Other = 'OTHER',
  Undetermined = 'UNDETERMINED'
}

/**
 * Requires a PKPaymentToken generated from apple pay as well as customer information generated by the
 * Apple Payment Sheet
 */
export type PlaceApplePayOrderInput = {
  /** GUID for the cart that has the order currently being placed. */
  cartGuid: Scalars['ID']
  /** A token generated by Apple Pay */
  pkPaymentToken: Scalars['String']
  /** Customer's information, generated by the Apple Payment Sheet */
  customer?: Maybe<CustomerInput>
  /**
   * Customer guid, should only be provided for an unconfirmed account - authenticated orders placed for a confirmed
   * account will use the customer guid from the access token (JWT)
   */
  customerGuid?: Maybe<Scalars['ID']>
  /**
   * The final delivery info to set on the order. If absent, will use whatever delivery info
   * was previously set on the cart, if any. It is an error if the cart's dining option behavior was
   * configured for **DELIVERY** but no delivery info is provided, either here or previously
   * set.
   */
  newAddress?: Maybe<NewAddressInput>
  /** The saved address to use for an authenticated customer. Mutually exclusive with **newAddresss**. */
  savedAddress?: Maybe<SavedAddressInput>
  /** Gift Card Payment */
  giftCard?: Maybe<GiftCardPaymentInput>
  /** The amount of tip the customer intends to give. */
  tipAmount: Scalars['Float']
  /**
   * The global (restaurant-agnostic) customer reward to be applied to the order being placed.
   * The absence of this input in the request indicates that no reward is to be applied.
   * Currently only first-order promo is supported.
   */
  applicableCustomerRewardInput?: Maybe<ApplicableCustomerRewardInput>
  /**
   * The promo code supplied by the user for order being placed, must be re-validated before applying.
   * The absence of this input in the request indicates that no promo code is to be applied.
   */
  promoCode?: Maybe<Scalars['String']>
  /**
   * ***MVP feature***:
   * The GUID of the dining option to be applied to the cart before placing the order.
   * This overrides the dining option selected from the restaurant admin online ordering configuration,
   * e.g. the takeout dining option would be automatically configured on the cart when it was first created,
   * but selecting an outpost dining option would override this selection during the **placeCcOrder** mutation.
   */
  diningOptionGuid?: Maybe<Scalars['ID']>
  /**
   * This input will determine whether the place order mutation should also call loyaltySignup for the user. If the
   * flag is false or not set, loyalty signup will not occur in the place order request.
   */
  signUpLoyalty?: Maybe<Scalars['Boolean']>
  /** Determines curbside pickup options for an order */
  curbsidePickup?: Maybe<CurbsidePickupInput>
  /** Curbside Pickup information for the order. Namely, a vehicle description. */
  curbsidePickupV2?: Maybe<CurbsidePickupInputV2>
  /**
   * Indicates whether the diner has given consent for the delivery provider to communicate to them.
   * Only relevant if the order is a DaaS order and consent is needed for the delivery provider fulfilling the delivery.
   * Since DaaS orders only accept CC payments, this field is only present on **PlaceCcOrderInput**.
   */
  deliveryCommunicationConsentGiven?: Maybe<Scalars['Boolean']>
  /**
   * Indicates if RoundUp for cause is turned on or not in the order
   * Represents the estimated roundUpAmount calculated in the frontend
   */
  tfgInput?: Maybe<TfgInput>
}

export type PlaceApplePayOrderResponse =
  | PlaceOrderResponse
  | PlaceOrderError
  | PlaceOrderOutOfStockError
  | PlaceOrderCartUpdatedError

/**  Customer is optional for future replacement with customerGuid on authenticated orders  */
export type PlaceCashOrderInput = {
  cartGuid: Scalars['ID']
  /**
   * Customer's information, required for unauthenticated **placeCashOrder**,
   * if absent for authenticated **placeCashOrder** it will be looked up based
   * on the customerGuid.
   */
  customer?: Maybe<CustomerInput>
  /**
   * The final delivery info to set on the order. If absent, will use whatever delivery info
   * was previously set on the cart, if any. It is an error if the cart's dining option behavior was
   * configured for **DELIVERY** but no delivery info is provided, either here or previously
   * set.
   */
  newAddress?: Maybe<NewAddressInput>
  /** The saved address to use for an authenticated customer. Mutually exclusive with **newAddresss**. */
  savedAddress?: Maybe<SavedAddressInput>
  /** Gift Card Payment */
  giftCard?: Maybe<GiftCardPaymentInput>
  /** Determines curbside pickup options for an order */
  curbsidePickup?: Maybe<CurbsidePickupInput>
  /** Curbside Pickup information for the order. Namely, a vehicle description. */
  curbsidePickupV2?: Maybe<CurbsidePickupInputV2>
  /**
   * Indicates if RoundUp for cause is turned on or not in the order
   * Represents the estimated roundUpAmount calculated in the frontend
   */
  tfgInput?: Maybe<TfgInput>
}

export type PlaceCashOrderResponse =
  | PlaceOrderResponse
  | PlaceOrderError
  | PlaceOrderOutOfStockError
  | PlaceOrderCartUpdatedError

/**
 * Either the CC payment is done via a new CC or a saved CC. This means that exactly 1 of **newCardInput**
 * and **savedCardInput** is non-null. Note that **savedCardInput** should only be non-null for an authenticated
 * **placeCcOrder** mutation. If both fields are null or if both fields are non-null, a GraphQL error will be returned.
 */
export type PlaceCcOrderInput = {
  /** GUID for the cart that has the order currently being placed. */
  cartGuid: Scalars['ID']
  /** A new credit card with encrypted data. */
  newCardInput?: Maybe<NewCardInput>
  /** A customer's saved credit card. */
  savedCardInput?: Maybe<SavedCardInput>
  /**
   * Customer's information, required for unauthenticated **placeCcOrder**,
   * if absent for authenticated **placeCcOrder** it will be looked up based
   * on the customerGuid.
   */
  customer?: Maybe<CustomerInput>
  /**
   * Customer guid, should only be provided for an unconfirmed account - authenticated orders placed for a confirmed
   * account will use the customer guid from the access token (JWT)
   */
  customerGuid?: Maybe<Scalars['ID']>
  /**
   * The final delivery info to set on the order. If absent, will use whatever delivery info
   * was previously set on the cart, if any. It is an error if the cart's dining option behavior was
   * configured for **DELIVERY** but no delivery info is provided, either here or previously
   * set.
   */
  newAddress?: Maybe<NewAddressInput>
  /** The saved address to use for an authenticated customer. Mutually exclusive with **newAddresss**. */
  savedAddress?: Maybe<SavedAddressInput>
  /** The amount of tip the customer intends to give. */
  tipAmount: Scalars['Float']
  /**
   * The global (restaurant-agnostic) customer reward to be applied to the order being placed.
   * The absence of this input in the request indicates that no reward is to be applied.
   * Currently only first-order promo is supported.
   */
  applicableCustomerRewardInput?: Maybe<ApplicableCustomerRewardInput>
  /**
   * The promo code supplied by the user for order being placed, must be re-validated before applying.
   * The absence of this input in the request indicates that no promo code is to be applied.
   */
  promoCode?: Maybe<Scalars['String']>
  /**
   * ***MVP feature***:
   * The GUID of the dining option to be applied to the cart before placing the order.
   * This overrides the dining option selected from the restaurant admin online ordering configuration,
   * e.g. the takeout dining option would be automatically configured on the cart when it was first created,
   * but selecting an outpost dining option would override this selection during the **placeCcOrder** mutation.
   */
  diningOptionGuid?: Maybe<Scalars['ID']>
  /**
   * This input will determine whether the place order mutation should also call loyaltySignup for the user. If the
   * flag is false or not set, loyalty signup will not occur in the place order request.
   */
  signUpLoyalty?: Maybe<Scalars['Boolean']>
  /** Gift Card Payment */
  giftCard?: Maybe<GiftCardPaymentInput>
  /** Determines curbside pickup options for an order */
  curbsidePickup?: Maybe<CurbsidePickupInput>
  /** Curbside Pickup information for the order. Namely, a vehicle description. */
  curbsidePickupV2?: Maybe<CurbsidePickupInputV2>
  /**
   * Indicates whether the diner has given consent for the delivery provider to communicate to them.
   * Only relevant if the order is a DaaS order and consent is needed for the delivery provider fulfilling the delivery.
   * Since DaaS orders only accept CC payments, this field is only present on **PlaceCcOrderInput**.
   */
  deliveryCommunicationConsentGiven?: Maybe<Scalars['Boolean']>
  /**
   * Indicates if RoundUp for cause is turned on or not in the order
   * Represents the estimated roundUpAmount calculated in the frontend
   */
  tfgInput?: Maybe<TfgInput>
}

export type PlaceCcOrderResponse =
  | PlaceOrderResponse
  | PlaceOrderError
  | PlaceOrderOutOfStockError
  | PlaceOrderCartUpdatedError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type PlaceOptOrderResponse = OptPayForCheckResponse | OptOrderError

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type PlaceOrderAtTableOrderInput = {
  /** GUID for the cart that has the order currently being placed. */
  cartGuid?: Maybe<Scalars['ID']>
  /** RestaurantGuid for the cart that has the order currently being placed. */
  restaurantGuid: Scalars['ID']
  /** A new credit card with encrypted data. */
  newCardInput?: Maybe<NewCardInput>
  /** A customer's saved credit card. */
  savedCardInput?: Maybe<SavedCardInput>
  /**
   * A customer's preauthedCard guid, if present, should be compared against the preauth guid stored on the order.
   * If it's a match, use it to submit the payment request.
   */
  preauthedCardGuid?: Maybe<Scalars['String']>
  /**
   * Customer's information, required for unauthenticated **placeCcOrder**,
   * if absent for authenticated **placeCcOrder** it will be looked up based
   * on the customerGuid.
   */
  customer?: Maybe<CustomerInput>
  /**
   * Customer guid, should only be provided for an unconfirmed account - authenticated orders placed for a confirmed
   * account will use the customer guid from the access token (JWT)
   */
  customerGuid?: Maybe<Scalars['ID']>
  /**
   * The final delivery info to set on the order. If absent, will use whatever delivery info
   * was previously set on the cart, if any. It is an error if the cart's dining option behavior was
   * configured for **DELIVERY** but no delivery info is provided, either here or previously
   * set.
   */
  newAddress?: Maybe<NewAddressInput>
  /** The saved address to use for an authenticated customer. Mutually exclusive with **newAddresss**. */
  savedAddress?: Maybe<SavedAddressInput>
  /** The amount of tip the customer intends to give. */
  tipAmount: Scalars['Float']
  /** Gift Card Payment */
  giftCard?: Maybe<GiftCardPaymentInput>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type PlaceOrderAtTableOrderInputV2 = {
  /** GUID for the cart that has the order currently being placed. If empty this is an order associated with an open tab (thus no cart) */
  cartGuid?: Maybe<Scalars['ID']>
  /** RestaurantGuid for the cart that has the order currently being placed. */
  restaurantGuid: Scalars['ID']
  /** A new credit card with encrypted data. */
  newCardInput?: Maybe<NewCardInput>
  /** A customer's saved credit card. */
  savedCardInput?: Maybe<SavedCardInput>
  /**
   * A customer's preauthedCard guid, if present, should be compared against the preauth guid stored on the order.
   * If it's a match, use it to submit the payment request.
   */
  preauthedCardGuid?: Maybe<Scalars['String']>
  /**
   * Customer's information, required for unauthenticated **placeCcOrder**,
   * if absent for authenticated **placeCcOrder** it will be looked up based
   * on the customerGuid.
   */
  customer?: Maybe<OrderAtTableCustomerInput>
  /**
   * Customer guid, should only be provided for an unconfirmed account - authenticated orders placed for a confirmed
   * account will use the customer guid from the access token (JWT)
   */
  customerGuid?: Maybe<Scalars['ID']>
  /**
   * The final delivery info to set on the order. If absent, will use whatever delivery info
   * was previously set on the cart, if any. It is an error if the cart's dining option behavior was
   * configured for **DELIVERY** but no delivery info is provided, either here or previously
   * set.
   */
  newAddress?: Maybe<NewAddressInput>
  /** The saved address to use for an authenticated customer. Mutually exclusive with **newAddresss**. */
  savedAddress?: Maybe<SavedAddressInput>
  /** The amount of tip the customer intends to give. */
  tipAmount: Scalars['Float']
  /** GUID for the check associated with this tab if a tab exists. If none is provided, the payment will close out the current order associated with the current cart. */
  tabCheckGuid?: Maybe<Scalars['ID']>
  /** GUID for the order associated with this tab if a tab exists. If none is provided, the payment will close out the current order associated with the current cart. */
  tabOrderGuid?: Maybe<Scalars['ID']>
  /** Gift Card Payment */
  giftCard?: Maybe<GiftCardPaymentInput>
}

/** Returned when the server prevents the placing of an order due to the cart being updated automatically by the server. */
export type PlaceOrderCartUpdatedError = Error & {
  __typename?: 'PlaceOrderCartUpdatedError'
  code: PlaceOrderCartUpdatedErrorCode
  message: Scalars['String']
  /** The updated cart as a result of server updates. */
  cart: Cart
}

export enum PlaceOrderCartUpdatedErrorCode {
  /** A number of items on the cart has been automatically removed due to being out of stock. */
  OutOfStock = 'OUT_OF_STOCK',
  /**
   * The fulfillment time (for FUTURE) or quote time (for ASAP) on the cart is out of date and no longer accurate.
   *
   * E.g. the server automatically changed the fulfillment/quote time due to price-based prep or kitchen capacity, but the order can still be placed.
   * In this case the **cart** field of this response will have the updated fulfillment/quote time.
   *
   * In contrast, if it is no longer possible to place an order at all, a **PlaceOrderError** would be returned with a code of **ORDER_TIME_UNAVAILABLE**.
   */
  OrderTimeChanged = 'ORDER_TIME_CHANGED'
}

export type PlaceOrderError = Error & {
  __typename?: 'PlaceOrderError'
  code: PlaceOrderErrorCode
  message: Scalars['String']
}

export enum PlaceOrderErrorCode {
  UnsatisfiedApprovalRule = 'UNSATISFIED_APPROVAL_RULE',
  CartContentNotFound = 'CART_CONTENT_NOT_FOUND',
  InvalidCreditCard = 'INVALID_CREDIT_CARD',
  OoDisabled = 'OO_DISABLED',
  OoDeliveryDisabled = 'OO_DELIVERY_DISABLED',
  OrderTimeUnavailable = 'ORDER_TIME_UNAVAILABLE',
  PlaceOrderEmptyCart = 'PLACE_ORDER_EMPTY_CART',
  PlaceOrderFailed = 'PLACE_ORDER_FAILED',
  PlaceOrderInvalidEmail = 'PLACE_ORDER_INVALID_EMAIL',
  RewardNotFound = 'REWARD_NOT_FOUND',
  UnsupportedPaymentMethod = 'UNSUPPORTED_PAYMENT_METHOD',
  MissingCustomerInfo = 'MISSING_CUSTOMER_INFO',
  MissingDeliveryInfo = 'MISSING_DELIVERY_INFO',
  DiningOptionUnavailable = 'DINING_OPTION_UNAVAILABLE',
  NotInDeliveryArea = 'NOT_IN_DELIVERY_AREA',
  LoyaltyRedemptionRemoved = 'LOYALTY_REDEMPTION_REMOVED',
  UnsupportedPromoCode = 'UNSUPPORTED_PROMO_CODE',
  CriticalError = 'CRITICAL_ERROR',
  AlcoholUnsupported = 'ALCOHOL_UNSUPPORTED',
  OoAutofireOffline = 'OO_AUTOFIRE_OFFLINE'
}

/** Deprecated - use **PlaceOrderCartUpdatedError** */
export type PlaceOrderOutOfStockError = Error & {
  __typename?: 'PlaceOrderOutOfStockError'
  message: Scalars['String']
  cart: Cart
}

export type PlaceOrderResponse = {
  __typename?: 'PlaceOrderResponse'
  completedOrder: CompletedOrder
  warnings: Array<PlaceOrderWarning>
}

export type PlaceOrderWarning = Warning & {
  __typename?: 'PlaceOrderWarning'
  code: PlaceOrderWarningCode
  message: Scalars['String']
}

export enum PlaceOrderWarningCode {
  AfterPlaceOrderFailed = 'AFTER_PLACE_ORDER_FAILED',
  LoyaltySignupFailed = 'LOYALTY_SIGNUP_FAILED'
}

export type PopularItemsConfig = {
  __typename?: 'PopularItemsConfig'
  enabled: Scalars['Boolean']
}

export type PopularItemsError = Error & {
  __typename?: 'PopularItemsError'
  message: Scalars['String']
}

export type PopularItemsInput = {
  restaurantGuid: Scalars['ID']
  menusInput: MenusInput
}

export type PopularItemsResponse = {
  __typename?: 'PopularItemsResponse'
  items: Array<MenuItem>
}

export type PopularItemsResponseOrError =
  | PopularItemsResponse
  | PopularItemsError

/** Pre-calculated tips deriving from a restaurant-specified configuration */
export type PreComputedTip = {
  __typename?: 'PreComputedTip'
  /** Percent as a whole number (18% = 18) */
  percent: Scalars['Int']
  /** Percent as a decimal float (18% = 0.18) */
  percentV2: Scalars['Float']
  /** Represents PreComputedTip percent as string rounded to single decimal place */
  percentLabel: Scalars['String']
  /** Represents final computation of tippable amount * tip percentage in USD */
  value: Scalars['Float']
  /** Whether preComputedTip is default preferred tip specified by restaurant */
  isDefault?: Maybe<Scalars['Boolean']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type PricingRules = {
  __typename?: 'PricingRules'
  timeSpecificPricingRules: Array<Scalars['Int']>
  sizeSpecificPricingGuid: Scalars['String']
  sizeSequencePricingRules: Array<Scalars['Int']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export enum PricingStrategy {
  None = 'NONE',
  BasePrice = 'BASE_PRICE',
  MenuSpecificPrice = 'MENU_SPECIFIC_PRICE',
  GroupPrice = 'GROUP_PRICE',
  SizePrice = 'SIZE_PRICE',
  TimeSpecificPrice = 'TIME_SPECIFIC_PRICE',
  SequencePrice = 'SEQUENCE_PRICE',
  OpenPrice = 'OPEN_PRICE'
}

export type PromoBanner = {
  __typename?: 'PromoBanner'
  /** The GUID of the banner */
  bannerGuid: Scalars['String']
  /** The banner text */
  bannerText: Scalars['String']
  /**
   * The promo code that can be applied at the checkout.
   * Example: PIZZA5
   */
  promoCode: Scalars['String']
}

export type PromoBannerAvailability = {
  __typename?: 'PromoBannerAvailability'
  /**
   * Time range constraints on a banners promotion (e.g. available between 4-6PM)
   * If null, there are no constraints (e.g. available all day)
   */
  time?: Maybe<PromoBannerAvailabilityTime>
}

export type PromoBannerAvailabilityTime = {
  __typename?: 'PromoBannerAvailabilityTime'
  startTime: Scalars['String']
  endTime: Scalars['String']
}

export type PromoBannerBase = {
  /** The GUID of the banner */
  bannerGuid: Scalars['String']
  /** The banner text */
  bannerText: Scalars['String']
  /** The banner type */
  type: Scalars['String']
}

export type PromoBannerInput = {
  /** The guid of the restaurant which has the order being paid for */
  restaurantGuid: Scalars['ID']
  /** The source of the request, ONLINE_ORDERING or TOAST_APP */
  requestSource?: Maybe<Scalars['String']>
}

export type PromoBannerResponse = {
  __typename?: 'PromoBannerResponse'
  /** @deprecated in favor of banner specific fields */
  banners: Array<PromoBanner>
  promoCodeBanners: Array<PromoCodeBanner>
  loyaltyBanners: Array<LoyaltyBanner>
  deliveryBanners: Array<DeliveryBanner>
}

export type PromoBannerResponseOrError = PromoBannerResponse | GeneralError

export type PromoCodeBanner = PromoBannerBase & {
  __typename?: 'PromoCodeBanner'
  /** The GUID of the banner */
  bannerGuid: Scalars['String']
  /** The banner text */
  bannerText: Scalars['String']
  /**
   * The promo code that can be applied at the checkout.
   * Example: PIZZA5
   */
  promoCode: Scalars['String']
  /** The promo code banner type */
  type: Scalars['String']
}

export enum PushNotificationTargetType {
  Ios = 'IOS',
  Android = 'ANDROID'
}

export type Query = {
  __typename?: 'Query'
  isNvBrandingEnabled: Scalars['Boolean']
  /** @deprecated use cartV2 */
  cart?: Maybe<Cart>
  /** Gets the cart represented by **guid**. */
  cartV2: CartResponseOrError
  /** Fetches loyalty information about a user at a restaurant. */
  customerLoyaltyInfo: CustomerLoyaltyInfoResponseOrError
  /** Get gift card balance */
  giftCardBalanceInquiry: GiftCardBalanceInquiryResponseOrError
  customer: Customer
  /**
   * Checks whether the **resetToken** obtained from the email sent by **initiateEmailPasswordReset** is still valid or not.
   * This isn't necessary to use the **resetPassword** mutation, but can be used to check the token validity
   * before prompting the user to enter a new password in.
   */
  isResetTokenValid: Scalars['Boolean']
  /** Get's import details related to the guest-profiles account associated to the given access token */
  importStatus: ImportStatus
  /**
   * There are two valid ways to use this API
   *
   * 1. Provide a valid Auth0 (i.e. passwordless) JWT. We will extract the email on the JWT and use
   *    that to search in customermgmt. Do not provide a [SearchForCustomermgmtAccountInput].
   * 2. Provide a [SearchForCustomermgmtAccountInput] which will always take precedent over the email
   *    extracted from the JWT if provided. The JWT is optional.
   */
  searchForCustomermgmtAccount: SearchForCustomermgmtAccountResponse
  doMenus_findMenuItem: DoMenus_FindMenuItemResult
  doMenus_findMenuItems: DoMenus_FindMenuItemsResult
  doMenus_popularItems: DoMenus_PopularItemsResult
  shouldForceUpdate: Scalars['Boolean']
  shouldForceUpdateAndroid: Scalars['Boolean']
  itemOfferBadges: ItemDetailOfferBadgeResponseOrError
  /** @deprecated use menusV3 */
  menus: Array<Menu>
  /** @deprecated use menusV3 */
  menusV2: Array<Menu>
  /**
   * Fetches the menus given the specified shortUrl and dateTime.
   * If the dateTime is not specified, defaults to now.
   */
  menusV3: MenusResponseOrError
  /** @deprecated use menuItemDetails */
  modifierGroups: Array<ModifierGroup>
  /** @deprecated use selectionItemDetails */
  menuItemFromCart?: Maybe<MenuItemFromCart>
  /**
   * Menu item details including out-of-stock status and
   * all modifier groups/modifiers
   */
  menuItemDetails: MenuItem
  /**
   * Cart item details including out-of-stock status and
   * all modifier groups/modifiers (selected and unselected)
   */
  selectionItemDetails: SelectionItem
  /**
   * Fetches the popular menu items for a given restaurant.
   * Returns a maximum of 3 menu items in the response case or an error in error case.
   * Every item returned by this query must be available to order.
   */
  popularItems: PopularItemsResponseOrError
  /** Returns a single outpost. */
  outpost: Outpost
  /** Returns a list of outpost locations.  */
  outposts: Array<Outpost>
  /** Returns an outpost-restaurant pair.  */
  outpostRestaurant: OutpostRestaurant
  /**
   * Returns a list of outpost days which contain a day of week and a list of restaurants available to outpost on that day.
   * Only days that contain a restaurant will be represented in the list.
   * @deprecated get schedule from outpost query now
   */
  outpostSchedule: Array<OutpostDay>
  pastOrders?: Maybe<Array<Maybe<CompletedOrder>>>
  paginatedOrders: PaginatedOrderResponse
  /**
   * Retrieve paginated order history for a single guest. This API is only
   * compatable with guest-profiles accounts
   * (i.e. passwordless-based).
   */
  orderHistory: OrderHistoryResponse
  /**
   * Retrieve a combined item order history for a single guest across all their orders.
   * This API is only compatable with guest-profiles accounts (i.e. passwordless-based).
   */
  itemOrderHistory: ItemOrderHistoryResponseOrError
  /** Allows user to query a single past order. */
  completedOrder: CompletedOrder
  /** Allows clients to query orders that are still open by check number (legacy) or check guid */
  inProgressOrder?: Maybe<InProgressOrder>
  /** gets the most recent InProgressOrder attached to a specified table */
  inProgressOrderForTable?: Maybe<InProgressOrder>
  /** Fetches menus for restaurant */
  orderAtTableMenus: Array<MenuV2>
  /** Fetches all items previously ordered on tab for this session */
  orderAtTableTabHistory?: Maybe<OptTabHistoryResponse>
  /** Fetches menu item in a group for restaurant */
  orderAtTableMenuItem: MenuItemV2
  /** Fetches a given cart */
  orderAtTableCart: OrderAtTableCart
  /** Fetches selection item in a cart */
  orderAtTableSelectionItem: SelectionItemV2
  /** Fetches the table guid for a given table name at a restaurant */
  orderAtTableTableGuid: Table
  /** Fetches all of the service areas in a restaurant */
  orderAtTableServiceAreas: Array<ServiceArea>
  /** Fetches all of the service areas in a restaurant */
  orderAtTableTablesInServiceAreas: Array<Table>
  /** Gets a gift card associated with an OPT cart */
  orderAtTableGiftCardBalanceInquiry: OrderAtTheTableGiftCardBalanceInquiryResponseOrError
  /** Gets a gift card associated with an OPT tab */
  orderAtTableGiftCardTabBalanceInquiry: OrderAtTheTableGiftCardBalanceInquiryResponseOrError
  /** GROUP ORDERING: Gets the latest Party, using table name and restaurant guid */
  optLookupPartyByTable: OptPartyStubResponse
  /** GROUP ORDERING: Gets the latest Party, using table name and restaurant guid */
  optLookupPartyByTableAndPin: OptPartyStubResponse
  /** GROUP ORDERING: Gets a Party, using its guid */
  optUnverifiedLookupPartyByGuid: OptPartyStubResponse
  /** GROUP ORDERING: Gets a Party, using its guid */
  optLookupPartyByGuid: OptPartyRefreshResponse
  /** GROUP ORDERING: Gets a group member's cart */
  optLookupPartyCart: OptPartyCartResponse
  /** GROUP ORDERING: Gets split payment breakdown */
  optCalcSplitPaymentCharges: OptSplitPaymentChargesResponseOrError
  /**
   * Validates whether the provided latitude+longitude fall within the provided
   * restaurant's delivery area. Requires that an address has already been geocoded.
   * @deprecated use validateDeliveryLocationV2
   */
  validateDeliveryLocation: ValidateDeliveryLocationResponse
  /**
   * For 1PD: Validates whether the provided address falls within the provided restaurant's delivery area.
   * For DaaS: Validates whether the provided address can be serviced by the delivery provider from the given restaurant.
   * Requires that an address has already been geocoded.
   */
  validateDeliveryLocationV2: ValidateDeliveryLocationResponse
  nearbyRestaurants: Array<Restaurant>
  nearbyFavorites: Array<Restaurant>
  /** @deprecated use restaurantV2 */
  restaurant?: Maybe<Restaurant>
  /** Restaurant configuration data such as its name and location, searched for by guid. */
  restaurantV2: RestaurantOrError
  /** Restaurant configuration data such as its name and location, searched for by shortUrl. */
  restaurantV2ByShortUrl: RestaurantOrError
  /** Restaurant configuration data such as name and location, searched in batch by guid */
  restaurants: Array<RestaurantOrError>
  restaurantOfferBadges: RestaurantOfferBadgeResponseOrError
  findConsumerAppRestaurants: RestaurantSearchResultsOrError
  diningOptions: Array<DiningOption>
  promoBanners: PromoBannerResponseOrError
  menuOfferBadges: MenuOfferBadgesResponseOrError
  /** @deprecated use discounts field on cart */
  availableRewards?: Maybe<Array<Reward>>
}

export type QueryIsNvBrandingEnabledArgs = {
  input: NvBrandsInput
}

export type QueryCartArgs = {
  guid: Scalars['ID']
}

export type QueryCartV2Args = {
  guid: Scalars['ID']
}

export type QueryCustomerLoyaltyInfoArgs = {
  input: CustomerLoyaltyInfoInput
}

export type QueryGiftCardBalanceInquiryArgs = {
  input: GiftCardBalanceInquiryInput
}

export type QueryIsResetTokenValidArgs = {
  resetToken: Scalars['String']
}

export type QuerySearchForCustomermgmtAccountArgs = {
  input?: Maybe<SearchForCustomermgmtAccountInput>
}

export type QueryDoMenus_FindMenuItemArgs = {
  input: DoMenus_FindMenuItemInput
}

export type QueryDoMenus_FindMenuItemsArgs = {
  input: DoMenus_FindMenuItemsInput
}

export type QueryDoMenus_PopularItemsArgs = {
  input: DoMenus_PopularItemsInput
}

export type QueryShouldForceUpdateArgs = {
  version: IosVersionInput
}

export type QueryShouldForceUpdateAndroidArgs = {
  version: AndroidVersionInput
}

export type QueryItemOfferBadgesArgs = {
  input: ItemDetailOfferBadgeInput
}

export type QueryMenusArgs = {
  shortUrl: Scalars['ID']
}

export type QueryMenusV2Args = {
  input: MenusInput
}

export type QueryMenusV3Args = {
  input: MenusInput
}

export type QueryModifierGroupsArgs = {
  input: ModifierGroupsInput
}

export type QueryMenuItemFromCartArgs = {
  input: MenuItemFromCartInput
}

export type QueryMenuItemDetailsArgs = {
  input: MenuItemDetailsInput
}

export type QuerySelectionItemDetailsArgs = {
  input: SelectionItemDetailsInput
}

export type QueryPopularItemsArgs = {
  input: PopularItemsInput
}

export type QueryOutpostArgs = {
  input: SingleOutpostInput
}

export type QueryOutpostsArgs = {
  input: OutpostInput
}

export type QueryOutpostRestaurantArgs = {
  input: OutpostRestaurantInput
}

export type QueryOutpostScheduleArgs = {
  input: OutpostScheduleInput
}

export type QueryPastOrdersArgs = {
  input: PastOrdersInput
}

export type QueryPaginatedOrdersArgs = {
  input: PaginatedOrdersInput
}

export type QueryOrderHistoryArgs = {
  input: OrderHistoryInput
}

export type QueryItemOrderHistoryArgs = {
  input: ItemOrderHistoryInput
}

export type QueryCompletedOrderArgs = {
  input: CompletedOrderInput
}

export type QueryInProgressOrderArgs = {
  input: InProgressOrderInput
}

export type QueryInProgressOrderForTableArgs = {
  input: InProgressOrderForTableInput
}

export type QueryOrderAtTableMenusArgs = {
  restaurantGuid: Scalars['ID']
}

export type QueryOrderAtTableTabHistoryArgs = {
  restaurantGuid: Scalars['ID']
  tabCheckGuid: Scalars['ID']
  tabOrderGuid: Scalars['ID']
}

export type QueryOrderAtTableMenuItemArgs = {
  restaurantGuid: Scalars['ID']
  itemGuid: Scalars['ID']
  itemGroupGuid: Scalars['ID']
  nestingLevel: Scalars['Int']
}

export type QueryOrderAtTableCartArgs = {
  guid: Scalars['ID']
}

export type QueryOrderAtTableSelectionItemArgs = {
  input: OrderAtTableSelectionItemDetailsInput
  restaurantGuid: Scalars['ID']
  nestingLevel: Scalars['Int']
}

export type QueryOrderAtTableTableGuidArgs = {
  input: OrderAtTableTableGuidInput
}

export type QueryOrderAtTableServiceAreasArgs = {
  input: OrderAtTableServiceAreasInput
}

export type QueryOrderAtTableTablesInServiceAreasArgs = {
  input: OrderAtTableTablesInServiceAreasInput
}

export type QueryOrderAtTableGiftCardBalanceInquiryArgs = {
  input: OrderAtTheTableGiftCardBalanceInquiryInput
}

export type QueryOrderAtTableGiftCardTabBalanceInquiryArgs = {
  input: OrderAtTableGiftCardTabBalanceInquiryInput
}

export type QueryOptLookupPartyByTableArgs = {
  restaurantGuid: Scalars['ID']
  tableName: Scalars['String']
}

export type QueryOptLookupPartyByTableAndPinArgs = {
  restaurantGuid: Scalars['ID']
  tableName: Scalars['String']
  pin: Scalars['String']
}

export type QueryOptUnverifiedLookupPartyByGuidArgs = {
  partyGuid: Scalars['ID']
}

export type QueryOptLookupPartyByGuidArgs = {
  partyGuid: Scalars['ID']
  partyMemberGuid: Scalars['ID']
  memberAuthToken: Scalars['String']
}

export type QueryOptLookupPartyCartArgs = {
  partyGuid: Scalars['ID']
  partyMemberGuid: Scalars['ID']
  memberAuthToken: Scalars['String']
}

export type QueryOptCalcSplitPaymentChargesArgs = {
  partyGuid: Scalars['ID']
  partyMemberGuid: Scalars['ID']
  memberAuthToken: Scalars['String']
}

export type QueryValidateDeliveryLocationArgs = {
  input: ValidateDeliveryLocationInput
}

export type QueryValidateDeliveryLocationV2Args = {
  input: ValidateDeliveryLocationInputV2
}

export type QueryNearbyRestaurantsArgs = {
  input: NearbyRestaurantsInput
}

export type QueryNearbyFavoritesArgs = {
  input: FavoriteRestaurantsInput
}

export type QueryRestaurantArgs = {
  guid: Scalars['ID']
}

export type QueryRestaurantV2Args = {
  guid: Scalars['ID']
}

export type QueryRestaurantV2ByShortUrlArgs = {
  shortUrl: Scalars['String']
}

export type QueryRestaurantsArgs = {
  guids: Array<Scalars['ID']>
}

export type QueryRestaurantOfferBadgesArgs = {
  input: RestaurantOfferBadgeInput
}

export type QueryFindConsumerAppRestaurantsArgs = {
  input?: Maybe<RestaurantSearchInput>
}

export type QueryDiningOptionsArgs = {
  input: DiningOptionsInput
}

export type QueryPromoBannersArgs = {
  input: PromoBannerInput
}

export type QueryMenuOfferBadgesArgs = {
  input: MenuOfferBadgesInput
}

/** Response type returned when a refresh request fails (e.g. the refresh token is expired) */
export type RefreshError = Error & {
  __typename?: 'RefreshError'
  /** Type of error encountered - use this field to differentiate between errors */
  code: RefreshErrorCode
  /** Additional information about the error encountered */
  message: Scalars['String']
}

export enum RefreshErrorCode {
  InvalidRefreshToken = 'INVALID_REFRESH_TOKEN',
  ServiceUnavailable = 'SERVICE_UNAVAILABLE'
}

export type RefreshInput = {
  /** JWT refresh token */
  refreshToken: Scalars['String']
}

export type RefreshResponse = AuthenticationResponse | RefreshError

export enum RegistrationSource {
  OnlineOrdering = 'ONLINE_ORDERING',
  ToastApp = 'TOAST_APP',
  OrderAndPay = 'ORDER_AND_PAY',
  Sites = 'SITES',
  Local = 'LOCAL'
}

export type RemoveLoyaltyRedemptionInput = {
  cartGuid: Scalars['ID']
  /** Guid of the applied redemption to be removed */
  redemptionGuid: Scalars['ID']
}

export type RemoveLoyaltyRedemptionV2Input = {
  cartGuid: Scalars['ID']
  redemption: LoyaltyRedemptionInput
}

export type RemovePromoCodeInput = {
  /** The guid of the cart in the order the user wishes to apply the promo code reward to. */
  cartGuid: Scalars['ID']
  /** A generic string that is used to look up rewards. */
  discountType: DiscountType
}

/**  Input for when a customer attempts to remove all promo code based rewards from their order.  */
export type RemovePromoCodeInputV2 = {
  /** The guid of the cart in the order the user wishes to apply the promo code reward to. */
  cartGuid: Scalars['ID']
}

export type RemovePromoCodeResponseOrError =
  | CartResponse
  | CartModificationError
  | CartOutOfStockError

export type ReorderError = Error & {
  __typename?: 'ReorderError'
  code: ReorderErrorCode
  message: Scalars['String']
}

export enum ReorderErrorCode {
  /** Indicates that none of the items from the order could be added to the cart. */
  NoAvailableItems = 'NO_AVAILABLE_ITEMS',
  /** Indicates that the order referenced by **orderGuid** could not be fetched. */
  OrderUnavailable = 'ORDER_UNAVAILABLE',
  /** Indicates that the order couldn't be successfully reordered due to unknown reasons. */
  ReorderFailed = 'REORDER_FAILED'
}

export type ReorderInput = {
  /** References the restaurant that the order being reordered belongs to. */
  restaurantGuid: Scalars['ID']
  /** References the order to be reordered. */
  orderGuid: Scalars['ID']
  /** The product that the cart is created on. Optional to ensure backwards compatability, please provide this in all future usages. */
  orderSource?: Maybe<OrderSource>
  /** Indicates how the order on the cart should be fulfilled. Optional to ensure backwards compatability, please provide this in all future usages. */
  cartFulfillmentInput?: Maybe<CartFulfillmentInput>
}

export type ReorderResponse = {
  __typename?: 'ReorderResponse'
  /** The resulting **Cart** with as many items added as possible from the order being reordered. */
  cart: Cart
  /** @deprecated use warningsV2 */
  warnings: Array<Maybe<Scalars['String']>>
  /** If the client is using **reorderV2**, then the client should request this field and not **warnings**. */
  warningsV2: Array<ReorderWarning>
  outOfStockItems: Array<CartOutOfStockItem>
}

export type ReorderResponseOrError = ReorderResponse | ReorderError

export type ReorderWarning = Warning & {
  __typename?: 'ReorderWarning'
  code: ReorderWarningCode
  message: Scalars['String']
}

export enum ReorderWarningCode {
  /** Indicates that the restaurant cannot fulfill the current cart at this time. */
  OrderTimeUnavailable = 'ORDER_TIME_UNAVAILABLE',
  /**
   * A warning with this enum as the code will only occur if some, but not all, of the items are unavailable for reorder.
   * If all of the items in the order are unavailable, then a ReorderError will return with a code of NO_AVAILABLE_ITEMS.
   */
  ItemsUnavailable = 'ITEMS_UNAVAILABLE'
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export enum RequiredMode {
  Required = 'REQUIRED',
  OptionalForceShow = 'OPTIONAL_FORCE_SHOW',
  Optional = 'OPTIONAL'
}

export type ResetPasswordError = Error & {
  __typename?: 'ResetPasswordError'
  code: ResetPasswordErrorCode
  message: Scalars['String']
}

export enum ResetPasswordErrorCode {
  InvalidToken = 'INVALID_TOKEN',
  InvalidNewPassword = 'INVALID_NEW_PASSWORD'
}

export type ResetPasswordInput = {
  /** Reset token, obtained as a query parameter from the link in the email sent by **initiateEmailPasswordReset** */
  resetToken: Scalars['String']
  /** The desired new password for the customer account */
  newPassword: Scalars['String']
}

export type ResetPasswordResponse = AuthenticationResponse | ResetPasswordError

export type Restaurant = {
  __typename?: 'Restaurant'
  guid: Scalars['ID']
  shortUrl: Scalars['String']
  /**
   * The restaurant's name that an aggregator client should use. Accounts
   * for Takeout-specific name override
   */
  name: Scalars['String']
  /** The restaurant's name that a white-label client should use */
  whiteLabelName: Scalars['String']
  description?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  /**
   * The restaurant's cuisineType that is a high-level descriptor for the type of food served by the restaurant.
   * This value is defined under the consumer app config and is NOT set by the restaurant. If it is null, then the
   * property has not been defined on the consumer app config.
   */
  cuisineType?: Maybe<Scalars['String']>
  minimumDeliveryTime: Scalars['Int']
  minimumTakeoutTime: Scalars['Int']
  location: Location
  i18n?: I18n
  /** The restaurant's loyalty settings, defaulting to disabled settings if the restaurant has no loyalty or if the loyalty service is unavailable */
  loyaltyConfig: LoyaltyConfig
  /** The restaurant's Toast For Good settings, defaulting to disabled settings if the restaurant has no Toast For Good settings or if the Toast For Good settings service is unavailable */
  tfgConfig: TfgConfig
  /**
   * The **DiningOption**s available to be selected at the moment
   * @deprecated use diningOptions
   */
  currentlyAvailableDiningOptions: Array<DiningOption>
  /** Gift card links */
  giftCardLinks: GiftCardLinks
  /** Gift card config */
  giftCardConfig: GiftCardConfig
  /** Links to resturant social media platforms. */
  socialMediaLinks: SocialMediaLinks
  /** Configuration for a restaurant's curbside pickup order fulfillment option */
  curbsidePickupConfig?: Maybe<CurbsidePickupConfig>
  /** Configuration for optional information a restaurant could spotlight */
  spotlightConfig?: Maybe<SpotlightConfig>
  /** Configuration for a restaurant's popular items display */
  popularItemsConfig?: Maybe<PopularItemsConfig>
  /** Configuration for a restaurant's item upsells */
  upsellsConfig?: Maybe<UpsellsConfig>
  /** Restaurant TimeZone identifier */
  timeZoneId?: Maybe<Scalars['String']>
  /** Determines whether a restaurant has both the online ordering product module and that the online ordering module is set to an active state. */
  onlineOrderingEnabled?: Maybe<Scalars['Boolean']>
  /** Flag indicating whether a restaurant can be ordered from within the consumer app. Only populated when searching by shortUrl. */
  allowOrderingInConsumerApp?: Maybe<Scalars['Boolean']>
  creditCardConfig: RestaurantCreditCardConfig
  /** The restaurant's configured delivery providers */
  deliveryProviders: Array<DeliveryProviderConfig>
  logoUrls?: Maybe<ImageSet>
  bannerUrls?: Maybe<ImageSet>
  orderAndPayConfig?: Maybe<OptConfig>
  schedule: Schedule
  specialRequestsConfig: SpecialRequestsConfig
}

export interface I18n {
  currency: Currency
  locale: Locale
  country: CountryIsoCode
}

export type RestaurantCreditCardConfig = {
  __typename?: 'RestaurantCreditCardConfig'
  /**
   * Indicates if the restaurant accepts American Express credit card payments,
   * assuming that the restaurant allows payment via credit card in the first place.
   */
  amexAccepted: Scalars['Boolean']
  /**
   * Indicates if the restaurant allows the diner to specify a tip when paying via
   * credit card, assuming that the restaurant allows payment via credit card in
   * the first place.
   * Note: This only applies to OO/off-premise configurations. Currently all
   * on-premise dining has tips enabled.
   */
  tipEnabled: Scalars['Boolean']
}

/** An object representing a restaurant reward, i.e. a reward provided by a restaurant itself. */
export type RestaurantDiscount = {
  __typename?: 'RestaurantDiscount'
  name: Scalars['String']
  guid: Scalars['ID']
  amount: Scalars['Float']
  /**
   * Promo code can be empty string if the discount is added on the POS and doesn't have a promoCode attached.
   * In this case use name.
   */
  promoCode: Scalars['String']
}

export type RestaurantOfferBadge = {
  __typename?: 'RestaurantOfferBadge'
  /** The GUID of the Restaurant to be badged */
  restaurantGuid: Scalars['String']
  /** The badge text */
  badgeText: Scalars['String']
  /** The offer count */
  offerCount: Scalars['Int']
}

export type RestaurantOfferBadgeInput = {
  /** The guid of the restaurant which has the order being paid for */
  restaurantGuids: Array<Scalars['ID']>
}

export type RestaurantOfferBadgeResponse = {
  __typename?: 'RestaurantOfferBadgeResponse'
  restaurantOfferBadges: Array<RestaurantOfferBadge>
}

export type RestaurantOfferBadgeResponseOrError =
  | RestaurantOfferBadgeResponse
  | GeneralError

export type RestaurantOrError = Restaurant | GeneralError

export type RestaurantSearchInput = {
  q?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['Int']>
}

export type RestaurantSearchResult = {
  __typename?: 'RestaurantSearchResult'
  guid: Scalars['ID']
  shortUrl?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['Float']>
  longitude?: Maybe<Scalars['Float']>
  distance?: Maybe<Scalars['Float']>
  consumerAppDisplayName?: Maybe<Scalars['String']>
  consumerAppDescription?: Maybe<Scalars['String']>
  consumerAppListImage?: Maybe<Scalars['String']>
  consumerAppCuisineType?: Maybe<Scalars['String']>
  loyaltyVendor?: Maybe<Scalars['String']>
  offPremDerivedDeliveryEnabled: Scalars['Boolean']
  offPremDerivedTakeoutEnabled: Scalars['Boolean']
}

export type RestaurantSearchResults = {
  __typename?: 'RestaurantSearchResults'
  results: Array<RestaurantSearchResult>
  totalCount: Scalars['Int']
}

export type RestaurantSearchResultsOrError =
  | RestaurantSearchResults
  | GeneralError

export type Reward = {
  __typename?: 'Reward'
  name?: Maybe<Scalars['String']>
  rewardType?: Maybe<RewardType>
  amount?: Maybe<Scalars['Float']>
  beforeTax?: Maybe<Scalars['Boolean']>
}

export enum RewardType {
  SignUp = 'SIGN_UP',
  CustomerNUse = 'CUSTOMER_N_USE',
  GlobalNUse = 'GLOBAL_N_USE',
  ToastGc = 'TOAST_GC'
}

export type SavedAddress = {
  __typename?: 'SavedAddress'
  guid: Scalars['ID']
  /** The name/label for this address, e.g. "Home", "Work" */
  name?: Maybe<Scalars['String']>
  deliveryInfo: DeliveryInfo
}

export type SavedAddressInput = {
  addressGuid: Scalars['ID']
}

export type SavedCardInput = {
  cardGuid: Scalars['ID']
}

/** Saved credit card information; the token itself is stored securely in customermgmt and not provided to the client */
export type SavedCreditCard = {
  __typename?: 'SavedCreditCard'
  /** The card guid, used as an identifier for the card */
  guid: Scalars['ID']
  /** The masked card number with only the last four digits visible, in the format of "XXXX-1234" */
  maskedPan: Scalars['String']
  /** Two digit expiration month */
  expirationMonth: Scalars['String']
  /** Four digit expiration year */
  expirationYear: Scalars['String']
  /** The issuing network of the card, e.g. "VISA" */
  cardType: CardType
  /** Flag indicating whether the card is the primary (selected by default) card for the account */
  isPrimary: Scalars['Boolean']
}

export type Schedule = {
  __typename?: 'Schedule'
  /**
   * This field will be superseded by **asapSchedule.availableNow** on one of the
   * returned **DiningOption**s for the appropriate inputs to the **diningOptions()** query.
   */
  asapAvailableForDelivery?: Maybe<Scalars['Boolean']>
  /**
   * This field will be superseded by **asapSchedule.availableNow** on one of the
   * returned **DiningOption**s for the appropriate inputs to the **diningOptions()** query.
   */
  asapAvailableForTakeout?: Maybe<Scalars['Boolean']>
  /**
   * The overall OO delivery hours for the current business day. This does not indicate
   * if there are any service gaps within the day. If null, indicates the restaurant
   * does not have any hours for today.
   */
  todaysHoursForDelivery?: Maybe<ServicePeriod>
  /**
   * The overall OO takeout hours for the current business day. This does not indicate
   * if there are any service gaps within the day. If null, indicates the restaurant
   * does not have any hours for today.
   */
  todaysHoursForTakeout?: Maybe<ServicePeriod>
  /** The schedules for the upcoming 7 business days (including today) */
  upcomingSchedules: Array<UpcomingSchedules>
}

export type SearchForCustomermgmtAccountInput = {
  /** Email used to find an associated customermgmt account. If this is provided then it will always take precedent over the email extracted from the JWT.  */
  email: Scalars['String']
}

export type SearchForCustomermgmtAccountResponse = {
  __typename?: 'SearchForCustomermgmtAccountResponse'
  /**
   * true: a confirmed customermgmt account exists for the email on the guest-profiles account
   * false: otherwise
   *
   * can be used to determine if the user *might* have a customermgmt account to import into their guest-profiles account
   */
  foundMatchingEmail?: Maybe<Scalars['Boolean']>
}

export type Selection = {
  __typename?: 'Selection'
  guid?: Maybe<Scalars['ID']>
  itemGuid?: Maybe<Scalars['ID']>
  itemGroupGuid?: Maybe<Scalars['ID']>
  optionGroupGuid?: Maybe<Scalars['ID']>
  /** Key used to group identical duplicate selections */
  groupingKey?: Maybe<Scalars['ID']>
  modifiers?: Maybe<Array<Maybe<Selection>>>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  /** The cost of the Selection before any discounts are applied to the order */
  preDiscountPrice?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Int']>
  usesFractionalQuantity: Scalars['Boolean']
  fractionalQuantity: FractionalQuantity
}

export type SelectionInput = {
  itemGroupGuid?: Maybe<Scalars['ID']>
  itemGuid: Scalars['ID']
  /** Field is only applicable for upsells and only when it represents the item, not a modifier. */
  itemMasterId?: Maybe<Scalars['String']>
  modifierGroups: Array<ModifierGroupInput>
  quantity: Scalars['Int']
  fractionalQuantity?: Maybe<FractionalQuantityInput>
  specialInstructions?: Maybe<Scalars['String']>
}

/** A selection in the cart */
export type SelectionItem = {
  __typename?: 'SelectionItem'
  guid: Scalars['ID']
  itemGuid: Scalars['ID']
  itemGroupGuid: Scalars['ID']
  specialRequest?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  /** @deprecated use imageUrls */
  imageUrl?: Maybe<Scalars['String']>
  outOfStock?: Maybe<Scalars['Boolean']>
  menuItemPrice?: Maybe<Scalars['Float']>
  selectionItemPrice?: Maybe<Scalars['Float']>
  /**
   * This optional argument allows the query to specify the level of
   * nesting in the modifiers. Any top level modifier with greater than
   * the specified level of nesting is removed, and empty modifier
   * groups are removed as well. A level of 1 will return mods
   * without nesting. A level of 2 will return mods with at most one
   * level of nesting, and so on.
   */
  modifierGroups: Array<SelectionModifierGroup>
  calories?: Maybe<Scalars['Int']>
  usesFractionalQuantity: Scalars['Boolean']
  fractionalQuantity: FractionalQuantity
  imageUrls?: Maybe<ImageSet>
}

/** A selection in the cart */
export type SelectionItemModifierGroupsArgs = {
  nestingLevel?: Maybe<Scalars['Int']>
}

/** Note that shortUrl will remain required throughout the transition to the DO menu API */
export type SelectionItemDetailsInput = {
  shortUrl: Scalars['String']
  /** Determine which menu API should be used for retrieving menu and stock data */
  menuApi?: Maybe<MenuApiType>
  /** Required if menuApi is DO */
  restaurantGuid?: Maybe<Scalars['ID']>
  itemGuid: Scalars['ID']
  itemGroupGuid: Scalars['ID']
  cartGuid: Scalars['ID']
  selectionGuid: Scalars['ID']
  /** The cart schema is the same across services but is stored and retrieved differently. */
  cartApi?: Maybe<CartApiType>
  /**
   * Date should be in ISO 8601 format and include date and time.
   * Example: 2019-08-07T13:00:00-04:00
   * This is necessary if fetching selection items that are not currently available.
   * If the dateTime is not provided, the current time is used.
   */
  dateTime?: Maybe<Scalars['String']>
  /** Determines the visibility to filter menu items by. Defaults to TOAST_ONLINE_ORDERING. */
  visibility?: Maybe<Menus_VisibilityEnum>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type SelectionItemV2 = {
  __typename?: 'SelectionItemV2'
  guid: Scalars['ID']
  itemGuid: Scalars['ID']
  itemGroupGuid: Scalars['ID']
  specialRequest?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  outOfStock?: Maybe<Scalars['Boolean']>
  stockQuantity?: Maybe<Scalars['Float']>
  menuItemPrice?: Maybe<Scalars['Float']>
  selectionItemPrice?: Maybe<Scalars['Float']>
  unitOfMeasure: UnitOfMeasure
  modifierGroups: Array<SelectionModifierGroupV2>
  calories?: Maybe<Scalars['Int']>
  usesFractionalQuantity: Scalars['Boolean']
  fractionalQuantity: FractionalQuantity
  imageUrl?: Maybe<Scalars['String']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type SelectionItemV2ModifierGroupsArgs = {
  nestingLevel?: Maybe<Scalars['Int']>
}

export type SelectionModifier = {
  __typename?: 'SelectionModifier'
  itemGuid: Scalars['ID']
  itemGroupGuid?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Int']>
  selected?: Maybe<Scalars['Boolean']>
  isDefault?: Maybe<Scalars['Boolean']>
  modifierGroups: Array<SelectionModifierGroup>
  outOfStock?: Maybe<Scalars['Boolean']>
  /**
   * Required for modifier quantity implementation.
   * Will only be populated if retrieved from DO Menus.
   */
  allowsDuplicates?: Maybe<Scalars['Boolean']>
}

export type SelectionModifierGroup = {
  __typename?: 'SelectionModifierGroup'
  guid: Scalars['ID']
  maxSelections?: Maybe<Scalars['Int']>
  minSelections?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  pricingMode?: Maybe<Scalars['String']>
  modifiers: Array<SelectionModifier>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type SelectionModifierGroupV2 = {
  __typename?: 'SelectionModifierGroupV2'
  guid: Scalars['ID']
  minSelections: Scalars['Int']
  maxSelections?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  requiredMode: RequiredMode
  modifiers: Array<SelectionModifierOptionV2>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type SelectionModifierOptionV2 = {
  __typename?: 'SelectionModifierOptionV2'
  itemGuid: Scalars['ID']
  itemGroupGuid?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  quantity?: Maybe<Scalars['Int']>
  selected?: Maybe<Scalars['Boolean']>
  modifierGroups: Array<SelectionModifierGroupV2>
  outOfStock: Scalars['Boolean']
  stockQuantity?: Maybe<Scalars['Float']>
}

export type SendPayAtADistanceResponse = {
  __typename?: 'SendPayAtADistanceResponse'
  success?: Maybe<Scalars['Boolean']>
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type ServiceArea = {
  __typename?: 'ServiceArea'
  guid: Scalars['ID']
  name: Scalars['String']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type ServiceAreaInput = {
  guid: Scalars['ID']
}

export type ServicePeriod = {
  __typename?: 'ServicePeriod'
  /**
   * An hh:mm:ss.mmm formatted local time representing the start of the period
   * within the context of the restaurant's timezone.
   */
  startTime: Scalars['String']
  /**
   * An hh:mm:ss.mmm formatted local time representing the end of the period
   * within the context of the restaurant's timezone.
   */
  endTime: Scalars['String']
}

export type SignUpToLoyaltyError = Error & {
  __typename?: 'SignUpToLoyaltyError'
  message: Scalars['String']
  code: SignUpToLoyaltyErrorCode
}

export enum SignUpToLoyaltyErrorCode {
  CannotSignUp = 'CANNOT_SIGN_UP'
}

export type SignUpToLoyaltyInput = {
  /** The guid of the paid check */
  checkGuid: Scalars['ID']
  /** The guid of the restaurant which has the paid check */
  restaurantGuid: Scalars['ID']
  /** The guest's email to sign-up to rewards program - either email or phone number, or both, must be provided */
  email?: Maybe<Scalars['String']>
  /** The guest's phone number to sign-up to rewards program - either email or phone number, or both must, be provided */
  phone?: Maybe<Scalars['String']>
}

export type SignUpToLoyaltyResponse = {
  __typename?: 'SignUpToLoyaltyResponse'
  hasLoyalty?: Maybe<Scalars['Boolean']>
  newAccount?: Maybe<Scalars['Boolean']>
}

export type SignUpToLoyaltyResponseOrError =
  | SignUpToLoyaltyResponse
  | SignUpToLoyaltyError

export type SingleOutpostInput = {
  /** The guid of the outpost. */
  guid: Scalars['ID']
}

/**
 * Social Media Links allow a restaurant to point customers to their social
 * media pages.
 */
export type SocialMediaLinks = {
  __typename?: 'SocialMediaLinks'
  /** Link to a restaurant's home page */
  resturantLink?: Maybe<Scalars['String']>
  /** Link to the restaurant's facebook page. */
  facebookLink?: Maybe<Scalars['String']>
  /** Link to the restaurant's twitter feed */
  twitterLink?: Maybe<Scalars['String']>
  /** Twitter user */
  twitterUser?: Maybe<Scalars['String']>
  /** Instagram */
  instagramLink?: Maybe<Scalars['String']>
}

export enum Source {
  ToastAppIos = 'TOAST_APP_IOS',
  ToastAppAndroid = 'TOAST_APP_ANDROID',
  ToastSites = 'TOAST_SITES',
  ToastLocal = 'TOAST_LOCAL'
}

export type SpecialRequestsConfig = {
  __typename?: 'SpecialRequestsConfig'
  /**
   * Indicates whether special requests input box should be
   * visible in modifier dialogs.
   */
  enabled: Scalars['Boolean']
  /**
   * Indicates what hint text to display in the special requests
   * input box if special requests is enabled.
   */
  placeholderMessage: Scalars['String']
}

/** Configuration for optional information a restaurant could spotlight */
export type SpotlightConfig = {
  __typename?: 'SpotlightConfig'
  /**
   * Spotlight primary header text. Should be a relatively short message
   * Max length of 150 characters
   */
  headerText?: Maybe<Scalars['String']>
  /** Spotlight secondary header text. Can be arbitrary length */
  bodyText?: Maybe<Scalars['String']>
}

export type StartIdentityProfileError = Error & {
  __typename?: 'StartIdentityProfileError'
  code: StartIdentityProfileErrorCode
  message: Scalars['String']
}

export enum StartIdentityProfileErrorCode {
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  InvalidPhone = 'INVALID_PHONE'
}

export type StartIdentityProfileInput = {
  /** phoneNumber for the account. */
  phone: Scalars['String']
}

export type StartProfileCreationError = Error & {
  __typename?: 'StartProfileCreationError'
  code: StartProfileCreationErrorCode
  message: Scalars['String']
}

export enum StartProfileCreationErrorCode {
  ServiceUnavailable = 'SERVICE_UNAVAILABLE',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidName = 'INVALID_NAME'
}

export type StartProfileCreationInput = {
  /** Email for the new account. Cannot be changed once the account is created. */
  email: Scalars['String']
  /**
   * phoneNumber for the new account. Must not be in use by another account, and cannot be changed once the account is created.
   * A verification text will be sent to this number
   */
  phone: Scalars['String']
  /** The source for the account creation (Toast App vs web Online Ordering vs Order and Pay) */
  source: RegistrationSource
  /** Optional basic info can be provided to create the account with these fields (first name, last name, phone number) already set */
  basicInfo?: Maybe<BasicInfoInput>
  /**
   * Optional input to create a loyalty account at the same time as the OO account is created. **basicInfo** is required for the
   * loyalty account to be successfully created.
   */
  loyaltyAccountCreationInput?: Maybe<LoyaltyAccountCreationInput>
}

export type StartProfileCreationResponse = {
  __typename?: 'StartProfileCreationResponse'
  guestGuid: Scalars['ID']
}

export type StartProfileCreationResponseOrError =
  | StartProfileCreationResponse
  | StartProfileCreationError

/** generic key-value pair object */
export type StringKeyVal = {
  __typename?: 'StringKeyVal'
  key: Scalars['String']
  value?: Maybe<Scalars['String']>
}

/** generic key-value pair object */
export type StringKeyValInput = {
  key: Scalars['String']
  value?: Maybe<Scalars['String']>
}

export type SubmitGuestFeedbackV2Input = {
  /** Restaurant identifier for the feedback */
  restaurantGuid: Scalars['ID']
  /** identifier of the check associated with this feedback */
  checkGuid: Scalars['ID']
  /** identifier of the order associated with this feedback */
  orderGuid: Scalars['ID']
  /** identifier of the order payment associated with this feedback */
  orderPaymentGuid: Scalars['ID']
  /** a transposed rating that maps any rating structure to (negative, passive, positive) */
  feedbackRating: FeedbackRatingV2
  /** a rating linked to the actual rating system in use (thumb down, thumb up, stars, etc) */
  feedbackRawRating: FeedbackRawRatingV2
  /** Source the feedback is coming from Online Ordering, OPT, etc */
  feedbackSource: Scalars['String']
  /** Any free form feedback that was given by the user */
  feedbackFreeFormText?: Maybe<Scalars['String']>
  /** A JSON array of feedback reasons */
  feedbackReasons?: Maybe<Array<Maybe<Scalars['String']>>>
  /** Indicates whether the feedback is meant for Toast or a restaurant. Defaults to true. */
  internalFeedback?: Maybe<Scalars['Boolean']>
}

export type SubmitGuestFeedbackV2Response =
  | GuestFeedbackV2SuccessResponse
  | GuestFeedbackV2Error

/** Tipping values based on amount based on restaurant specified preferences */
export type SuggestedTip = {
  __typename?: 'SuggestedTip'
  /** Pre-calculated tips based on suggested tips in restaurant preferences */
  preComputedTips: Array<PreComputedTip>
  /**
   * Base value upon which a percent tip is evaluated,
   * to be used for consistency in client-side computations
   * of percentage-based tips.
   */
  tippableAmount: Scalars['Float']
}

/** ONLY FOR ORDER AT TABLE DO NOT USE IN ONLINE ORDERING OR CONSUMER APP */
export type Table = {
  __typename?: 'Table'
  guid: Scalars['ID']
  name: Scalars['String']
}

export type TfgConfig = {
  __typename?: 'TfgConfig'
  /** service charge associated with the Toast for good config */
  serviceChargeGuid?: Maybe<Scalars['String']>
  /** campaign name */
  campaignName?: Maybe<Scalars['String']>
  /** campaign Description */
  campaignDescription?: Maybe<Scalars['String']>
  /** campaign logo */
  campaignLogoURL?: Maybe<Scalars['String']>
  /** Whether roundup feature is enabled or not */
  active?: Maybe<Scalars['Boolean']>
}

export type TfgInput = {
  roundUpEnabled: Scalars['Boolean']
  estimatedRoundUpValue: Scalars['Float']
}

/** Field representing the unit which is used to measure the weight of an item. */
export enum UnitOfMeasure {
  /** None - this is the default */
  None = 'NONE',
  /** Pounds */
  Lb = 'LB',
  /** Ounces */
  Oz = 'OZ',
  /** Kilograms */
  Kg = 'KG',
  /** Grams */
  G = 'G'
}

export type UpcomingSchedules = {
  __typename?: 'UpcomingSchedules'
  /** The type of the dining option */
  behavior: DiningOptionBehavior
  dailySchedules: Array<DailySchedule>
}

export type UpdateAddressError = Error & {
  __typename?: 'UpdateAddressError'
  code: UpdateAddressErrorCode
  message: Scalars['String']
}

export enum UpdateAddressErrorCode {
  AddressNotFound = 'ADDRESS_NOT_FOUND'
}

export type UpdateAddressInput = {
  guid: Scalars['ID']
  /** The name/label for this address, e.g. "Home", "Work" */
  name: Scalars['String']
  deliveryInfo: DeliveryInfoInput
}

export type UpdateAddressResponse = {
  __typename?: 'UpdateAddressResponse'
  customer: Customer
}

export type UpdateAddressResponseOrError =
  | UpdateAddressResponse
  | UpdateAddressError

export type UpdateBasicInfoError = Error & {
  __typename?: 'UpdateBasicInfoError'
  code: UpdateBasicInfoErrorCode
  message: Scalars['String']
}

export enum UpdateBasicInfoErrorCode {
  InvalidBasicInfo = 'INVALID_BASIC_INFO'
}

export type UpdateBasicInfoResponse = {
  __typename?: 'UpdateBasicInfoResponse'
  customer: Customer
}

export type UpdateBasicInfoResponseOrError =
  | UpdateBasicInfoResponse
  | UpdateBasicInfoError

export type UpdateDiningOptionInput = {
  cartGuid: Scalars['ID']
  restaurantGuid: Scalars['ID']
  diningOption: DiningOptionBehavior
}

export type UpdateFulfillmentAndClearCartInput = {
  /** The GUID of the cart for which the fulfillment information is to be updated. */
  cartGuid: Scalars['ID']
  /** Indicates how the order on the cart should be fulfilled. */
  cartFulfillmentInput: CartFulfillmentInput
}

export type UpdateFulfillmentAndValidateInput = {
  /** The GUID of the cart for which the fulfillment information is to be updated. */
  cartGuid: Scalars['ID']
  /** Indicates how the order on the cart should be fulfilled. */
  cartFulfillmentInput: CartFulfillmentInput
}

export type UpdatePasswordError = Error & {
  __typename?: 'UpdatePasswordError'
  code: UpdatePasswordErrorCode
  message: Scalars['String']
}

export enum UpdatePasswordErrorCode {
  PasswordMismatch = 'PASSWORD_MISMATCH',
  InvalidNewPassword = 'INVALID_NEW_PASSWORD'
}

export type UpdatePasswordInput = {
  /** Current password, prior to update */
  oldPassword: Scalars['String']
  /** The desired password to be updated to */
  newPassword: Scalars['String']
  /**
   * Restaurant guid, which is used to fetch the restaurant name used in the password reset email to give context to the user
   * where their account is from. This is more important for white label sources where the user may not know what "Toast" is.
   * If not provided, will send the email with "Toast" as the restaurant name.
   */
  restaurantGuid?: Maybe<Scalars['ID']>
}

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse'
  customer: Customer
}

export type UpdatePasswordResponseOrError =
  | UpdatePasswordResponse
  | UpdatePasswordError

export type UpsellsConfig = {
  __typename?: 'UpsellsConfig'
  enabled: Scalars['Boolean']
}

export type ValidateApplePayMerchantError = Error & {
  __typename?: 'ValidateApplePayMerchantError'
  message: Scalars['String']
  code: ValidateApplePayMerchantErrorCode
}

export enum ValidateApplePayMerchantErrorCode {
  ValidateMerchantFailed = 'VALIDATE_MERCHANT_FAILED'
}

export type ValidateApplePayMerchantInput = {
  /** The GUID of the restaurant that client is using for checkout */
  restaurantGuid: Scalars['ID']
  /** The url generated by the Apple Pay JS client that will be used for merchant validation */
  validationURL: Scalars['String']
  /** The domain which the Apple Pay JS client is running */
  merchantDomain: Scalars['String']
}

export type ValidateApplePayMerchantResponse =
  | ValidateApplePayMerchantSuccessResponse
  | ValidateApplePayMerchantError

export type ValidateApplePayMerchantSuccessResponse = {
  __typename?: 'ValidateApplePayMerchantSuccessResponse'
  merchantSession: Scalars['String']
}

export type ValidateCartPreCheckoutInput = {
  /** The GUID of the cart that needs to be validated */
  cartGuid: Scalars['ID']
}

/** deprecated: use ValidateDeliveryLocationInputV2 */
export type ValidateDeliveryLocationInput = {
  restaurantGuid: Scalars['ID']
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type ValidateDeliveryLocationInputV2 = {
  restaurantGuid: Scalars['ID']
  /** The diner's location */
  deliveryInfo: DeliveryInfoInput
}

export type ValidateDeliveryLocationResponse = {
  __typename?: 'ValidateDeliveryLocationResponse'
  /** Indicates whether the input restaurant can deliver to the input location. */
  valid: Scalars['Boolean']
  /** Provides reasons for why delivery is unavailable. If valid or no reason, left as an empty list */
  unavailabilityReasons: Array<DeliveryUnavailableReason>
}

export type VerifyEmailError = Error & {
  __typename?: 'VerifyEmailError'
  code: VerifyEmailErrorCode
  message: Scalars['String']
}

export enum VerifyEmailErrorCode {
  InvalidToken = 'INVALID_TOKEN',
  TokenAlreadyUsed = 'TOKEN_ALREADY_USED'
}

export type VerifyEmailInput = {
  /**
   * Token obtained from the email sent by the **createAccount** mutation, which is appended to the redirectUrl as a query parameter
   * on the link in the email
   */
  verificationToken: Scalars['String']
}

export type VerifyEmailResponse = AuthenticationResponse | VerifyEmailError

export type VerifySmsCodeInput = {
  phoneNumber: Scalars['String']
  smsCode: Scalars['String']
}

export type VerifySmsCodeResponse = {
  __typename?: 'VerifySmsCodeResponse'
  success: Scalars['Boolean']
}

/**
 * Indicates that although the intended result of a query or mutation was achieved,
 * there are side effects that would affect downstream actions.
 */
export type Warning = {
  message: Scalars['String']
}

export type Login_ServerMutationVariables = Exact<{
  input: LoginInput
}>

export type Login_ServerMutation = { __typename?: 'Mutation' } & {
  login:
    | ({ __typename?: 'AuthenticationResponse' } & Pick<
        AuthenticationResponse,
        'customerGuid' | 'accessToken' | 'refreshToken'
      >)
    | ({ __typename?: 'MfaChallengeGeneratedResponse' } & Pick<
        MfaChallengeGeneratedResponse,
        'challengeToken' | 'mfaType' | 'maskedRecipient'
      >)
    | ({ __typename?: 'LoginError' } & Pick<LoginError, 'code' | 'message'>)
}

export type Mfa_Login_ServerMutationVariables = Exact<{
  input: MfaLoginInput
}>

export type Mfa_Login_ServerMutation = { __typename?: 'Mutation' } & {
  mfaLogin:
    | ({ __typename?: 'AuthenticationResponse' } & Pick<
        AuthenticationResponse,
        'customerGuid' | 'accessToken' | 'refreshToken'
      >)
    | ({ __typename?: 'MfaChallengeGeneratedResponse' } & Pick<
        MfaChallengeGeneratedResponse,
        'challengeToken' | 'mfaType' | 'maskedRecipient'
      >)
    | ({ __typename?: 'LoginError' } & Pick<LoginError, 'code' | 'message'>)
}

export type Customer_ServerQueryVariables = Exact<{ [key: string]: never }>

export type Customer_ServerQuery = { __typename?: 'Query' } & {
  customer: { __typename?: 'Customer' } & Pick<
    Customer,
    'guid' | 'firstName' | 'lastName' | 'phone' | 'email'
  > & {
      creditCards: Array<
        { __typename?: 'SavedCreditCard' } & Pick<
          SavedCreditCard,
          | 'guid'
          | 'maskedPan'
          | 'expirationMonth'
          | 'expirationYear'
          | 'cardType'
          | 'isPrimary'
        >
      >
    }
}

export type RefreshMutationVariables = Exact<{
  input: RefreshInput
}>

export type RefreshMutation = { __typename?: 'Mutation' } & {
  refresh:
    | ({ __typename?: 'AuthenticationResponse' } & Pick<
        AuthenticationResponse,
        'customerGuid' | 'accessToken' | 'refreshToken'
      >)
    | ({ __typename?: 'RefreshError' } & Pick<RefreshError, 'code' | 'message'>)
}

export type Create_AccountMutationVariables = Exact<{
  input: CreateAccountInput
}>

export type Create_AccountMutation = { __typename?: 'Mutation' } & {
  createAccount:
    | ({ __typename?: 'CreateAccountResponse' } & Pick<
        CreateAccountResponse,
        'customerGuid'
      >)
    | ({ __typename?: 'CreateAccountError' } & Pick<
        CreateAccountError,
        'code' | 'message'
      >)
}

export type Get_CartQueryVariables = Exact<{
  guid: Scalars['ID']
}>

export type Get_CartQuery = { __typename?: 'Query' } & {
  cartV2:
    | ({ __typename?: 'CartResponse' } & CartResponseFragment)
    | ({ __typename?: 'CartError' } & Pick<CartError, 'code' | 'message'>)
}

export type Get_Cart_For_CheckoutQueryVariables = Exact<{
  guid: Scalars['ID']
}>

export type Get_Cart_For_CheckoutQuery = { __typename?: 'Query' } & {
  cartV2:
    | ({ __typename?: 'CartResponse' } & {
        cart: { __typename?: 'Cart' } & {
          restaurant?: Maybe<
            { __typename?: 'Restaurant' } & {
              creditCardConfig: {
                __typename?: 'RestaurantCreditCardConfig'
              } & Pick<
                RestaurantCreditCardConfig,
                'amexAccepted' | 'tipEnabled'
              >
              loyaltyConfig: { __typename?: 'LoyaltyConfig' } & Pick<
                LoyaltyConfig,
                | 'loyaltyRedemptionEnabled'
                | 'loyaltySignupEnabled'
                | 'loyaltySignupBonus'
                | 'conversionRate'
                | 'accrualTarget'
              >
              tfgConfig: { __typename?: 'TfgConfig' } & Pick<
                TfgConfig,
                | 'serviceChargeGuid'
                | 'campaignName'
                | 'campaignDescription'
                | 'campaignLogoURL'
                | 'active'
              >
            }
          >
        } & CartFragment
        warnings: Array<
          { __typename?: 'CartWarning' } & Pick<CartWarning, 'code' | 'message'>
        >
        info: Array<
          { __typename?: 'CartInfo' } & Pick<CartInfo, 'code' | 'message'>
        >
      })
    | ({ __typename?: 'CartError' } & Pick<CartError, 'code' | 'message'>)
}

export type Add_Item_To_CartMutationVariables = Exact<{
  input: AddItemToCartInputV2
}>

export type Add_Item_To_CartMutation = { __typename?: 'Mutation' } & {
  addItemToCartV2:
    | ({ __typename?: 'CartResponse' } & CartResponseFragment)
    | ({ __typename?: 'CartModificationError' } & Pick<
        CartModificationError,
        'code' | 'message'
      >)
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & {
          cart: { __typename?: 'Cart' } & CartFragment
          items: Array<
            { __typename?: 'CartOutOfStockItem' } & Pick<
              CartOutOfStockItem,
              'name' | 'guid'
            >
          >
        })
}

export type Delete_Item_From_CartMutationVariables = Exact<{
  input: DeleteItemFromCartInput
}>

export type Delete_Item_From_CartMutation = { __typename?: 'Mutation' } & {
  deleteItemFromCartV2:
    | ({ __typename?: 'CartResponse' } & CartResponseFragment)
    | ({ __typename?: 'CartModificationError' } & Pick<
        CartModificationError,
        'code' | 'message'
      >)
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & {
          cart: { __typename?: 'Cart' } & CartFragment
          items: Array<
            { __typename?: 'CartOutOfStockItem' } & Pick<
              CartOutOfStockItem,
              'name' | 'guid'
            >
          >
        })
}

export type Edit_Item_In_CartMutationVariables = Exact<{
  input: EditItemInCartInput
}>

export type Edit_Item_In_CartMutation = { __typename?: 'Mutation' } & {
  editItemInCartV2:
    | ({ __typename?: 'CartResponse' } & CartResponseFragment)
    | ({ __typename?: 'CartModificationError' } & Pick<
        CartModificationError,
        'code' | 'message'
      >)
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & {
          cart: { __typename?: 'Cart' } & CartFragment
          items: Array<
            { __typename?: 'CartOutOfStockItem' } & Pick<
              CartOutOfStockItem,
              'name' | 'guid'
            >
          >
        })
}

export type Update_Fulfillment_And_ValidateMutationVariables = Exact<{
  input: UpdateFulfillmentAndValidateInput
}>

export type Update_Fulfillment_And_ValidateMutation = {
  __typename?: 'Mutation'
} & {
  updateFulfillmentAndValidate:
    | ({ __typename?: 'CartResponse' } & CartResponseFragment)
    | ({ __typename?: 'CartModificationError' } & Pick<
        CartModificationError,
        'code' | 'message'
      >)
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & {
          cart: { __typename?: 'Cart' } & CartFragment
          items: Array<
            { __typename?: 'CartOutOfStockItem' } & Pick<
              CartOutOfStockItem,
              'guid' | 'name'
            >
          >
        })
}

export type Update_Fulfillment_And_Clear_CartMutationVariables = Exact<{
  input: UpdateFulfillmentAndClearCartInput
}>

export type Update_Fulfillment_And_Clear_CartMutation = {
  __typename?: 'Mutation'
} & {
  updateFulfillmentAndClearCart:
    | ({ __typename?: 'CartResponse' } & CartResponseFragment)
    | ({ __typename?: 'CartModificationError' } & Pick<
        CartModificationError,
        'code' | 'message'
      >)
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & { cart: { __typename?: 'Cart' } & CartFragment })
}

export type Update_Dining_OptionMutationVariables = Exact<{
  input: UpdateDiningOptionInput
}>

export type Update_Dining_OptionMutation = { __typename?: 'Mutation' } & {
  updateDiningOption:
    | ({ __typename?: 'CartResponse' } & CartResponseFragment)
    | ({ __typename?: 'CartModificationError' } & Pick<
        CartModificationError,
        'code' | 'message'
      >)
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & { cart: { __typename?: 'Cart' } & CartFragment })
}

export type Place_Cash_OrderMutationVariables = Exact<{
  input: PlaceCashOrderInput
}>

export type Place_Cash_OrderMutation = { __typename?: 'Mutation' } & {
  placeCashOrder:
    | ({ __typename?: 'PlaceOrderResponse' } & {
        completedOrder: {
          __typename?: 'CompletedOrder'
        } & CompletedOrderFragment
        warnings: Array<
          { __typename?: 'PlaceOrderWarning' } & Pick<
            PlaceOrderWarning,
            'code' | 'message'
          >
        >
      })
    | ({ __typename?: 'PlaceOrderError' } & Pick<
        PlaceOrderError,
        'code' | 'message'
      >)
    | { __typename?: 'PlaceOrderOutOfStockError' }
    | ({ __typename?: 'PlaceOrderCartUpdatedError' } & Pick<
        PlaceOrderCartUpdatedError,
        'message'
      > & { cartUpdatedCode: PlaceOrderCartUpdatedError['code'] } & {
          cart: { __typename?: 'Cart' } & CartFragment
        })
}

export type Place_Cc_OrderMutationVariables = Exact<{
  input: PlaceCcOrderInput
}>

export type Place_Cc_OrderMutation = { __typename?: 'Mutation' } & {
  placeCcOrder:
    | ({ __typename?: 'PlaceOrderResponse' } & {
        completedOrder: {
          __typename?: 'CompletedOrder'
        } & CompletedOrderFragment
        warnings: Array<
          { __typename?: 'PlaceOrderWarning' } & Pick<
            PlaceOrderWarning,
            'code' | 'message'
          >
        >
      })
    | ({ __typename?: 'PlaceOrderError' } & Pick<
        PlaceOrderError,
        'code' | 'message'
      >)
    | { __typename?: 'PlaceOrderOutOfStockError' }
    | ({ __typename?: 'PlaceOrderCartUpdatedError' } & Pick<
        PlaceOrderCartUpdatedError,
        'message'
      > & { cartUpdatedCode: PlaceOrderCartUpdatedError['code'] } & {
          cart: { __typename?: 'Cart' } & CartFragment
        })
}

export type Place_Apple_Pay_OrderMutationVariables = Exact<{
  input: PlaceApplePayOrderInput
}>

export type Place_Apple_Pay_OrderMutation = { __typename?: 'Mutation' } & {
  placeApplePayOrder:
    | ({ __typename?: 'PlaceOrderResponse' } & {
        completedOrder: {
          __typename?: 'CompletedOrder'
        } & CompletedOrderFragment
        warnings: Array<
          { __typename?: 'PlaceOrderWarning' } & Pick<
            PlaceOrderWarning,
            'code' | 'message'
          >
        >
      })
    | ({ __typename?: 'PlaceOrderError' } & Pick<
        PlaceOrderError,
        'code' | 'message'
      >)
    | { __typename?: 'PlaceOrderOutOfStockError' }
    | ({ __typename?: 'PlaceOrderCartUpdatedError' } & Pick<
        PlaceOrderCartUpdatedError,
        'message'
      > & { cartUpdatedCode: PlaceOrderCartUpdatedError['code'] } & {
          cart: { __typename?: 'Cart' } & CartFragment
        })
}

export type Apply_Promo_Code_V3MutationVariables = Exact<{
  input: ApplyPromoCodeInputV3
}>

export type Apply_Promo_Code_V3Mutation = { __typename?: 'Mutation' } & {
  applyPromoCodeV3:
    | ({ __typename?: 'CartResponse' } & {
        cart: { __typename?: 'Cart' } & CartFragment
      })
    | ({ __typename?: 'CartModificationError' } & Pick<
        CartModificationError,
        'code' | 'message'
      >)
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & {
          cart: { __typename?: 'Cart' } & CartFragment
          items: Array<
            { __typename?: 'CartOutOfStockItem' } & Pick<
              CartOutOfStockItem,
              'name' | 'guid'
            >
          >
        })
    | { __typename?: 'ApplyPromoCodeError' }
}

export type Apply_Promo_CodeMutationVariables = Exact<{
  input: ApplyPromoCodeInputV2
}>

export type Apply_Promo_CodeMutation = { __typename?: 'Mutation' } & {
  applyPromoCodeV2:
    | ({ __typename?: 'CartResponse' } & {
        cart: { __typename?: 'Cart' } & CartFragment
      })
    | ({ __typename?: 'CartModificationError' } & Pick<
        CartModificationError,
        'code' | 'message'
      >)
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & {
          cart: { __typename?: 'Cart' } & CartFragment
          items: Array<
            { __typename?: 'CartOutOfStockItem' } & Pick<
              CartOutOfStockItem,
              'name' | 'guid'
            >
          >
        })
    | { __typename?: 'ApplyPromoCodeError' }
}

export type Remove_Promo_CodeMutationVariables = Exact<{
  input: RemovePromoCodeInput
}>

export type Remove_Promo_CodeMutation = { __typename?: 'Mutation' } & {
  removePromoCode:
    | ({ __typename?: 'CartResponse' } & {
        cart: { __typename?: 'Cart' } & CartFragment
      })
    | ({ __typename?: 'CartModificationError' } & Pick<
        CartModificationError,
        'code' | 'message'
      >)
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & { cart: { __typename?: 'Cart' } & CartFragment })
}

export type Inquire_LoyaltyMutationVariables = Exact<{
  input: LoyaltyInquiryInput
}>

export type Inquire_LoyaltyMutation = { __typename?: 'Mutation' } & {
  loyaltyInquiry:
    | ({ __typename?: 'LoyaltyInquiryResponse' } & Pick<
        LoyaltyInquiryResponse,
        'loyaltyAccountIdentifier'
      > & {
          redemptions: Array<
            { __typename?: 'LoyaltyRedemption' } & Pick<
              LoyaltyRedemption,
              'redemptionAmount' | 'redemptionGuid'
            >
          >
        })
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & {
          cart: { __typename?: 'Cart' } & CartFragment
          items: Array<
            { __typename?: 'CartOutOfStockItem' } & Pick<
              CartOutOfStockItem,
              'name' | 'guid'
            >
          >
        })
    | { __typename?: 'CustomerLoyaltyError' }
}

export type Add_Loyalty_Redemption_To_CartMutationVariables = Exact<{
  input: AddLoyaltyRedemptionInput
}>

export type Add_Loyalty_Redemption_To_CartMutation = {
  __typename?: 'Mutation'
} & {
  addLoyaltyRedemption:
    | ({ __typename?: 'CartResponse' } & {
        cart: { __typename?: 'Cart' } & CartFragment
        info: Array<
          { __typename?: 'CartInfo' } & Pick<CartInfo, 'code' | 'message'>
        >
        warnings: Array<
          { __typename?: 'CartWarning' } & Pick<CartWarning, 'code' | 'message'>
        >
      })
    | { __typename?: 'CartModificationError' }
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & {
          cart: { __typename?: 'Cart' } & CartFragment
          items: Array<
            { __typename?: 'CartOutOfStockItem' } & Pick<
              CartOutOfStockItem,
              'name' | 'guid'
            >
          >
        })
}

export type Remove_Loyalty_Redemption_From_CartMutationVariables = Exact<{
  input: RemoveLoyaltyRedemptionInput
}>

export type Remove_Loyalty_Redemption_From_CartMutation = {
  __typename?: 'Mutation'
} & {
  removeLoyaltyRedemption:
    | ({ __typename?: 'CartResponse' } & {
        cart: { __typename?: 'Cart' } & CartFragment
        info: Array<
          { __typename?: 'CartInfo' } & Pick<CartInfo, 'code' | 'message'>
        >
        warnings: Array<
          { __typename?: 'CartWarning' } & Pick<CartWarning, 'code' | 'message'>
        >
      })
    | { __typename?: 'CartModificationError' }
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & {
          cart: { __typename?: 'Cart' } & CartFragment
          items: Array<
            { __typename?: 'CartOutOfStockItem' } & Pick<
              CartOutOfStockItem,
              'name' | 'guid'
            >
          >
        })
}

export type Reorder_And_Replace_CartMutationVariables = Exact<{
  input: ReorderInput
}>

export type Reorder_And_Replace_CartMutation = { __typename?: 'Mutation' } & {
  reorderV2?: Maybe<
    | ({ __typename?: 'ReorderResponse' } & {
        cart: { __typename?: 'Cart' } & CartFragment
        warningsV2: Array<
          { __typename?: 'ReorderWarning' } & Pick<
            ReorderWarning,
            'message' | 'code'
          >
        >
        outOfStockItems: Array<
          { __typename?: 'CartOutOfStockItem' } & Pick<
            CartOutOfStockItem,
            'name' | 'guid'
          >
        >
      })
    | ({ __typename?: 'ReorderError' } & Pick<ReorderError, 'code' | 'message'>)
  >
}

export type Get_Completed_OrderQueryVariables = Exact<{
  input: CompletedOrderInput
}>

export type Get_Completed_OrderQuery = { __typename?: 'Query' } & {
  completedOrder: { __typename?: 'CompletedOrder' } & CompletedOrderFragment
}

export type Validate_CartMutationVariables = Exact<{
  input: ValidateCartPreCheckoutInput
}>

export type Validate_CartMutation = { __typename?: 'Mutation' } & {
  validateCartPreCheckout:
    | ({ __typename?: 'CartResponse' } & CartResponseFragment)
    | ({ __typename?: 'CartModificationError' } & Pick<
        CartModificationError,
        'code' | 'message'
      >)
    | ({ __typename?: 'CartOutOfStockError' } & Pick<
        CartOutOfStockError,
        'message'
      > & {
          cart: { __typename?: 'Cart' } & CartFragment
          items: Array<
            { __typename?: 'CartOutOfStockItem' } & Pick<
              CartOutOfStockItem,
              'name' | 'guid'
            >
          >
        })
}

export type CartFragment = { __typename?: 'Cart' } & Pick<
  Cart,
  | 'guid'
  | 'quoteTime'
  | 'diningOptionBehavior'
  | 'fulfillmentType'
  | 'fulfillmentDateTime'
  | 'takeoutQuoteTime'
  | 'deliveryQuoteTime'
> & {
    order?: Maybe<
      { __typename?: 'Order' } & Pick<
        Order,
        | 'numberOfSelections'
        | 'discountsTotal'
        | 'deliveryChargeTotal'
        | 'serviceChargeTotal'
        | 'subtotal'
        | 'tax'
        | 'total'
      > & {
          deliveryInfo?: Maybe<
            { __typename?: 'DeliveryInfo' } & Pick<
              DeliveryInfo,
              | 'address1'
              | 'address2'
              | 'city'
              | 'state'
              | 'zipCode'
              | 'latitude'
              | 'longitude'
              | 'notes'
            >
          >
          selections?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Selection' } & Pick<
                  Selection,
                  | 'guid'
                  | 'groupingKey'
                  | 'itemGuid'
                  | 'itemGroupGuid'
                  | 'name'
                  | 'preDiscountPrice'
                  | 'price'
                  | 'quantity'
                  | 'usesFractionalQuantity'
                > & {
                    fractionalQuantity: {
                      __typename?: 'FractionalQuantity'
                    } & Pick<FractionalQuantity, 'unitOfMeasure' | 'quantity'>
                    modifiers?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'Selection' } & Pick<
                            Selection,
                            'guid' | 'name' | 'price' | 'groupingKey'
                          > & {
                              modifiers?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'Selection' } & Pick<
                                      Selection,
                                      'guid' | 'name' | 'price' | 'groupingKey'
                                    > & {
                                        modifiers?: Maybe<
                                          Array<
                                            Maybe<
                                              {
                                                __typename?: 'Selection'
                                              } & Pick<
                                                Selection,
                                                | 'guid'
                                                | 'name'
                                                | 'price'
                                                | 'groupingKey'
                                              > & {
                                                  modifiers?: Maybe<
                                                    Array<
                                                      Maybe<
                                                        {
                                                          __typename?: 'Selection'
                                                        } & Pick<
                                                          Selection,
                                                          | 'guid'
                                                          | 'name'
                                                          | 'price'
                                                          | 'groupingKey'
                                                        > & {
                                                            modifiers?: Maybe<
                                                              Array<
                                                                Maybe<
                                                                  {
                                                                    __typename?: 'Selection'
                                                                  } & Pick<
                                                                    Selection,
                                                                    | 'guid'
                                                                    | 'name'
                                                                    | 'price'
                                                                    | 'groupingKey'
                                                                  > & {
                                                                      modifiers?: Maybe<
                                                                        Array<
                                                                          Maybe<
                                                                            {
                                                                              __typename?: 'Selection'
                                                                            } & Pick<
                                                                              Selection,
                                                                              | 'guid'
                                                                              | 'name'
                                                                              | 'price'
                                                                              | 'groupingKey'
                                                                            > & {
                                                                                modifiers?: Maybe<
                                                                                  Array<
                                                                                    Maybe<
                                                                                      {
                                                                                        __typename?: 'Selection'
                                                                                      } & Pick<
                                                                                        Selection,
                                                                                        | 'guid'
                                                                                        | 'name'
                                                                                        | 'price'
                                                                                        | 'groupingKey'
                                                                                      > & {
                                                                                          modifiers?: Maybe<
                                                                                            Array<
                                                                                              Maybe<
                                                                                                {
                                                                                                  __typename?: 'Selection'
                                                                                                } & Pick<
                                                                                                  Selection,
                                                                                                  | 'guid'
                                                                                                  | 'name'
                                                                                                  | 'price'
                                                                                                  | 'groupingKey'
                                                                                                > & {
                                                                                                    modifiers?: Maybe<
                                                                                                      Array<
                                                                                                        Maybe<
                                                                                                          {
                                                                                                            __typename?: 'Selection'
                                                                                                          } & Pick<
                                                                                                            Selection,
                                                                                                            | 'guid'
                                                                                                            | 'name'
                                                                                                            | 'price'
                                                                                                            | 'groupingKey'
                                                                                                          > & {
                                                                                                              modifiers?: Maybe<
                                                                                                                Array<
                                                                                                                  Maybe<
                                                                                                                    {
                                                                                                                      __typename?: 'Selection'
                                                                                                                    } & Pick<
                                                                                                                      Selection,
                                                                                                                      | 'guid'
                                                                                                                      | 'name'
                                                                                                                      | 'price'
                                                                                                                      | 'groupingKey'
                                                                                                                    >
                                                                                                                  >
                                                                                                                >
                                                                                                              >
                                                                                                            }
                                                                                                        >
                                                                                                      >
                                                                                                    >
                                                                                                  }
                                                                                              >
                                                                                            >
                                                                                          >
                                                                                        }
                                                                                    >
                                                                                  >
                                                                                >
                                                                              }
                                                                          >
                                                                        >
                                                                      >
                                                                    }
                                                                >
                                                              >
                                                            >
                                                          }
                                                      >
                                                    >
                                                  >
                                                }
                                            >
                                          >
                                        >
                                      }
                                  >
                                >
                              >
                            }
                        >
                      >
                    >
                  }
              >
            >
          >
          discounts: { __typename?: 'Discounts' } & {
            restaurantDiscount?: Maybe<
              { __typename?: 'RestaurantDiscount' } & Pick<
                RestaurantDiscount,
                'guid' | 'name' | 'amount' | 'promoCode'
              >
            >
            loyaltyDiscount?: Maybe<
              { __typename?: 'LoyaltyDiscount' } & Pick<
                LoyaltyDiscount,
                'guid' | 'amount'
              >
            >
            loyaltyDiscounts?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'LoyaltyDiscount' } & Pick<
                    LoyaltyDiscount,
                    'type' | 'amount' | 'guid'
                  >
                >
              >
            >
            globalReward?: Maybe<
              { __typename?: 'AppliedCustomerReward' } & Pick<
                AppliedCustomerReward,
                'amount' | 'name' | 'rewardType' | 'total'
              >
            >
          }
        }
    >
    paymentOptions: { __typename?: 'CartPaymentOptions' } & {
      atCheckout: Array<
        Maybe<
          { __typename?: 'CartPaymentOption' } & Pick<
            CartPaymentOption,
            'paymentType'
          >
        >
      >
      uponReceipt: Array<
        Maybe<
          { __typename?: 'CartPaymentOption' } & Pick<
            CartPaymentOption,
            'paymentType'
          >
        >
      >
    }
    preComputedTips: Array<
      Maybe<
        { __typename?: 'PreComputedTip' } & Pick<
          PreComputedTip,
          'percent' | 'value'
        >
      >
    >
    approvalRules: Array<
      { __typename?: 'ApprovalRule' } & Pick<
        ApprovalRule,
        'ruleType' | 'requiredAdjustment' | 'thresholdAmount'
      >
    >
    deliveryProviderInfo?: Maybe<
      { __typename?: 'DeliveryProviderInfo' } & Pick<
        DeliveryProviderInfo,
        'provider' | 'needsDeliveryCommunicationConsent'
      >
    >
    cartUpsellInfo?: Maybe<
      { __typename?: 'CartUpsellInfo' } & Pick<CartUpsellInfo, 'upsellItems'>
    >
  }

export type CompletedOrderFragment = { __typename?: 'CompletedOrder' } & Pick<
  CompletedOrder,
  | 'guid'
  | 'discountsTotal'
  | 'checkNumber'
  | 'checkGuid'
  | 'guestCommunication'
  | 'deliveryChargeTotal'
  | 'serviceChargeTotal'
  | 'tfgRoundUpTotal'
  | 'subtotal'
  | 'tax'
  | 'tip'
  | 'total'
  | 'estimatedFulfillmentDate'
  | 'paymentType'
  | 'orderPaymentGuid'
  | 'hasLoyaltyAttached'
> & {
    restaurant: { __typename?: 'Restaurant' } & Pick<
      Restaurant,
      'guid' | 'name' | 'whiteLabelName' | 'timeZoneId'
    > & {
        location: { __typename?: 'Location' } & Pick<
          Location,
          'address1' | 'address2' | 'city' | 'state' | 'phone'
        >
        creditCardConfig: { __typename?: 'RestaurantCreditCardConfig' } & Pick<
          RestaurantCreditCardConfig,
          'amexAccepted' | 'tipEnabled'
        >
        loyaltyConfig: { __typename?: 'LoyaltyConfig' } & Pick<
          LoyaltyConfig,
          | 'loyaltyRedemptionEnabled'
          | 'loyaltySignupEnabled'
          | 'loyaltySignupBonus'
          | 'conversionRate'
          | 'accrualTarget'
        >
        socialMediaLinks: { __typename?: 'SocialMediaLinks' } & Pick<
          SocialMediaLinks,
          'twitterLink' | 'facebookLink'
        >
      }
    customerV2?: Maybe<
      { __typename?: 'CustomerInfo' } & Pick<
        CustomerInfo,
        'firstName' | 'lastName' | 'email' | 'phone'
      >
    >
    selections: Array<
      { __typename?: 'CompletedOrderSelection' } & Pick<
        CompletedOrderSelection,
        'name' | 'price' | 'preDiscountPrice' | 'usesFractionalQuantity'
      > & {
          modifiers: Array<
            { __typename?: 'CompletedOrderModifierSelection' } & Pick<
              CompletedOrderModifierSelection,
              'name'
            > & {
                modifiers: Array<
                  { __typename?: 'CompletedOrderModifierSelection' } & Pick<
                    CompletedOrderModifierSelection,
                    'name'
                  >
                >
              }
          >
          fractionalQuantity: { __typename?: 'FractionalQuantity' } & Pick<
            FractionalQuantity,
            'unitOfMeasure' | 'quantity'
          >
        }
    >
    discounts: { __typename?: 'Discounts' } & {
      restaurantDiscount?: Maybe<
        { __typename?: 'RestaurantDiscount' } & Pick<
          RestaurantDiscount,
          'guid' | 'name' | 'amount' | 'promoCode'
        >
      >
      loyaltyDiscount?: Maybe<
        { __typename?: 'LoyaltyDiscount' } & Pick<
          LoyaltyDiscount,
          'guid' | 'amount'
        >
      >
      loyaltyDiscounts?: Maybe<
        Array<
          Maybe<
            { __typename?: 'LoyaltyDiscount' } & Pick<
              LoyaltyDiscount,
              'type' | 'amount' | 'guid'
            >
          >
        >
      >
      globalReward?: Maybe<
        { __typename?: 'AppliedCustomerReward' } & Pick<
          AppliedCustomerReward,
          'name' | 'amount'
        >
      >
    }
    curbsidePickup?: Maybe<
      { __typename?: 'CurbsidePickup' } & Pick<CurbsidePickup, 'selected'>
    >
    curbsidePickupV2?: Maybe<
      { __typename?: 'CurbsidePickupV2' } & Pick<
        CurbsidePickupV2,
        'transportDescription' | 'transportColor'
      >
    >
    giftCard?: Maybe<
      { __typename?: 'GiftCardPayment' } & Pick<
        GiftCardPayment,
        'appliedBalance'
      >
    >
    deliveryInfo?: Maybe<
      { __typename?: 'DeliveryInfo' } & Pick<
        DeliveryInfo,
        'address1' | 'address2' | 'city' | 'state' | 'zipCode' | 'notes'
      >
    >
  }

export type CartResponseFragment = { __typename?: 'CartResponse' } & {
  cart: { __typename?: 'Cart' } & CartFragment
  warnings: Array<
    { __typename?: 'CartWarning' } & Pick<CartWarning, 'code' | 'message'>
  >
  info: Array<{ __typename?: 'CartInfo' } & Pick<CartInfo, 'code' | 'message'>>
}

export type CartOutOfStockErrorFragment = {
  __typename?: 'CartOutOfStockError'
} & Pick<CartOutOfStockError, 'message'> & {
    cart: { __typename?: 'Cart' } & CartFragment
    items: Array<
      { __typename?: 'CartOutOfStockItem' } & Pick<
        CartOutOfStockItem,
        'name' | 'guid'
      >
    >
  }

export type Update_Basic_InfoMutationVariables = Exact<{
  input: BasicInfoInput
}>

export type Update_Basic_InfoMutation = { __typename?: 'Mutation' } & {
  updateBasicInfo:
    | ({ __typename?: 'UpdateBasicInfoResponse' } & {
        customer: { __typename?: 'Customer' } & Pick<
          Customer,
          'guid' | 'firstName' | 'lastName' | 'phone'
        >
      })
    | ({ __typename?: 'UpdateBasicInfoError' } & Pick<
        UpdateBasicInfoError,
        'code' | 'message'
      >)
}

export type Update_PasswordMutationVariables = Exact<{
  input: UpdatePasswordInput
}>

export type Update_PasswordMutation = { __typename?: 'Mutation' } & {
  updatePassword:
    | ({ __typename?: 'UpdatePasswordResponse' } & {
        customer: { __typename?: 'Customer' } & Pick<Customer, 'guid'>
      })
    | ({ __typename?: 'UpdatePasswordError' } & Pick<
        UpdatePasswordError,
        'code' | 'message'
      >)
}

export type Dining_OptionsQueryVariables = Exact<{
  input: DiningOptionsInput
}>

export type Dining_OptionsQuery = { __typename?: 'Query' } & {
  diningOptions: Array<
    { __typename?: 'DiningOption' } & Pick<
      DiningOption,
      'guid' | 'behavior'
    > & {
        deliveryProvider?: Maybe<
          { __typename?: 'DeliveryProviderInfo' } & Pick<
            DeliveryProviderInfo,
            'provider'
          >
        >
        asapSchedule?: Maybe<
          { __typename?: 'AsapSchedule' } & Pick<
            AsapSchedule,
            'availableNow' | 'availableAt'
          >
        >
        futureSchedule?: Maybe<
          { __typename?: 'FutureSchedule' } & {
            dates: Array<
              { __typename?: 'FutureFulfillmentDate' } & Pick<
                FutureFulfillmentDate,
                'date'
              > & {
                  timesAndGaps: Array<
                    | ({ __typename?: 'FutureFulfillmentTime' } & Pick<
                        FutureFulfillmentTime,
                        'time'
                      >)
                    | ({ __typename?: 'FutureFulfillmentServiceGap' } & Pick<
                        FutureFulfillmentServiceGap,
                        'description'
                      >)
                  >
                }
            >
          }
        >
      }
  >
}

export type Inquire_Gift_Card_BalanceQueryVariables = Exact<{
  input: GiftCardBalanceInquiryInput
}>

export type Inquire_Gift_Card_BalanceQuery = { __typename?: 'Query' } & {
  giftCardBalanceInquiry:
    | ({ __typename?: 'GiftCardBalanceInquiryResponse' } & Pick<
        GiftCardBalanceInquiryResponse,
        'cardNumber' | 'availableBalance'
      >)
    | ({ __typename?: 'GiftCardBalanceInquiryError' } & Pick<
        GiftCardBalanceInquiryError,
        'code' | 'message'
      >)
}

export type ModifierGroupFieldsFragment = {
  __typename?: 'ModifierGroup'
} & Pick<
  ModifierGroup,
  'guid' | 'minSelections' | 'maxSelections' | 'name' | 'pricingMode'
>

export type ModifierFieldsFragment = { __typename?: 'Modifier' } & Pick<
  Modifier,
  | 'itemGuid'
  | 'itemGroupGuid'
  | 'name'
  | 'price'
  | 'selected'
  | 'isDefault'
  | 'outOfStock'
  | 'allowsDuplicates'
>

export type SelectionModifierFieldsFragment = {
  __typename?: 'SelectionModifier'
} & Pick<
  SelectionModifier,
  | 'itemGuid'
  | 'itemGroupGuid'
  | 'name'
  | 'price'
  | 'selected'
  | 'isDefault'
  | 'outOfStock'
  | 'quantity'
  | 'allowsDuplicates'
>

export type SelectionModifierGroupFieldsFragment = {
  __typename?: 'SelectionModifierGroup'
} & Pick<
  SelectionModifierGroup,
  'guid' | 'minSelections' | 'maxSelections' | 'name' | 'pricingMode'
>

export type Selection_Item_DetailsQueryVariables = Exact<{
  input: SelectionItemDetailsInput
  nestingLevel?: Maybe<Scalars['Int']>
}>

export type Selection_Item_DetailsQuery = { __typename?: 'Query' } & {
  selectionItemDetails: { __typename?: 'SelectionItem' } & Pick<
    SelectionItem,
    | 'description'
    | 'name'
    | 'guid'
    | 'menuItemPrice'
    | 'itemGuid'
    | 'itemGroupGuid'
    | 'quantity'
    | 'specialRequest'
    | 'calories'
    | 'imageUrl'
    | 'usesFractionalQuantity'
  > & {
      fractionalQuantity: { __typename?: 'FractionalQuantity' } & Pick<
        FractionalQuantity,
        'unitOfMeasure' | 'quantity'
      >
      modifierGroups: Array<
        { __typename?: 'SelectionModifierGroup' } & {
          modifiers: Array<
            { __typename?: 'SelectionModifier' } & {
              modifierGroups: Array<
                { __typename?: 'SelectionModifierGroup' } & {
                  modifiers: Array<
                    { __typename?: 'SelectionModifier' } & {
                      modifierGroups: Array<
                        { __typename?: 'SelectionModifierGroup' } & {
                          modifiers: Array<
                            { __typename?: 'SelectionModifier' } & {
                              modifierGroups: Array<
                                { __typename?: 'SelectionModifierGroup' } & {
                                  modifiers: Array<
                                    { __typename?: 'SelectionModifier' } & {
                                      modifierGroups: Array<
                                        {
                                          __typename?: 'SelectionModifierGroup'
                                        } & {
                                          modifiers: Array<
                                            {
                                              __typename?: 'SelectionModifier'
                                            } & {
                                              modifierGroups: Array<
                                                {
                                                  __typename?: 'SelectionModifierGroup'
                                                } & {
                                                  modifiers: Array<
                                                    {
                                                      __typename?: 'SelectionModifier'
                                                    } & {
                                                      modifierGroups: Array<
                                                        {
                                                          __typename?: 'SelectionModifierGroup'
                                                        } & {
                                                          modifiers: Array<
                                                            {
                                                              __typename?: 'SelectionModifier'
                                                            } & {
                                                              modifierGroups: Array<
                                                                {
                                                                  __typename?: 'SelectionModifierGroup'
                                                                } & {
                                                                  modifiers: Array<
                                                                    {
                                                                      __typename?: 'SelectionModifier'
                                                                    } & {
                                                                      modifierGroups: Array<
                                                                        {
                                                                          __typename?: 'SelectionModifierGroup'
                                                                        } & {
                                                                          modifiers: Array<
                                                                            {
                                                                              __typename?: 'SelectionModifier'
                                                                            } & {
                                                                              modifierGroups: Array<
                                                                                {
                                                                                  __typename?: 'SelectionModifierGroup'
                                                                                } & {
                                                                                  modifiers: Array<
                                                                                    {
                                                                                      __typename?: 'SelectionModifier'
                                                                                    } & SelectionModifierFieldsFragment
                                                                                  >
                                                                                } & SelectionModifierGroupFieldsFragment
                                                                              >
                                                                            } & SelectionModifierFieldsFragment
                                                                          >
                                                                        } & SelectionModifierGroupFieldsFragment
                                                                      >
                                                                    } & SelectionModifierFieldsFragment
                                                                  >
                                                                } & SelectionModifierGroupFieldsFragment
                                                              >
                                                            } & SelectionModifierFieldsFragment
                                                          >
                                                        } & SelectionModifierGroupFieldsFragment
                                                      >
                                                    } & SelectionModifierFieldsFragment
                                                  >
                                                } & SelectionModifierGroupFieldsFragment
                                              >
                                            } & SelectionModifierFieldsFragment
                                          >
                                        } & SelectionModifierGroupFieldsFragment
                                      >
                                    } & SelectionModifierFieldsFragment
                                  >
                                } & SelectionModifierGroupFieldsFragment
                              >
                            } & SelectionModifierFieldsFragment
                          >
                        } & SelectionModifierGroupFieldsFragment
                      >
                    } & SelectionModifierFieldsFragment
                  >
                } & SelectionModifierGroupFieldsFragment
              >
            } & SelectionModifierFieldsFragment
          >
        } & SelectionModifierGroupFieldsFragment
      >
    }
}

export type Menu_Item_DetailsQueryVariables = Exact<{
  input: MenuItemDetailsInput
  nestingLevel?: Maybe<Scalars['Int']>
}>

export type Menu_Item_DetailsQuery = { __typename?: 'Query' } & {
  menuItemDetails: { __typename?: 'MenuItem' } & Pick<
    MenuItem,
    | 'description'
    | 'name'
    | 'guid'
    | 'itemGroupGuid'
    | 'calories'
    | 'price'
    | 'imageUrl'
    | 'usesFractionalQuantity'
    | 'unitOfMeasure'
    | 'masterId'
  > & {
      modifierGroups: Array<
        { __typename?: 'ModifierGroup' } & {
          modifiers: Array<
            { __typename?: 'Modifier' } & {
              modifierGroups: Array<
                { __typename?: 'ModifierGroup' } & {
                  modifiers: Array<
                    { __typename?: 'Modifier' } & {
                      modifierGroups: Array<
                        { __typename?: 'ModifierGroup' } & {
                          modifiers: Array<
                            { __typename?: 'Modifier' } & {
                              modifierGroups: Array<
                                { __typename?: 'ModifierGroup' } & {
                                  modifiers: Array<
                                    { __typename?: 'Modifier' } & {
                                      modifierGroups: Array<
                                        { __typename?: 'ModifierGroup' } & {
                                          modifiers: Array<
                                            { __typename?: 'Modifier' } & {
                                              modifierGroups: Array<
                                                {
                                                  __typename?: 'ModifierGroup'
                                                } & {
                                                  modifiers: Array<
                                                    {
                                                      __typename?: 'Modifier'
                                                    } & {
                                                      modifierGroups: Array<
                                                        {
                                                          __typename?: 'ModifierGroup'
                                                        } & {
                                                          modifiers: Array<
                                                            {
                                                              __typename?: 'Modifier'
                                                            } & {
                                                              modifierGroups: Array<
                                                                {
                                                                  __typename?: 'ModifierGroup'
                                                                } & {
                                                                  modifiers: Array<
                                                                    {
                                                                      __typename?: 'Modifier'
                                                                    } & {
                                                                      modifierGroups: Array<
                                                                        {
                                                                          __typename?: 'ModifierGroup'
                                                                        } & {
                                                                          modifiers: Array<
                                                                            {
                                                                              __typename?: 'Modifier'
                                                                            } & {
                                                                              modifierGroups: Array<
                                                                                {
                                                                                  __typename?: 'ModifierGroup'
                                                                                } & {
                                                                                  modifiers: Array<
                                                                                    {
                                                                                      __typename?: 'Modifier'
                                                                                    } & ModifierFieldsFragment
                                                                                  >
                                                                                } & ModifierGroupFieldsFragment
                                                                              >
                                                                            } & ModifierFieldsFragment
                                                                          >
                                                                        } & ModifierGroupFieldsFragment
                                                                      >
                                                                    } & ModifierFieldsFragment
                                                                  >
                                                                } & ModifierGroupFieldsFragment
                                                              >
                                                            } & ModifierFieldsFragment
                                                          >
                                                        } & ModifierGroupFieldsFragment
                                                      >
                                                    } & ModifierFieldsFragment
                                                  >
                                                } & ModifierGroupFieldsFragment
                                              >
                                            } & ModifierFieldsFragment
                                          >
                                        } & ModifierGroupFieldsFragment
                                      >
                                    } & ModifierFieldsFragment
                                  >
                                } & ModifierGroupFieldsFragment
                              >
                            } & ModifierFieldsFragment
                          >
                        } & ModifierGroupFieldsFragment
                      >
                    } & ModifierFieldsFragment
                  >
                } & ModifierGroupFieldsFragment
              >
            } & ModifierFieldsFragment
          >
        } & ModifierGroupFieldsFragment
      >
    }
}

export type MenusQueryVariables = Exact<{
  input: MenusInput
}>

export type MenusQuery = { __typename?: 'Query' } & {
  menusV3:
    | ({ __typename?: 'MenusResponse' } & {
        menus: Array<
          { __typename?: 'Menu' } & Pick<Menu, 'isActive' | 'id' | 'name'> & {
              groups?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'MenuGroup' } & Pick<
                      MenuGroup,
                      'id' | 'guid' | 'name' | 'description'
                    > & {
                        items?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'MenuItem' } & Pick<
                                MenuItem,
                                | 'description'
                                | 'guid'
                                | 'name'
                                | 'outOfStock'
                                | 'price'
                                | 'imageUrl'
                                | 'calories'
                                | 'itemGroupGuid'
                                | 'unitOfMeasure'
                                | 'usesFractionalQuantity'
                                | 'masterId'
                              >
                            >
                          >
                        >
                      }
                  >
                >
              >
            }
        >
      })
    | ({ __typename?: 'GeneralError' } & Pick<GeneralError, 'code' | 'message'>)
}

export type Get_Paginated_OrdersQueryVariables = Exact<{
  input: PaginatedOrdersInput
}>

export type Get_Paginated_OrdersQuery = { __typename?: 'Query' } & {
  paginatedOrders: { __typename?: 'PaginatedOrderResponse' } & Pick<
    PaginatedOrderResponse,
    'totalSize'
  > & {
      orders: Array<
        { __typename?: 'CompletedOrder' } & Pick<
          CompletedOrder,
          'guid' | 'totalV2' | 'estimatedFulfillmentDate'
        > & {
            selections: Array<
              { __typename?: 'CompletedOrderSelection' } & Pick<
                CompletedOrderSelection,
                'name' | 'quantity'
              >
            >
          }
      >
    }
}

export type Get_Order_HistoryQueryVariables = Exact<{
  input: OrderHistoryInput
}>

export type Get_Order_HistoryQuery = { __typename?: 'Query' } & {
  orderHistory: { __typename?: 'OrderHistoryResponse' } & Pick<
    OrderHistoryResponse,
    'nextPageToken'
  > & {
      orders: Array<
        { __typename?: 'CompletedOrder' } & Pick<
          CompletedOrder,
          'guid' | 'totalV2' | 'estimatedFulfillmentDate'
        > & {
            selections: Array<
              { __typename?: 'CompletedOrderSelection' } & Pick<
                CompletedOrderSelection,
                'name' | 'quantity'
              >
            >
          }
      >
    }
}

export type AuthorizePaymentMutationVariables = Exact<{
  input: AuthorizePaymentInput
}>

export type AuthorizePaymentMutation = { __typename?: 'Mutation' } & {
  authorizePayment:
    | ({ __typename: 'AuthorizePaymentResponse' } & Pick<
        AuthorizePaymentResponse,
        'paymentGuid' | 'paymentState' | 'amount' | 'tipAmount' | 'denialReason'
      >)
    | ({ __typename: 'AuthorizePaymentError' } & Pick<
        AuthorizePaymentError,
        'message'
      >)
}

export type Restaurant_InfoQueryVariables = Exact<{
  restaurantGuid: Scalars['ID']
}>

export type Restaurant_InfoQuery = { __typename?: 'Query' } & {
  restaurantV2:
    | ({ __typename?: 'Restaurant' } & Pick<
        Restaurant,
        | 'guid'
        | 'whiteLabelName'
        | 'description'
        | 'imageUrl'
        | 'minimumTakeoutTime'
        | 'minimumDeliveryTime'
        | 'timeZoneId'
        | 'onlineOrderingEnabled'
      > & {
          bannerUrls?: Maybe<
            { __typename?: 'ImageSet' } & Pick<ImageSet, 'raw'>
          >
          location: { __typename?: 'Location' } & Pick<
            Location,
            | 'address1'
            | 'address2'
            | 'city'
            | 'state'
            | 'zip'
            | 'phone'
            | 'latitude'
            | 'longitude'
          >
          logoUrls?: Maybe<
            { __typename?: 'ImageSet' } & Pick<ImageSet, 'small'>
          >
          schedule: { __typename?: 'Schedule' } & Pick<
            Schedule,
            'asapAvailableForTakeout'
          > & {
              todaysHoursForTakeout?: Maybe<
                { __typename?: 'ServicePeriod' } & Pick<
                  ServicePeriod,
                  'startTime' | 'endTime'
                >
              >
              upcomingSchedules: Array<
                { __typename?: 'UpcomingSchedules' } & Pick<
                  UpcomingSchedules,
                  'behavior'
                > & {
                    dailySchedules: Array<
                      { __typename?: 'DailySchedule' } & Pick<
                        DailySchedule,
                        'date'
                      > & {
                          servicePeriods: Array<
                            { __typename?: 'ServicePeriod' } & Pick<
                              ServicePeriod,
                              'startTime' | 'endTime'
                            >
                          >
                        }
                    >
                  }
              >
            }
          socialMediaLinks: { __typename?: 'SocialMediaLinks' } & Pick<
            SocialMediaLinks,
            'facebookLink' | 'twitterLink' | 'instagramLink'
          >
          giftCardLinks: { __typename?: 'GiftCardLinks' } & Pick<
            GiftCardLinks,
            'purchaseLink' | 'checkValueLink' | 'addValueEnabled'
          >
          giftCardConfig: { __typename?: 'GiftCardConfig' } & Pick<
            GiftCardConfig,
            'redemptionAllowed'
          >
          specialRequestsConfig: {
            __typename?: 'SpecialRequestsConfig'
          } & Pick<SpecialRequestsConfig, 'enabled' | 'placeholderMessage'>
          spotlightConfig?: Maybe<
            { __typename?: 'SpotlightConfig' } & Pick<
              SpotlightConfig,
              'headerText' | 'bodyText'
            >
          >
          curbsidePickupConfig?: Maybe<
            { __typename?: 'CurbsidePickupConfig' } & Pick<
              CurbsidePickupConfig,
              'enabled' | 'enabledV2'
            >
          >
          popularItemsConfig?: Maybe<
            { __typename?: 'PopularItemsConfig' } & Pick<
              PopularItemsConfig,
              'enabled'
            >
          >
          upsellsConfig?: Maybe<
            { __typename?: 'UpsellsConfig' } & Pick<UpsellsConfig, 'enabled'>
          >
          creditCardConfig: {
            __typename?: 'RestaurantCreditCardConfig'
          } & Pick<RestaurantCreditCardConfig, 'amexAccepted' | 'tipEnabled'>
        })
    | ({ __typename?: 'GeneralError' } & Pick<GeneralError, 'code' | 'message'>)
}

export type CustomerFragment = { __typename?: 'Customer' } & Pick<
  Customer,
  'guid'
> & {
    addresses: Array<
      { __typename?: 'SavedAddress' } & Pick<SavedAddress, 'guid' | 'name'> & {
          deliveryInfo: { __typename?: 'DeliveryInfo' } & Pick<
            DeliveryInfo,
            | 'address1'
            | 'address2'
            | 'city'
            | 'state'
            | 'zipCode'
            | 'latitude'
            | 'longitude'
            | 'notes'
          >
        }
    >
  }

export type Saved_AddressesQueryVariables = Exact<{ [key: string]: never }>

export type Saved_AddressesQuery = { __typename?: 'Query' } & {
  customer: { __typename?: 'Customer' } & CustomerFragment
}

export type Update_AddressMutationVariables = Exact<{
  input: UpdateAddressInput
}>

export type Update_AddressMutation = { __typename?: 'Mutation' } & {
  updateAddress:
    | ({ __typename?: 'UpdateAddressResponse' } & {
        customer: { __typename?: 'Customer' } & CustomerFragment
      })
    | ({ __typename?: 'UpdateAddressError' } & Pick<
        UpdateAddressError,
        'code' | 'message'
      >)
}

export type Delete_AddressMutationVariables = Exact<{
  input: DeleteAddressInput
}>

export type Delete_AddressMutation = { __typename?: 'Mutation' } & {
  deleteAddress:
    | ({ __typename?: 'DeleteAddressResponse' } & {
        customer: { __typename?: 'Customer' } & CustomerFragment
      })
    | ({ __typename?: 'DeleteAddressError' } & Pick<
        DeleteAddressError,
        'code' | 'message'
      >)
}

// Generated on 2022-11-21T12:01:09-05:00

export const CompletedOrderFragmentDoc = gql`
  fragment completedOrder on CompletedOrder {
    guid
    restaurant {
      guid
      name
      whiteLabelName
      timeZoneId
      location {
        address1
        address2
        city
        state
        phone
      }
      creditCardConfig {
        amexAccepted
        tipEnabled
      }
      loyaltyConfig {
        loyaltyRedemptionEnabled
        loyaltySignupEnabled
        loyaltySignupBonus
        conversionRate
        accrualTarget
      }
      socialMediaLinks {
        twitterLink
        facebookLink
      }
    }
    customerV2 {
      firstName
      lastName
      email
      phone
    }
    selections {
      modifiers {
        name
        modifiers {
          name
        }
      }
      name
      price
      preDiscountPrice
      usesFractionalQuantity
      fractionalQuantity {
        unitOfMeasure
        quantity
      }
    }
    discounts {
      restaurantDiscount {
        guid
        name
        amount
        promoCode
      }
      loyaltyDiscount {
        guid
        amount
      }
      loyaltyDiscounts {
        type
        amount
        guid
      }
      globalReward {
        name
        amount
      }
    }
    discountsTotal
    checkNumber
    checkGuid
    guestCommunication
    deliveryChargeTotal
    serviceChargeTotal
    tfgRoundUpTotal
    subtotal
    tax
    tip
    total
    estimatedFulfillmentDate
    paymentType
    orderPaymentGuid
    hasLoyaltyAttached
    curbsidePickup {
      selected
    }
    curbsidePickupV2 {
      transportDescription
      transportColor
    }
    giftCard {
      appliedBalance
    }
    deliveryInfo {
      address1
      address2
      city
      state
      zipCode
      notes
    }
  }
`
export const CartFragmentDoc = gql`
  fragment cart on Cart {
    guid
    order {
      deliveryInfo {
        address1
        address2
        city
        state
        zipCode
        latitude
        longitude
        notes
      }
      numberOfSelections
      selections {
        guid
        groupingKey
        itemGuid
        itemGroupGuid
        name
        preDiscountPrice
        price
        quantity
        usesFractionalQuantity
        fractionalQuantity {
          unitOfMeasure
          quantity
        }
        modifiers {
          guid
          name
          price
          groupingKey
          modifiers {
            guid
            name
            price
            groupingKey
            modifiers {
              guid
              name
              price
              groupingKey
              modifiers {
                guid
                name
                price
                groupingKey
                modifiers {
                  guid
                  name
                  price
                  groupingKey
                  modifiers {
                    guid
                    name
                    price
                    groupingKey
                    modifiers {
                      guid
                      name
                      price
                      groupingKey
                      modifiers {
                        guid
                        name
                        price
                        groupingKey
                        modifiers {
                          guid
                          name
                          price
                          groupingKey
                          modifiers {
                            guid
                            name
                            price
                            groupingKey
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      discounts {
        restaurantDiscount {
          guid
          name
          amount
          promoCode
        }
        loyaltyDiscount {
          guid
          amount
        }
        loyaltyDiscounts {
          type
          amount
          guid
        }
        globalReward {
          amount
          name
          rewardType
          total
        }
      }
      discountsTotal
      deliveryChargeTotal
      serviceChargeTotal
      subtotal
      tax
      total
    }
    quoteTime
    paymentOptions {
      atCheckout {
        paymentType
      }
      uponReceipt {
        paymentType
      }
    }
    preComputedTips {
      percent
      value
    }
    approvalRules {
      ruleType
      requiredAdjustment
      thresholdAmount
    }
    diningOptionBehavior
    fulfillmentType
    fulfillmentDateTime
    takeoutQuoteTime
    deliveryQuoteTime
    deliveryProviderInfo {
      provider
      needsDeliveryCommunicationConsent
    }
    cartUpsellInfo {
      upsellItems
    }
  }
`
export const CartResponseFragmentDoc = gql`
  fragment cartResponse on CartResponse {
    cart {
      ...cart
    }
    warnings {
      code
      message
    }
    info {
      code
      message
    }
  }
  ${CartFragmentDoc}
`
export const CartOutOfStockErrorFragmentDoc = gql`
  fragment cartOutOfStockError on CartOutOfStockError {
    cart {
      ...cart
    }
    message
    items {
      name
      guid
    }
  }
  ${CartFragmentDoc}
`
export const ModifierGroupFieldsFragmentDoc = gql`
  fragment ModifierGroupFields on ModifierGroup {
    guid
    minSelections
    maxSelections
    name
    pricingMode
  }
`
export const ModifierFieldsFragmentDoc = gql`
  fragment ModifierFields on Modifier {
    itemGuid
    itemGroupGuid
    name
    price
    selected
    isDefault
    outOfStock
    allowsDuplicates
  }
`
export const SelectionModifierFieldsFragmentDoc = gql`
  fragment SelectionModifierFields on SelectionModifier {
    itemGuid
    itemGroupGuid
    name
    price
    selected
    isDefault
    outOfStock
    quantity
    allowsDuplicates
  }
`
export const SelectionModifierGroupFieldsFragmentDoc = gql`
  fragment SelectionModifierGroupFields on SelectionModifierGroup {
    guid
    minSelections
    maxSelections
    name
    pricingMode
  }
`
export const CustomerFragmentDoc = gql`
  fragment customer on Customer {
    guid
    addresses {
      guid
      name
      deliveryInfo {
        address1
        address2
        city
        state
        zipCode
        latitude
        longitude
        notes
      }
    }
  }
`
export const Login_ServerDocument = gql`
  mutation LOGIN_SERVER($input: LoginInput!) {
    login(input: $input) {
      ... on AuthenticationResponse {
        customerGuid
        accessToken
        refreshToken
      }
      ... on MfaChallengeGeneratedResponse {
        challengeToken
        mfaType
        maskedRecipient
      }
      ... on LoginError {
        code
        message
      }
    }
  }
`
export type Login_ServerMutationFn = Apollo.MutationFunction<
  Login_ServerMutation,
  Login_ServerMutationVariables
>

/**
 * __useLogin_ServerMutation__
 *
 * To run a mutation, you first call `useLogin_ServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogin_ServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginServerMutation, { data, loading, error }] = useLogin_ServerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogin_ServerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Login_ServerMutation,
    Login_ServerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Login_ServerMutation,
    Login_ServerMutationVariables
  >(Login_ServerDocument, options)
}
export type Login_ServerMutationHookResult = ReturnType<
  typeof useLogin_ServerMutation
>
export type Login_ServerMutationResult =
  Apollo.MutationResult<Login_ServerMutation>
export type Login_ServerMutationOptions = Apollo.BaseMutationOptions<
  Login_ServerMutation,
  Login_ServerMutationVariables
>
export const Mfa_Login_ServerDocument = gql`
  mutation MFA_LOGIN_SERVER($input: MfaLoginInput!) {
    mfaLogin(input: $input) {
      ... on AuthenticationResponse {
        customerGuid
        accessToken
        refreshToken
      }
      ... on MfaChallengeGeneratedResponse {
        challengeToken
        mfaType
        maskedRecipient
      }
      ... on LoginError {
        code
        message
      }
    }
  }
`
export type Mfa_Login_ServerMutationFn = Apollo.MutationFunction<
  Mfa_Login_ServerMutation,
  Mfa_Login_ServerMutationVariables
>

/**
 * __useMfa_Login_ServerMutation__
 *
 * To run a mutation, you first call `useMfa_Login_ServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMfa_Login_ServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mfaLoginServerMutation, { data, loading, error }] = useMfa_Login_ServerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMfa_Login_ServerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Mfa_Login_ServerMutation,
    Mfa_Login_ServerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Mfa_Login_ServerMutation,
    Mfa_Login_ServerMutationVariables
  >(Mfa_Login_ServerDocument, options)
}
export type Mfa_Login_ServerMutationHookResult = ReturnType<
  typeof useMfa_Login_ServerMutation
>
export type Mfa_Login_ServerMutationResult =
  Apollo.MutationResult<Mfa_Login_ServerMutation>
export type Mfa_Login_ServerMutationOptions = Apollo.BaseMutationOptions<
  Mfa_Login_ServerMutation,
  Mfa_Login_ServerMutationVariables
>
export const Customer_ServerDocument = gql`
  query CUSTOMER_SERVER @toastRetry {
    customer {
      guid
      firstName
      lastName
      phone
      email
      creditCards {
        guid
        maskedPan
        expirationMonth
        expirationYear
        cardType
        isPrimary
      }
    }
  }
`

/**
 * __useCustomer_ServerQuery__
 *
 * To run a query within a React component, call `useCustomer_ServerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomer_ServerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomer_ServerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomer_ServerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Customer_ServerQuery,
    Customer_ServerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Customer_ServerQuery, Customer_ServerQueryVariables>(
    Customer_ServerDocument,
    options
  )
}
export function useCustomer_ServerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Customer_ServerQuery,
    Customer_ServerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Customer_ServerQuery,
    Customer_ServerQueryVariables
  >(Customer_ServerDocument, options)
}
export type Customer_ServerQueryHookResult = ReturnType<
  typeof useCustomer_ServerQuery
>
export type Customer_ServerLazyQueryHookResult = ReturnType<
  typeof useCustomer_ServerLazyQuery
>
export type Customer_ServerQueryResult = Apollo.QueryResult<
  Customer_ServerQuery,
  Customer_ServerQueryVariables
>
export const RefreshDocument = gql`
  mutation REFRESH($input: RefreshInput!) {
    refresh(input: $input) {
      ... on AuthenticationResponse {
        customerGuid
        accessToken
        refreshToken
      }
      ... on RefreshError {
        code
        message
      }
    }
  }
`
export type RefreshMutationFn = Apollo.MutationFunction<
  RefreshMutation,
  RefreshMutationVariables
>

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshMutation,
    RefreshMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(
    RefreshDocument,
    options
  )
}
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>
export type RefreshMutationOptions = Apollo.BaseMutationOptions<
  RefreshMutation,
  RefreshMutationVariables
>
export const Create_AccountDocument = gql`
  mutation CREATE_ACCOUNT($input: CreateAccountInput!) {
    createAccount(input: $input) {
      ... on CreateAccountResponse {
        customerGuid
      }
      ... on CreateAccountError {
        code
        message
      }
    }
  }
`
export type Create_AccountMutationFn = Apollo.MutationFunction<
  Create_AccountMutation,
  Create_AccountMutationVariables
>

/**
 * __useCreate_AccountMutation__
 *
 * To run a mutation, you first call `useCreate_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreate_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreate_AccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreate_AccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Create_AccountMutation,
    Create_AccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Create_AccountMutation,
    Create_AccountMutationVariables
  >(Create_AccountDocument, options)
}
export type Create_AccountMutationHookResult = ReturnType<
  typeof useCreate_AccountMutation
>
export type Create_AccountMutationResult =
  Apollo.MutationResult<Create_AccountMutation>
export type Create_AccountMutationOptions = Apollo.BaseMutationOptions<
  Create_AccountMutation,
  Create_AccountMutationVariables
>
export const Get_CartDocument = gql`
  query GET_CART($guid: ID!) {
    cartV2(guid: $guid) {
      ... on CartResponse {
        ...cartResponse
      }
      ... on CartError {
        code
        message
      }
    }
  }
  ${CartResponseFragmentDoc}
`

/**
 * __useGet_CartQuery__
 *
 * To run a query within a React component, call `useGet_CartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_CartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_CartQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useGet_CartQuery(
  baseOptions: Apollo.QueryHookOptions<Get_CartQuery, Get_CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Get_CartQuery, Get_CartQueryVariables>(
    Get_CartDocument,
    options
  )
}
export function useGet_CartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_CartQuery,
    Get_CartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Get_CartQuery, Get_CartQueryVariables>(
    Get_CartDocument,
    options
  )
}
export type Get_CartQueryHookResult = ReturnType<typeof useGet_CartQuery>
export type Get_CartLazyQueryHookResult = ReturnType<
  typeof useGet_CartLazyQuery
>
export type Get_CartQueryResult = Apollo.QueryResult<
  Get_CartQuery,
  Get_CartQueryVariables
>
export const Get_Cart_For_CheckoutDocument = gql`
  query GET_CART_FOR_CHECKOUT($guid: ID!) @toastRetry {
    cartV2(guid: $guid) {
      ... on CartResponse {
        cart {
          ...cart
          restaurant {
            creditCardConfig {
              amexAccepted
              tipEnabled
            }
            loyaltyConfig {
              loyaltyRedemptionEnabled
              loyaltySignupEnabled
              loyaltySignupBonus
              conversionRate
              accrualTarget
            }
            tfgConfig {
              serviceChargeGuid
              campaignName
              campaignDescription
              campaignLogoURL
              active
            }
          }
        }
        warnings {
          code
          message
        }
        info {
          code
          message
        }
      }
      ... on CartError {
        code
        message
      }
    }
  }
  ${CartFragmentDoc}
`

/**
 * __useGet_Cart_For_CheckoutQuery__
 *
 * To run a query within a React component, call `useGet_Cart_For_CheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Cart_For_CheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Cart_For_CheckoutQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useGet_Cart_For_CheckoutQuery(
  baseOptions: Apollo.QueryHookOptions<
    Get_Cart_For_CheckoutQuery,
    Get_Cart_For_CheckoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Get_Cart_For_CheckoutQuery,
    Get_Cart_For_CheckoutQueryVariables
  >(Get_Cart_For_CheckoutDocument, options)
}
export function useGet_Cart_For_CheckoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Cart_For_CheckoutQuery,
    Get_Cart_For_CheckoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Get_Cart_For_CheckoutQuery,
    Get_Cart_For_CheckoutQueryVariables
  >(Get_Cart_For_CheckoutDocument, options)
}
export type Get_Cart_For_CheckoutQueryHookResult = ReturnType<
  typeof useGet_Cart_For_CheckoutQuery
>
export type Get_Cart_For_CheckoutLazyQueryHookResult = ReturnType<
  typeof useGet_Cart_For_CheckoutLazyQuery
>
export type Get_Cart_For_CheckoutQueryResult = Apollo.QueryResult<
  Get_Cart_For_CheckoutQuery,
  Get_Cart_For_CheckoutQueryVariables
>
export const Add_Item_To_CartDocument = gql`
  mutation ADD_ITEM_TO_CART($input: AddItemToCartInputV2!) {
    addItemToCartV2(input: $input) {
      ... on CartResponse {
        ...cartResponse
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
        items {
          name
          guid
        }
      }
    }
  }
  ${CartResponseFragmentDoc}
  ${CartFragmentDoc}
`
export type Add_Item_To_CartMutationFn = Apollo.MutationFunction<
  Add_Item_To_CartMutation,
  Add_Item_To_CartMutationVariables
>

/**
 * __useAdd_Item_To_CartMutation__
 *
 * To run a mutation, you first call `useAdd_Item_To_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Item_To_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemToCartMutation, { data, loading, error }] = useAdd_Item_To_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdd_Item_To_CartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Add_Item_To_CartMutation,
    Add_Item_To_CartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Add_Item_To_CartMutation,
    Add_Item_To_CartMutationVariables
  >(Add_Item_To_CartDocument, options)
}
export type Add_Item_To_CartMutationHookResult = ReturnType<
  typeof useAdd_Item_To_CartMutation
>
export type Add_Item_To_CartMutationResult =
  Apollo.MutationResult<Add_Item_To_CartMutation>
export type Add_Item_To_CartMutationOptions = Apollo.BaseMutationOptions<
  Add_Item_To_CartMutation,
  Add_Item_To_CartMutationVariables
>
export const Delete_Item_From_CartDocument = gql`
  mutation DELETE_ITEM_FROM_CART($input: DeleteItemFromCartInput!) {
    deleteItemFromCartV2(input: $input) {
      ... on CartResponse {
        ...cartResponse
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
        items {
          name
          guid
        }
      }
    }
  }
  ${CartResponseFragmentDoc}
  ${CartFragmentDoc}
`
export type Delete_Item_From_CartMutationFn = Apollo.MutationFunction<
  Delete_Item_From_CartMutation,
  Delete_Item_From_CartMutationVariables
>

/**
 * __useDelete_Item_From_CartMutation__
 *
 * To run a mutation, you first call `useDelete_Item_From_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Item_From_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemFromCartMutation, { data, loading, error }] = useDelete_Item_From_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelete_Item_From_CartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Delete_Item_From_CartMutation,
    Delete_Item_From_CartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Delete_Item_From_CartMutation,
    Delete_Item_From_CartMutationVariables
  >(Delete_Item_From_CartDocument, options)
}
export type Delete_Item_From_CartMutationHookResult = ReturnType<
  typeof useDelete_Item_From_CartMutation
>
export type Delete_Item_From_CartMutationResult =
  Apollo.MutationResult<Delete_Item_From_CartMutation>
export type Delete_Item_From_CartMutationOptions = Apollo.BaseMutationOptions<
  Delete_Item_From_CartMutation,
  Delete_Item_From_CartMutationVariables
>
export const Edit_Item_In_CartDocument = gql`
  mutation EDIT_ITEM_IN_CART($input: EditItemInCartInput!) {
    editItemInCartV2(input: $input) {
      ... on CartResponse {
        ...cartResponse
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
        items {
          name
          guid
        }
      }
    }
  }
  ${CartResponseFragmentDoc}
  ${CartFragmentDoc}
`
export type Edit_Item_In_CartMutationFn = Apollo.MutationFunction<
  Edit_Item_In_CartMutation,
  Edit_Item_In_CartMutationVariables
>

/**
 * __useEdit_Item_In_CartMutation__
 *
 * To run a mutation, you first call `useEdit_Item_In_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEdit_Item_In_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editItemInCartMutation, { data, loading, error }] = useEdit_Item_In_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEdit_Item_In_CartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Edit_Item_In_CartMutation,
    Edit_Item_In_CartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Edit_Item_In_CartMutation,
    Edit_Item_In_CartMutationVariables
  >(Edit_Item_In_CartDocument, options)
}
export type Edit_Item_In_CartMutationHookResult = ReturnType<
  typeof useEdit_Item_In_CartMutation
>
export type Edit_Item_In_CartMutationResult =
  Apollo.MutationResult<Edit_Item_In_CartMutation>
export type Edit_Item_In_CartMutationOptions = Apollo.BaseMutationOptions<
  Edit_Item_In_CartMutation,
  Edit_Item_In_CartMutationVariables
>
export const Update_Fulfillment_And_ValidateDocument = gql`
  mutation UPDATE_FULFILLMENT_AND_VALIDATE(
    $input: UpdateFulfillmentAndValidateInput!
  ) {
    updateFulfillmentAndValidate(input: $input) {
      ... on CartResponse {
        ...cartResponse
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
        items {
          guid
          name
        }
      }
    }
  }
  ${CartResponseFragmentDoc}
  ${CartFragmentDoc}
`
export type Update_Fulfillment_And_ValidateMutationFn = Apollo.MutationFunction<
  Update_Fulfillment_And_ValidateMutation,
  Update_Fulfillment_And_ValidateMutationVariables
>

/**
 * __useUpdate_Fulfillment_And_ValidateMutation__
 *
 * To run a mutation, you first call `useUpdate_Fulfillment_And_ValidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Fulfillment_And_ValidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFulfillmentAndValidateMutation, { data, loading, error }] = useUpdate_Fulfillment_And_ValidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Fulfillment_And_ValidateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Update_Fulfillment_And_ValidateMutation,
    Update_Fulfillment_And_ValidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Update_Fulfillment_And_ValidateMutation,
    Update_Fulfillment_And_ValidateMutationVariables
  >(Update_Fulfillment_And_ValidateDocument, options)
}
export type Update_Fulfillment_And_ValidateMutationHookResult = ReturnType<
  typeof useUpdate_Fulfillment_And_ValidateMutation
>
export type Update_Fulfillment_And_ValidateMutationResult =
  Apollo.MutationResult<Update_Fulfillment_And_ValidateMutation>
export type Update_Fulfillment_And_ValidateMutationOptions =
  Apollo.BaseMutationOptions<
    Update_Fulfillment_And_ValidateMutation,
    Update_Fulfillment_And_ValidateMutationVariables
  >
export const Update_Fulfillment_And_Clear_CartDocument = gql`
  mutation UPDATE_FULFILLMENT_AND_CLEAR_CART(
    $input: UpdateFulfillmentAndClearCartInput!
  ) {
    updateFulfillmentAndClearCart(input: $input) {
      ... on CartResponse {
        ...cartResponse
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
      }
    }
  }
  ${CartResponseFragmentDoc}
  ${CartFragmentDoc}
`
export type Update_Fulfillment_And_Clear_CartMutationFn =
  Apollo.MutationFunction<
    Update_Fulfillment_And_Clear_CartMutation,
    Update_Fulfillment_And_Clear_CartMutationVariables
  >

/**
 * __useUpdate_Fulfillment_And_Clear_CartMutation__
 *
 * To run a mutation, you first call `useUpdate_Fulfillment_And_Clear_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Fulfillment_And_Clear_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFulfillmentAndClearCartMutation, { data, loading, error }] = useUpdate_Fulfillment_And_Clear_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Fulfillment_And_Clear_CartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Update_Fulfillment_And_Clear_CartMutation,
    Update_Fulfillment_And_Clear_CartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Update_Fulfillment_And_Clear_CartMutation,
    Update_Fulfillment_And_Clear_CartMutationVariables
  >(Update_Fulfillment_And_Clear_CartDocument, options)
}
export type Update_Fulfillment_And_Clear_CartMutationHookResult = ReturnType<
  typeof useUpdate_Fulfillment_And_Clear_CartMutation
>
export type Update_Fulfillment_And_Clear_CartMutationResult =
  Apollo.MutationResult<Update_Fulfillment_And_Clear_CartMutation>
export type Update_Fulfillment_And_Clear_CartMutationOptions =
  Apollo.BaseMutationOptions<
    Update_Fulfillment_And_Clear_CartMutation,
    Update_Fulfillment_And_Clear_CartMutationVariables
  >
export const Update_Dining_OptionDocument = gql`
  mutation UPDATE_DINING_OPTION($input: UpdateDiningOptionInput!) {
    updateDiningOption(input: $input) {
      ... on CartResponse {
        ...cartResponse
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
      }
    }
  }
  ${CartResponseFragmentDoc}
  ${CartFragmentDoc}
`
export type Update_Dining_OptionMutationFn = Apollo.MutationFunction<
  Update_Dining_OptionMutation,
  Update_Dining_OptionMutationVariables
>

/**
 * __useUpdate_Dining_OptionMutation__
 *
 * To run a mutation, you first call `useUpdate_Dining_OptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Dining_OptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiningOptionMutation, { data, loading, error }] = useUpdate_Dining_OptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Dining_OptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Update_Dining_OptionMutation,
    Update_Dining_OptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Update_Dining_OptionMutation,
    Update_Dining_OptionMutationVariables
  >(Update_Dining_OptionDocument, options)
}
export type Update_Dining_OptionMutationHookResult = ReturnType<
  typeof useUpdate_Dining_OptionMutation
>
export type Update_Dining_OptionMutationResult =
  Apollo.MutationResult<Update_Dining_OptionMutation>
export type Update_Dining_OptionMutationOptions = Apollo.BaseMutationOptions<
  Update_Dining_OptionMutation,
  Update_Dining_OptionMutationVariables
>
export const Place_Cash_OrderDocument = gql`
  mutation PLACE_CASH_ORDER($input: PlaceCashOrderInput!) {
    placeCashOrder(input: $input) {
      ... on PlaceOrderResponse {
        completedOrder {
          ...completedOrder
        }
        warnings {
          code
          message
        }
      }
      ... on PlaceOrderCartUpdatedError {
        cartUpdatedCode: code
        message
        cart {
          ...cart
        }
      }
      ... on PlaceOrderError {
        code
        message
      }
    }
  }
  ${CompletedOrderFragmentDoc}
  ${CartFragmentDoc}
`
export type Place_Cash_OrderMutationFn = Apollo.MutationFunction<
  Place_Cash_OrderMutation,
  Place_Cash_OrderMutationVariables
>

/**
 * __usePlace_Cash_OrderMutation__
 *
 * To run a mutation, you first call `usePlace_Cash_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlace_Cash_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeCashOrderMutation, { data, loading, error }] = usePlace_Cash_OrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlace_Cash_OrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Place_Cash_OrderMutation,
    Place_Cash_OrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Place_Cash_OrderMutation,
    Place_Cash_OrderMutationVariables
  >(Place_Cash_OrderDocument, options)
}
export type Place_Cash_OrderMutationHookResult = ReturnType<
  typeof usePlace_Cash_OrderMutation
>
export type Place_Cash_OrderMutationResult =
  Apollo.MutationResult<Place_Cash_OrderMutation>
export type Place_Cash_OrderMutationOptions = Apollo.BaseMutationOptions<
  Place_Cash_OrderMutation,
  Place_Cash_OrderMutationVariables
>
export const Place_Cc_OrderDocument = gql`
  mutation PLACE_CC_ORDER($input: PlaceCcOrderInput!) {
    placeCcOrder(input: $input) {
      ... on PlaceOrderResponse {
        completedOrder {
          ...completedOrder
        }
        warnings {
          code
          message
        }
      }
      ... on PlaceOrderCartUpdatedError {
        cartUpdatedCode: code
        message
        cart {
          ...cart
        }
      }
      ... on PlaceOrderError {
        code
        message
      }
    }
  }
  ${CompletedOrderFragmentDoc}
  ${CartFragmentDoc}
`
export type Place_Cc_OrderMutationFn = Apollo.MutationFunction<
  Place_Cc_OrderMutation,
  Place_Cc_OrderMutationVariables
>

/**
 * __usePlace_Cc_OrderMutation__
 *
 * To run a mutation, you first call `usePlace_Cc_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlace_Cc_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeCcOrderMutation, { data, loading, error }] = usePlace_Cc_OrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlace_Cc_OrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Place_Cc_OrderMutation,
    Place_Cc_OrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Place_Cc_OrderMutation,
    Place_Cc_OrderMutationVariables
  >(Place_Cc_OrderDocument, options)
}
export type Place_Cc_OrderMutationHookResult = ReturnType<
  typeof usePlace_Cc_OrderMutation
>
export type Place_Cc_OrderMutationResult =
  Apollo.MutationResult<Place_Cc_OrderMutation>
export type Place_Cc_OrderMutationOptions = Apollo.BaseMutationOptions<
  Place_Cc_OrderMutation,
  Place_Cc_OrderMutationVariables
>
export const Place_Apple_Pay_OrderDocument = gql`
  mutation PLACE_APPLE_PAY_ORDER($input: PlaceApplePayOrderInput!) {
    placeApplePayOrder(input: $input) {
      ... on PlaceOrderResponse {
        completedOrder {
          ...completedOrder
        }
        warnings {
          code
          message
        }
      }
      ... on PlaceOrderCartUpdatedError {
        cartUpdatedCode: code
        message
        cart {
          ...cart
        }
      }
      ... on PlaceOrderError {
        code
        message
      }
    }
  }
  ${CompletedOrderFragmentDoc}
  ${CartFragmentDoc}
`
export type Place_Apple_Pay_OrderMutationFn = Apollo.MutationFunction<
  Place_Apple_Pay_OrderMutation,
  Place_Apple_Pay_OrderMutationVariables
>

/**
 * __usePlace_Apple_Pay_OrderMutation__
 *
 * To run a mutation, you first call `usePlace_Apple_Pay_OrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlace_Apple_Pay_OrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeApplePayOrderMutation, { data, loading, error }] = usePlace_Apple_Pay_OrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlace_Apple_Pay_OrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Place_Apple_Pay_OrderMutation,
    Place_Apple_Pay_OrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Place_Apple_Pay_OrderMutation,
    Place_Apple_Pay_OrderMutationVariables
  >(Place_Apple_Pay_OrderDocument, options)
}
export type Place_Apple_Pay_OrderMutationHookResult = ReturnType<
  typeof usePlace_Apple_Pay_OrderMutation
>
export type Place_Apple_Pay_OrderMutationResult =
  Apollo.MutationResult<Place_Apple_Pay_OrderMutation>
export type Place_Apple_Pay_OrderMutationOptions = Apollo.BaseMutationOptions<
  Place_Apple_Pay_OrderMutation,
  Place_Apple_Pay_OrderMutationVariables
>
export const Apply_Promo_Code_V3Document = gql`
  mutation APPLY_PROMO_CODE_V3($input: ApplyPromoCodeInputV3!) {
    applyPromoCodeV3(input: $input) {
      ... on CartResponse {
        cart {
          ...cart
        }
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
        items {
          name
          guid
        }
      }
    }
  }
  ${CartFragmentDoc}
`
export type Apply_Promo_Code_V3MutationFn = Apollo.MutationFunction<
  Apply_Promo_Code_V3Mutation,
  Apply_Promo_Code_V3MutationVariables
>

/**
 * __useApply_Promo_Code_V3Mutation__
 *
 * To run a mutation, you first call `useApply_Promo_Code_V3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApply_Promo_Code_V3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPromoCodeV3Mutation, { data, loading, error }] = useApply_Promo_Code_V3Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApply_Promo_Code_V3Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    Apply_Promo_Code_V3Mutation,
    Apply_Promo_Code_V3MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Apply_Promo_Code_V3Mutation,
    Apply_Promo_Code_V3MutationVariables
  >(Apply_Promo_Code_V3Document, options)
}
export type Apply_Promo_Code_V3MutationHookResult = ReturnType<
  typeof useApply_Promo_Code_V3Mutation
>
export type Apply_Promo_Code_V3MutationResult =
  Apollo.MutationResult<Apply_Promo_Code_V3Mutation>
export type Apply_Promo_Code_V3MutationOptions = Apollo.BaseMutationOptions<
  Apply_Promo_Code_V3Mutation,
  Apply_Promo_Code_V3MutationVariables
>
export const Apply_Promo_CodeDocument = gql`
  mutation APPLY_PROMO_CODE($input: ApplyPromoCodeInputV2!) {
    applyPromoCodeV2(input: $input) {
      ... on CartResponse {
        cart {
          ...cart
        }
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
        items {
          name
          guid
        }
      }
    }
  }
  ${CartFragmentDoc}
`
export type Apply_Promo_CodeMutationFn = Apollo.MutationFunction<
  Apply_Promo_CodeMutation,
  Apply_Promo_CodeMutationVariables
>

/**
 * __useApply_Promo_CodeMutation__
 *
 * To run a mutation, you first call `useApply_Promo_CodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApply_Promo_CodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPromoCodeMutation, { data, loading, error }] = useApply_Promo_CodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApply_Promo_CodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Apply_Promo_CodeMutation,
    Apply_Promo_CodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Apply_Promo_CodeMutation,
    Apply_Promo_CodeMutationVariables
  >(Apply_Promo_CodeDocument, options)
}
export type Apply_Promo_CodeMutationHookResult = ReturnType<
  typeof useApply_Promo_CodeMutation
>
export type Apply_Promo_CodeMutationResult =
  Apollo.MutationResult<Apply_Promo_CodeMutation>
export type Apply_Promo_CodeMutationOptions = Apollo.BaseMutationOptions<
  Apply_Promo_CodeMutation,
  Apply_Promo_CodeMutationVariables
>
export const Remove_Promo_CodeDocument = gql`
  mutation REMOVE_PROMO_CODE($input: RemovePromoCodeInput!) {
    removePromoCode(input: $input) {
      ... on CartResponse {
        cart {
          ...cart
        }
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
      }
    }
  }
  ${CartFragmentDoc}
`
export type Remove_Promo_CodeMutationFn = Apollo.MutationFunction<
  Remove_Promo_CodeMutation,
  Remove_Promo_CodeMutationVariables
>

/**
 * __useRemove_Promo_CodeMutation__
 *
 * To run a mutation, you first call `useRemove_Promo_CodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemove_Promo_CodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePromoCodeMutation, { data, loading, error }] = useRemove_Promo_CodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemove_Promo_CodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Remove_Promo_CodeMutation,
    Remove_Promo_CodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Remove_Promo_CodeMutation,
    Remove_Promo_CodeMutationVariables
  >(Remove_Promo_CodeDocument, options)
}
export type Remove_Promo_CodeMutationHookResult = ReturnType<
  typeof useRemove_Promo_CodeMutation
>
export type Remove_Promo_CodeMutationResult =
  Apollo.MutationResult<Remove_Promo_CodeMutation>
export type Remove_Promo_CodeMutationOptions = Apollo.BaseMutationOptions<
  Remove_Promo_CodeMutation,
  Remove_Promo_CodeMutationVariables
>
export const Inquire_LoyaltyDocument = gql`
  mutation INQUIRE_LOYALTY($input: LoyaltyInquiryInput!) {
    loyaltyInquiry(input: $input) {
      ... on LoyaltyInquiryResponse {
        redemptions {
          redemptionAmount
          redemptionGuid
        }
        loyaltyAccountIdentifier
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
        items {
          name
          guid
        }
      }
    }
  }
  ${CartFragmentDoc}
`
export type Inquire_LoyaltyMutationFn = Apollo.MutationFunction<
  Inquire_LoyaltyMutation,
  Inquire_LoyaltyMutationVariables
>

/**
 * __useInquire_LoyaltyMutation__
 *
 * To run a mutation, you first call `useInquire_LoyaltyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquire_LoyaltyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquireLoyaltyMutation, { data, loading, error }] = useInquire_LoyaltyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInquire_LoyaltyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Inquire_LoyaltyMutation,
    Inquire_LoyaltyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Inquire_LoyaltyMutation,
    Inquire_LoyaltyMutationVariables
  >(Inquire_LoyaltyDocument, options)
}
export type Inquire_LoyaltyMutationHookResult = ReturnType<
  typeof useInquire_LoyaltyMutation
>
export type Inquire_LoyaltyMutationResult =
  Apollo.MutationResult<Inquire_LoyaltyMutation>
export type Inquire_LoyaltyMutationOptions = Apollo.BaseMutationOptions<
  Inquire_LoyaltyMutation,
  Inquire_LoyaltyMutationVariables
>
export const Add_Loyalty_Redemption_To_CartDocument = gql`
  mutation ADD_LOYALTY_REDEMPTION_TO_CART($input: AddLoyaltyRedemptionInput!) {
    addLoyaltyRedemption(input: $input) {
      ... on CartResponse {
        cart {
          ...cart
        }
        info {
          code
          message
        }
        warnings {
          code
          message
        }
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
        items {
          name
          guid
        }
      }
    }
  }
  ${CartFragmentDoc}
`
export type Add_Loyalty_Redemption_To_CartMutationFn = Apollo.MutationFunction<
  Add_Loyalty_Redemption_To_CartMutation,
  Add_Loyalty_Redemption_To_CartMutationVariables
>

/**
 * __useAdd_Loyalty_Redemption_To_CartMutation__
 *
 * To run a mutation, you first call `useAdd_Loyalty_Redemption_To_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Loyalty_Redemption_To_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLoyaltyRedemptionToCartMutation, { data, loading, error }] = useAdd_Loyalty_Redemption_To_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdd_Loyalty_Redemption_To_CartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Add_Loyalty_Redemption_To_CartMutation,
    Add_Loyalty_Redemption_To_CartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Add_Loyalty_Redemption_To_CartMutation,
    Add_Loyalty_Redemption_To_CartMutationVariables
  >(Add_Loyalty_Redemption_To_CartDocument, options)
}
export type Add_Loyalty_Redemption_To_CartMutationHookResult = ReturnType<
  typeof useAdd_Loyalty_Redemption_To_CartMutation
>
export type Add_Loyalty_Redemption_To_CartMutationResult =
  Apollo.MutationResult<Add_Loyalty_Redemption_To_CartMutation>
export type Add_Loyalty_Redemption_To_CartMutationOptions =
  Apollo.BaseMutationOptions<
    Add_Loyalty_Redemption_To_CartMutation,
    Add_Loyalty_Redemption_To_CartMutationVariables
  >
export const Remove_Loyalty_Redemption_From_CartDocument = gql`
  mutation REMOVE_LOYALTY_REDEMPTION_FROM_CART(
    $input: RemoveLoyaltyRedemptionInput!
  ) {
    removeLoyaltyRedemption(input: $input) {
      ... on CartResponse {
        cart {
          ...cart
        }
        info {
          code
          message
        }
        warnings {
          code
          message
        }
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        message
        items {
          name
          guid
        }
      }
    }
  }
  ${CartFragmentDoc}
`
export type Remove_Loyalty_Redemption_From_CartMutationFn =
  Apollo.MutationFunction<
    Remove_Loyalty_Redemption_From_CartMutation,
    Remove_Loyalty_Redemption_From_CartMutationVariables
  >

/**
 * __useRemove_Loyalty_Redemption_From_CartMutation__
 *
 * To run a mutation, you first call `useRemove_Loyalty_Redemption_From_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemove_Loyalty_Redemption_From_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLoyaltyRedemptionFromCartMutation, { data, loading, error }] = useRemove_Loyalty_Redemption_From_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemove_Loyalty_Redemption_From_CartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Remove_Loyalty_Redemption_From_CartMutation,
    Remove_Loyalty_Redemption_From_CartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Remove_Loyalty_Redemption_From_CartMutation,
    Remove_Loyalty_Redemption_From_CartMutationVariables
  >(Remove_Loyalty_Redemption_From_CartDocument, options)
}
export type Remove_Loyalty_Redemption_From_CartMutationHookResult = ReturnType<
  typeof useRemove_Loyalty_Redemption_From_CartMutation
>
export type Remove_Loyalty_Redemption_From_CartMutationResult =
  Apollo.MutationResult<Remove_Loyalty_Redemption_From_CartMutation>
export type Remove_Loyalty_Redemption_From_CartMutationOptions =
  Apollo.BaseMutationOptions<
    Remove_Loyalty_Redemption_From_CartMutation,
    Remove_Loyalty_Redemption_From_CartMutationVariables
  >
export const Reorder_And_Replace_CartDocument = gql`
  mutation REORDER_AND_REPLACE_CART($input: ReorderInput!) {
    reorderV2(input: $input) {
      ... on ReorderResponse {
        cart {
          ...cart
        }
        warningsV2 {
          message
          code
        }
        outOfStockItems {
          name
          guid
        }
      }
      ... on ReorderError {
        code
        message
      }
    }
  }
  ${CartFragmentDoc}
`
export type Reorder_And_Replace_CartMutationFn = Apollo.MutationFunction<
  Reorder_And_Replace_CartMutation,
  Reorder_And_Replace_CartMutationVariables
>

/**
 * __useReorder_And_Replace_CartMutation__
 *
 * To run a mutation, you first call `useReorder_And_Replace_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorder_And_Replace_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderAndReplaceCartMutation, { data, loading, error }] = useReorder_And_Replace_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorder_And_Replace_CartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Reorder_And_Replace_CartMutation,
    Reorder_And_Replace_CartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Reorder_And_Replace_CartMutation,
    Reorder_And_Replace_CartMutationVariables
  >(Reorder_And_Replace_CartDocument, options)
}
export type Reorder_And_Replace_CartMutationHookResult = ReturnType<
  typeof useReorder_And_Replace_CartMutation
>
export type Reorder_And_Replace_CartMutationResult =
  Apollo.MutationResult<Reorder_And_Replace_CartMutation>
export type Reorder_And_Replace_CartMutationOptions =
  Apollo.BaseMutationOptions<
    Reorder_And_Replace_CartMutation,
    Reorder_And_Replace_CartMutationVariables
  >
export const Get_Completed_OrderDocument = gql`
  query GET_COMPLETED_ORDER($input: CompletedOrderInput!) @toastRetry {
    completedOrder(input: $input) {
      ...completedOrder
    }
  }
  ${CompletedOrderFragmentDoc}
`

/**
 * __useGet_Completed_OrderQuery__
 *
 * To run a query within a React component, call `useGet_Completed_OrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Completed_OrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Completed_OrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGet_Completed_OrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    Get_Completed_OrderQuery,
    Get_Completed_OrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Get_Completed_OrderQuery,
    Get_Completed_OrderQueryVariables
  >(Get_Completed_OrderDocument, options)
}
export function useGet_Completed_OrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Completed_OrderQuery,
    Get_Completed_OrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Get_Completed_OrderQuery,
    Get_Completed_OrderQueryVariables
  >(Get_Completed_OrderDocument, options)
}
export type Get_Completed_OrderQueryHookResult = ReturnType<
  typeof useGet_Completed_OrderQuery
>
export type Get_Completed_OrderLazyQueryHookResult = ReturnType<
  typeof useGet_Completed_OrderLazyQuery
>
export type Get_Completed_OrderQueryResult = Apollo.QueryResult<
  Get_Completed_OrderQuery,
  Get_Completed_OrderQueryVariables
>
export const Validate_CartDocument = gql`
  mutation VALIDATE_CART($input: ValidateCartPreCheckoutInput!) {
    validateCartPreCheckout(input: $input) {
      ... on CartResponse {
        ...cartResponse
      }
      ... on CartModificationError {
        code
        message
      }
      ... on CartOutOfStockError {
        cart {
          ...cart
        }
        items {
          name
          guid
        }
        message
      }
    }
  }
  ${CartResponseFragmentDoc}
  ${CartFragmentDoc}
`
export type Validate_CartMutationFn = Apollo.MutationFunction<
  Validate_CartMutation,
  Validate_CartMutationVariables
>

/**
 * __useValidate_CartMutation__
 *
 * To run a mutation, you first call `useValidate_CartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidate_CartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateCartMutation, { data, loading, error }] = useValidate_CartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidate_CartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Validate_CartMutation,
    Validate_CartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Validate_CartMutation,
    Validate_CartMutationVariables
  >(Validate_CartDocument, options)
}
export type Validate_CartMutationHookResult = ReturnType<
  typeof useValidate_CartMutation
>
export type Validate_CartMutationResult =
  Apollo.MutationResult<Validate_CartMutation>
export type Validate_CartMutationOptions = Apollo.BaseMutationOptions<
  Validate_CartMutation,
  Validate_CartMutationVariables
>
export const Update_Basic_InfoDocument = gql`
  mutation UPDATE_BASIC_INFO($input: BasicInfoInput!) {
    updateBasicInfo(input: $input) {
      ... on UpdateBasicInfoResponse {
        customer {
          guid
          firstName
          lastName
          phone
        }
      }
      ... on UpdateBasicInfoError {
        code
        message
      }
    }
  }
`
export type Update_Basic_InfoMutationFn = Apollo.MutationFunction<
  Update_Basic_InfoMutation,
  Update_Basic_InfoMutationVariables
>

/**
 * __useUpdate_Basic_InfoMutation__
 *
 * To run a mutation, you first call `useUpdate_Basic_InfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Basic_InfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasicInfoMutation, { data, loading, error }] = useUpdate_Basic_InfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_Basic_InfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Update_Basic_InfoMutation,
    Update_Basic_InfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Update_Basic_InfoMutation,
    Update_Basic_InfoMutationVariables
  >(Update_Basic_InfoDocument, options)
}
export type Update_Basic_InfoMutationHookResult = ReturnType<
  typeof useUpdate_Basic_InfoMutation
>
export type Update_Basic_InfoMutationResult =
  Apollo.MutationResult<Update_Basic_InfoMutation>
export type Update_Basic_InfoMutationOptions = Apollo.BaseMutationOptions<
  Update_Basic_InfoMutation,
  Update_Basic_InfoMutationVariables
>
export const Update_PasswordDocument = gql`
  mutation UPDATE_PASSWORD($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      ... on UpdatePasswordResponse {
        customer {
          guid
        }
      }
      ... on UpdatePasswordError {
        code
        message
      }
    }
  }
`
export type Update_PasswordMutationFn = Apollo.MutationFunction<
  Update_PasswordMutation,
  Update_PasswordMutationVariables
>

/**
 * __useUpdate_PasswordMutation__
 *
 * To run a mutation, you first call `useUpdate_PasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_PasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdate_PasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_PasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Update_PasswordMutation,
    Update_PasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Update_PasswordMutation,
    Update_PasswordMutationVariables
  >(Update_PasswordDocument, options)
}
export type Update_PasswordMutationHookResult = ReturnType<
  typeof useUpdate_PasswordMutation
>
export type Update_PasswordMutationResult =
  Apollo.MutationResult<Update_PasswordMutation>
export type Update_PasswordMutationOptions = Apollo.BaseMutationOptions<
  Update_PasswordMutation,
  Update_PasswordMutationVariables
>
export const Dining_OptionsDocument = gql`
  query DINING_OPTIONS($input: DiningOptionsInput!) @toastRetry {
    diningOptions(input: $input) {
      guid
      behavior
      deliveryProvider {
        provider
      }
      asapSchedule {
        availableNow
        availableAt
      }
      futureSchedule {
        dates {
          date
          timesAndGaps {
            ... on FutureFulfillmentTime {
              time
            }
            ... on FutureFulfillmentServiceGap {
              description
            }
          }
        }
      }
    }
  }
`

/**
 * __useDining_OptionsQuery__
 *
 * To run a query within a React component, call `useDining_OptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDining_OptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDining_OptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDining_OptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Dining_OptionsQuery,
    Dining_OptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Dining_OptionsQuery, Dining_OptionsQueryVariables>(
    Dining_OptionsDocument,
    options
  )
}
export function useDining_OptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Dining_OptionsQuery,
    Dining_OptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Dining_OptionsQuery, Dining_OptionsQueryVariables>(
    Dining_OptionsDocument,
    options
  )
}
export type Dining_OptionsQueryHookResult = ReturnType<
  typeof useDining_OptionsQuery
>
export type Dining_OptionsLazyQueryHookResult = ReturnType<
  typeof useDining_OptionsLazyQuery
>
export type Dining_OptionsQueryResult = Apollo.QueryResult<
  Dining_OptionsQuery,
  Dining_OptionsQueryVariables
>
export const Inquire_Gift_Card_BalanceDocument = gql`
  query INQUIRE_GIFT_CARD_BALANCE($input: GiftCardBalanceInquiryInput!)
  @toastRetry {
    giftCardBalanceInquiry(input: $input) {
      ... on GiftCardBalanceInquiryResponse {
        cardNumber
        availableBalance
      }
      ... on GiftCardBalanceInquiryError {
        code
        message
      }
    }
  }
`

/**
 * __useInquire_Gift_Card_BalanceQuery__
 *
 * To run a query within a React component, call `useInquire_Gift_Card_BalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquire_Gift_Card_BalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquire_Gift_Card_BalanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInquire_Gift_Card_BalanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    Inquire_Gift_Card_BalanceQuery,
    Inquire_Gift_Card_BalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Inquire_Gift_Card_BalanceQuery,
    Inquire_Gift_Card_BalanceQueryVariables
  >(Inquire_Gift_Card_BalanceDocument, options)
}
export function useInquire_Gift_Card_BalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Inquire_Gift_Card_BalanceQuery,
    Inquire_Gift_Card_BalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Inquire_Gift_Card_BalanceQuery,
    Inquire_Gift_Card_BalanceQueryVariables
  >(Inquire_Gift_Card_BalanceDocument, options)
}
export type Inquire_Gift_Card_BalanceQueryHookResult = ReturnType<
  typeof useInquire_Gift_Card_BalanceQuery
>
export type Inquire_Gift_Card_BalanceLazyQueryHookResult = ReturnType<
  typeof useInquire_Gift_Card_BalanceLazyQuery
>
export type Inquire_Gift_Card_BalanceQueryResult = Apollo.QueryResult<
  Inquire_Gift_Card_BalanceQuery,
  Inquire_Gift_Card_BalanceQueryVariables
>
export const Selection_Item_DetailsDocument = gql`
  query SELECTION_ITEM_DETAILS(
    $input: SelectionItemDetailsInput!
    $nestingLevel: Int = 1
  ) @toastRetry {
    selectionItemDetails(input: $input) {
      description
      name
      guid
      menuItemPrice
      itemGuid
      itemGroupGuid
      quantity
      specialRequest
      calories
      imageUrl
      usesFractionalQuantity
      fractionalQuantity {
        unitOfMeasure
        quantity
      }
      modifierGroups(nestingLevel: $nestingLevel) {
        ...SelectionModifierGroupFields
        modifiers {
          ...SelectionModifierFields
          modifierGroups {
            ...SelectionModifierGroupFields
            modifiers {
              ...SelectionModifierFields
              modifierGroups {
                ...SelectionModifierGroupFields
                modifiers {
                  ...SelectionModifierFields
                  modifierGroups {
                    ...SelectionModifierGroupFields
                    modifiers {
                      ...SelectionModifierFields
                      modifierGroups {
                        ...SelectionModifierGroupFields
                        modifiers {
                          ...SelectionModifierFields
                          modifierGroups {
                            ...SelectionModifierGroupFields
                            modifiers {
                              ...SelectionModifierFields
                              modifierGroups {
                                ...SelectionModifierGroupFields
                                modifiers {
                                  ...SelectionModifierFields
                                  modifierGroups {
                                    ...SelectionModifierGroupFields
                                    modifiers {
                                      ...SelectionModifierFields
                                      modifierGroups {
                                        ...SelectionModifierGroupFields
                                        modifiers {
                                          ...SelectionModifierFields
                                          modifierGroups {
                                            ...SelectionModifierGroupFields
                                            modifiers {
                                              ...SelectionModifierFields
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${SelectionModifierGroupFieldsFragmentDoc}
  ${SelectionModifierFieldsFragmentDoc}
`

/**
 * __useSelection_Item_DetailsQuery__
 *
 * To run a query within a React component, call `useSelection_Item_DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelection_Item_DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelection_Item_DetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      nestingLevel: // value for 'nestingLevel'
 *   },
 * });
 */
export function useSelection_Item_DetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Selection_Item_DetailsQuery,
    Selection_Item_DetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Selection_Item_DetailsQuery,
    Selection_Item_DetailsQueryVariables
  >(Selection_Item_DetailsDocument, options)
}
export function useSelection_Item_DetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Selection_Item_DetailsQuery,
    Selection_Item_DetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Selection_Item_DetailsQuery,
    Selection_Item_DetailsQueryVariables
  >(Selection_Item_DetailsDocument, options)
}
export type Selection_Item_DetailsQueryHookResult = ReturnType<
  typeof useSelection_Item_DetailsQuery
>
export type Selection_Item_DetailsLazyQueryHookResult = ReturnType<
  typeof useSelection_Item_DetailsLazyQuery
>
export type Selection_Item_DetailsQueryResult = Apollo.QueryResult<
  Selection_Item_DetailsQuery,
  Selection_Item_DetailsQueryVariables
>
export const Menu_Item_DetailsDocument = gql`
  query MENU_ITEM_DETAILS($input: MenuItemDetailsInput!, $nestingLevel: Int = 1)
  @toastRetry {
    menuItemDetails(input: $input) {
      description
      name
      guid
      itemGroupGuid
      calories
      price
      imageUrl
      usesFractionalQuantity
      unitOfMeasure
      masterId
      modifierGroups(nestingLevel: $nestingLevel) {
        ...ModifierGroupFields
        modifiers {
          ...ModifierFields
          modifierGroups {
            ...ModifierGroupFields
            modifiers {
              ...ModifierFields
              modifierGroups {
                ...ModifierGroupFields
                modifiers {
                  ...ModifierFields
                  modifierGroups {
                    ...ModifierGroupFields
                    modifiers {
                      ...ModifierFields
                      modifierGroups {
                        ...ModifierGroupFields
                        modifiers {
                          ...ModifierFields
                          modifierGroups {
                            ...ModifierGroupFields
                            modifiers {
                              ...ModifierFields
                              modifierGroups {
                                ...ModifierGroupFields
                                modifiers {
                                  ...ModifierFields
                                  modifierGroups {
                                    ...ModifierGroupFields
                                    modifiers {
                                      ...ModifierFields
                                      modifierGroups {
                                        ...ModifierGroupFields
                                        modifiers {
                                          ...ModifierFields
                                          modifierGroups {
                                            ...ModifierGroupFields
                                            modifiers {
                                              ...ModifierFields
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${ModifierGroupFieldsFragmentDoc}
  ${ModifierFieldsFragmentDoc}
`

/**
 * __useMenu_Item_DetailsQuery__
 *
 * To run a query within a React component, call `useMenu_Item_DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenu_Item_DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenu_Item_DetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      nestingLevel: // value for 'nestingLevel'
 *   },
 * });
 */
export function useMenu_Item_DetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Menu_Item_DetailsQuery,
    Menu_Item_DetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Menu_Item_DetailsQuery,
    Menu_Item_DetailsQueryVariables
  >(Menu_Item_DetailsDocument, options)
}
export function useMenu_Item_DetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Menu_Item_DetailsQuery,
    Menu_Item_DetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Menu_Item_DetailsQuery,
    Menu_Item_DetailsQueryVariables
  >(Menu_Item_DetailsDocument, options)
}
export type Menu_Item_DetailsQueryHookResult = ReturnType<
  typeof useMenu_Item_DetailsQuery
>
export type Menu_Item_DetailsLazyQueryHookResult = ReturnType<
  typeof useMenu_Item_DetailsLazyQuery
>
export type Menu_Item_DetailsQueryResult = Apollo.QueryResult<
  Menu_Item_DetailsQuery,
  Menu_Item_DetailsQueryVariables
>
export const MenusDocument = gql`
  query MENUS($input: MenusInput!) @toastRetry {
    menusV3(input: $input) {
      ... on MenusResponse {
        menus {
          isActive @client
          id
          name
          groups {
            id @client
            guid
            name
            description
            items {
              description
              guid
              name
              outOfStock
              price
              imageUrl
              calories
              itemGroupGuid
              unitOfMeasure
              usesFractionalQuantity
              masterId
            }
          }
        }
      }
      ... on GeneralError {
        code
        message
      }
    }
  }
`

/**
 * __useMenusQuery__
 *
 * To run a query within a React component, call `useMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMenusQuery(
  baseOptions: Apollo.QueryHookOptions<MenusQuery, MenusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MenusQuery, MenusQueryVariables>(
    MenusDocument,
    options
  )
}
export function useMenusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MenusQuery, MenusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MenusQuery, MenusQueryVariables>(
    MenusDocument,
    options
  )
}
export type MenusQueryHookResult = ReturnType<typeof useMenusQuery>
export type MenusLazyQueryHookResult = ReturnType<typeof useMenusLazyQuery>
export type MenusQueryResult = Apollo.QueryResult<
  MenusQuery,
  MenusQueryVariables
>
export const Get_Paginated_OrdersDocument = gql`
  query GET_PAGINATED_ORDERS($input: PaginatedOrdersInput!) @toastRetry {
    paginatedOrders(input: $input) {
      totalSize
      orders {
        guid
        totalV2
        estimatedFulfillmentDate
        selections {
          name
          quantity
        }
      }
    }
  }
`

/**
 * __useGet_Paginated_OrdersQuery__
 *
 * To run a query within a React component, call `useGet_Paginated_OrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Paginated_OrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Paginated_OrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGet_Paginated_OrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Get_Paginated_OrdersQuery,
    Get_Paginated_OrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Get_Paginated_OrdersQuery,
    Get_Paginated_OrdersQueryVariables
  >(Get_Paginated_OrdersDocument, options)
}
export function useGet_Paginated_OrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Paginated_OrdersQuery,
    Get_Paginated_OrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Get_Paginated_OrdersQuery,
    Get_Paginated_OrdersQueryVariables
  >(Get_Paginated_OrdersDocument, options)
}
export type Get_Paginated_OrdersQueryHookResult = ReturnType<
  typeof useGet_Paginated_OrdersQuery
>
export type Get_Paginated_OrdersLazyQueryHookResult = ReturnType<
  typeof useGet_Paginated_OrdersLazyQuery
>
export type Get_Paginated_OrdersQueryResult = Apollo.QueryResult<
  Get_Paginated_OrdersQuery,
  Get_Paginated_OrdersQueryVariables
>
export const Get_Order_HistoryDocument = gql`
  query GET_ORDER_HISTORY($input: OrderHistoryInput!) @toastRetry {
    orderHistory(input: $input) {
      nextPageToken
      orders {
        guid
        totalV2
        estimatedFulfillmentDate
        selections {
          name
          quantity
        }
      }
    }
  }
`

/**
 * __useGet_Order_HistoryQuery__
 *
 * To run a query within a React component, call `useGet_Order_HistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Order_HistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Order_HistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGet_Order_HistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    Get_Order_HistoryQuery,
    Get_Order_HistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    Get_Order_HistoryQuery,
    Get_Order_HistoryQueryVariables
  >(Get_Order_HistoryDocument, options)
}
export function useGet_Order_HistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Order_HistoryQuery,
    Get_Order_HistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Get_Order_HistoryQuery,
    Get_Order_HistoryQueryVariables
  >(Get_Order_HistoryDocument, options)
}
export type Get_Order_HistoryQueryHookResult = ReturnType<
  typeof useGet_Order_HistoryQuery
>
export type Get_Order_HistoryLazyQueryHookResult = ReturnType<
  typeof useGet_Order_HistoryLazyQuery
>
export type Get_Order_HistoryQueryResult = Apollo.QueryResult<
  Get_Order_HistoryQuery,
  Get_Order_HistoryQueryVariables
>
export const AuthorizePaymentDocument = gql`
  mutation AuthorizePayment($input: AuthorizePaymentInput!) {
    authorizePayment(input: $input) {
      __typename
      ... on AuthorizePaymentResponse {
        paymentGuid
        paymentState
        amount
        tipAmount
        denialReason
      }
      ... on AuthorizePaymentError {
        message
      }
    }
  }
`
export type AuthorizePaymentMutationFn = Apollo.MutationFunction<
  AuthorizePaymentMutation,
  AuthorizePaymentMutationVariables
>

/**
 * __useAuthorizePaymentMutation__
 *
 * To run a mutation, you first call `useAuthorizePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizePaymentMutation, { data, loading, error }] = useAuthorizePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthorizePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorizePaymentMutation,
    AuthorizePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AuthorizePaymentMutation,
    AuthorizePaymentMutationVariables
  >(AuthorizePaymentDocument, options)
}
export type AuthorizePaymentMutationHookResult = ReturnType<
  typeof useAuthorizePaymentMutation
>
export type AuthorizePaymentMutationResult =
  Apollo.MutationResult<AuthorizePaymentMutation>
export type AuthorizePaymentMutationOptions = Apollo.BaseMutationOptions<
  AuthorizePaymentMutation,
  AuthorizePaymentMutationVariables
>
export const Restaurant_InfoDocument = gql`
  query RESTAURANT_INFO($restaurantGuid: ID!) @toastRetry {
    restaurantV2(guid: $restaurantGuid) {
      ... on Restaurant {
        guid
        whiteLabelName
        description
        imageUrl
        bannerUrls {
          raw
        }
        minimumTakeoutTime
        minimumDeliveryTime
        location {
          address1
          address2
          city
          state
          zip
          phone
          latitude
          longitude
        }
        logoUrls {
          small
        }
        schedule {
          asapAvailableForTakeout
          todaysHoursForTakeout {
            startTime
            endTime
          }
          upcomingSchedules {
            behavior
            dailySchedules {
              date
              servicePeriods {
                startTime
                endTime
              }
            }
          }
        }
        timeZoneId
        onlineOrderingEnabled
        socialMediaLinks {
          facebookLink
          twitterLink
          instagramLink
        }
        giftCardLinks {
          purchaseLink
          checkValueLink
          addValueEnabled
        }
        giftCardConfig {
          redemptionAllowed
        }
        specialRequestsConfig {
          enabled
          placeholderMessage
        }
        spotlightConfig {
          headerText
          bodyText
        }
        curbsidePickupConfig {
          enabled
          enabledV2
        }
        popularItemsConfig {
          enabled
        }
        upsellsConfig {
          enabled
        }
        creditCardConfig {
          amexAccepted
          tipEnabled
        }
      }
      ... on GeneralError {
        code
        message
      }
    }
  }
`

/**
 * __useRestaurant_InfoQuery__
 *
 * To run a query within a React component, call `useRestaurant_InfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurant_InfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurant_InfoQuery({
 *   variables: {
 *      restaurantGuid: // value for 'restaurantGuid'
 *   },
 * });
 */
export function useRestaurant_InfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    Restaurant_InfoQuery,
    Restaurant_InfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Restaurant_InfoQuery, Restaurant_InfoQueryVariables>(
    Restaurant_InfoDocument,
    options
  )
}
export function useRestaurant_InfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Restaurant_InfoQuery,
    Restaurant_InfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Restaurant_InfoQuery,
    Restaurant_InfoQueryVariables
  >(Restaurant_InfoDocument, options)
}
export type Restaurant_InfoQueryHookResult = ReturnType<
  typeof useRestaurant_InfoQuery
>
export type Restaurant_InfoLazyQueryHookResult = ReturnType<
  typeof useRestaurant_InfoLazyQuery
>
export type Restaurant_InfoQueryResult = Apollo.QueryResult<
  Restaurant_InfoQuery,
  Restaurant_InfoQueryVariables
>
export const Saved_AddressesDocument = gql`
  query SAVED_ADDRESSES @toastRetry {
    customer {
      ...customer
    }
  }
  ${CustomerFragmentDoc}
`

/**
 * __useSaved_AddressesQuery__
 *
 * To run a query within a React component, call `useSaved_AddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSaved_AddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSaved_AddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSaved_AddressesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Saved_AddressesQuery,
    Saved_AddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Saved_AddressesQuery, Saved_AddressesQueryVariables>(
    Saved_AddressesDocument,
    options
  )
}
export function useSaved_AddressesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Saved_AddressesQuery,
    Saved_AddressesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Saved_AddressesQuery,
    Saved_AddressesQueryVariables
  >(Saved_AddressesDocument, options)
}
export type Saved_AddressesQueryHookResult = ReturnType<
  typeof useSaved_AddressesQuery
>
export type Saved_AddressesLazyQueryHookResult = ReturnType<
  typeof useSaved_AddressesLazyQuery
>
export type Saved_AddressesQueryResult = Apollo.QueryResult<
  Saved_AddressesQuery,
  Saved_AddressesQueryVariables
>
export const Update_AddressDocument = gql`
  mutation UPDATE_ADDRESS($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      ... on UpdateAddressResponse {
        customer {
          ...customer
        }
      }
      ... on UpdateAddressError {
        code
        message
      }
    }
  }
  ${CustomerFragmentDoc}
`
export type Update_AddressMutationFn = Apollo.MutationFunction<
  Update_AddressMutation,
  Update_AddressMutationVariables
>

/**
 * __useUpdate_AddressMutation__
 *
 * To run a mutation, you first call `useUpdate_AddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_AddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdate_AddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdate_AddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Update_AddressMutation,
    Update_AddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Update_AddressMutation,
    Update_AddressMutationVariables
  >(Update_AddressDocument, options)
}
export type Update_AddressMutationHookResult = ReturnType<
  typeof useUpdate_AddressMutation
>
export type Update_AddressMutationResult =
  Apollo.MutationResult<Update_AddressMutation>
export type Update_AddressMutationOptions = Apollo.BaseMutationOptions<
  Update_AddressMutation,
  Update_AddressMutationVariables
>
export const Delete_AddressDocument = gql`
  mutation DELETE_ADDRESS($input: DeleteAddressInput!) {
    deleteAddress(input: $input) {
      ... on DeleteAddressResponse {
        customer {
          ...customer
        }
      }
      ... on DeleteAddressError {
        code
        message
      }
    }
  }
  ${CustomerFragmentDoc}
`
export type Delete_AddressMutationFn = Apollo.MutationFunction<
  Delete_AddressMutation,
  Delete_AddressMutationVariables
>

/**
 * __useDelete_AddressMutation__
 *
 * To run a mutation, you first call `useDelete_AddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_AddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDelete_AddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDelete_AddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Delete_AddressMutation,
    Delete_AddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Delete_AddressMutation,
    Delete_AddressMutationVariables
  >(Delete_AddressDocument, options)
}
export type Delete_AddressMutationHookResult = ReturnType<
  typeof useDelete_AddressMutation
>
export type Delete_AddressMutationResult =
  Apollo.MutationResult<Delete_AddressMutation>
export type Delete_AddressMutationOptions = Apollo.BaseMutationOptions<
  Delete_AddressMutation,
  Delete_AddressMutationVariables
>
