import { useLocation } from 'react-router-dom'
import { getQueryParams } from '../../../api/util'
import { FulfillmentFormValues } from '../../../types/fulfillment'
import { DiningOptionBehavior } from '../../../types/cart'
import { useMemo } from 'react'
import { DeliveryInfo } from '../../../types/orders'

export interface FulfillmentQueryParams extends DeliveryInfo {
  mode?: string
  behavior?: DiningOptionBehavior
  dateTime?: string
}

export const queryParamsToDeliveryInfo = (
  queryParams?: FulfillmentQueryParams
): DeliveryInfo | undefined => {
  const {
    address1,
    address2,
    city,
    state,
    zipCode,
    latitude,
    longitude,
    notes
  } = queryParams ?? {}

  if (address1 && city && state && zipCode && latitude && longitude) {
    return {
      address1,
      address2,
      city,
      state,
      zipCode,
      latitude,
      longitude,
      notes
    }
  }
  return undefined
}

export const useLocationSwitch = (fulfillmentValues: FulfillmentFormValues) => {
  const { diningOptionBehavior, deliveryInfo, fulfillmentTime } =
    fulfillmentValues

  const fulfillmentInfo: FulfillmentQueryParams = {
    mode: 'fulfillment',
    behavior: diningOptionBehavior,
    dateTime: fulfillmentTime,
    ...deliveryInfo
  }

  const switchLocations = (shortUrl: string) => {
    window.location.assign(
      `${window.location.origin}/catering/${shortUrl}/${getQueryParams(
        fulfillmentInfo
      )}`
    )
  }

  return switchLocations
}

export const useGetFulfillmentFromQueryParams = (): FulfillmentQueryParams => {
  const location = useLocation()
  const searchParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search]
  )

  // Ensures that lat/long get typed as numbers since they're strings in the query
  const latitude = searchParams['latitude']
    ? parseFloat(searchParams['latitude'])
    : undefined

  const longitude = searchParams['longitude']
    ? parseFloat(searchParams['longitude'])
    : undefined

  return { ...(searchParams as FulfillmentQueryParams), longitude, latitude }
}
