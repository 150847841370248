import * as React from 'react'
import {
  Checkbox,
  FieldShapeProps,
  FormShapeProps,
  Multibox
} from '@local/do-secundo-form'
import { getNumSelectedModifiersByGroupGuid } from '@local/do-secundo-modifier-form-helpers'

export interface ModifierBoxInputProps {
  onSelect?(): void
  field: FieldShapeProps
  form: FormShapeProps
  disabled?: boolean
  id: string
  label: string
  style?: string
  isMultibox?: boolean
  minSelections: number
  maxSelections: number | null
  groupGuid: string
}

/**
 * Wrapper component that decides what input to render based on isMultibox
 * in the future checkbox modifiers could use the multibox component directly
 */
const ModifierBoxInput = ({
  onSelect,
  field,
  form,
  disabled,
  id,
  label,
  isMultibox = false,
  minSelections,
  maxSelections,
  groupGuid,
  ...props
}: ModifierBoxInputProps) => {
  const { setFieldValue, setFieldTouched } = form
  const { name } = field

  const numSelected = getNumSelectedModifiersByGroupGuid(form, groupGuid)
  const onMultiboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue
    if (e.target && e.target.value) {
      newValue = parseInt(e.target.value)
      if (numSelected === maxSelections && newValue === 0) return
      if (newValue === 0) {
        newValue = 1
      } else {
        newValue = 0
      }

      setFieldTouched(name, true)
      setFieldValue(name, newValue)
    }
  }

  /**
   * To apply the schema of ModifierBoxInput and to align the api of Multibox and Checkbox, this custom onChange handler is passed to the Checkbox component to properly update the 'quantity' value. This is needed because the built in formik onChange handler updates than the 'selected' value by default and only accepts a boolean value
   */
  const onModifierCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setFieldValue(name, 1)
    } else {
      setFieldValue(name, 0)
    }
  }

  const sharedProps = {
    field,
    form,
    id,
    disabled,
    label,
    ...props
  }
  const multiboxProps = {
    ...sharedProps,
    onSelect: onSelect || onMultiboxChange,
    minSelections,
    maxSelections,
    groupGuid,
    dataTestId: 'mod-quantity'
  }
  const CheckboxProps = {
    ...sharedProps,
    onSelect: onSelect || onModifierCheckboxChange
  }

  if (isMultibox) {
    return <Multibox {...multiboxProps} />
  }

  return <Checkbox {...CheckboxProps} />
}

export { ModifierBoxInput }
