import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)
export const isMobileSafari = browser.satisfies({
  mobile: {
    safari: '>=9'
  }
})

/**
 * On mobile safari, set the html and body element styles so that the browser chrome shows always and prevents the hiding/showing of the browser navigation. This allows our bottom button from having to be double tapped.
 */
export const handleMobilSafariNav = () => {
  if (isMobileSafari) {
    const stylesToAdd =
      'height:100%;overflow-y: scroll;-webkit-overflow-scrolling: touch;'
    const htmlElement = document.getElementsByTagName('html')[0]
    const bodyElement = document.getElementsByTagName('body')[0]
    htmlElement.setAttribute('style', stylesToAdd)
    bodyElement.setAttribute('style', stylesToAdd)
  }
}
