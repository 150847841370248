import { getIn } from 'formik'
import { Model } from './Model'
import { ModifierGroup } from './ModifierGroup'
import { CooModifier, CooModifierGroup } from '../../../client/types/menu'
import { ModifierFormValueTypes } from '@local/do-secundo-modifier-form-helpers'

export class Modifier extends Model {
  groupIndex: number
  parent: ModifierGroup
  modifierGroups: ModifierGroup[]
  defaultSelected: boolean
  _isSelected: boolean
  isCurrent: boolean

  quantity: number
  allowsDuplicates: boolean
  name: string
  price: number
  itemGuid: string
  guid: string
  outOfStock: boolean
  isDefault: boolean

  _formikValues: ModifierFormValueTypes | null

  constructor(
    modifier: CooModifier,
    parent: ModifierGroup,
    groupIndex: number
  ) {
    super(modifier)
    this.groupIndex = groupIndex
    this.parent = parent
    this.modifierGroups = this.mapModifierGroups(modifier.modifierGroups || [])

    this.defaultSelected = modifier.isDefault || false

    this._formikValues = null

    this._isSelected = this.defaultSelected

    this.isCurrent = false

    this.quantity = 0

    this.allowsDuplicates = modifier.allowsDuplicates || false

    this.name = modifier.name
    this.price = modifier.price || 0
    this.itemGuid = modifier.guid
    this.guid = modifier.guid
    this.outOfStock = modifier.outOfStock || false
    this.isDefault = modifier.isDefault || false
  }

  mapModifierGroups(groups: CooModifierGroup[]) {
    return groups.map((modifierGroup) => new ModifierGroup(modifierGroup, this))
  }

  getNextValues() {
    return this.modifierGroups
  }

  resetDefaults() {}

  getIn(values: keyof Modifier) {
    return getIn(values, this.deepFieldNameByGuid)
  }

  /**
   * @returns {boolean} True if this modifier has nested modifiers.
   */
  get hasNested(): boolean {
    return Boolean(this.modifierGroups.length)
  }

  get deepPrefix(): string {
    return this.parentModifier
      ? `${this.parentModifier.deepFieldNameByGuid}.`
      : ''
  }

  /**
   * @returns {string} If the group is single select return the group key
   *   else return the modifier's formik key along with the group key.
   */
  get valueFieldName() {
    const baseKey = this.parent.valueFieldName
    return this.parent.isSingleSelect
      ? baseKey
      : `${baseKey}.${this.groupIndex}.selected`
  }

  /**
   * Returns a reference to the quantity field for use with fields that can have duplicate modifier quantities
   * @returns {string}
   */
  get valueFieldNameQuantity() {
    return `${this.parent.valueFieldName}.${this.groupIndex}.quantity`
  }

  get deepValueFieldName() {
    return `${this.deepPrefix}${this.valueFieldName}`
  }

  /**
   * Returns a reference to the quantity field for use with fields that can have duplicate nested modifier quantities
   * @returns {string}
   * */
  get deepValueFieldNameQuantity() {
    return `${this.deepPrefix}${this.valueFieldNameQuantity}`
  }

  get fieldNameByGuid() {
    return `${this.parent.fieldName}.detailsByItemGuid.${this.itemGuid}`
  }

  get deepFieldNameByGuid() {
    return `${this.deepPrefix}${this.fieldNameByGuid}`
  }

  /**
   * @returns {string} The guid of this modifier's parent group.
   */
  get groupGuid() {
    return this.parent.guid
  }

  /**
   * @returns {Modifier|null} The parent modifier of which this
   *   modifier's group is child to or null if this is the top
   *   level modifier.
   */
  get parentModifier() {
    return this.parent.parentModifier
  }

  /**
   * Set's the formik model to true and stores the selected state
   * locally.
   */
  setSelected(
    setFieldValue: (name: string, value: any) => void,
    value: any,
    isDeep = false
  ) {
    const valueField = isDeep ? this.deepValueFieldName : this.valueFieldName
    if (this.parent.isSingleSelect) {
      // Value can be a boolean or a guid
      const guidValue =
        typeof value === 'boolean' ? (value ? this.guid : '') : value
      this.parent.selectedGuid = guidValue
      setFieldValue(valueField, guidValue)
    } else {
      this._isSelected = value
      setFieldValue(valueField, value)
    }
  }

  /**
   * Set's the formik model quantity stores the selected state
   * locally.
   */
  setModifierQuantity(
    setFieldValue: (name: string, value: any) => void,
    value: any,
    isDeep = false
  ) {
    const valueField = isDeep
      ? this.deepValueFieldNameQuantity
      : this.valueFieldNameQuantity
    if (this.parent.isSingleSelect) {
      // Value can be a boolean or a guid
      const guidValue =
        typeof value === 'boolean' ? (value ? this.guid : '') : value
      this.parent.selectedGuid = guidValue
      setFieldValue(valueField, guidValue)
    } else {
      this._isSelected = value > 0
      setFieldValue(valueField, value)
    }
  }

  get selectedValue() {
    return this.parent.isSingleSelect ? this.guid : this.isSelected
  }

  get isSelected() {
    if (this.parent.isSingleSelect) {
      return this.guid === this.parent.selectedGuid
    }
    return this._isSelected
  }

  set formikValues(values) {
    this._formikValues = values
  }

  get formikValues() {
    return this._formikValues
  }
}
