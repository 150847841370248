import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../Link/Link'

export const OrderingNotAvailableBanner = ({ menuLink }) => {
  return (
    <>
      {menuLink ? (
        <p>
          Items cannot be added to cart <br />
          <Link
            data-testid='link-to-menu-from-empty-cart-ordering-unavailable'
            to={menuLink}
            style='primary'
          >
            Feel free to browse our menu
          </Link>
        </p>
      ) : (
        <p>Items cannot be added to cart</p>
      )}
    </>
  )
}

OrderingNotAvailableBanner.propTypes = {
  menuLink: PropTypes.string
}
