import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import KeyHandler from 'react-key-handler'
import FocusTrap from 'focus-trap-react'
import { CSSTransition } from 'react-transition-group'

import { ErrorComponent } from '@local/do-secundo-error'
import { CloseButton } from '@local/do-secundo-closebutton'

import style from './Drawer.module.css'

let drawerCount = 0
let drawerRoot = document.getElementById('drawer-root')
if (!drawerRoot) {
  drawerRoot = document.createElement('div')
  drawerRoot.id = 'drawer-root'
  document.body.appendChild(drawerRoot)
}

const stopEventPropagation = (event) => {
  event.stopPropagation()
}

export class Drawer extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.scrollWidth = window.innerWidth - document.body.clientWidth
    this.state = {
      mounted: false
    }
  }

  componentDidMount() {
    drawerCount++
    if (drawerCount === 1) {
      document.body.classList.add('drawer-open')
      document.body.style.margin = `0 ${this.scrollWidth}px 0 0`
    }
    this.setState({
      mounted: true
    })
    drawerRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    drawerCount--
    if (drawerCount === 0) {
      document.body.classList.remove('drawer-open')
      document.body.style.margin = ''
    }
    drawerRoot.removeChild(this.el)
  }

  render() {
    const {
      wrapper = (x) => x,
      onClose,
      children,
      header,
      error,
      footer
    } = this.props
    const closeHandler = (event) => {
      onClose(event)
    }
    const contents = wrapper(
      <FocusTrap>
        <div className={style.background} onClick={closeHandler}>
          <CSSTransition in classNames={{ ...style }} timeout={1000} appear>
            <div
              role='dialog'
              className={style.drawer}
              onClick={stopEventPropagation}
            >
              <div className={style.header}>
                {onClose && (
                  <div className={style.closeButton}>
                    <CloseButton
                      data-testid='drawer-close-button'
                      onClick={closeHandler}
                    />
                    <KeyHandler
                      keyEventName='keydown'
                      keyValue='Escape'
                      onKeyHandle={closeHandler}
                    />
                  </div>
                )}
                <div
                  data-testid='drawer-header-content'
                  className={style.headerContent}
                >
                  {header}
                </div>
              </div>

              {error && <ErrorComponent error={error} />}
              <div className={style.body}>{children}</div>
              {footer && <div className={style.footer}>{footer}</div>}
            </div>
          </CSSTransition>
        </div>
      </FocusTrap>
    )
    return ReactDOM.createPortal(contents, this.el)
  }
}

Drawer.propTypes = {
  wrapper: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  error: PropTypes.object,
  footer: PropTypes.node
}

export const TestDrawer = Drawer
