import React from 'react'
import cx from 'classnames'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import styles from './Button.module.css'

export const enum ButtonType {
  SUBMIT = 'submit',
  BUTTON = 'button',
  RESET = 'reset'
}

export const enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
  LINK_WIDE = 'linkWide'
}
export interface ButtonProps {
  children: React.ReactNode
  disabled?: boolean
  loading?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  type?: ButtonType
  variant?: ButtonVariant
  'data-testid'?: string
  id?: string
  className?: string
  eventData?: object
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      disabled,
      loading,
      onClick = () => {},
      variant = ButtonVariant.LINK,
      type = ButtonType.BUTTON,
      className = '',
      ...props
    },
    ref
  ) => {
    const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick(event)
    }

    const loadingStyle = variant.includes(LoadingVariant.PRIMARY)
      ? LoadingVariant.PRIMARY
      : LoadingVariant.LINK

    return (
      <button
        ref={ref}
        type={type}
        className={cx(styles[variant], className, {
          [styles.loading]: loading
        })}
        onClick={clickHandler}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? <Loading variant={loadingStyle} /> : children}
      </button>
    )
  }
)

// Need to set DisplayName so HOC name
// doesn't interfere with tests
Button.displayName = 'Button'
