import * as React from 'react'
import { modifierFormHelpers } from '@local/do-secundo-modifier-form-helpers'

export const useModifierFormHelpers = ({
  itemDetails,
  itemGroupGuid,
  itemQuantityRange,
  enforceMinRequirement
}) => {
  return React.useMemo(() => {
    if (!itemDetails) {
      return {
        getComputedPrice: () => 0,
        formikProps: {
          initialValues: {}
        },
        getModifierGroups: () => [],
        getSelectedNames: () => []
      }
    }

    const {
      schema,
      initialValues,
      getModifierGroups,
      getComputedPrice,
      getSelectedNames
    } = modifierFormHelpers({
      modifierGroups: itemDetails.modifierGroups,
      quantity: itemDetails.quantity,
      specialInstructions: itemDetails.specialRequest || '',
      itemGroupGuid,
      itemQuantityRange,
      enforceMinRequirement
    })

    return {
      getComputedPrice,
      formikProps: {
        validationSchema: schema,
        initialValues,
        validateOnMount: true
      },
      getModifierGroups,
      getSelectedNames
    }
  }, [itemDetails, itemGroupGuid, itemQuantityRange])
}
