import * as React from 'react'
import { onEnterOrSpace } from '@local/do-secundo-key-handler'
import { FormShapeProps, FieldShapeProps } from '../form-shared-types'
import styles from './Checkbox.module.css'
import {
  CheckboxUnselectedIcon,
  CheckboxSelectedIcon
} from '@toasttab/buffet-pui-icons'
import cx from 'classnames'

interface CheckboxProps {
  onSelect?(event?: React.ChangeEvent<HTMLInputElement>): void
  field: FieldShapeProps
  form: FormShapeProps
  disabled?: boolean
  id: string
  label: React.ReactNode
}

const Checkbox = ({
  onSelect,
  field: { name, value, onChange, onBlur },
  form: { setFieldValue, setFieldTouched },
  disabled,
  id,
  label,
  ...props
}: CheckboxProps) => {
  return (
    <div data-testid='checkbox-full' className={styles['defaultInputGroup']}>
      <div
        className={cx(
          'flex relative items-start pl-px overflow-visible select-none group',
          {
            'cursor-default pointer-events-none': disabled
          }
        )}
      >
        <div
          className={cx(
            'inline-flex w-10 h-10 mr-1 my-0.5 -ml-3 rounded-full box-border',
            'group-hover:bg-darken-4',
            {
              'text-secondary': !value && !disabled,
              'text-default': value && !disabled,
              'border-transparent text-disabled cursor-default': disabled
            }
          )}
        >
          {value ? (
            <CheckboxSelectedIcon className='p-2' accessibility='decorative' />
          ) : (
            <CheckboxUnselectedIcon
              className='p-2'
              accessibility='decorative'
            />
          )}
          <input
            data-testid={`checkbox-input-${id}`}
            type='checkbox'
            name={name}
            id={id}
            value={value}
            checked={value}
            onChange={(...a) => {
              if (onSelect) {
                onSelect(...a)
                return
              }
              setFieldTouched(name, true)
              onChange(...a)
            }}
            onBlur={onBlur}
            disabled={disabled}
            {...props}
            className='absolute left-0 w-10 h-10 opacity-0 cursor-pointer'
          />
        </div>
      </div>
      <label
        data-testid='checkbox-data'
        className={cx(
          styles['defaultLabel'],
          disabled ? 'text-disabled' : 'text-default'
        )}
        tabIndex={0}
        onKeyDown={onEnterOrSpace(() => {
          if (!disabled) {
            if (onSelect) {
              onSelect()
              return
            }
            setFieldValue(name, !value)
          }
        })}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  )
}

export { Checkbox, CheckboxProps }
