import { Address } from '../types/config'

export const getFormattedAddressShort = (address: {
  address1?: string
  address2?: string
  city?: string
}) => {
  const { address1, address2, city } = address
  if (!(address1 && city)) return ''

  const address2Str = address2 ? ` ${address2}` : ''
  return `${address1}${address2Str}, ${city}`
}

export const getAddressForLocation = (address: Address) => {
  const { address1, address2, city, state } = address
  const address2Str = address2 ? ` ${address2}` : ''
  return `${address1}${address2Str}, ${city}, ${state}`
}

/*
 * Gets a "miles away" formatted string for the given distance.
 * If distance is < 100, rounds to nearest tenth (8.6 miles)
 * If distance is >= 100, rounds to nearest whole number (120 miles)
 * If distance is >= 1000, thousands separator is used (locale-dependent) (1,514 miles)
 * If distance is 0, returns no string
 */
export const getFormattedMilesAway = (distanceMiles?: number) => {
  if (distanceMiles === undefined) return undefined
  const distancedRounded =
    distanceMiles < 100
      ? Math.round(distanceMiles * 10) / 10
      : Math.round(distanceMiles)

  if (distancedRounded === 0) return undefined
  return `${distancedRounded.toLocaleString()} ${
    distancedRounded === 1 ? 'mile' : 'miles'
  } away`
}
