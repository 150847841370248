import { useState } from 'react'
import { useMutation } from 'react-query'
import { addPromoCode, removePromoCode } from '../../../api/cart'
import { Cart } from '../../../types/cart'
import { AppliedDiscount, Check } from '../../../types/orders'
import { useDebounce } from '../../../hooks/util'
import { useCart } from '@local/do-secundo-cart-provider/src'

// Only one promo code per order is currently supported
export const getAppliedPromoCode = (check: Check): string | undefined => {
  const checkDiscount = check.appliedDiscounts.find(
    (discount) => discount.appliedPromoCode
  )

  if (checkDiscount) return checkDiscount.appliedPromoCode

  let selectionDiscount: AppliedDiscount | undefined = undefined
  for (let i = 0; i < check.selections.length; i++) {
    selectionDiscount = check.selections[i].appliedDiscounts.find(
      (discount) => discount.appliedPromoCode
    )
    if (selectionDiscount) break
  }

  return selectionDiscount?.appliedPromoCode
}

export const usePromoCode = (cartGuid: string) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const debouncedReset = useDebounce(() => setShowSuccessMessage(false), 2000)

  const { updateCartCache } = useCart()

  const mutationOptions = {
    onMutate: () => {
      setErrorMessage(undefined)
    },
    onSuccess: async (cartResponse: Cart) => {
      updateCartCache(cartResponse)
    },
    onError: async (error: Response) => {
      setErrorMessage((await error.json()).message)
    }
  }

  const applyPromoCodeMutation = useMutation(
    (promoCode: string) => addPromoCode(cartGuid, { promoCode }),
    {
      ...mutationOptions,
      onSuccess: async (cartResponse: Cart) => {
        mutationOptions.onSuccess(cartResponse)
        setShowSuccessMessage(true)
        debouncedReset()
      }
    }
  )

  const removePromoCodeMutation = useMutation(
    () => removePromoCode(cartGuid),
    mutationOptions
  )

  const applyPromoCode = (promoCode: string) => {
    applyPromoCodeMutation.mutate(promoCode)
  }

  const clearPromoCode = () => {
    removePromoCodeMutation.mutate()
  }

  return {
    applyPromoCode,
    clearPromoCode,
    isLoading:
      applyPromoCodeMutation.isLoading || removePromoCodeMutation.isLoading,
    errorMessage,
    setErrorMessage,
    showSuccessMessage
  }
}
