import React, { useCallback } from 'react'
import cx from 'classnames'
import style from './Toggle.module.css'

type Side = 'right' | 'left'

type Option<T> = {
  name: string
  icon?: React.ReactElement
  value: T
  testId?: string
}

type Props<T> = { [key in Side]: Option<T> } & {
  value: Side
  onChange: (value: T) => void
  className?: string
}

export const Toggle = <T,>(props: Props<T>) => {
  const selected = props.value ?? 'left'
  const select = useCallback(
    (side: Side) => () => {
      if (selected !== side) {
        props.onChange(props[side].value)
      }
    },
    [props, selected]
  )

  return (
    <div className={cx(style.toggle, props.className)} role='group'>
      <div
        className={cx(
          style.switch,
          selected === 'left' ? style.left : style.right
        )}
      />
      <button
        type='button'
        className={cx(style.option, {
          [style.selected]: selected === 'left'
        })}
        onClick={select('left')}
        data-testid={props.left.testId}
        aria-pressed={selected === 'left'}
        aria-label={`Switch to ${props.left.name}`}
      >
        {props.left.icon ? props.left.icon : null}
        <div>{props.left.name}</div>
      </button>
      <button
        type='button'
        className={cx(style.option, {
          [style.selected]: selected === 'right'
        })}
        onClick={select('right')}
        data-testid={props.right.testId}
        aria-pressed={selected === 'right'}
        aria-label={`Switch to ${props.right.name}`}
      >
        {props.right.icon ? props.right.icon : null}
        <div>{props.right.name}</div>
      </button>
    </div>
  )
}
