import React, { useState } from 'react'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import { isItemDiscount, rewardToCard, rewardToDiscount } from './loyalty'
import { Cart } from '../../types/cart'
import { CardSelector } from '@toasttab/buffet-pui-card-selector'
import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { hasAnyCheckDiscounts } from '../../utils/cart-helpers'
import { useLoyalty } from '../../api/loyalty'
import { Customer } from './CustomerInfo/CustomerInfo'
import { RewardType, SelectionType } from '../../types/loyalty'

export const LoyaltyRedemption = ({
  cart,
  customer
}: {
  cart: Cart
  customer: Customer
}) => {
  const {
    config,
    checkLoyaltyDiscounts,
    inquiryResponse,
    saving,
    applyLoyaltyDiscount
  } = useLoyalty(customer, cart)

  const [toRedeem, setToRedeem] = useState<number | undefined>()

  if (checkLoyaltyDiscounts.length > 0) {
    const alreadyAppliedRewards = checkLoyaltyDiscounts
      .map((d) => d.discountAmount ?? 0)
      .filter((n) => n)
      .reduce((a, b) => a + b, 0)

    if (alreadyAppliedRewards === 0) {
      return null
    }

    return (
      <>
        <div className={'text-center'}>
          <CelebrationIllustration
            className='w-32 mt-4 mb-2'
            resizeToContainer
          />
          <div className={'pb-6 mt-2 type-headline-5'}>
            You’ll save{' '}
            {formatCurrency(
              { amount: alreadyAppliedRewards, currency: Currency.USD },
              'en-US'
            )}{' '}
            on this order!
          </div>
        </div>
      </>
    )
  }

  if (!config?.enrolled || !inquiryResponse || hasAnyCheckDiscounts(cart)) {
    return null
  }

  const discountMap = new Map(
    inquiryResponse.discounts?.map((d) => [d.guid, d])
  )

  const switchReward = (key: number) => {
    if (toRedeem === key) {
      setToRedeem(undefined)
    } else {
      setToRedeem(key)
    }
  }

  const filteredRedemptions = inquiryResponse.availableRedemptions.filter(
    (r) =>
      r.availability === 'CURRENT_CHECK' &&
      (r.referenceId === RewardType.LOYALTY_DOLLARS_AMOUNTS ||
        r.integrationData?.selectionType === SelectionType.CHECK)
  )

  const redemptionViews = filteredRedemptions.map((r, i) => {
    return { key: i, redemption: r }
  })

  const rewardToDiscountMap = new Map(
    redemptionViews?.map((r) => [
      r.key,
      rewardToDiscount(
        r.redemption,
        inquiryResponse.vendor,
        discountMap.get(r.redemption.itemId)!!
      )
    ])
  )

  const total = cart.order.checks[0].totalAmount

  const options = redemptionViews
    .filter((r) => rewardToDiscountMap.get(r.key))
    .map((r) => rewardToCard(r, total, inquiryResponse.effectiveConversionRate))
    .filter((v) => v)
    .map((v) => v!!)

  if (options.length === 0) {
    // No rewards but they're still an enrolled loyalty member -
    // show point balance (only exists for Toast loyalty)
    const availablePoints = inquiryResponse.availableRedemptions.find(
      (r) => r.referenceId === 'LOYALTY_POINTS_AMOUNT'
    )?.quantity

    return (
      <>
        <div className='py-2 space-y-1 text-default'>
          {availablePoints && availablePoints === 0 && (
            <>
              <div className='font-semibold'>
                You don't have any points (yet!)
              </div>
              <div className='type-subhead'>
                Keep ordering to earn points and unlock rewards
              </div>
            </>
          )}
          {availablePoints && availablePoints > 0 && (
            <>
              <div className='font-semibold'>{`You have ${availablePoints} points!`}</div>
              <div className='type-subhead'>No rewards at this time.</div>
            </>
          )}
          {!availablePoints && <div>No rewards at this time.</div>}
        </div>
      </>
    )
  }

  return (
    <div className={'text-center'}>
      <CelebrationIllustration className='w-32 mt-4 mb-2' resizeToContainer />
      <div className={'type-headline-5 text-color-default'}>
        You have rewards!
      </div>
      <div className={'text-secondary'}>
        {options.length > 1 ? 'Select 1 reward to redeem' : 'Select to redeem'}
      </div>
      <div className={'pr-4 pl-4 pb-4 mt-2'}>
        {options.map((o) => {
          const key = o.value
          const reward = redemptionViews[key].redemption!!

          if (isItemDiscount(reward)) {
            return null
          }

          return (
            <div key={o.value} className={'mb-2'}>
              <CardSelector
                disabled={saving}
                checked={toRedeem === key}
                onChange={() => {
                  switchReward(key)

                  const discount = rewardToDiscountMap.get(key)!!
                  applyLoyaltyDiscount(discount)
                }}
              >
                {o.contents}
              </CardSelector>
            </div>
          )
        })}
      </div>
    </div>
  )
}
