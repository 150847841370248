import React, { ReactElement } from 'react'
import cx from 'classnames'
import { CartPage } from '../../CartPage/CartPage'
import { useHasMode } from '../../ModeRouter/ModeRouter'
import styles from './MenuPageLayout.module.css'
import { ShareCartButton } from '../../ShareCart/ShareCartButton'
import { useGetCart } from '../../CartQuery/CartQuery'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

interface Props {
  menus: ReactElement
  cartBody: ReactElement
  header: ReactElement
}

export const MenuPageLayout: React.FC<Props> = ({
  menus,
  cartBody,
  header
}) => {
  const isCartMode = useHasMode('cart')
  const { cart } = useGetCart()

  const {
    applicableConfigs: { brandingConfig }
  } = useRestaurant()

  return (
    <>
      {isCartMode && (
        <div className={styles.cartMobile}>
          <CartPage />
        </div>
      )}
      <div
        className={cx(styles.splitView, {
          [styles.cartMobile || '']: isCartMode
        })}
        itemType={'https://schema.org/Restaurant'}
      >
        <div
          className={styles.content}
          role='main'
          aria-label='menu'
          data-testid='MenuPageContent'
        >
          {header}
          <div className={styles.menus}>{menus}</div>
        </div>
        <div className={styles.cart} role='region' aria-label='cart'>
          <div className={styles.fixed}>
            <div className={'pb-2'}>
              <div className={'flex items-center w-full'}>
                <h2 className={cx(styles.label, 'type-headline-5 mr-1 flex-1')}>
                  {cart?.metadata?.name ||
                    `Your ${
                      brandingConfig?.removeCateringName
                        ? 'order'
                        : 'catering order'
                    }`}
                </h2>
                <div className={cx(styles.label, 'ml-1 text-right')}>
                  <ShareCartButton />
                </div>
              </div>
              {cart?.metadata?.description && (
                <div className={'px-6'}>{cart.metadata.description}</div>
              )}
            </div>
            {cartBody}
          </div>
        </div>
      </div>
    </>
  )
}
