import { useAvailability } from '@local/do-secundo-availability-provider'
import { useModeHistoryPushCallback } from '../components/ModeRouter/ModeRouter'
import { useDiningOptions } from './useDiningOptions'
import { useCallback, useMemo } from 'react'
import { useFulfillment } from '../components/FulfillmentProvider/FulfillmentProvider'

export const useRedirectToFulfillment = () => {
  const { orderingAvailable, standardOrderingAvailable } = useAvailability()
  const { selected, valid } = useFulfillment()
  const { independent, refetch } = useDiningOptions()

  const shouldShowFulfillmentModal = useMemo(
    () =>
      (!selected || !valid) &&
      (orderingAvailable || (standardOrderingAvailable && !independent)),
    [selected, valid, orderingAvailable, standardOrderingAvailable, independent]
  )

  const modeHistoryPush = useModeHistoryPushCallback({
    mode: 'fulfillment'
  })

  const redirectToFulfillment = useCallback(() => {
    refetch()
    modeHistoryPush()
  }, [refetch, modeHistoryPush])

  return { redirectToFulfillment, shouldShowFulfillmentModal }
}
