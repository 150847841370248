import * as Yup from 'yup'

export const deliveryInfoAddress2Schema = Yup.string()
  .label('Apt, suite, floor (optional)')
  .trim()
  .max(255)
  .default('')

export const deliveryInfoNotesSchema = Yup.string()
  .label('Delivery Instructions')
  .trim()
  .max(300)
  .default('')
