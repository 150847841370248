import React, { useContext } from 'react'
import { Formik, FormikConfig, FormikProps } from 'formik'

// @ts-ignore - stop complaining about default value not matching type
const FormikContext = React.createContext<FormikProps<any>>({})

export const FormikProvider: React.FC<FormikConfig<any>> = ({
  children,
  ...props
}) => {
  return (
    <Formik {...props}>
      {(formikProps) => (
        <FormikContext.Provider value={formikProps}>
          {typeof children === 'function' ? children(formikProps) : children}
        </FormikContext.Provider>
      )}
    </Formik>
  )
}

export const useFormik = () => useContext(FormikContext)
