import { Tooltip, TooltipProps } from '@toasttab/buffet-pui-tooltip'
import React from 'react'

interface TooltipWrapperProps extends Omit<TooltipProps, 'content'> {
  enableTooltip: boolean
  message: string
}

const TooltipWrapper = (
  props: React.PropsWithChildren<TooltipWrapperProps>
) => {
  return props.enableTooltip ? (
    <Tooltip content={() => props.message} {...props}>
      {props.children}
    </Tooltip>
  ) : (
    <>{props.children}</>
  )
}

export default TooltipWrapper
