import { DateTime } from 'luxon'
import { getCurrentLocalTime } from './time-utils'
import cloneDeep from 'lodash/cloneDeep'

/**
 * Takes in a military time and converts it to am/pm on a 12 hour clock. Exp: '16:00' -> '4:00 pm'
 * @param {string | undefined} localTime ISO-formatted local time
 * @param {string | undefined | null} timeZoneId restaurant time zone
 * @param {boolean} showOffset hides UTC offset abbreviation if true (useful when displaying start - end times)
 * @returns {string} formatted hours for display
 */
export const formatHours = (localTime, timeZoneId, showOffset = true) => {
  const guestTimeZone = DateTime.local().zoneName
  const showRestaurantTimeZone = showOffset && guestTimeZone !== timeZoneId
  const displayTime = DateTime.fromISO(localTime, {
    zone: timeZoneId
  }).toLocaleString({
    hour: 'numeric', // 9:00 AM instead of 09:00 AM
    minute: '2-digit',
    timeZoneName: showRestaurantTimeZone ? 'short' : undefined
  })
  return displayTime
}

/**
 * Takes in the takeout schedule and the delivery schedule and merges the intervals into one online ordering schedule.
 * @param {dayIntervals[]} takeoutScheduleIntervals
 * @param {dayIntervals[]} deliveryScheduleIntervals
 * @returns interval[]
 */
export const createOnlineOrderingDaySchedule = (upcomingSchedules) => {
  const clonedUpcomingSchedules = cloneDeep(upcomingSchedules)
  const takeoutScheduleIntervals =
    clonedUpcomingSchedules[0]?.dailySchedules[0]?.servicePeriods || []
  const deliveryScheduleIntervals =
    clonedUpcomingSchedules[1]?.dailySchedules[0]?.servicePeriods || []
  const allIntervals = [
    ...takeoutScheduleIntervals,
    ...deliveryScheduleIntervals
  ]

  if (allIntervals.length === 0) return []

  // sort all intervals by start time
  allIntervals.sort((interval1, interval2) => {
    return interval1.startTime > interval2.startTime ? 1 : -1
  })

  // starts with earliest starting timed interval
  const onlineOrderingIntervals = [allIntervals.shift()]

  allIntervals.forEach(({ startTime, endTime, __typename }) => {
    const prev = onlineOrderingIntervals[onlineOrderingIntervals.length - 1]
    if (startTime > prev.endTime) {
      onlineOrderingIntervals.push({ startTime, endTime, __typename })
    } else {
      prev.endTime = prev.endTime < endTime ? endTime : prev.endTime
    }
  })

  return onlineOrderingIntervals
}

/**
 * Takes in a days schedule and timeZoneId and returns a boolean of whether the restaurant is open or not.
 * This can be determined by either having a time in between opening and closing of a given interval, or
 * having a time that is greater than the startTime and having a startTime greater than the endTime.
 * Exp 4am - 3:45am and current time is 5pm.
 *
 * @param {intervals[]} onlineOrderingSchedule
 * @param {string} timeZoneId
 * @returns boolean
 */
export const checkIfRestaurantIsOpen = (onlineOrderingSchedule, timeZoneId) => {
  const currentTimeForRestaurant = getCurrentLocalTime(timeZoneId)
  for (const { startTime, endTime } of onlineOrderingSchedule) {
    if (
      (currentTimeForRestaurant >=
        DateTime.fromISO(startTime, { zone: timeZoneId }).toISO() &&
        currentTimeForRestaurant <
          DateTime.fromISO(endTime, { zone: timeZoneId }).toISO()) ||
      (currentTimeForRestaurant >=
        DateTime.fromISO(startTime, { zone: timeZoneId }).toISO() &&
        DateTime.fromISO(startTime, { zone: timeZoneId }).toISO() >=
          DateTime.fromISO(endTime, { zone: timeZoneId }).toISO())
    ) {
      return true
    }
  }
  return false
}

/**
 *This gets called whenever a restaurant is determined as open and the closing time needs to be calculated.
 * Returns a formatted time of when the restaurant will be closing.
 *
 * @param {intervals[]} onlineOrderingSchedule
 * @param {string} timeZoneId
 * @returns {string} formatted hours for display
 */
export const getNextClosingTime = (onlineOrderingSchedule, timeZoneId) => {
  const currentTimeForRestaurant = getCurrentLocalTime(timeZoneId)
  for (const { startTime, endTime } of onlineOrderingSchedule) {
    if (
      currentTimeForRestaurant <
        DateTime.fromISO(endTime, { zone: timeZoneId }).toISO() ||
      (DateTime.fromISO(startTime, { zone: timeZoneId }).toISO() >
        DateTime.fromISO(endTime, { zone: timeZoneId }).toISO() &&
        currentTimeForRestaurant >
          DateTime.fromISO(startTime, { zone: timeZoneId }).toISO())
    ) {
      return formatHours(endTime, timeZoneId)
    }
  }

  // Should never reach this because it's only called after checking if open
  return ''
}

/**
 *
 * @param {intervals[]} daySchedule
 * @param {string} timeZoneId
 * @param {RestaurantSchedule} schedule
 * @returns {Object} {time: string, date: yr-month-day}
 */
export const getNextOpenTime = (daySchedule, timeZoneId, schedule) => {
  const currentTimeForRestaurant = getCurrentLocalTime(timeZoneId)
  const { upcomingSchedules } = schedule

  for (const { startTime } of daySchedule) {
    if (
      currentTimeForRestaurant <=
      DateTime.fromISO(startTime, { zone: timeZoneId }).toISO()
    ) {
      const todaysDate =
        upcomingSchedules[0]?.dailySchedules?.[0]?.date ||
        upcomingSchedules[1]?.dailySchedules?.[0]?.date
      //This should be todays date
      return { time: formatHours(startTime, timeZoneId), date: todaysDate }
    }
  }
  const firstSchedule = upcomingSchedules[0]?.dailySchedules || []
  const secondSchedule = upcomingSchedules[1]?.dailySchedules || []

  //Iterates through days of week, skipping current day
  for (let i = 1; i < 7; i++) {
    const firstScheduleIntervals = firstSchedule[i]?.servicePeriods || []
    const secondScheduleIntervals = secondSchedule[i]?.servicePeriods || []
    if (firstScheduleIntervals.length || secondScheduleIntervals.length) {
      // 25 because time will be greater than any time in day; index 0 because we only care about first interval of day
      const firstScheduleStartTime =
        firstScheduleIntervals[0]?.startTime || '25'
      const secondScheduleStartTime =
        secondScheduleIntervals[0]?.startTime || '25'

      const dayObj =
        firstScheduleStartTime < secondScheduleStartTime
          ? firstSchedule[i]
          : secondSchedule[i]

      const openingHours = formatHours(
        firstScheduleStartTime < secondScheduleStartTime
          ? firstScheduleStartTime
          : secondScheduleStartTime,
        timeZoneId
      )
      return { time: openingHours, date: dayObj.date }
    }
  }
  return { time: null, date: null }
}

/**
 *
 * @param {string} date
 * @param {string} timeZoneId
 * @returns {string} Today | Tomorrow | Month/Day
 */
export const formatOpenDay = (date, timeZoneId) => {
  if (!date) return ''
  const [currentMonth, currentDay, currentYear] = DateTime.local()
    .setZone(timeZoneId)
    .toLocaleString()
    .split('/')
    .map(Number)
  const [tomorrowMonth, tomorrowDay, tomorrowYear] = DateTime.local()
    .setZone(timeZoneId)
    .plus({ days: 1 })
    .toLocaleString()
    .split('/')
    .map(Number)

  const [year, month, day] = date.split('-').map(Number)

  if (month === currentMonth && day === currentDay && year === currentYear) {
    return 'Today'
  } else if (
    month === tomorrowMonth &&
    day === tomorrowDay &&
    year === tomorrowYear
  ) {
    return 'Tomorrow'
  } else if (month && day) {
    return `${month}/${day}`
  }
  return ''
}

/**
 * @param {intervals[]} daySchedule
 * @param {string} timeZoneId
 * @param {RestaurantSchedule} schedule
 * @returns {string} Describes when a restaurant is next open
 */
export const getNextOpenString = ({ daySchedule, timeZoneId, schedule }) => {
  const { time, date } = getNextOpenTime(daySchedule, timeZoneId, schedule)
  const openingDay = formatOpenDay(date)

  if (time && openingDay) {
    return `Opens ${openingDay} at ${time}`
  }
  // This is to account for the case that there are no upcoming open days
  return ''
}
