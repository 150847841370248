import React from 'react'
import {
  deliveryInfoAddress2Schema,
  deliveryInfoNotesSchema
} from '../../../utils/form-schemas'
import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'
import { Fieldset } from '@local/do-secundo-fieldset'
import { NotInDeliveryAreaWarning } from '../../NotInDeliveryAreaWarning/NotInDeliveryAreaWarning'
import { Progress } from '@local/do-secundo-progress'
import { NewAddress } from './NewAddress'
import { DeliveryInfo } from '../../../types/orders'
import { FulfillmentInfo } from '../../../types/cart'

export const CheckoutDeliveryInfo: React.FC = () => {
  const fulfillmentContext = useFulfillment()

  if (fulfillmentContext.diningOptionBehavior !== 'DELIVERY') {
    return null
  }

  return (
    <Fieldset label='Delivery Info' collapsable id='delivery_info_fields'>
      {fulfillmentContext.updating ? (
        <Progress />
      ) : (
        <>
          <NewAddress />
          <NotInDeliveryAreaWarning
            deliveryInfo={fulfillmentContext.deliveryInfo}
            diningOptionBehavior={fulfillmentContext.diningOptionBehavior}
            severity='error'
            customLocationName={fulfillmentContext.customLocationName}
          />
        </>
      )}
    </Fieldset>
  )
}

export const getArgsForSubmit = ({
  fulfillmentContext,
  values
}): { newAddress: { deliveryInfo: DeliveryInfo } } | null => {
  if (fulfillmentContext.diningOptionBehavior !== 'DELIVERY') {
    return null
  }

  if (
    values.customerDeliveryInfoAddress &&
    values.customerDeliveryInfoAddress.address1
  ) {
    return {
      newAddress: {
        deliveryInfo: {
          ...values.customerDeliveryInfoAddress,
          address2: values.customerDeliveryInfoAddress2,
          notes: values.customerDeliveryInfoNotes
        }
      }
    }
  }

  return null
}

export const getInitialValues = ({
  fulfillmentContext
}: {
  fulfillmentContext: FulfillmentInfo
}) => {
  if (fulfillmentContext.diningOptionBehavior !== 'DELIVERY') {
    return null
  }

  let deliveryInfo = fulfillmentContext.deliveryInfo || {}

  let customerDeliveryInfoAddress: Partial<DeliveryInfo> = {}
  let customerDeliveryInfoAddress2 = ''
  let customerDeliveryInfoNotes = ''

  if (deliveryInfo && deliveryInfo.address1) {
    const { address2, notes } = deliveryInfo
    customerDeliveryInfoAddress = {
      ...deliveryInfo
    }

    customerDeliveryInfoAddress2 = address2 || ''
    customerDeliveryInfoNotes = notes || ''
  }

  return {
    customerDeliveryInfoAddress,
    customerDeliveryInfoAddress2,
    customerDeliveryInfoNotes,
    contactlessCustomerDeliveryInfoNotes: ''
  }
}

export const getValidationSchema = ({ fulfillmentContext }) => {
  if (fulfillmentContext.diningOptionBehavior !== 'DELIVERY') {
    return null
  }

  return {
    customerDeliveryInfoAddress2: deliveryInfoAddress2Schema,
    customerDeliveryInfoNotes: deliveryInfoNotesSchema,
    contactlessCustomerDeliveryInfoNotes: deliveryInfoNotesSchema
  }
}
