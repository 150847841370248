import React from 'react'
import { FieldArray, Field, getIn, useFormikContext } from 'formik'

import { Radio } from '@local/do-secundo-form'
import { ModifierFieldset } from '@local/do-secundo-modifierfieldset'
import { ModifierFieldLabel } from '@local/do-secundo-modifier-field-label'
import { useNestedModifiers } from '../NestedModifiersProvider/NestedModifiersProvider'
import {
  Modifier,
  ModifierGroup as ModifierGroupModel
} from '@local/do-secundo-model'
import { ModifierBoxInput } from '@local/do-secundo-modifier-box-input'
import {
  getNumSelectedModifiers,
  isSelected,
  ModifierFormItemDetails
} from '@local/do-secundo-modifier-form-helpers'
import {
  getPriceToRender,
  getModifierQuantityFromFormik
} from '@local/do-secundo-modifier-price-helpers'
import styles from './ModifierGroup.module.css'
import {
  ModifierValue,
  ModifierFormValueTypes
} from '@local/do-secundo-modifier-form-helpers'

interface Props {
  modifierGroup: ModifierGroupModel
  getSelectedNames: (value: ModifierFormItemDetails) => string[]
  modifierGroupPrice?: number
  isSequencePricing?: boolean
  minPrice?: number
  defaultOptionsChargePrice?: boolean
  selectedSizeBasedPrice?: number
  readOnly: boolean
}

export const ModifierGroup: React.FC<Props> = ({
  modifierGroup,
  getSelectedNames,
  modifierGroupPrice,
  isSequencePricing = false,
  minPrice,
  defaultOptionsChargePrice,
  selectedSizeBasedPrice,
  readOnly
}) => {
  const { pushNestedModifier, recordCurrentSelections } = useNestedModifiers()
  const { values, setFieldValue, initialValues } =
    useFormikContext<ModifierFormValueTypes>()

  const {
    name,
    modifiers,
    maxSelections,
    minSelections,
    pricingMode,
    guid: groupGuid,
    isSingleSelect
  } = modifierGroup

  const getModifierFieldComponent = (modifier: Modifier) => {
    /**
     * Handler triggered when a nested modifier is clicked
     * Responsible for setting the value of that modifier and then updating its children modifiers after those have been updated
     */
    const onSelect = () => {
      if (!modifier.isSelected) {
        const shouldSetQuantity = !isSingleSelect
        const existingValue = shouldSetQuantity
          ? getIn(initialValues, modifier.valueFieldNameQuantity)
          : getIn(initialValues, modifier.valueFieldName)
        const currentValue = shouldSetQuantity
          ? getIn(values, modifier.valueFieldNameQuantity)
          : getIn(values, modifier.valueFieldName)
        const isSet =
          typeof currentValue === 'boolean' ||
          (typeof currentValue === 'string' && currentValue.length > 0) ||
          (typeof currentValue === 'number' && currentValue > 0)
        if (shouldSetQuantity) {
          modifier.setModifierQuantity(
            setFieldValue,
            isSet ? +currentValue + 1 : existingValue
          )
        } else {
          modifier.setSelected(
            setFieldValue,
            isSet ? currentValue : existingValue
          )
        }
      }

      const parentModifier = modifier.parent.parent

      if ('formikValues' in parentModifier) {
        parentModifier.formikValues = values
      }

      recordCurrentSelections(modifier.parent.parent)

      pushNestedModifier(modifier)
    }

    const SelectorComponent = isSingleSelect ? Radio : ModifierBoxInput
    const displayName = isSingleSelect ? 'Radio' : 'ModifierBoxInput'

    const Component: React.FC = (props) => {
      const componentProps = {
        onSelect: modifier.hasNested ? onSelect : undefined,
        ...props,
        // !modifier.hasNested below disables duplicate mods on nested modifiers. In the future, we will most likely create functionality to enable customization
        ...(displayName === 'Radio'
          ? {}
          : {
              isMultibox: modifier.allowsDuplicates,
              maxSelections,
              groupGuid
            })
      }

      // @ts-ignore
      return <SelectorComponent {...componentProps} />
    }

    if (modifier.hasNested) {
      Component.displayName = `NestedModifier${displayName}Field`
    }

    return Component
  }

  const modifierIsSelected = (
    formValue: string | ModifierValue[],
    itemGuid: string,
    index: number
  ) => {
    if (typeof formValue === 'string') {
      return formValue === itemGuid
    }

    return isSelected(formValue[index])
  }

  const getHasMaxSelections = (
    isSelected: boolean,
    value: string | ModifierValue[]
  ): Boolean =>
    !isSingleSelect &&
    !isSelected &&
    //@ts-ignore this won't get called if value is a string because that's single select
    getNumSelectedModifiers(value) === maxSelections

  return modifiers.length > 0 ? (
    <ModifierFieldset
      label={name}
      rules={{ minSelections, maxSelections, pricingMode }}
      name={`modifiers.${groupGuid}.value`}
      isSingleSelect={isSingleSelect}
      modifierGroupPrice={modifierGroupPrice}
      isSequencePricing={isSequencePricing}
    >
      <FieldArray
        name={groupGuid}
        render={() => (
          <div data-testid='modifier-group-body' className={styles.modifiers}>
            {modifiers.map((modifier: Modifier, index: number) => {
              const {
                itemGuid,
                price,
                name,
                hasNested,
                valueFieldName,
                valueFieldNameQuantity,
                deepFieldNameByGuid,
                isDefault
              } = modifier

              const quantity = getModifierQuantityFromFormik(
                values.modifiers[groupGuid].value,
                itemGuid
              )

              const fieldName = !isSingleSelect
                ? valueFieldNameQuantity
                : valueFieldName
              const formValue = values.modifiers[groupGuid].value
              const isSelected = modifierIsSelected(formValue, itemGuid, index)
              const hasMaxSelections = getHasMaxSelections(
                isSelected,
                formValue
              )
              const isOutOfStock = modifier.outOfStock === true
              const isDisabled = hasMaxSelections || isOutOfStock
              const nestedModifiersValues =
                values.modifiers[groupGuid].detailsByItemGuid[itemGuid]
              const nestedModifiersNames =
                hasNested && isSelected
                  ? getSelectedNames(nestedModifiersValues)
                  : []

              // OO-3648: change when OO supports substitution pricing strategy
              const priceProp = getPriceToRender({
                isDefault,
                selectedSizeBasedPrice,
                minPrice,
                price,
                defaultOptionsChargePrice,
                quantity
              })

              return (
                <Field
                  disabled={isDisabled || readOnly}
                  key={itemGuid}
                  name={fieldName}
                  value={itemGuid}
                  component={getModifierFieldComponent(modifier)}
                  data-testid={itemGuid}
                  id={deepFieldNameByGuid}
                  label={
                    <ModifierFieldLabel
                      name={name}
                      price={priceProp}
                      hasNested={hasNested}
                      outOfStock={isOutOfStock}
                      nestedModifiersNames={nestedModifiersNames}
                    />
                  }
                />
              )
            })}
          </div>
        )}
      />
    </ModifierFieldset>
  ) : null
}
