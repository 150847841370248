import React, { useEffect } from 'react'
import { SparkleIcon } from '@toasttab/buffet-pui-icons'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useCart } from '@local/do-secundo-cart-provider/src'
import { useGetCart } from '../../CartQuery/CartQuery'
import { Customer } from '../../CheckoutForm/CustomerInfo/CustomerInfo'
import { Cart } from '../../../types/cart'
import { useLoyalty } from '../../../api/loyalty'

export const LoyaltyAccrue = ({
  cart,
  customer,
  paymentType
}: {
  cart: Cart
  customer: Customer
  paymentType?: string
}) => {
  const { setWillEarnLoyalty, willEarnLoyalty } = useCart()
  const { cartTotal } = useGetCart()

  const { config } = useLoyalty(customer, cart)

  useEffect(() => {
    if (config?.enrolled && !willEarnLoyalty && paymentType === 'CREDIT') {
      // since we attach loyalty asynchronously, we will use this to show
      // the confirmation before applied loyalty appears on the order
      setWillEarnLoyalty(true)
    } else {
      setWillEarnLoyalty(false)
    }
  }, [config?.enrolled, paymentType])

  if (paymentType !== 'CREDIT' || cartTotal === 0) {
    return null
  }

  if (config?.enrolled) {
    return (
      <>
        <Alert className={'w-full mt-5'} showIcon={false}>
          <SparkleIcon size={'sm'} />
          <span className={'ml-2'}>
            You will receive loyalty points for this order!
          </span>
        </Alert>
      </>
    )
  } else {
    return null
  }
}
