import React from 'react'
import { Field } from 'formik'
import uniqBy from 'lodash/uniqBy'
import { string } from 'yup'
import { Dollars } from '@local/do-secundo-dollars'
import { Fieldset } from '@local/do-secundo-fieldset'
import { Radio } from '@local/do-secundo-form'
import { useGetCart } from '../../CartQuery/CartQuery'
import styles from './Tip.module.css'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { PreComputedTip } from '../../../types/cart'

export const Component = () => {
  const { cart } = useGetCart()
  if (!cart) return null

  const { preComputedTips } = cart
  const uniquePreComputedTips = uniqBy(preComputedTips, 'percent')
  const fieldsetLabel = 'Tip'

  return (
    <Fieldset label={fieldsetLabel} collapsable id='tip_fields'>
      <div data-testid='label-tip' className={styles.tips}>
        <div className='flex mb-4 flex-column'>
          {uniquePreComputedTips &&
            uniquePreComputedTips.map((tip, index) => (
              <Field
                component={Radio}
                style='chunky'
                label={
                  <div className='flex flex-col items-center my-2'>
                    {tip.percent === 0 ? (
                      <span className={styles.percent}>No tip</span>
                    ) : (
                      <>
                        <span className={styles.percent}>
                          {/* Want to calculate tip.perecent * 100 - The rest
                              is necessary to fix rounding precision problem */}
                          {Math.round(tip.percent * 10000) / 100}%
                        </span>
                        <span className={styles.dollars}>
                          <Dollars amount={tip.value} />
                        </span>
                      </>
                    )}
                  </div>
                }
                data-testid={'percent-tip-' + tip.percent}
                name='paymentTip'
                id={`tip_${index}`}
                key={`tip_${index}`}
                percent={tip.percent}
                value={tip.value.toFixed(2)}
                containerClassName='py-2'
              />
            ))}
        </div>
        <TextInputField
          data-testid='custom-tip'
          label='Custom Amount'
          type='text'
          name='paymentTip'
          inputMode='decimal'
          id='payment_tip'
          key='payment_tip'
        />
      </div>
    </Fieldset>
  )
}

export const getInitialValues = ({
  preComputedTips
}: {
  preComputedTips?: PreComputedTip[]
}) => {
  return {
    paymentTip:
      preComputedTips?.find((tip) => tip.isDefault)?.value.toFixed(2) ?? ''
  }
}

export const getValidationSchema = () => {
  return {
    paymentTip: string().when('paymentType', {
      is: (paymentType) => {
        const creditPaymentTypes = ['CREDIT']
        return creditPaymentTypes.includes(paymentType)
      },
      then: string()
        .trim()
        .default('')
        // TODO more robust currency validation
        .matches(/^([1-9][0-9]*|0)?(\.\d{2})?$/, 'Must be a dollar amount'),
      otherwise: string().default('')
    })
  }
}

export const getArgsForSubmit = ({ values }) => {
  return { tipAmount: parseFloat(values.paymentTip) || 0 }
}
