import { useEffect } from 'react'
import * as Sentry from '@sentry/react'

const globalWindow = window

export const useMessage = ({
  origin,
  messageHandlers = {},
  window = globalWindow
}) => {
  useEffect(() => {
    const receiveMessage = (event) => {
      if (origin !== event.origin) {
        Sentry.withScope((scope) => {
          scope.setLevel('warning')
          scope.setExtra('eventOrigin', event.origin)
          scope.setExtra('expectedOrigin', origin)

          Sentry.captureMessage('useMessage.receiveMessage: unexpected origin')
        })

        return
      }

      if (typeof event.data !== 'string') {
        // Ignoring an invalid message
        return
      }

      let messageData
      try {
        messageData = JSON.parse(event.data)
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setLevel('warning')
          // Just want the string value, don't need the whole error object.
          scope.setExtra('error', `${err}`)

          Sentry.captureMessage('useMessage.receiveMessage: JSON.parse() error')
        })

        return
      }

      const { name, data } = messageData || {}
      if (messageHandlers[name]) {
        messageHandlers[name](data)
      }
    }

    window.addEventListener('message', receiveMessage, false)
    return () => {
      window.removeEventListener('message', receiveMessage, false)
    }
  }, [messageHandlers, origin, window])
}
