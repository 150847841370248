// TODO: Investigate if we can use the es6 Symbol primitive.
//       It isn't supported by IE11 and unclear if it is polly filled

export enum AnimationStates {
  ZERO_STATE = 'zero_state',
  POP = 'pop',
  PUSH = 'push',
  ENTER_START = 'enter_start',
  LEAVE_START = 'leave_start',
  ENTER_FINISH = 'enter_finish',
  LEAVE_FINISH = 'leave_finish',
  COMPLETE = 'complete'
}
