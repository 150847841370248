import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '../Link/Link'

export const OrderingAvailableBanner = ({ menuLink }) => {
  return (
    <>
      {menuLink ? (
        <p className={'type-default'}>
          Ready to go! <br />
          <Link
            data-testid='link-to-menu-from-empty-cart'
            to={menuLink}
            style='primary'
          >
            Add items to get started
          </Link>
        </p>
      ) : (
        <p className={'type-default'}>
          Ready to go! <br /> Add items to get started
        </p>
      )}
    </>
  )
}

OrderingAvailableBanner.propTypes = {
  orderingAvailable: PropTypes.bool,
  menuLink: PropTypes.string
}
