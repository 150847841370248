import { domain, get } from '../../client/api/util'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { useQuery } from 'react-query'

export interface AdsConversionTag {
  conversionTagId: string
  eventLabel: string
  eventType: ConversionTagEventType
  conversionName: string
}

export type ConversionTagEventType = 'SUBMIT_LEAD_FORM' | 'PURCHASE'

const getAdsConversionTag = async (
  managementSetGuid: string
): Promise<AdsConversionTag[]> => {
  let r = await get(
    `https://${domain}/brands/v1/google-ads/public/conversion-tags/catering`,
    {
      headers: {
        'Toast-Management-Set-GUID': managementSetGuid
      }
    }
  )
  return await r.json()
}

export const useAdsConversionTags = () => {
  const { restaurantInfo } = useRestaurant()

  const query = useQuery<AdsConversionTag[]>(
    ['ads-conversion-tag', restaurantInfo?.managementSetGuid],
    () => getAdsConversionTag(restaurantInfo!!.managementSetGuid),
    {
      enabled: !!restaurantInfo?.managementSetGuid,
      onError: () => {}
    }
  )

  const purchaseTags = query?.data?.filter(
    (tag) => tag.eventType === 'PURCHASE'
  )

  return {
    ...query,
    data: purchaseTags && purchaseTags.length > 0 ? purchaseTags[0] : undefined
  }
}
