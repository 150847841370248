import React from 'react'

interface Props {
  address1: string
  address2?: string | null
  city: string
  state: string
  className?: string
}

export const RestaurantAddress: React.FC<Props> = ({
  address1,
  address2,
  city,
  state,
  className = 'type-default text-secondary'
}) => {
  return (
    <div className={className}>
      {address1}
      {address2 ? ` ${address2}` : ''}, {city}, {state}
    </div>
  )
}
