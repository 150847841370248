import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { toRelativeDateString } from '../../utils/time-utils'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { formatHours } from '../../utils/restaurant-schedule-helpers'

export const FutureEstimate = ({ textClass = 'text-default' }) => {
  const { fulfillmentDateTime } = useFulfillment()
  const { restaurantInfo, loading, error } = useRestaurant()

  if (loading || error) return null

  const timeZoneId = restaurantInfo?.timeZoneId
  const fulTime = fulfillmentDateTime || new Date().toISOString()

  const dt = DateTime.fromISO(fulTime)
  const date = toRelativeDateString(fulTime, timeZoneId)
  const time = formatHours(dt, timeZoneId)

  return (
    <>
      <span
        data-testid='fulfillment-scheduled-ready-date'
        className={textClass}
      >
        {date}
      </span>
      ,
      <span
        data-testid='fulfillment-scheduled-ready-time'
        className={`whitespace-nowrap ${textClass}`}
      >
        &nbsp;{time}
      </span>
    </>
  )
}

FutureEstimate.propTypes = {
  textClass: PropTypes.string
}
