import { CartOrderApprovalRuleResult } from '../../../types/cart'
import React from 'react'
import { Notification } from '@local/do-secundo-notification'
import { Dollars } from '@local/do-secundo-dollars'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'

interface Props {
  rule: CartOrderApprovalRuleResult
}

export const OrderGreaterThanRule: React.FC<Props> = ({ rule }) => {
  const { restaurantInfo } = useRestaurant()
  const restaurantName = restaurantInfo?.name

  return (
    <Notification
      severity='warning'
      isActionable={false}
      actionContent='Add to order'
    >
      {restaurantName ?? 'The restaurant'} has an order limit of{' '}
      <span data-testid='threshold-amount'>
        <Dollars amount={rule.thresholdAmount} />
      </span>
      . Please remove{' '}
      <span data-testid='required-adjustment'>
        <Dollars amount={rule.requiredAdjustment} />
      </span>{' '}
      to place your order. Have questions? Contact us!
    </Notification>
  )
}
