import { useCallback } from 'react'
import { Cart } from '../types/cart'
import { AppliedDiscount, AppliedServiceCharge, Check } from '../types/orders'

const toDollars = (number: number | undefined) =>
  number ? +(+number).toFixed(2) : 0

export const getCartQueryKey = (cartGuid: string) => {
  return ['oo-cart', cartGuid]
}

export const getCheckTotal = (cart?: Cart) => {
  return cart?.order.checks[0].totalAmount ?? 0
}

export const hasAnyCheckDiscounts = (cart: Cart) => {
  return (
    cart.order.checks.filter((c) => c.appliedDiscounts.length > 0).length > 0
  )
}

const getPreDiscountItemsSubtotal = (check: Check) => {
  const selections = check.selections || []
  return selections.reduce(
    (itemsSubtotal, currentSelection) =>
      itemsSubtotal + currentSelection.preDiscountPrice,
    0
  )
}

const getAllDiscounts = (check: Check): AppliedDiscount[] => {
  const appliedDiscounts = [...check.appliedDiscounts]

  // Item-level discounts are not currently supported for loyalty or promo
  // codes. Included here for good measure but these should remain empty.
  check.selections.forEach((sel) => {
    appliedDiscounts.push(...sel.appliedDiscounts)
  })

  return appliedDiscounts
}

export const useLineItems = ({
  cart,
  showTip,
  showTotal
}: {
  cart: Pick<Cart, 'order'>
  showTip: boolean
  showTotal: boolean
}) => {
  return useCallback(
    (prePaymentTip: number | undefined) => {
      const check = cart.order.checks[0]!!
      const displaySubTotal = getPreDiscountItemsSubtotal(check)

      const displayTax = check.taxAmount
      const displayTip = prePaymentTip || check.tipAmount || 0
      const displayTotal =
        check.totalAmount + (prePaymentTip !== undefined ? prePaymentTip : 0)

      const preTaxNonGratuityCharges = check.appliedServiceCharges.filter(
        (c) => c.taxable && !c.gratuity
      )
      const preTaxGratuityCharges = check.appliedServiceCharges.filter(
        (c) => c.taxable && c.gratuity
      )
      const postTaxNonGratuityCharges = check.appliedServiceCharges.filter(
        (c) => !c.taxable && !c.gratuity
      )
      const postTaxGratuityCharges = check.appliedServiceCharges.filter(
        (c) => !c.taxable && c.gratuity
      )

      const toDisplayCharge = (charge: AppliedServiceCharge) => ({
        label: charge.name,
        amount: toDollars(charge.chargeAmount),
        id: `service-charge-${charge.serviceCharge.guid}`
      })

      const toDiscountDisplay = (discount: AppliedDiscount) => ({
        label: discount.appliedPromoCode
          ? 'Promo code'
          : discount.loyaltyDetails
          ? 'Loyalty redemption'
          : 'Discount',
        amount: toDollars(discount.discountAmount) * -1,
        id: `discount-${discount.discount.guid}`
      })

      const totals = [
        {
          label: 'Item subtotal',
          amount: toDollars(displaySubTotal),
          id: 'subtotal-amount'
        },
        ...getAllDiscounts(check).map(toDiscountDisplay),
        ...postTaxNonGratuityCharges.map(toDisplayCharge),
        ...preTaxNonGratuityCharges.map(toDisplayCharge),
        ...preTaxGratuityCharges.map(toDisplayCharge),
        {
          label: 'Tax',
          amount: toDollars(displayTax),
          id: 'tax-amount'
        },
        ...postTaxGratuityCharges.map(toDisplayCharge),
        showTip
          ? {
              label: 'Tip',
              amount: toDollars(displayTip),
              id: 'tip-amount'
            }
          : null
      ].filter((row) => row)

      if (showTotal) {
        totals.push({
          label: 'Total',
          amount: toDollars(displayTotal),
          id: 'total-order-amount'
        })
      }

      return totals
    },
    [cart, showTip, showTotal]
  )
}

export const ITEMS_UNAVAILABLE = 'ITEMS_UNAVAILABLE'
export const MODIFICATION_ERROR_SOURCE_REORDER = 'REORDER'

const getUnavailableMessage = (items: { name: string }[] = []) => {
  const filteredItems = items.filter(({ name }) => Boolean(name))
  if (filteredItems.length > 0) {
    return filteredItems.length === 1
      ? 'The following item is no longer available to order at this time:' // only some items OOS, comes as a warning on ReorderResponse
      : 'The following items are no longer available to order at this time:'
  }

  return items.length === 1
    ? 'An item is no longer available to order at this time and has been removed from your cart'
    : 'Items are no longer available to order at this time and have been removed from your cart'
}

// In some cases we want to overwrite error messages from bff.
export const getErrorMessageByCode = ({
  items = [],
  code
}: {
  items: any[]
  code: string
}) => {
  const map: Record<string, string> = {
    NO_AVAILABLE_ITEMS:
      'Your items are no longer available to order at this time.', // all items OOS, comes as ReorderError
    [ITEMS_UNAVAILABLE]: getUnavailableMessage(items)
  }
  return map[code]
}

export const createOutOfStockError = ({ items = [] }: { items: any[] }) => ({
  items,
  message: getErrorMessageByCode({ items, code: ITEMS_UNAVAILABLE })
})
