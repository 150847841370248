import React from 'react'
import { SelectionViewModel, useEditItemInCart } from '../CartMutation'
import { useMenus } from '../../hooks/use-menus'
import {
  findItemInMenus,
  getItemQuantityRange,
  getSpecialRequest
} from '../../utils/menu-group-helpers'
import { useGetCart } from '../CartQuery/CartQuery'
import { ItemDetails, UpdateItemFormModal } from './UpdateItemFormModal'

interface Props {
  itemGuid: string
  itemGroupGuid: string
  onClose: () => void
  selectionGuid: string
}

export const EditItem: React.FC<Props> = ({
  itemGuid,
  itemGroupGuid,
  onClose,
  selectionGuid
}) => {
  const { data: cart, error: cartError, loading: cartLoading } = useGetCart()
  const {
    menus,
    error: menuError,
    loading: menuLoading,
    menuItemQuantityRules
  } = useMenus()

  const isError = !!cartError || menuError
  const isLoading = cartLoading || menuLoading

  const selection = cart?.order?.checks[0]?.selections.find(
    (sel) => sel.externalId === selectionGuid
  )
  const item = menus && findItemInMenus(menus, itemGroupGuid, itemGuid)
  const allowedQuantityRange = getItemQuantityRange(
    itemGuid,
    menuItemQuantityRules
  )

  const itemDetails: ItemDetails | undefined = React.useMemo(
    () =>
      item && selection
        ? {
            guid: selectionGuid,
            itemGuid,
            itemGroupGuid,
            specialRequest: getSpecialRequest(selection),
            quantity: selection.quantity,
            name: item.name,
            description: item.description,
            menuItemPrice: item.price || 0,
            selectionItemPrice: selection.price,
            calories: item.calories || 0,
            modifierGroups: item.modifierGroups,
            allowedQuantityRange,

            // pass to children resolvers
            imageLink: item.imageLink,
            idString: item.guid,
            selection,
            usesFractionalQuantity:
              selection.unitOfMeasure && selection.unitOfMeasure !== 'NONE',
            fractionalQuantity: {
              unitOfMeasure: selection.unitOfMeasure || 'NONE',
              quantity: selection.quantity
            }
          }
        : undefined,
    [selection]
  )

  const basePrice = itemDetails?.menuItemPrice || 0

  const editItemInCart = useEditItemInCart()
  const handleEditItem = (selection: SelectionViewModel) =>
    editItemInCart(selectionGuid, selection).then((cart) => {
      onClose()
      return cart
    })

  return (
    <UpdateItemFormModal
      updateCart={handleEditItem}
      error={isError}
      loading={isLoading}
      itemDetails={itemDetails}
      basePrice={basePrice}
      onClose={onClose}
      itemGuid={itemGuid}
      itemGroupGuid={itemGroupGuid}
      selectionGuid={selectionGuid}
      mayDelete
    />
  )
}
