import React from 'react'
import { Dollars } from '@local/do-secundo-dollars'

interface Props {
  price: number
  unitOfMeasure?: string
  usesFractionalQuantity?: boolean
  shouldRenderSchema?: boolean
  disabled?: boolean
}

export const MenuItemPrice = ({
  price,
  unitOfMeasure = '',
  usesFractionalQuantity = false,
  shouldRenderSchema,
  disabled = false
}: Props) => {
  return (
    <span
      data-testid='menu-item-price'
      className={`type-default ${disabled ? 'text-disabled' : 'text-default'}`}
      itemProp={shouldRenderSchema ? 'offers' : undefined}
      itemScope={shouldRenderSchema}
      itemType={shouldRenderSchema ? 'https://schema.org/Offer' : undefined}
    >
      <Dollars amount={price} />
      {usesFractionalQuantity && `/${unitOfMeasure.toLowerCase()}`}
    </span>
  )
}
