import React, { createContext } from 'react'
import { useMenus } from '../../hooks/use-menus'
import { CooMenu } from '../../types/menu'
interface MenuPageContextType {
  menus?: CooMenu[]
  menusError: boolean
  menusLoading: boolean
  menusRetry: () => void
}

//@ts-ignore
const MenuPageContext = createContext<MenuPageContextType>({})

export const useMenuPageContext = () => React.useContext(MenuPageContext)

export const MenuPageContextProvider: React.FC = ({ children }) => {
  const { loading, error, menus, refetch } = useMenus()

  return (
    <MenuPageContext.Provider
      value={{
        menus,
        menusLoading: loading,
        menusError: error,
        menusRetry: refetch
      }}
    >
      {children}
    </MenuPageContext.Provider>
  )
}
