import { Field } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useDiningOptions } from '@local/do-secundo-use-dining-options'
import { AddressAutocomplete } from '../../AddressAutocomplete/AddressAutocomplete'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { FulfillmentFormValues } from '../../../types/fulfillment'
import { NotInDeliveryAreaWarning } from '../../NotInDeliveryAreaWarning/NotInDeliveryAreaWarning'
import { DiningOptionFulfillmentData } from '../../../hooks/useDiningOptions'
import { Toggle } from './Toggle'
import { DeliveryIcon, PickUpIcon } from '@toasttab/buffet-pui-icons'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import {
  CustomLocationPicker,
  getDefaultCustomLocation
} from './CustomLocationPicker'

interface Props {
  values: FulfillmentFormValues
  data: DiningOptionFulfillmentData[]
  setValues: (values: FulfillmentFormValues) => void
}

export const DiningOptionSelector: React.FC<Props> = ({
  values,
  data,
  setValues
}) => {
  const {
    diningOptionBehavior,
    deliveryInfo,
    customLocationName,
    standardFulfillment
  } = values

  const { applicableConfigs } = useRestaurant()

  const customLocationsConfig = applicableConfigs.customLocations
  const fulfillmentInstructions = applicableConfigs.fulfillmentInstructions

  const toggleValue = useMemo(
    () => (diningOptionBehavior === 'DELIVERY' ? 'left' : 'right'),
    [diningOptionBehavior]
  )

  const { isLoading, error } = useDiningOptions()
  const { availability } = useAvailability()

  const [hideAddress, setHideAddress] = useState(false)

  useEffect(() => {
    if (
      diningOptionBehavior === 'DELIVERY' &&
      !standardFulfillment &&
      customLocationsConfig?.locations.filter(
        (location) => location.behavior === 'DELIVERY'
      ).length
    ) {
      setHideAddress(true)
    } else {
      setHideAddress(false)
    }
  }, [diningOptionBehavior, customLocationsConfig, standardFulfillment])

  if (isLoading || error) {
    return null
  }

  const foundTakeout = data.find((option) => option.behavior === 'TAKE_OUT')
  const foundDelivery = data.find((option) => option.behavior === 'DELIVERY')

  const showDiningOptions = Boolean(
    availability &&
      availability['TAKE_OUT'] &&
      foundTakeout &&
      availability['DELIVERY'] &&
      foundDelivery
  )

  return (
    <>
      {showDiningOptions && (
        <Toggle
          className='mb-4'
          value={toggleValue}
          onChange={(value) => {
            const behavior = value === 'DELIVERY' ? 'DELIVERY' : 'TAKE_OUT'

            const defaultCustomLocation = getDefaultCustomLocation(
              customLocationsConfig,
              behavior
            )

            setValues({
              ...values,
              diningOptionBehavior: behavior,
              customLocationName: defaultCustomLocation?.name,
              standardFulfillment: defaultCustomLocation ? false : undefined,
              deliveryInfo: defaultCustomLocation?.address
            })
          }}
          left={{
            name: 'Delivery',
            value: 'DELIVERY',
            icon: <DeliveryIcon size={'md'} accessibility='decorative' />,
            testId: 'DeliveryDiningOption'
          }}
          right={{
            name: 'Pickup',
            value: 'TAKE_OUT',
            icon: <PickUpIcon size={'md'} accessibility='decorative' />,
            testId: 'TakeoutDiningOption'
          }}
        />
      )}
      {fulfillmentInstructions && (
        <p className='p-2 bg-gray-0'>{fulfillmentInstructions}</p>
      )}
      <CustomLocationPicker
        setValues={setValues}
        values={values}
        customLocationsConfig={customLocationsConfig}
      />
      {diningOptionBehavior === 'DELIVERY' && !hideAddress && (
        <>
          <Field
            id='delivery_info'
            name='deliveryInfo'
            component={AddressAutocomplete}
          />
          <NotInDeliveryAreaWarning
            deliveryInfo={deliveryInfo}
            diningOptionBehavior={diningOptionBehavior}
            customLocationName={customLocationName}
          />
        </>
      )}
    </>
  )
}
