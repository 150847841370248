import React, { useEffect, useRef } from 'react'
import {
  Button,
  ButtonProps,
  ButtonType,
  ButtonVariant
} from '@local/do-secundo-button'
import {
  useDeliveryDistanceValidator,
  useFulfillmentDateTimeValidator
} from '../fulfillment-helpers'
import { FormikProps } from 'formik'
import { FulfillmentFormValues } from '../../../types/fulfillment'
import { useNavigate } from 'react-router-dom'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'

interface Props {
  formik: FormikProps<FulfillmentFormValues>
  editing: boolean
  noAvailableDates: boolean
}

export const FulfillmentFooter: React.FC<Props> = ({
  formik,
  editing,
  noAvailableDates
}) => {
  const { values, isSubmitting, isValid: isFulfillmentFormValid } = formik
  const {
    deliveryInfo,
    diningOptionBehavior,
    fulfillmentDate,
    fulfillmentTime,
    customLocationName,
    standardFulfillment
  } = values

  const { applicableConfigs } = useRestaurant()
  const customLocationsConfig = applicableConfigs.customLocations

  const isCustomLocationChoiceValid =
    !customLocationsConfig ||
    standardFulfillment ||
    (customLocationName &&
      customLocationsConfig.locations.filter(
        (location) => location.name == customLocationName
      ).length > 0) ||
    customLocationsConfig.locations.filter(
      (location) => location.behavior == diningOptionBehavior
    ).length == 0

  const { valid: isDeliveryDistanceValid, loading } =
    useDeliveryDistanceValidator(
      deliveryInfo,
      diningOptionBehavior,
      customLocationName
    )
  const { valid: isDateAndTimeValid } = useFulfillmentDateTimeValidator(
    diningOptionBehavior,
    fulfillmentDate,
    fulfillmentTime
  )

  const navigate = useNavigate()

  const buttonRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    buttonRef.current?.focus()
  }, [])

  const buttonProps: Partial<ButtonProps> = {
    type: ButtonType.SUBMIT,
    variant: ButtonVariant.PRIMARY,
    loading: isSubmitting || loading,
    disabled:
      !isFulfillmentFormValid ||
      !isDeliveryDistanceValid ||
      (!isDateAndTimeValid && !noAvailableDates) ||
      !isCustomLocationChoiceValid
  }
  let message = 'Start order'

  if (noAvailableDates) {
    buttonProps.type = ButtonType.BUTTON
    buttonProps.onClick = () => navigate('/')
    message = 'Go to main menu'
  } else if (editing) {
    message = 'Update'
  }

  return (
    <Button
      data-testid={'fulfillment-selector-submit'}
      ref={buttonRef}
      {...buttonProps}
    >
      {message}
    </Button>
  )
}
