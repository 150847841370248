import { useWindowSize } from '@toasttab/buffet-use-window-size'

export const useGetWindowSize = () => {
  const windowSize = useWindowSize()

  return {
    isMobile: windowSize.width <= 768,
    isDesktop: windowSize.width > 768,
    hasLimitedScreenHeight: windowSize.height < 650
  }
}
