import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Button, ButtonVariant } from '@local/do-secundo-button'

import styles from '../ConfirmPage.module.css'

export const BackToMenuButton = ({ getRestaurantPath }) => (
  <Link className={styles.backToMenu} to={getRestaurantPath()}>
    <div className={styles.backToMenuWrapper}>
      <Button data-testid='back-to-menu-button' variant={ButtonVariant.LINK}>
        Back to menu
      </Button>
    </div>
  </Link>
)

BackToMenuButton.propTypes = {
  getRestaurantPath: PropTypes.func.isRequired
}
