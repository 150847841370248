import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  AuthorizePaymentDocument,
  AuthorizePaymentInput,
  AuthorizePaymentMutation,
  AuthorizePaymentMutationVariables,
  AuthorizePaymentResponse
} from '../../apollo/generated/TakeoutWebGraphQLOperations'
import { useApolloClient } from '@apollo/client'

export const useAuthorizeCard = () => {
  const { restaurantGuid } = useRestaurant()
  const apolloClient = useApolloClient()

  const doAuthorization = (
    input: Omit<AuthorizePaymentInput, 'restaurantGuid'>
  ): Promise<AuthorizePaymentResponse | undefined> => {
    return apolloClient
      .mutate<AuthorizePaymentMutation, AuthorizePaymentMutationVariables>({
        mutation: AuthorizePaymentDocument,
        variables: { input: { restaurantGuid, ...input } }
      })
      .then((result) => {
        if (
          result.data?.authorizePayment.__typename === 'AuthorizePaymentError'
        ) {
          throw Error(result.data?.authorizePayment.message)
        }

        return result.data?.authorizePayment
      })
  }

  return {
    doAuthorization
  }
}
