import { domain, get } from './util'
import { useMutation, useQuery } from 'react-query'
import * as Sentry from '@sentry/react'
import { AppliedDiscount } from '../types/orders'
import { addLoyaltyDiscount, loyaltyInquire } from './cart'
import { Cart } from '../types/cart'
import { useEffect, useState } from 'react'
import { useDebounce } from '../hooks/util'
import { Customer } from '../components/CheckoutForm/CustomerInfo/CustomerInfo'
import { useCart } from '@local/do-secundo-cart-provider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

function validateEmail(email?: string) {
  if (!email) {
    return false
  }

  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

function validatePhone(phone?: string) {
  if (!phone) {
    return false
  }

  return phone.length >= 10
}

export const customerNotValidForLookup = (customer: Customer) => {
  return !validateEmail(customer.email) || !validatePhone(customer.phone)
}

interface LoyaltyConfig {
  enrolled: boolean
  canEnroll: boolean
}

const getLoyaltyConfig = (customer: Customer): Promise<LoyaltyConfig> => {
  return get(
    `https://${domain}/invoice-service/v1/public/loyalty/guest-lookup?email=${customer.email}&phone=${customer.phone}&first=${customer.firstName}&last=${customer.lastName}`
  ).then((response) => response.json())
}

const getCheckLoyaltyDiscounts = (cart: Cart) => {
  return cart.order.checks[0].appliedDiscounts.filter((d) => !!d.loyaltyDetails)
}

export const useLoyalty = (customer: Customer, cart: Cart | undefined) => {
  const { ooConfig } = useRestaurant()
  const { optedInTo3pAccountLookup, updateCartCache } = useCart()

  const hasLoyaltyProgram =
    ooConfig?.loyaltyVendor && ooConfig?.loyaltyVendor !== 'NONE'
  const hasThirdPartyLoyalty =
    hasLoyaltyProgram && ooConfig?.loyaltyVendor !== 'TOAST'

  // Can lookup account if loyalty is Toast, or if it is 3p then if user opted in
  const canLookupAccount = hasThirdPartyLoyalty
    ? optedInTo3pAccountLookup
    : hasLoyaltyProgram
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [saving, setIsSaving] = useState(false)
  const [loyaltyConfig, setLoyaltyConfig] = useState<LoyaltyConfig>()

  const mutationOptions = {
    onMutate: () => {
      setIsSaving(true)
      setErrorMessage(undefined)
    },
    onSuccess: async (cartResponse: Cart) => {
      updateCartCache(cartResponse)
      setIsSaving(false)
    },
    onError: async (error: Response) => {
      Sentry.withScope((scope) => {
        scope.setLevel('error')
        scope.setExtra('error', `${error}`)
        Sentry.captureMessage('loyalty error')
      })
      setErrorMessage((await error.json()).message)
      setIsSaving(false)
    }
  }

  const applyLoyaltyDiscountMutation = useMutation(
    (discount: AppliedDiscount) => addLoyaltyDiscount(cart!!.guid, discount),
    mutationOptions
  )

  const applyLoyaltyDiscount = (discount: AppliedDiscount) => {
    applyLoyaltyDiscountMutation.mutate(discount)
  }

  const [guestLookupLoading, setGuestLookupLoading] = useState(false)
  const doGuestLookup = async (customer: Customer) => {
    if (customerNotValidForLookup(customer)) {
      setLoyaltyConfig({ enrolled: false, canEnroll: false })
    } else {
      setGuestLookupLoading(true)
      await getLoyaltyConfig(customer!!)
        .then((response) => {
          setLoyaltyConfig(response)
          setGuestLookupLoading(false)
        })
        .catch((e) => {
          Sentry.withScope((scope) => {
            scope.setLevel('error')
            scope.setExtra('error', `${e}`)
            Sentry.captureMessage('failed loyalty guest lookup')
          })
          setLoyaltyConfig(undefined)
          setGuestLookupLoading(false)
        })
    }
  }

  const debouncedGuestLookup = useDebounce(doGuestLookup, 1000)

  useEffect(() => {
    if (canLookupAccount) {
      debouncedGuestLookup(customer)
    }
    // eslint-disable-next-line
  }, [customer])

  useEffect(() => {
    if (canLookupAccount) {
      doGuestLookup(customer)
    }
    // eslint-disable-next-line
  }, [canLookupAccount])

  const checkLoyaltyDiscounts = cart ? getCheckLoyaltyDiscounts(cart) : []

  const {
    data: inquiryResponse,
    refetch: refetchRewards,
    isLoading: inquireLoading
  } = useQuery(
    ['inquire', cart?.guid, cart?.order],
    () => {
      if (customerNotValidForLookup(customer)) {
        return Promise.resolve(undefined)
      } else {
        return loyaltyInquire(cart!!.guid).catch((e) =>
          Sentry.withScope((scope) => {
            scope.setLevel('error')
            scope.setExtra('error', `${e}`)
            Sentry.captureMessage('failed to inquire for loyalty redemptions')
            return undefined
          })
        )
      }
    },
    {
      enabled: cart && !!loyaltyConfig?.enrolled && canLookupAccount
    }
  )

  return {
    config: loyaltyConfig,
    inquiryResponse,
    applyLoyaltyDiscount,
    errorMessage,
    checkLoyaltyDiscounts,
    saving,
    refetchRewards,
    hasLoyaltyProgram,
    canLookupAccount,
    isLoyaltyLoading: guestLookupLoading || inquireLoading
  }
}
