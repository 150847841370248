import React from 'react'
import { BrowserRouter, Route, MemoryRouter, Routes } from 'react-router-dom'
import { OOGlobalsConsumer } from '@local/do-secundo-oo-globals-provider'
import { RestaurantPage } from '../RestaurantPage/RestaurantPage'
import { RestaurantProvider } from '@local/do-secundo-restaurant-provider'
import { ServiceAvailabilityProvider } from '../ServiceAvailabilityProvider/ServiceAvaialbilityProvider'
import AllPagesTag from '../../../packages/GooglePlixels/AllPagesTag'

export const OnlineOrderingApp: React.FC<{ useMemoryRouter: boolean }> = ({
  useMemoryRouter = false
}) => {
  const startPath = window['CI_START_PATH'] || '/'

  const content = (
    <Routes>
      <Route path='/menu/:fastLinkName/*' element={<RestaurantPage />} />
      <Route path='/*' element={<RestaurantPage />} />
    </Routes>
  )

  return (
    <OOGlobalsConsumer>
      {({ restaurantGuid, routerBasename }) => (
        <ServiceAvailabilityProvider>
          <RestaurantProvider restaurantGuid={restaurantGuid}>
            <>
              <AllPagesTag />
              {!useMemoryRouter && (
                <BrowserRouter basename={routerBasename}>
                  {content}
                </BrowserRouter>
              )}
              {useMemoryRouter && (
                <MemoryRouter initialEntries={[startPath]} initialIndex={0}>
                  {content}
                </MemoryRouter>
              )}
            </>
          </RestaurantProvider>
        </ServiceAvailabilityProvider>
      )}
    </OOGlobalsConsumer>
  )
}
