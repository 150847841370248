import React from 'react'
import { CustomerCareDisplayIcon } from '@toasttab/buffet-pui-icons'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { Button } from '@toasttab/buffet-pui-buttons'
import { getReplateLink } from '../../api/restaurant'

const REPLATE_LOCATIONS = [
  'SAN FRANCISCO, CA',
  'LOS ANGELES, CA',
  'MANHATTAN, NY',
  'BROOKLYN, NY',
  'AUSTIN, TX',
  'CHICAGO, IL',
  'SEATTLE, WA',
  'DENVER, CO'
]

const replateEnabledForCity = (location: { city?: string; state?: string }) => {
  const { city, state } = location
  if (!city || !state) return false
  return REPLATE_LOCATIONS.includes(`${city}, ${state}`.toUpperCase())
}

interface Props {
  restaurantGuid: string
  orderGuid: string
  location: {
    city?: string
    state?: string
  }
}

export const ReplateBanner = ({
  restaurantGuid,
  orderGuid,
  location
}: Props) => {
  const isFeatureEnabled = useFlag(FF.NVS_CATERING_REPLATE_LINK)
  if (!isFeatureEnabled || !replateEnabledForCity(location)) return null

  return (
    <div className='p-10 m-2 mb-8 rounded-sm bg-info-0'>
      <CustomerCareDisplayIcon />
      <div className='mt-2 mb-4 font-bold text-primary-75 type-headline-5'>
        Connect your organization to communities facing food insecurity with
        Replate
      </div>
      <div className='font-bold text-primary-75 type-default'>
        What is Replate?
      </div>
      <div className='pb-4 type-subhead'>
        Replate is a food recovery nonprofit that redirects your surplus food to
        communities facing food insecurity. Replate accepts individually
        packaged meals and unopened catering trays.
      </div>
      <Button
        as='a'
        href={getReplateLink(restaurantGuid, orderGuid)}
        target='_blank'
        rel='noreferrer'
      >
        Learn more
      </Button>
    </div>
  )
}
