import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CooMenu, CooMenuGroup } from '../../../types/menu'
import { getAllSubgroups } from '../../../utils/menu-group-helpers'

export interface RefMenuGroup extends CooMenuGroup {
  ref: React.RefObject<HTMLDivElement>
}

export interface RefMenu extends Omit<CooMenu, 'groups'> {
  ref: React.RefObject<HTMLDivElement>
  groups: RefMenuGroup[]
}

export const useMenuNav = (menus?: CooMenu[]) => {
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0)
  const [scrolledMenuIndex, setScrolledMenuIndex] = useState(0)
  const [scrolledGroupGuid, setScrolledGroupGuid] = useState<string>()
  const [selectedGroupGuid, setSelectedGroupGuid] = useState<string>()

  const menuRefs: RefMenu[] = useMemo(
    () =>
      menus
        ? menus.map((menu) => ({
            ...menu,
            ref: React.createRef<HTMLDivElement>(),
            groups: (
              Object.values(getAllSubgroups(menu))
                .filter((group) => group.items.length)
                .map((group) => ({
                  ...group,
                  ref: React.createRef<HTMLDivElement>(),
                  guid: group.guid || group.name,
                  id: group.guid || group.name
                })) || []
            ).sort((a, b) => {
              if (a.enabled && !b.enabled) return -1
              if (!a.enabled && b.enabled) return 1
              return 0
            })
          }))
        : [],
    [menus]
  )

  return {
    selectedMenuIndex,
    setSelectedMenuIndex,
    scrolledMenuIndex,
    setScrolledMenuIndex,
    scrolledGroupGuid,
    setScrolledGroupGuid,
    selectedGroupGuid,
    setSelectedGroupGuid,
    menuRefs
  }
}

export const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
  if (typeof window !== 'undefined' && !!ref.current) {
    const yOffset = 150 // Accounts for height of sticky components
    window.scrollTo({
      top:
        window.scrollY +
        (ref.current.getBoundingClientRect().top || 0) -
        yOffset,
      behavior: 'smooth'
    })
    // preventScroll here is so that it does not stop the smooth scroll above
    ref.current.focus({ preventScroll: true })
  }
}

export enum ScrollDirection {
  Forwards = 1,
  Backwards = -1
}

export const useHorizontalScroll = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)

  const scroll = useCallback(
    (direction: ScrollDirection) => () => {
      const ref = scrollContainerRef.current
      if (ref) {
        const position = ref.scrollLeft
        const maxScroll = ref.scrollWidth - ref.getBoundingClientRect().width
        const scrollDistance =
          direction === ScrollDirection.Backwards
            ? Math.min(position, 200)
            : Math.min(maxScroll - position, 200)

        scrollContainerRef.current.scrollBy({
          left: scrollDistance * direction,
          behavior: 'smooth'
        })
      }
    },
    [scrollContainerRef]
  )

  const scrollEvent = useCallback(() => {
    if (scrollContainerRef.current) {
      const ref = scrollContainerRef.current
      const position = ref.scrollLeft
      if (position === 0) {
        setShowLeftArrow(false)
      } else {
        setShowLeftArrow(true)
      }

      if (
        Math.abs(
          position - (ref.scrollWidth - ref.getBoundingClientRect().width)
        ) <= 1
      ) {
        setShowRightArrow(false)
      } else {
        setShowRightArrow(true)
      }
    }
  }, [scrollContainerRef])

  useEffect(() => {
    window.addEventListener('resize', scrollEvent)
    return () => {
      window.removeEventListener('resize', scrollEvent)
    }
  }, [scrollEvent])

  return {
    scroll,
    scrollEvent,
    scrollContainerRef,
    showLeftArrow,
    showRightArrow
  }
}
