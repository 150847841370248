import { useDebounce } from '../../hooks/util'
import { LinkIcon } from '@toasttab/buffet-pui-icons'
import { TextInput, TextInputProps } from '@toasttab/buffet-pui-text-input'
import React, { useState } from 'react'
import { useClipboard } from 'use-clipboard-copy'
import cx from 'classnames'
import { Button } from '@local/do-secundo-button'

export interface CopyableLinkProps extends TextInputProps {
  url: string
}

export const CopyableLink: React.FC<CopyableLinkProps> = ({
  url,
  ...props
}) => {
  const { copied, onCopy } = useCopy(url)

  return (
    <div>
      <span className={'font-semibold text-default'}>URL</span>
      <TextInput
        containerClassName={'rounded-full'}
        className={'mx-1'}
        transparentBackground
        {...props}
        readOnly
        value={url}
        onClick={onCopy}
        suffix={<CopyURLButton copied={copied} onCopy={onCopy} />}
        suffixVariant={'iconButton'}
      />
    </div>
  )
}

const CopyURLButton = ({
  copied,
  onCopy
}: {
  copied: boolean
  onCopy: () => void
  testId?: string
  cropToIcon?: boolean
  disabled?: boolean
  disabledMessage?: string
}) => {
  return (
    <Button onClick={onCopy} className={'pr-2'}>
      <>
        <LinkIcon aria-label={'copy-url'} />
        <span className={cx('pl-1', { 'pr-3': !copied })}>
          {copied ? 'Copied' : 'Copy'}
        </span>
      </>
    </Button>
  )
}

const useCopy = (url: string) => {
  const clipboard = useClipboard()

  const [copied, setCopied] = useState(false)
  const debouncedReset = useDebounce(() => setCopied(false), 2000)

  const onCopy = () => {
    clipboard.copy(url)
    setCopied(true)
    debouncedReset()
  }

  return { copied, onCopy }
}
