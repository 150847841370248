import React from 'react'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { FulfillmentDisplay } from '../FulfillmentDisplay/FulfillmentDisplay'
import { useModeHistoryPushCallback } from '../ModeRouter/ModeRouter'

export const FulfillmentSelector: React.FC = () => {
  const { orderingAvailable } = useAvailability()

  const navToFulfillment = useModeHistoryPushCallback({
    mode: 'fulfillment'
  })

  return (
    <>
      <button
        className={orderingAvailable ? 'cursor-pointer' : 'pointer-events-none'}
        id='editFulfillment'
        data-testid='edit-fulfillment'
        disabled={!orderingAvailable}
        onClick={navToFulfillment}
        type='button'
      >
        <FulfillmentDisplay
          editable={orderingAvailable}
          inline
          showDeliveryAddress={true}
          showPickupAddress={true}
        />
      </button>
    </>
  )
}
