import { useEffect } from 'react'
import { useCart } from '@local/do-secundo-cart-provider'
import { useGetCart } from '../components/CartQuery/CartQuery'

export const useValidateCartOnMount = () => {
  const { cartGuid } = useCart()
  const { refetch } = useGetCart()
  useEffect(() => {
    if (cartGuid) {
      refetch()
    }
  }, [cartGuid, refetch])
}
