import * as React from 'react'

import { Dollars } from '@local/do-secundo-dollars'

import styles from './ItemPrice.module.css'

export const ItemPrice = ({ preDiscountPrice, price }) => {
  const renderDiscount = preDiscountPrice !== price

  if (renderDiscount) {
    return (
      <>
        <td data-testid='discount-item-price' className={styles.number}>
          <span data-testid='pre-discount-price' className={styles.crossed}>
            <Dollars amount={preDiscountPrice} />
          </span>{' '}
          <span data-testid='discount-price' className={styles.primary}>
            <Dollars amount={price} />
          </span>
        </td>
      </>
    )
  }
  return (
    <>
      <td data-testid='item-price' className={styles.number}>
        <Dollars amount={preDiscountPrice} />
      </td>
    </>
  )
}
