import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { onEnterOrSpace } from '@local/do-secundo-key-handler'
import { getStyle } from '@local/do-secundo-style-helpers'

import styles from './Radio.module.css'

const radioStyles = Object.freeze({
  DEFAULT: 'default',
  TAB: 'tab',
  CHUNKY: 'chunky',
  SVG: 'svg'
})

const Radio = ({
  field,
  id,
  value,
  label,
  form,
  disabled,
  style = radioStyles.DEFAULT,
  onSelect,
  containerClassName = '',
  ...props
}) => {
  const selectedStyle = getStyle(style, radioStyles)
  return (
    <div
      data-testid='radio-input-container'
      className={cx(styles[`${selectedStyle}InputGroup`], selectedStyle)}
    >
      <input
        data-testid='radio-input-control'
        name={field.name}
        id={id}
        type='radio'
        value={value}
        checked={field.value === value}
        onClick={onSelect}
        onChange={(...a) => {
          if (onSelect) {
            onSelect()
            return
          }

          form.setFieldTouched(field.name, true)
          field.onChange(...a)
        }}
        onBlur={field.onBlur}
        disabled={disabled}
        {...props}
        className={styles[`${selectedStyle}Radio`]}
      />
      <label
        data-testid={`radio-label-${id}`}
        className={styles[`${selectedStyle}Label`]}
        htmlFor={id}
        tabIndex='0'
        onKeyDown={onEnterOrSpace(() => {
          if (!disabled) {
            if (onSelect) {
              onSelect()
              return
            }
            form.setFieldValue(field.name, value)
          }
        })}
      >
        {label}
      </label>
    </div>
  )
}

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

Radio.propTypes = {
  field: FieldShape.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  style: PropTypes.string,
  onSelect: PropTypes.func
}

export { Radio, radioStyles }
