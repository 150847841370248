import {
  CooModifier,
  CooModifierGroup,
  DoPricingMode
} from '../../../client/types/menu'
import { MenuItemDetails } from './MenuItemDetails'
import { Model } from './Model'
import { Modifier } from './Modifier'

export class ModifierGroup extends Model {
  modifiers: Modifier[]
  parent: Modifier | MenuItemDetails
  selectedGuid: string
  name: string
  maxSelections: number | undefined
  minSelections: number | undefined
  pricingMode: DoPricingMode
  guid: string
  defaultOptionsChargePrice: boolean

  constructor(
    modifierGroup: CooModifierGroup,
    parent: Modifier | MenuItemDetails
  ) {
    super(modifierGroup)
    this.modifiers = this.mapModifiers(modifierGroup.options || [])
    this.parent = parent
    this.selectedGuid = ''

    this.name = modifierGroup.name
    this.maxSelections = modifierGroup.maxSelections
    this.minSelections = modifierGroup.minSelections
    this.pricingMode = modifierGroup.pricingMode
    this.guid = modifierGroup.guid
    this.defaultOptionsChargePrice = modifierGroup.defaultOptionsChargePrice
  }

  mapModifiers(modifiers: CooModifier[]) {
    return modifiers.map((modifier, i) => new Modifier(modifier, this, i))
  }

  getNextValues() {
    return this.modifiers
  }

  resetDefaults() {}

  /**
   * @returns {boolean} True if the constraints only allow one selection in the group.
   */
  get isSingleSelect() {
    return this.maxSelections === 1 && this.minSelections === 1
  }

  get fieldName() {
    return `modifiers.${this.guid}`
  }

  /**
   * @returns {string} The formik key for this group
   */
  get valueFieldName() {
    return `${this.fieldName}.value`
  }

  /**
   * @returns {Modifier|null} If the parent is a modifier return it else
   *   return null
   */
  get parentModifier(): Modifier | null {
    if (this.parent instanceof Modifier) {
      return this.parent
    }

    return null
  }
}
