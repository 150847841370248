import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import { ToastNotification } from './ToastNotification/ToastNotification'
import { Cart } from '../../types/cart'
import { SelectionViewModel } from '../CartMutation'
import { GetModifierGroups } from '@local/do-secundo-modifier-form-helpers'

export const useHandleSubmit = ({
  itemGuid,
  itemGroupGuid,
  getModifierGroups,
  updateCart,
  setError
}: {
  itemGuid: string
  itemGroupGuid: string
  getModifierGroups: GetModifierGroups
  updateCart: (selection: SelectionViewModel) => Promise<Cart>
  setError: (error: any) => void
}) => {
  return useCallback(
    async (values, { setSubmitting }) => {
      const { specialInstructions, quantity } = values

      const selection = {
        itemGuid,
        itemGroupGuid,
        quantity,
        modifierGroups: getModifierGroups(values, quantity),
        specialInstructions
      }

      setSubmitting(true)
      await updateCart(selection)
        .then(() => {
          const totalQuantity = quantity
          const text = `${totalQuantity} item${
            totalQuantity > 1 ? 's' : ''
          } added`
          toast(<ToastNotification checkmark>{text}</ToastNotification>)
        })
        .catch(async (e: Response) => setError(await e.json()))
        .finally(() => {
          setSubmitting(false)
        })
    },
    [itemGuid, itemGroupGuid, getModifierGroups, updateCart, setError]
  )
}
