import { DateTime } from 'luxon'
import {
  DiningOptionFulfillmentData,
  DINING_OPTION_STRINGS
} from '@local/do-secundo-use-dining-options'
import { Cart, DiningOptionBehavior } from '../../types/cart'
import { DeliveryInfo } from '../../types/orders'
import { getDateString, isSameISODay } from '../../utils/time-utils'
import { FulfillmentStateData } from '../UseUpdateFulfillment/UseUpdateFulfillment'
import { useQuery } from 'react-query'
import { getDeliveryAddressValid } from '../../api/validation'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useDiningOptions } from '../../hooks/useDiningOptions'
import { formatHours } from '../../utils/restaurant-schedule-helpers'

export const getDatesAndTimesFromResponse = (
  data: DiningOptionFulfillmentData[],
  selectedDate: string | undefined,
  diningOptionBehavior?: DiningOptionBehavior
) => {
  if (data.length < 1 || !diningOptionBehavior) return {}
  let found =
    data.find((option) => option.behavior === diningOptionBehavior) || data[0]

  const dates =
    found?.futureSchedule.dates.filter(
      ({ timesAndGaps = [] }) => timesAndGaps.length >= 1
    ) || []

  if (dates.length === 0) {
    return { diningOptionBehavior: found?.behavior }
  }

  const unavailableDates =
    found?.futureSchedule.dates
      .filter(({ timesAndGaps = [] }) => timesAndGaps.length === 0)
      ?.map((d) => d.date) || []

  const dateList = dates.map((d) => d.date)
  let currentDate = dates[0]
  if (selectedDate) {
    const matchingDate = dates.find(({ date }) =>
      isSameISODay(date, selectedDate)
    )
    if (matchingDate) {
      currentDate = matchingDate
    }
  }

  return {
    dateList,
    timeList: currentDate?.timesAndGaps,
    currentDate,
    diningOptionBehavior: found?.behavior,
    unavailableDates
  }
}

export const getFirstAvailableDateTime = (
  data?: DiningOptionFulfillmentData[],
  diningOptionBehavior?: DiningOptionBehavior
) => {
  const dateAndTimes = data?.find(
    (datum) => datum.behavior === diningOptionBehavior
  )?.futureSchedule.dates

  return dateAndTimes?.find((dt) => dt.times.length > 0)?.times[0].time
}

/**
 * Returns true if fulfillment time is valid and is in the future
 * @param {string} fulfillmentTime - iso date string
 * @returns {boolean}
 */
export const isFulfillmentTimeValid = (fulfillmentTime: string) => {
  if (!fulfillmentTime) {
    return false
  }

  const dateTime = DateTime.fromISO(fulfillmentTime).toUTC()
  if (dateTime.isValid) {
    return dateTime.toMillis() > DateTime.utc().toMillis()
  }
  return false
}

export const normalizeFulfillment = (
  fulfillmentData: FulfillmentStateData
): FulfillmentStateData => {
  const isDelivery = fulfillmentData.diningOptionBehavior === 'DELIVERY'
  const deliveryInfo = isDelivery ? fulfillmentData.deliveryInfo : undefined

  return {
    ...fulfillmentData,
    deliveryInfo
  }
}

export const normalizeFulfillmentFromCart = ({
  fulfillmentDateTime,
  diningOptionBehavior,
  order
}: Pick<
  Cart,
  'fulfillmentDateTime' | 'diningOptionBehavior' | 'order'
>): FulfillmentStateData => {
  return normalizeFulfillment({
    fulfillmentDateTime,
    diningOptionBehavior,
    deliveryInfo: order.deliveryInfo
  })
}

const hasLatLng = (latitude?: number, longitude?: number) =>
  Boolean(typeof latitude === 'number' && typeof longitude === 'number')

const hasAddressComponents = (deliveryInfo?: DeliveryInfo) => {
  if (!deliveryInfo) return false

  const { address1, address2, city, state, zipCode, latitude, longitude } =
    deliveryInfo

  return Boolean(
    typeof address1 === 'string' &&
      (address2 === null ||
        address2 === undefined ||
        typeof address2 === 'string') &&
      typeof city === 'string' &&
      typeof state === 'string' &&
      typeof zipCode === 'string' &&
      hasLatLng(latitude, longitude)
  )
}

export const useFulfillmentDateTimeValidator = (
  diningOptionBehavior?: DiningOptionBehavior,
  fulfillmentDate?: string,
  fulfillmentTime?: string
) => {
  const { data } = useDiningOptions()
  const { restaurantInfo } = useRestaurant()

  const timeZoneId = restaurantInfo?.timeZoneId

  if (!data || !timeZoneId) {
    return {
      valid: true,
      message: undefined
    }
  }

  const { unavailableDates, timeList } = getDatesAndTimesFromResponse(
    data,
    fulfillmentDate,
    diningOptionBehavior
  )

  const dateUnavailable =
    unavailableDates &&
    fulfillmentDate &&
    unavailableDates!!.includes(fulfillmentDate.split('T')[0])

  const formattedDate = fulfillmentDate && getDateString(fulfillmentDate)
  const formattedBehavior =
    diningOptionBehavior === 'DELIVERY' ? 'Delivery' : 'Pickup'

  if (dateUnavailable) {
    return {
      valid: false,
      message: `${formattedBehavior} on ${formattedDate} is unavailable. Please select a new date.`
    }
  }

  const timeValid =
    timeList &&
    fulfillmentTime &&
    timeList.map((t) => t.time).includes(fulfillmentTime)

  if (!timeValid) {
    const formattedTime = formatHours(fulfillmentTime, timeZoneId, false)

    return {
      valid: false,
      message: `${formattedBehavior} at ${formattedTime} is unavailable on ${formattedDate}. Please select a new time.`
    }
  }

  return {
    valid: true,
    message: undefined
  }
}

export const useDeliveryDistanceValidator = (
  address?: DeliveryInfo,
  diningOptionBehavior?: DiningOptionBehavior,
  customLocationName?: string
) => {
  const { restaurantGuid } = useRestaurant()
  const { availability } = useAvailability()

  const skip =
    customLocationName ||
    diningOptionBehavior !== 'DELIVERY' ||
    !hasAddressComponents(address)

  const { data, isLoading: loading } = useQuery(
    ['validate-delivery', address?.address1],
    () =>
      getDeliveryAddressValid(restaurantGuid, {
        address: address!!
      }),
    {
      enabled: !skip
    }
  )

  if (skip) return { valid: true, loading }
  if (loading) return { valid: true, loading }

  const valid = Boolean(data?.valid)
  const takeoutEnabled = Boolean(availability && availability['TAKE_OUT'])
  const message = valid
    ? undefined
    : `You’re out of range for delivery. To place an order, choose a different delivery address${
        takeoutEnabled ? ' or switch to Pickup.' : '.'
      }`

  return { valid, message, loading }
}

export const getFormattedDiningOption = (
  diningOptionBehavior: DiningOptionBehavior
) => {
  return DINING_OPTION_STRINGS[diningOptionBehavior] || 'Pickup'
}
