import PropTypes from 'prop-types'

// Several files reference these prop-type shapes.
// TODO: Once all files referencing these prop-type declarations are gone, delete these
const FormShape = PropTypes.shape({
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func
})

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

export { FormShape, FieldShape }
