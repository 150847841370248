import { useOOGlobals } from '@local/do-secundo-oo-globals-provider'

// In a separate file so we can mock "useFlag"
// in tests
export const useFlag = (name, defaultValue = false) => {
  const { enabledFeatureFlags } = useOOGlobals()
  if (
    Array.isArray(enabledFeatureFlags) &&
    enabledFeatureFlags.includes(name)
  ) {
    return true
  }
  return defaultValue
}

export const useFlags = (names, defaultValue = false) => {
  const { enabledFeatureFlags } = useOOGlobals()

  if (!Array.isArray(names)) {
    return false
  }

  return names.every((name) => {
    if (
      Array.isArray(enabledFeatureFlags) &&
      enabledFeatureFlags.includes(name)
    ) {
      return true
    }
    return defaultValue
  })
}

export const useAtLeastOneFlag = (names, defaultValue = false) => {
  const { enabledFeatureFlags } = useOOGlobals()

  if (!Array.isArray(names)) {
    return false
  }

  return names.some((name) => {
    if (
      Array.isArray(enabledFeatureFlags) &&
      enabledFeatureFlags.includes(name)
    ) {
      return true
    }
    return defaultValue
  })
}
