import { DateTime } from 'luxon'
import capitalize from 'lodash/capitalize'
import { parseISO } from 'date-fns'
import { utcToZonedTime } from '@toasttab/buffet-pui-date-utilities'

export const getRxDateFromISO = (
  isoStr?: string | Date,
  timezoneId?: string
) => {
  if (!isoStr) return undefined
  if (typeof isoStr === 'object') return utcToZonedTime(isoStr, timezoneId!!)
  return utcToZonedTime(isoStr, timezoneId!!)
}

export const isSameISODay = (first?: string, second?: string) => {
  if (!first || !second) {
    return false
  }
  const firstDate = DateTime.fromISO(first)
  const secondDate = DateTime.fromISO(second)
  return firstDate.hasSame(secondDate, 'day')
}

export const isSameISOTime = (first?: string, second?: string) => {
  if (!first || !second) {
    return false
  }
  const firstTime = DateTime.fromISO(first)
  const secondTime = DateTime.fromISO(second)
  return (
    firstTime.hour === secondTime.hour && firstTime.minute === secondTime.minute
  )
}

export const isISODateTimeEqual = (a: string, b: string) =>
  a === b || +DateTime.fromISO(a) === +DateTime.fromISO(b)

export const toRelativeDateString = (isoDate: string, timezone: string) => {
  const dateTime = DateTime.fromISO(isoDate, { zone: timezone })
  const diffDays = dateTime
    .startOf('day')
    .diff(DateTime.local().startOf('day'), 'days')
    .toObject().days

  if (diffDays === 0) return 'Today'
  if (diffDays === 1) return 'Tomorrow'
  return capitalize(
    dateTime.toLocaleString({
      weekday: 'short',
      month: 'numeric',
      day: 'numeric'
    })
  )
}

export const toShortDateString = (isoDate: string, timezone?: string) => {
  const dateTime = DateTime.fromISO(isoDate, { zone: timezone })
  return capitalize(
    dateTime.toLocaleString({
      month: 'numeric',
      day: 'numeric'
    })
  )
}

export const getCurrentLocalTime = (timeZoneId: string) =>
  DateTime.local().setZone(timeZoneId).toISO()

export const getCurrentWeekday = (timeZoneId: string) =>
  DateTime.local().setZone(timeZoneId).weekdayLong

export const getDateString = (isoDate: string) => {
  const dateTime = DateTime.fromISO(isoDate)
  return capitalize(
    dateTime.toLocaleString({
      month: 'numeric',
      day: 'numeric'
    })
  )
}

/*
 * Gets the day code corresponding to the daysOfWeek array
 * from @toasttab/buffet-pui-date-utilities
 * Namely, the Date object's Sunday value is 0 but the
 * daysOfWeek Sunday value is 7.
 */
export const getDayCode = (date: Date): number => {
  const dayVal = date.getDay()
  return dayVal === 0 ? 7 : dayVal
}

export const isDateInRange = (
  dateToCheck: Date,
  startDateStr: string,
  endDateStr: string
) => {
  const startDate = parseISO(startDateStr)
  const endDate = parseISO(endDateStr)
  endDate.setDate(endDate.getDate() + 1)
  return dateToCheck >= startDate && dateToCheck < endDate
}
