import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from '@local/do-secundo-form'

import styles from './Textarea.module.css'

export const Textarea = (props) => {
  const {
    instructions,
    id,
    field,
    form,
    label,
    placeholder,
    disabled,
    rows,
    maxLength
  } = props
  const { errors, touched } = form
  const { name } = field
  const textAreaProps = { placeholder, disabled, maxLength, rows }
  const error = errors[name] && touched[name]
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <textarea
        className={styles.textInput}
        {...field}
        id={id}
        {...textAreaProps}
      />
      {error ? (
        <div data-testid='error-message'>
          <ErrorMessage name={name} />
        </div>
      ) : (
        <div data-testid='instructions'>{instructions}</div>
      )}
    </>
  )
}

Textarea.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  instructions: PropTypes.node,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
}
