import React, { useState, useCallback, useMemo, useRef } from 'react'
import { Field, Formik } from 'formik'
import { useHandleSubmit } from './modifier-helpers'
import { useModifierFormHelpers } from '@local/do-secundo-use-modifier-form-helpers'
import { Modal } from '@local/do-secundo-modal'
import { useAvailability } from '@local/do-secundo-availability-provider'
import {
  ModifierBody,
  NestedModifiersProvider,
  ModifiersFooter,
  RemoveItemButton
} from '@local/do-secundo-modifiers'
import { ItemQuantity } from '@local/do-secundo-itemquantity'
import { PanelStack } from '@local/do-secundo-panel-stack'
import { PanelProvider } from '@local/do-secundo-panel-stack'
import { MenuItemDetails } from '@local/do-secundo-model'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { ItemProvider } from '@local/do-secundo-item-provider'
import { Selection, UnitOfMeasure } from '../../types/orders'
import { Cart } from '../../types/cart'
import { SelectionViewModel, useHandleDelete } from '../CartMutation'
import { CooModifierGroup } from '../../types/menu'
import { ErrorComponent } from '@local/do-secundo-error'
import { ModifiersLoader } from './Modifiers.loader'
import { QuantityRange } from '../../types/config'
import { MenuItemModal } from './MenuItemModal'

export interface ItemDetails {
  guid?: string
  itemGuid: string
  itemGroupGuid: string
  specialRequest?: string
  quantity?: number
  name: string
  description: string
  menuItemPrice: number
  selectionItemPrice?: number
  calories: number
  modifierGroups: CooModifierGroup[]
  allowedQuantityRange?: QuantityRange

  // pass to children resolvers
  imageLink: string
  idString?: string
  selection?: Selection
  usesFractionalQuantity?: boolean
  fractionalQuantity?: {
    unitOfMeasure: UnitOfMeasure
    quantity: number
  }
}

interface Props {
  updateCart: (selection: SelectionViewModel) => Promise<Cart>
  error: boolean
  loading: boolean
  itemDetails?: ItemDetails
  basePrice: number
  onClose: () => void
  itemGuid: string
  itemGroupGuid: string
  selectionGuid?: string
  mayDelete: boolean
}

export const UpdateItemFormModal: React.FC<Props> = ({
  updateCart,
  error,
  loading,
  itemDetails: itemDetailsData,
  basePrice,
  onClose,
  itemGuid,
  itemGroupGuid,
  selectionGuid,
  mayDelete
}) => {
  const { ooConfig } = useRestaurant()
  const { orderingAvailable } = useAvailability()

  const [modalError, setModalError] = useState(null)

  const itemDetails = useMemo(
    () => (itemDetailsData ? new MenuItemDetails(itemDetailsData) : null),
    [itemDetailsData]
  )
  const itemQuantityRange = itemDetailsData?.allowedQuantityRange
  const validateAtItemLevel = !ooConfig?.minRuleAtCartLevel

  const { getComputedPrice, getModifierGroups, formikProps, getSelectedNames } =
    useModifierFormHelpers({
      itemDetails,
      itemGroupGuid,
      itemQuantityRange,
      enforceMinRequirement: validateAtItemLevel
    })

  const handleSubmit = useHandleSubmit({
    itemGuid,
    itemGroupGuid,
    getModifierGroups,
    updateCart,
    setError: setModalError
  })

  const handleDelete = useHandleDelete({
    selectionGuid,
    setError: setModalError
  })

  const getDisplayPrice = useCallback(
    (values) => {
      if (isNaN(values.quantity)) return undefined
      return (getComputedPrice(values) + basePrice) * values.quantity
    },
    [basePrice, getComputedPrice]
  )

  const modalBodyRef = useRef<HTMLDivElement>(null)

  if (loading) {
    return (
      <Modal onClose={onClose}>
        <ModifiersLoader />
      </Modal>
    )
  }

  if (!loading && !ooConfig) {
    return (
      <Modal onClose={onClose}>
        <ErrorComponent isActionable={false} />
      </Modal>
    )
  }

  if (!itemDetails) {
    return null
  }

  const ModifierBodyComponent = (
    <ModifierBody
      itemDetails={itemDetails}
      specialRequestsConfig={{
        enabled: ooConfig?.allowSpecialRequests,
        placeholderMessage: ooConfig?.specialRequestsMessage
      }}
      getSelectedNames={getSelectedNames}
      quantityInputField={null}
      deleteButton={
        mayDelete ? <RemoveItemButton handleDelete={handleDelete} /> : null
      }
      disabled={!orderingAvailable}
    />
  )

  const ModifierFooterComponent = (
    <ModifiersFooter
      getDisplayPrice={getDisplayPrice}
      onClose={onClose}
      isEditModal={mayDelete}
      quantityRange={itemQuantityRange}
      itemName={itemDetails.name}
      quantityInputField={
        <Field
          name='quantity'
          range={itemQuantityRange}
          component={ItemQuantity}
          disableLessThanMin={validateAtItemLevel}
        />
      }
      validationSchema={formikProps?.validationSchema}
    />
  )

  return (
    <Formik {...formikProps} onSubmit={handleSubmit}>
      <PanelProvider>
        <ItemProvider>
          <NestedModifiersProvider>
            <MenuItemModal
              onClose={onClose}
              menuItemDetails={itemDetails}
              modalBodyRef={modalBodyRef}
              error={error || modalError}
              footer={ModifierFooterComponent}
            >
              {modalBodyRef.current ? (
                // Don't render the panel stack until the modalBodyRef is populated
                // to avoid a NPE. Since a ref change doesn't trigger a component
                // re-render, this only works since we trigger a network request
                // whenever the modal opens, which causes the re-render.
                <PanelStack panelBody={modalBodyRef.current}>
                  {ModifierBodyComponent}
                </PanelStack>
              ) : (
                ModifierBodyComponent
              )}
            </MenuItemModal>
          </NestedModifiersProvider>
        </ItemProvider>
      </PanelProvider>
    </Formik>
  )
}
