import React from 'react'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  brandingPublicAssetURL,
  restaurantPublicAssetUrl
} from '../../api/util'

const stripLeadingSlash = (str: string) => {
  if (str.charAt(0) === '/') return str.substring(1)
  return str
}

export const getImageUrl = (publicAssetUrl: string, src?: string) => {
  if (!src) return undefined

  let isUrl = true
  try {
    new URL(src)
  } catch (_) {
    isUrl = false
  }

  return isUrl ? src : `${publicAssetUrl}${stripLeadingSlash(src)}`
}

export const RestaurantLogo = ({ className }: { className?: string }) => {
  const {
    applicableConfigs: { brandingConfig },
    restaurantInfo,
    loading,
    error
  } = useRestaurant()

  if (loading || error) return null

  let logoUrl: string | undefined
  if (brandingConfig) {
    logoUrl = getImageUrl(brandingPublicAssetURL, brandingConfig.logo)
  } else {
    logoUrl = restaurantInfo?.imagePath
      ? getImageUrl(restaurantPublicAssetUrl, restaurantInfo?.imagePath) +
        '?size=small'
      : undefined
  }

  if (!logoUrl) return <></>

  return (
    <img
      data-testid='RestaurantLogo'
      className={className}
      src={logoUrl}
      alt={restaurantInfo?.name}
    />
  )
}
