export const formatPhoneNumber = (phone: number | string): string | null => {
  if (typeof phone === 'number') phone = String(phone)
  if (typeof phone === 'string' && phone.length === 10) {
    return `(${phone.substring(0, 3)}) ${phone.substring(
      3,
      6
    )}-${phone.substring(6)}`
  }
  return null
}
