import { LoyaltyRedemption } from './LoyaltyRedemption'
import { LoyaltyEnroll } from './LoyaltyEnroll'
import { Customer } from './CustomerInfo/CustomerInfo'
import { Cart } from '../../types/cart'
import React from 'react'
import { customerNotValidForLookup, useLoyalty } from '../../api/loyalty'
import { Fieldset } from '@local/do-secundo-fieldset'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useCart } from '@local/do-secundo-cart-provider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useFormikContext } from 'formik'
import { InfoTooltip, TooltipTextLayout } from '@toasttab/buffet-pui-tooltip'
import { formatPhoneNumber } from '../../utils/phone-number'

export const LoyaltyAccountLookup = ({
  cart,
  customer
}: {
  cart: Cart
  customer: Customer
}) => {
  const { restaurantInfo } = useRestaurant()

  const { optedInTo3pAccountLookup, setOptedInTo3pAccountLookup } = useCart()
  const { hasLoyaltyProgram, canLookupAccount, config, isLoyaltyLoading } =
    useLoyalty(customer, cart)

  const { setFieldValue } = useFormikContext()

  if (!hasLoyaltyProgram || !restaurantInfo?.name) {
    return null
  }

  if (optedInTo3pAccountLookup && !isLoyaltyLoading && !config?.enrolled) {
    return (
      <Fieldset label={'Rewards'} collapsable collapsed={false}>
        <div className='py-2 space-y-1'>
          <TooltipTextLayout
            className='font-semibold text-error'
            helperIconButton={
              <InfoTooltip placement='auto'>
                <div className='type-subhead'>
                  {`Email: ${customer.email}`}
                  <br />
                  {`Phone: ${formatPhoneNumber(customer.phone)}`}
                </div>
              </InfoTooltip>
            }
          >
            No loyalty account found
          </TooltipTextLayout>
          <div className='type-subhead text-default'>
            {`Please confirm that the email address or phone number of your 
          ${restaurantInfo.name} rewards program matches the information you entered above. 
          If not, create a new rewards account or update the information on
          your existing one.`}
          </div>
        </div>
      </Fieldset>
    )
  }

  if (canLookupAccount) {
    return (
      <Fieldset label={'Rewards'} collapsable collapsed={false}>
        <LoyaltyRedemption cart={cart} customer={customer} />
        <LoyaltyEnroll cart={cart} customer={customer} />
      </Fieldset>
    )
  }

  return (
    <Fieldset label={'Rewards'} collapsable collapsed={false}>
      <div className='py-2'>
        <p className={'font-semibold text-color-default'}>Get rewarded!</p>
        <p className={'type-subhead'}>
          Lookup your account to earn points on this order and apply the
          discounts you've earned.
        </p>
        <Button
          testId='lookup-loyalty-button'
          variant='secondary'
          onClick={() => {
            setFieldValue('optedInToLoyaltyLookup', true)
            setOptedInTo3pAccountLookup(true)
          }}
          className='my-4 w-52'
          disabled={customerNotValidForLookup(customer)}
        >
          Lookup My Rewards
        </Button>
        <p
          className={'type-caption'}
          data-testid='legal-links'
          role='group'
          aria-label='legal information'
        >
          By looking up rewards, you give Toast permission to share your contact
          information with this restaurant's rewards provider with the sole
          purpose of looking up your account.
        </p>
      </div>
    </Fieldset>
  )
}
