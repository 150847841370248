import { DiningOptionBehavior } from '../types/cart'
import { EventType } from '../types/form'
import { domain, get } from './util'

export const getEventTypeForBehavior = (
  restaurantGuid: string,
  behavior: DiningOptionBehavior
): Promise<EventType> => {
  return get(
    `https://${domain}/catering/v1/public/forms?diningOptionBehavior=${behavior}`
  ).then((r) => r.json())
}
