import React, { useEffect } from 'react'
import { Button, ButtonVariant } from '@local/do-secundo-button'
import { Modal } from '@local/do-secundo-modal'
import Warning from '../../assets/warning-outline.svg'
import styles from './MenuPage.error.module.css'
import { formatPhoneNumber } from '../../utils/phone-number'
import { logError } from '@local/do-secundo-error'
import { RestaurantInfo } from '../../types/config'

interface Props {
  retry: () => void
  restaurantInfo?: RestaurantInfo
  error?: any
}

export const MenuPageError = ({ retry, restaurantInfo, error }: Props) => {
  useEffect(() => {
    logError(error, (scope) => scope.setTag('Origin', 'Menu Page'))
  }, [error])
  const phoneNumber = restaurantInfo?.address?.phone
  return (
    <div className={styles.error}>
      <Modal responsive={false}>
        <div className={styles.modal}>
          <Warning />
          <h1 className={styles.header}>Unable to load restaurant page</h1>
          <p className={styles.text}>
            Our system is having an issue loading the restaurant page right now.
            Please try refreshing the page in a few minutes, or contact the
            restaurant to order
            {phoneNumber && (
              <span>
                {' '}
                by calling{' '}
                <a href={`tel:${phoneNumber}`}>
                  {formatPhoneNumber(phoneNumber)}
                </a>
              </span>
            )}
            .
          </p>
          <p className={styles.text}>
            If you’re outside the US or on a remote VPN, the site will not load.
          </p>
          <Button
            onClick={retry}
            variant={ButtonVariant.LINK_WIDE}
            data-testid='retry-button'
          >
            Try again
          </Button>
        </div>
      </Modal>
    </div>
  )
}
