import { useCart } from '@local/do-secundo-cart-provider'
import { useCallback, useReducer } from 'react'
import { createCart, updateFulfillment } from '../../api/cart'
import { Cart, DiningOptionBehavior } from '../../types/cart'
import { DeliveryInfo } from '../../types/orders'
import { normalizeFulfillmentFromCart } from '../FulfillmentSelectorModal/fulfillment-helpers'
import isEqual from 'lodash/isEqual'
import { isISODateTimeEqual } from '../../utils/time-utils'

const needsUpdate = (a: FulfillmentStateData, b: FulfillmentStateData) => {
  return (
    a.diningOptionBehavior !== b.diningOptionBehavior ||
    !isEqual(a.deliveryInfo, b.deliveryInfo) ||
    !isISODateTimeEqual(a.fulfillmentDateTime, b.fulfillmentDateTime) ||
    a.customLocationName !== b.customLocationName ||
    a.standardFulfillment !== b.standardFulfillment
  )
}

interface FulfillmentState {
  data?: FulfillmentStateData
  error?: Error
  loading: boolean
}

export interface FulfillmentStateData {
  fulfillmentDateTime: string
  diningOptionBehavior: DiningOptionBehavior
  deliveryInfo?: DeliveryInfo
  customLocationName?: string
  standardFulfillment?: boolean
}

export const useUpdateFulfillment = () => {
  const { cartGuid, updateCartCache } = useCart()

  const [fulfillmentState, setFulfillmentState] = useReducer(
    (_: FulfillmentState, { data, error, loading }: FulfillmentState) => ({
      data,
      error,
      loading
    }),
    {
      data: undefined,
      error: undefined,
      loading: false
    }
  )

  const mutation = useCallback(
    async (
      prev: Cart | undefined,
      next: FulfillmentStateData,
      fastLinkName: string | undefined
    ) => {
      const currentFulfillment = prev
        ? normalizeFulfillmentFromCart(prev)
        : undefined
      if (currentFulfillment && !needsUpdate(currentFulfillment, next)) {
        setFulfillmentState({
          data: next,
          loading: false
        })

        return { data: next }
      }

      setFulfillmentState({
        data: currentFulfillment,
        loading: true
      })

      try {
        let cart: Cart
        if (!cartGuid) {
          cart = await createCart({
            fulfillment: {
              diningOptionBehavior: next.diningOptionBehavior,
              fulfillmentDate: next.fulfillmentDateTime,
              deliveryInfo: next.deliveryInfo,
              customLocationName: next.customLocationName,
              standardFulfillment: next.standardFulfillment,
              fastLinkName: fastLinkName
            },
            selections: [],
            metadata: { fastLinkName: fastLinkName }
          })
        } else {
          cart = await updateFulfillment(cartGuid, {
            diningOptionBehavior: next.diningOptionBehavior,
            fulfillmentDate: next.fulfillmentDateTime,
            deliveryInfo: next.deliveryInfo,
            customLocationName: next.customLocationName,
            standardFulfillment: next.standardFulfillment,
            fastLinkName: fastLinkName
          })
        }

        updateCartCache(cart)
        const data = normalizeFulfillmentFromCart(cart)

        setFulfillmentState({ data, loading: false })
        return { data, error: undefined }
      } catch (error: any) {
        setFulfillmentState({ error, loading: false })
        return { data: undefined, error }
      }
    },
    [cartGuid, setFulfillmentState]
  )

  return { updateFulfillment: mutation, fulfillmentState }
}
