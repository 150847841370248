import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Anchor } from '../Anchor/Anchor'
import { Drawer } from '../Drawer/Drawer'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { GiftCardLinks } from './GiftCardLinks/GiftCardLinks'
import { NavigationDrawerHeader } from './NavigationDrawerHeader/NavigationDrawerHeader'
import styles from './NavigationDrawer.module.css'
import { Link } from 'react-router-dom'

export const NavigationDrawer = ({ onClose }) => {
  return (
    <Drawer
      header={<NavigationDrawerHeader onClose={onClose} />}
      onClose={onClose}
      footer={
        <a
          href='https://www.toasttab.com/local'
          aria-label='Toast discover page'
          className='w-full text-center'
        >
          <PoweredByToast className={styles.poweredBy} />
        </a>
      }
    >
      <ul className={styles.list}>
        <li>
          <Link
            className={cx(styles.listItem, 'type-default')}
            to={'/'}
            onClick={onClose}
          >
            Home
          </Link>
        </li>
        <GiftCardLinks />
        <li>
          <Anchor
            data-testid='nav-drawer-link-privacy'
            className={cx(styles.listItem, 'type-default')}
            href='https://pos.toasttab.com/privacy'
            target='_blank'
          >
            Privacy Statement
          </Anchor>
        </li>
        <li>
          <Anchor
            data-testid='nav-drawer-link-terms'
            className={cx(styles.listItem, 'type-default')}
            href='https://pos.toasttab.com/terms-of-service/#diner-tos'
            target='_blank'
          >
            Diner Terms of Service
          </Anchor>
        </li>
      </ul>
    </Drawer>
  )
}

NavigationDrawer.propTypes = {
  onClose: PropTypes.func.isRequired
}
