import React, { useEffect } from 'react'
import cx from 'classnames'
import { MenuItems } from '../MenuItems/MenuItems'
import { MoreInfo } from '@local/do-secundo-more-info'
import styles from './MenuGroups.module.css'
import { RefMenuGroup } from '../MenuNav/util'
import { ViewableMonitor } from '../../ViewableMonitor/ViewableMonitor'
import { MenuFutureAvailability } from '../MenuFutureAvailability'

interface MenuGroupsProps {
  menuGroups: RefMenuGroup[]
  menuName: string
  menuDisabled: boolean
  orderingAvailable: boolean
  fulfillmentSelected: boolean
  onMenuVisible: (groupGuid: string) => void
}

interface MenuGroupInnerProps
  extends Omit<MenuGroupsProps, 'menuGroups' | 'menuName'> {
  group: RefMenuGroup
  isIntersecting: boolean
}

const MenuGroupsInner = ({
  group,
  menuDisabled,
  orderingAvailable,
  fulfillmentSelected,
  onMenuVisible,
  isIntersecting
}: MenuGroupInnerProps) => {
  const { guid, name, description, items, ref } = group

  useEffect(() => {
    if (isIntersecting) {
      onMenuVisible(group.guid)
    }
  }, [isIntersecting, group.guid, onMenuVisible])

  return (
    <li
      data-testid='menu-groups'
      key={guid}
      role='group'
      aria-label={`${name} menu`}
    >
      <h3
        ref={ref}
        data-testid='menu-group-name'
        className={cx(
          styles.groupLabel,
          'type-large bold',
          'text-coo-primary-text'
        )}
        title={name}
      >
        {name}
      </h3>
      <div className={styles.description}>
        <MoreInfo text={description} />
      </div>
      <MenuFutureAvailability
        futureAvailability={group.futureAvailability}
        containerClassName='pb-3 -mt-2'
      />
      <MenuItems
        items={items}
        itemGroupGuid={guid}
        menuDisabled={menuDisabled || !group.enabled}
        orderingAvailable={orderingAvailable}
        fulfillmentSelected={fulfillmentSelected}
      />
    </li>
  )
}

export const MenuGroups: React.FC<MenuGroupsProps> = (props) => {
  const { menuGroups, menuName } = props
  return (
    <ul className={styles.menuGroup}>
      {menuName && <meta itemProp='name' content={menuName} />}
      {menuGroups.map((group) => {
        if (group.items.length === 0) {
          return null
        }
        return (
          <ViewableMonitor rootMargin={'-45% 0px -55% 0px'} key={group.guid}>
            {(isIntersecting) => (
              <MenuGroupsInner
                group={group}
                {...props}
                isIntersecting={isIntersecting}
              />
            )}
          </ViewableMonitor>
        )
      })}
    </ul>
  )
}
