import React, { useEffect } from 'react'

/**
 * Scrolls window/provided HTMlElement to the top
 */
export const ScrollToTop: React.FC<{
  el?: { scrollTo: (x: number, y: number) => void }
}> = ({ el = window }) => {
  useEffect(() => {
    el.scrollTo(0, 0)
  }, [el])

  return null
}
