import React, { useCallback } from 'react'
import { Button } from '@local/do-secundo-button'
import styles from './RemoveItemButton.module.css'
import { useFormik } from '@local/do-secundo-formik-provider'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useNestedModifiers } from '../NestedModifiersProvider/NestedModifiersProvider'

interface Props {
  handleDelete: () => Promise<void>
}

export const RemoveItemButton: React.FC<Props> = ({ handleDelete }) => {
  const { modalLoading, setModalLoading } = useNestedModifiers()
  const { isSubmitting } = useFormik()
  const { loading: availabilityLoading } = useAvailability()

  const handleClick = useCallback(async () => {
    setModalLoading(true)
    try {
      await handleDelete()
    } finally {
      setModalLoading(false)
    }
  }, [setModalLoading, handleDelete])

  const isDisabled = modalLoading || isSubmitting || availabilityLoading

  return (
    <div className={styles.delete}>
      <Button
        data-testid='modifier-remove-item-button'
        onClick={handleClick}
        disabled={isDisabled}
      >
        Remove item
      </Button>
    </div>
  )
}
