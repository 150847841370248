import React, { useState, useRef, useCallback, useEffect } from 'react'
import styles from './Spotlight.module.css'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { AnnouncementIcon } from '@toasttab/buffet-pui-icons'
import { useGetWindowSize } from '@local/do-secundo-use-get-window-size'
import { SpotlightConfig } from '../../../types/config'
import { useRedirectToFulfillment } from '../../../hooks/useRedirectToFullfilment'

const formatUrl = (url: string) => {
  if (url.startsWith('https://')) {
    return url
  } else if (url.startsWith('http://')) {
    return url.replace('http://', 'https://')
  } else {
    return `https://${url}`
  }
}

interface Props extends SpotlightConfig {
  showModal: boolean
  setShowModal: (modalShown: boolean) => void
}

export const Spotlight = ({
  enabled,
  headerText,
  bodyText,
  url,
  modalOpenByDefault,
  showModal,
  setShowModal
}: Props) => {
  const { shouldShowFulfillmentModal, redirectToFulfillment } =
    useRedirectToFulfillment()

  const [didClickToClose, setDidClickToClose] = useState(false)

  const openModal = () => setShowModal(true)
  const closeModal = () => {
    if (modalOpenByDefault && !didClickToClose && shouldShowFulfillmentModal) {
      setDidClickToClose(true)
      redirectToFulfillment()
    }
    setShowModal(false)
  }

  const [isOverflowing, setIsOverflowing] = useState(showModal)
  const containerRef = useRef(null)

  const setOverflowingOnResize = useCallback(() => {
    if (containerRef.current) {
      const { clientWidth, scrollWidth } = containerRef.current
      setIsOverflowing(scrollWidth > clientWidth)
    }
  }, [containerRef])

  useEffect(() => {
    setOverflowingOnResize()
    window.addEventListener('resize', setOverflowingOnResize)
    return () => window.removeEventListener('resize', setOverflowingOnResize)
  }, [setOverflowingOnResize, containerRef])

  const { isMobile } = useGetWindowSize()

  if (!enabled || !headerText) return null

  return (
    <>
      <header className={styles.spotlight} role='banner'>
        <AnnouncementIcon accessibility='decorative' />
        <p
          data-testid='spotlight-header-content'
          className={styles.text}
          ref={containerRef}
        >
          {headerText}
        </p>
        {(bodyText || isOverflowing) && (
          <button
            data-testid='SpotlightMoreButton'
            className={styles.moreLink}
            onClick={openModal}
          >
            More
          </button>
        )}
      </header>
      <Modal
        testId='SpotlightModal'
        position={isMobile ? 'pin-bottom' : 'pin-center'}
        size='xl'
        isOpen={showModal}
        onRequestClose={closeModal}
      >
        <ModalHeader />
        <ModalBody className={styles.modalBody}>
          <p className={styles.header}>{headerText}</p>
          <p className={styles.body}>{bodyText}</p>
          {url && (
            <a
              className='text-link w-full text-center break-all mb-4'
              target='_blank'
              rel='noopener noreferrer'
              href={url}
            >
              {formatUrl(url)}
            </a>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}
