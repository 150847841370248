import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  AddressLookup,
  mapboxPublicToken,
  Address
} from '@toasttab/buffet-patterns-address-lookup'
import { DeliveryInfo } from '../../types/orders'
import { TextInput } from '@toasttab/buffet-pui-text-input'

const deliveryInfoToAddress = (
  deliveryInfo?: DeliveryInfo
): Address | undefined => {
  if (!deliveryInfo) return undefined
  return {
    ...deliveryInfo,
    stateCode: deliveryInfo?.state,
    country: 'US'
  }
}

const addressToDeliveryInfo = (address: Address): DeliveryInfo => {
  return {
    ...address,
    state: address?.stateCode
  }
}

const addressToString = (address?: Address | null) => {
  if (!address) return ''
  const { address1, city, stateCode, zipCode } = address
  return `${address1}, ${city}, ${stateCode} ${zipCode}`
}

export const AddressAutocomplete = ({ field, form }) => {
  const { loading, error, restaurantInfo } = useRestaurant()

  const address = restaurantInfo?.address

  const setValue = useCallback(
    (value = {}) => {
      form.setFieldValue(field.name, addressToDeliveryInfo(value))
    },
    [field.name, form]
  )

  if (loading || error) return null

  return (
    <>
      <AddressLookup
        testId='AddressAutocomplete'
        containerClassName='pb-3'
        countries='us'
        lookupServiceAccessToken={mapboxPublicToken}
        label='Address'
        placeholder='Enter your delivery address'
        required={true}
        includePOI={true}
        proximity={
          address?.latitude !== undefined &&
          address?.longitude !== undefined &&
          address?.latitude !== null &&
          address?.longitude !== null
            ? { latitude: address.latitude, longitude: address.longitude }
            : undefined
        }
        onChange={setValue}
        value={deliveryInfoToAddress(field.value)}
        valueToString={addressToString}
      />
      <TextInput
        containerClassName='mb-4'
        aria-label='Address line 2'
        onChange={(event) => {
          form.setFieldValue(field.name, {
            ...field.value,
            address2: event.target.value
          })
        }}
        placeholder={'Apt, suite, floor (optional)'}
        value={field.value?.address2}
      />
    </>
  )
}

const FormShape = PropTypes.shape({
  setFieldValue: PropTypes.func
})

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.object
})

AddressAutocomplete.propTypes = {
  field: FieldShape.isRequired,
  form: FormShape.isRequired
}
