import { onError } from '@apollo/client/link/error'
import { logError } from '@local/do-secundo-error'

export const getErrorLink = (config) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let { message, locations, path } of graphQLErrors) {
        // https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-error#retrying-failed-requests

        logError(
          `GraphQL error: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
    }

    if (networkError) {
      logError(`Network error: ${networkError}`)
    }
  })
