import PropTypes from 'prop-types'
import { useFlag, useFlags, useAtLeastOneFlag } from './use-flag'
import { FF } from './flags'

const isValueTrue = (value) => value === true
const isValueFalse = (value) => value === false

export { useFlag, useFlags, useAtLeastOneFlag, FF }

const getFlagComponent =
  ({ testFlagValue }) =>
  ({ name, defaultValue, children }) => {
    const value = useFlag(name, defaultValue)
    return testFlagValue(value) && children
  }

export const FlagOn = getFlagComponent({
  testFlagValue: isValueTrue
})

FlagOn.displayName = 'FlagOn'
FlagOn.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export const FlagOff = getFlagComponent({
  testFlagValue: isValueFalse
})

FlagOff.displayName = 'FlagOff'
FlagOff.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  children: PropTypes.node.isRequired
}

const getFlagWrapperComponent =
  ({ testFlagValue }) =>
  ({ name, defaultValue, wrapper, children }) => {
    const value = useFlag(name, defaultValue)
    if (value === null) return null
    if (testFlagValue(value)) {
      return wrapper(children)
    }
    return typeof children === 'function' ? children() : children
  }

export const WrapIfFlagOn = getFlagWrapperComponent({
  testFlagValue: isValueTrue
})

WrapIfFlagOn.displayName = 'WrapIfFlagOn'
WrapIfFlagOn.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  wrapper: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
}

export const WrapIfFlagOff = getFlagWrapperComponent({
  testFlagValue: isValueFalse
})

WrapIfFlagOff.displayName = 'WrapIfFlagOff'
WrapIfFlagOff.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  wrapper: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}
