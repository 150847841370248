import { DiningOptionBehavior } from './cart'
import { DeliveryInfo } from './orders'
import { DaysOfWeek, ScheduleInterval } from './config'

export enum FutureFulfillmentType {
  ServiceGap = 'FutureFulfillmentServiceGap',
  Time = 'FutureFulfillmentTime'
}

export interface FutureFulfillmentSlot {
  type: FutureFulfillmentType
  time?: string
  description?: string
}

export interface FutureScheduleDate {
  date: string
  times: FutureFulfillmentSlot[]
  timesAndGaps: FutureFulfillmentSlot[]
}

export interface FutureFulfillmentDateApi {
  date: string
  times: FutureFulfillmentSlotApi[]
}

export interface FutureFulfillmentSlotApi {
  time?: string
  serviceGap: boolean
}

export interface DiningOptionFulfillmentScheduleApi {
  deliveryProvider?: string
  diningOptionBehavior: DiningOptionBehavior
  diningOptionGuid: string
  futureFulfillment: {
    fulfillmentDates: FutureFulfillmentDateApi[]
  }
}

export interface MenuFulfillmentResponse {
  fulfillment: DiningOptionFulfillmentScheduleApi[]
  displayName: string | undefined
  availability: Availability | undefined
  independent: boolean
  fastLinkNotFound: boolean | undefined
}

export interface Availability {
  [behavior: string]: AvailabilityEntry
}

export interface AvailabilityEntry {
  availableDates: ScheduleInterval[]
  availableDaysOfWeek: DaysOfWeek[]
}

export interface FulfillmentFormValues {
  diningOptionBehavior?: DiningOptionBehavior
  fulfillmentDate?: string
  fulfillmentTime?: string
  deliveryInfo?: DeliveryInfo
  savedAddressGuid?: any
  savedAddresses?: any
  fastLinkName?: string
  customLocationName?: string
  standardFulfillment?: boolean
}
