import React from 'react'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { useNavigate } from 'react-router-dom'
import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'
import { Button, ButtonVariant } from '@local/do-secundo-button'

export const FastLinkNotFoundModal = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate()

  const onRequestClose = () => {
    navigate('/', { replace: true })
    onClose()
  }
  const { fastLinkName } = useFulfillment()

  if (!fastLinkName) {
    return null
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
    >
      <ModalHeader>
        <div className={'text-center w-full'}>
          Sorry! It looks like '{fastLinkName}' isn't available.
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={'pt-4 pb-1'}>
          <Button variant={ButtonVariant.PRIMARY} onClick={onRequestClose}>
            Main menu
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
