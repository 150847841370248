import React from 'react'
import { CartButton } from '@local/do-secundo-cart-button'
import { Dollars } from '@local/do-secundo-dollars'
import { ErrorComponent } from '@local/do-secundo-error'
import { useGetCart } from '../CartQuery/CartQuery'
import { EmptyCart } from '../EmptyCart/EmptyCart'
import { CartValidation, useCartValidator } from './Validation/CartValidation'
import { CartLoader } from './Cart.loader'
import { CartFooter } from './CartFooter/CartFooter'
import { CartModificationError } from './CartModificationError/CartModificationError'
import { CartTable } from './CartTable/CartTable'
import styles from './Cart.module.css'
import { ValidateCart } from '../ValidateCart/ValidateCart'

interface Props {
  checkoutLink: string
  orderingAvailable: boolean
  menuLink?: string
}

export const Cart: React.FC<Props> = ({
  checkoutLink,
  orderingAvailable,
  menuLink
}) => {
  const {
    error,
    loading,
    refetch,
    cart,
    cartTotal,
    cartIsEmpty,
    numberOfSelections
  } = useGetCart()
  const { cartValid } = useCartValidator(cart)

  const checkoutButtonEnabled = orderingAvailable && cartValid

  if (loading) {
    return <CartLoader />
  }

  if (error) {
    return <ErrorComponent error={error} retry={refetch} isActionable />
  }

  return (
    <>
      <CartModificationError />
      {cartIsEmpty && (
        <EmptyCart menuLink={menuLink} orderingAvailable={orderingAvailable} />
      )}
      {cart && !cartIsEmpty && (
        <>
          <div className={styles.long}>
            {<ValidateCart />}
            <CartTable cart={cart} editable />
          </div>
          <div className={styles.sticky}>
            <CartFooter cart={cart} />
            <CartValidation cart={cart} />
            <CartButton
              data-testid='cart-checkout-button'
              to={checkoutLink}
              left={<span>Checkout ({numberOfSelections})</span>}
              right={<Dollars testId='cart-total' amount={cartTotal} />}
              loading={false}
              disabled={!checkoutButtonEnabled}
            />
          </div>
        </>
      )}
    </>
  )
}
