import React from 'react'
import { CheckboxField } from '@toasttab/buffet-pui-forms'
import { useLoyalty } from '../../api/loyalty'
import { Customer } from './CustomerInfo/CustomerInfo'
import { Cart } from '../../types/cart'

export const LoyaltyEnroll = ({
  cart,
  customer
}: {
  cart: Cart
  customer: Customer
}) => {
  const { config } = useLoyalty(customer, cart)

  if (config?.canEnroll) {
    return (
      <div className={'mt-5'}>
        <div className={'ml-2'}>
          <CheckboxField
            label={'Enroll in loyalty program to start earning rewards'}
            name={'loyaltyEnroll'}
          />
        </div>
      </div>
    )
  } else {
    return <></>
  }
}
