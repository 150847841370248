import React from 'react'

import { Stepper } from '@local/do-secundo-stepper'
import styles from './ItemQuantity.module.css'
import { QuantityRange } from '../../../../client/types/config'

interface Props {
  field: { name: string; value: number }
  form: { setFieldValue: (name: string, value: number) => void }
  range?: QuantityRange
  disableLessThanMin: boolean
}

export const ItemQuantity: React.FC<Props> = ({
  field,
  form,
  range,
  disableLessThanMin
}) => {
  const { setFieldValue } = form
  const { value, name } = field

  const min = disableLessThanMin && range?.minimum ? range.minimum : 1
  const max = range?.maximum ?? Infinity

  const decrementDisabled = isNaN(value) || value <= min
  const incrementDisabled = isNaN(value) || value >= max

  return (
    <div data-testid='item-quantity' className={styles.wrapper}>
      <Stepper
        onChange={(value: number) => setFieldValue(name, value)}
        value={value}
        incrementDisabled={incrementDisabled}
        decrementDisabled={decrementDisabled}
        dataTestId={'item-quantity'}
      />
      <div />
    </div>
  )
}
