import { LoyaltyDisplayIcon } from '@toasttab/buffet-pui-icons'
import React from 'react'
import { Check } from '../../types/orders'
import { useCart } from '@local/do-secundo-cart-provider/src'

export const LoyaltyConfirmation = ({ check }: { check?: Check }) => {
  const { willEarnLoyalty } = useCart()

  if (
    (willEarnLoyalty || check?.appliedLoyaltyInfo) &&
    check?.paymentStatus !== 'OPEN' &&
    (check?.totalAmount ?? 0) > 0
  ) {
    return (
      <div className={'text-center mt-5'}>
        <LoyaltyDisplayIcon />
        <div className={'text-primary-75 type-headline-5 font-bold mb-4 mt-2'}>
          You earned points with this order!
        </div>
        <div className={'pb-2'}>Check your loyalty account for details</div>
      </div>
    )
  } else {
    return <></>
  }
}
