import { Label } from '@toasttab/buffet-pui-dropdowns'
import React from 'react'

export const RewardsCard = ({
  title,
  subtitle
}: {
  title: string | undefined
  subtitle: string | undefined
}) => {
  return (
    <>
      <div className={'grid grid-cols-2 w-full pl-2'}>
        <div className={'text-left'}>
          <Label>{title}</Label>
          {subtitle && <div>{subtitle}</div>}
        </div>
        <div
          className={'text-right text-primary-75 font-semibold block mt-3 pr-4'}
        >
          Redeem
        </div>
      </div>
    </>
  )
}
