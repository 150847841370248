import React from 'react'
import { FormFieldType } from '../../types/form'
import {
  TextInputField,
  TextareaField,
  SelectField,
  TimePickerField,
  RadioGroupField,
  SelectMultipleField,
  NumberInputField
} from '@toasttab/buffet-pui-forms'

interface FormFieldProps {
  type: FormFieldType
  options?: string[]
  label: string
  name: string
  isRequired: boolean
}

export const RenderFormField = ({
  type,
  options,
  label,
  name,
  isRequired
}: FormFieldProps) => {
  switch (type) {
    case 'TEXT':
      return (
        <TextInputField
          testId={`field-${name}`}
          id={name}
          label={label}
          name={name}
          type='text'
          required={isRequired}
        />
      )
    case 'TEXT_AREA':
      return (
        <TextareaField
          testId={`field-${name}`}
          id={name}
          label={label}
          name={name}
          required={isRequired}
        />
      )
    case 'YES_NO':
      return (
        <RadioGroupField
          testId={`field-${name}`}
          name={name}
          label={label}
          required={isRequired}
          itemsContainerClassName='ml-4'
          options={[
            {
              label: 'Yes',
              value: 'Yes'
            },
            {
              label: 'No',
              value: 'No'
            }
          ]}
        />
      )
    case 'SELECT':
      if (!options) {
        return null
      }

      return (
        <SelectField
          testId={`field-${name}`}
          id={name}
          name={name}
          label={label}
          options={options}
          required={isRequired}
        />
      )
    case 'TIME':
      return (
        <TimePickerField
          testId={`field-${name}`}
          containerClassName='max-w-xs'
          name={name}
          label={label}
          required={isRequired}
        />
      )

    case 'MULTI_SELECT':
      if (!options) {
        return null
      }

      return (
        <SelectMultipleField
          testId={`field-${name}`}
          name={name}
          label={label}
          options={options}
          placeholder={'Select'}
          required={isRequired}
        />
      )
    case 'GUEST_COUNT':
      return (
        <NumberInputField
          testId={`field-${name}`}
          id={name}
          label={label}
          name={name}
          maxLength={6}
          decimalScale={0}
          required={isRequired}
        />
      )
    default:
      return null
  }
}
