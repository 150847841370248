import { useEffect, useState } from 'react'

export const useResponseBody = <T>(response: Response | null | undefined) => {
  const [body, setBody] = useState<T>()

  useEffect(() => {
    if (response && !response.bodyUsed) {
      // There might be a race condition here :thinking_face:
      response.json().then((value) => setBody(value as T))
    }
  }, [response])

  return body
}
