import React from 'react'
import ContentLoader from 'react-content-loader'

export const ModifiersLoader = () => (
  <ContentLoader height={300} width={400} speed={2}>
    <rect x='10' y='13' rx='4' ry='4' width='380' height='65' />
    <rect x='20' y='110' rx='4' ry='4' width='25' height='25' />
    <rect x='70' y='110' rx='5' ry='5' width='310' height='25' />
    <rect x='20' y='150' rx='4' ry='4' width='25' height='25' />
    <rect x='70' y='150' rx='5' ry='5' width='310' height='25' />
    <rect x='20' y='190' rx='4' ry='4' width='25' height='25' />
    <rect x='70' y='190' rx='5' ry='5' width='310' height='25' />
    <rect x='20' y='230' rx='4' ry='4' width='25' height='25' />
    <rect x='70' y='230' rx='5' ry='5' width='310' height='25' />
  </ContentLoader>
)
