import { useEffect, useState } from 'react'
import { RECAPTCHA_SITE_KEY } from '../api/util'
import { FF, useFlag } from '@local/do-secundo-feature-flag'

declare global {
  interface Window {
    grecaptcha?: any
  }
}

export const useReCaptcha = () => {
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState<boolean>(false)
  const isFeatureEnabled = useFlag(FF.NVS_COO_RECAPTCHA)

  useEffect(() => {
    if (!isFeatureEnabled || isRecaptchaLoaded) return
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
    script.addEventListener('load', () => {
      setIsRecaptchaLoaded(true)
    })
    script.addEventListener('error', () => {
      setIsRecaptchaLoaded(false)
    })
    document.body.appendChild(script)
  }, [isFeatureEnabled, isRecaptchaLoaded])

  const generateReCaptchaToken = (
    action: string
  ): Promise<string | undefined> => {
    if (!isFeatureEnabled) {
      return Promise.resolve(undefined)
    }
    return new Promise((resolve, reject) => {
      if (!isRecaptchaLoaded || !window.grecaptcha) {
        return reject(new Error('Failed to load reCAPTCHA script'))
      }
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(RECAPTCHA_SITE_KEY, { action })
          .then((token: string) => {
            resolve(token)
          })
      })
    })
  }

  return { generateReCaptchaToken }
}
