import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { useOOGlobals } from '@local/do-secundo-oo-globals-provider'
import { useGetWindowSize } from '@local/do-secundo-use-get-window-size'
import { Button, ButtonVariant } from '@local/do-secundo-button'
import { useLocation } from 'react-router-dom'

export const SessionExpiredModal = () => {
  const { session } = useOOGlobals()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    let timerId: any

    if (session && session.expiresAt < Date.now() / 1000) {
      setShowModal(true)
    } else {
      timerId = setTimeout(() => {
        setShowModal(true)
      }, 1000 * 60 * 60) // 1 hour
    }

    return () => clearTimeout(timerId)
  }, [session])

  const { pathname } = useLocation()
  useEffect(() => {
    // This double checks the session token again on navigating
    // to the checkout page, in case something went wrong with
    // fetching in toasweb and we never got a token.
    if (
      pathname.includes('/checkout') &&
      (!session || session.expiresAt < Date.now() / 1000)
    ) {
      setShowModal(true)
    }
  }, [pathname])

  const { isMobile } = useGetWindowSize()

  return (
    <Modal
      size='md'
      isOpen={showModal}
      position={isMobile ? 'pin-bottom' : 'pin-center'}
    >
      <ModalHeader>
        <div className='type-large'>
          {session
            ? 'Session expired'
            : 'An error occurred loading your page session'}
        </div>
      </ModalHeader>
      <ModalBody>
        Please refresh the page to renew your session. Cart selections will not
        be lost.
        <Button
          className='mt-4'
          variant={ButtonVariant.SECONDARY}
          onClick={() => window.location.reload()}
        >
          Refresh page
        </Button>
      </ModalBody>
    </Modal>
  )
}
