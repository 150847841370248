import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import * as Sentry from '@sentry/react'

export interface ApiError extends Error {
  code?: string
  networkError?: { message: string }
  graphQLErrors?: { message: string }[]
  moreInfo?: {
    header: string
    message: string
  }
  retryMessage?: string
}

const UNKNOWN_MESSAGE = 'An unknown error occurred'

/**
 * Gets a message for display from the provided error object.
 *
 * @param {*} err Can be any object or `Error` with a `message` property.
 * `ApolloError`s will be handled specially to check for graphql or network errors.
 * @returns {string}
 */
export const getMessage = (err: ApiError | string) => {
  if (!err) {
    return UNKNOWN_MESSAGE
  }

  if (typeof err === 'string') {
    return err
  }

  if (err.graphQLErrors && err.graphQLErrors.length) {
    return err.graphQLErrors[0]?.message || UNKNOWN_MESSAGE
  }

  if (err.networkError && err.networkError.message) {
    return err.networkError.message || UNKNOWN_MESSAGE
  }

  // TODO Do we want to return `err` directly if it's a string?
  return err.message || UNKNOWN_MESSAGE
}

/**
 *
 * Gets the retry message from an error
 * This is nonstandard, and only to be used to display retries in the
 * Error component
 */
export const getRetryMessage = (err: { retryMessage?: string }) => {
  if (!err) {
    return null
  }
  return err && err.retryMessage
}

export const logError = (
  error: ApiError | string,
  beforeCapture: (_: Sentry.Scope) => void = () => { },
  context: any = null
) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(error)
  }

  const actualSentryInstance = window.sentryInstance || Sentry

  const message = getMessage(error)

  actualSentryInstance.withScope((scope) => {
    scope.setLevel('warning')
    beforeCapture(scope)
    if (error instanceof Error) {
      actualSentryInstance.captureException(error, context)
    } else {
      scope.setExtra('error', error)
      actualSentryInstance.captureMessage(message, context)
    }
  })
}

/**
 * Gets the error as an Error
 * @param {*} error
 * @returns Error
 */
export const getError = (error: Error | string | any): ApiError => {
  if (!error) return new Error(UNKNOWN_MESSAGE)
  else if (isObject(error) && error instanceof Error) {
    return error
  } else if (isPlainObject(error)) {
    const {
      code,
      message,
      networkError,
      graphQLErrors,
      moreInfo,
      retryMessage
    } = error

    const err = new Error(message) as ApiError
    err.code = code
    err.networkError = networkError
    err.graphQLErrors = graphQLErrors
    err.moreInfo = moreInfo
    err.retryMessage = retryMessage
    return err
  } else if (isString(error)) {
    return new Error(error)
  }
  return new Error(UNKNOWN_MESSAGE)
}
