import { TextInput } from '@toasttab/buffet-pui-text-input'
import React from 'react'
import { useFormikContext } from 'formik'
import { useCustomer } from '../../../api/customer'
import { Cart } from '../../../types/cart'
import { Customer } from '../CustomerInfo/CustomerInfo'
import { string } from 'yup'

export const CompanyName = ({
  customer,
  cart,
  companyName
}: {
  customer: Customer
  cart: Cart
  companyName: string | undefined
}) => {
  const { setFieldValue } = useFormikContext()
  const { onChange } = useCustomer(cart, customer)

  return (
    <TextInput
      containerClassName='mb-4'
      testId='company_name'
      id='company_name'
      label='Company'
      name='companyName'
      type='text'
      value={companyName}
      onChange={(event) => {
        setFieldValue('companyName', event.target.value)
        onChange(customer, cart, event.target.value)
      }}
    />
  )
}

export const getInitialValues = ({
  companyName
}: {
  companyName: string | undefined
}) => ({
  companyName: companyName
})

export const getValidationSchema = () => {
  return {
    companyName: string().nullable().notRequired()
  }
}
