import React, { useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { FulfillmentSelectorModal } from '../FulfillmentSelectorModal/FulfillmentSelectorModal'
import { useRestaurant } from '@local/do-secundo-restaurant-provider/src'
import { useDiningOptions } from '../../hooks/useDiningOptions'

export const ModeRouter = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const closeLocation = {
    ...location,
    search: ''
  }
  const replaceLocation = () => navigate(closeLocation, { replace: true })
  const { orderingAvailable, standardOrderingAvailable } = useAvailability()
  const { independent } = useDiningOptions()

  const routes: Record<string, React.JSX.Element> = {}

  if (orderingAvailable || (standardOrderingAvailable && !independent)) {
    routes['fulfillment'] = (
      <FulfillmentSelectorModal onClose={replaceLocation} />
    )
  }

  const search = new URLSearchParams(location.search)
  const mode = search.get('mode') as string
  return routes[mode] || null
}

export const useHasMode = (value: string) => {
  const location = useLocation()
  return new URLSearchParams(location.search).get('mode') === value
}

/**
 *
 * @param {Object} useModeHistoryPushCallbackParams
 * @param {string} useModeHistoryPushCallbackParams.mode
 * @param {import('history').LocationDescriptorObject|string|undefined} useModeHistoryPushCallbackParams.pathTo - A location object or pathname string (note: replaces search with 'mode' parameter)
 */
export const useModeHistoryPushCallback = ({
  mode,
  path
}: {
  mode: string
  path?: string
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )

  searchParams.set('mode', mode)

  const { getRestaurantPath } = useRestaurant()

  return useCallback(() => {
    navigate({
      ...{ pathname: getRestaurantPath(path) },
      search: '?' + searchParams.toString()
    })
  }, [navigate, searchParams, path, getRestaurantPath])
}
