import { ItemDetails } from '../../../client/components/Modifiers/UpdateItemFormModal'
import { CooModifierGroup } from '../../../client/types/menu'
import { Selection, UnitOfMeasure } from '../../../client/types/orders'
import {
  setDefaultModifiers,
  setModifiersFromSelection
} from '../../../client/utils/menu-group-helpers'
import { Model } from './Model'
import { ModifierGroup } from './ModifierGroup'

export class MenuItemDetails extends Model {
  description: string
  name: string
  guid?: string
  calories?: number
  price: number
  imageUrl: string
  usesFractionalQuantity: boolean
  unitOfMeasure: UnitOfMeasure
  modifierGroups: ModifierGroup[]

  constructor(menuItemDetails: ItemDetails) {
    super(menuItemDetails)
    this.description = menuItemDetails.description
    this.name = menuItemDetails.name
    this.calories = menuItemDetails.calories
    this.guid = menuItemDetails.guid
    this.price = menuItemDetails.menuItemPrice
    this.imageUrl = menuItemDetails.imageLink
    this.usesFractionalQuantity =
      menuItemDetails.usesFractionalQuantity || false
    this.unitOfMeasure =
      menuItemDetails.fractionalQuantity?.unitOfMeasure || 'NONE'
    this.modifierGroups = this.mapModifierGroups(
      menuItemDetails.modifierGroups || [],
      menuItemDetails.selection
    )
  }

  mapModifierGroups(
    cooModifierGroups: CooModifierGroup[],
    selection?: Selection
  ) {
    var modifierGroups = cooModifierGroups.map(
      (modifierGroup) => new ModifierGroup(modifierGroup, this)
    )

    if (selection) {
      setModifiersFromSelection(
        selection?.modifiers,
        modifierGroups,
        selection?.quantity
      )
    } else {
      setDefaultModifiers(modifierGroups)
    }

    return modifierGroups
  }

  getNextValues() {
    return this.modifierGroups
  }
}
