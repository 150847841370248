import React, { useEffect, useState } from 'react'
import styles from './MoreInfo.module.css'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useWindowSize } from '@toasttab/buffet-use-window-size'

const compareToLineHeight = (height, lineHeight = 20) => {
  return Math.round(height / lineHeight)
}

export const MoreInfo = ({ text }) => {
  const [overflowActive, setOverflowActive] = useState(false)
  const [numberOfLines, setNumberOfLines] = useState(0)
  const [expanded, setExpanded] = useState(false)
  const spanRef = React.useRef(null)

  const isEllipsisActive = React.useCallback((e) => {
    // iOS devices randomly add 1px, rounding here as we don't want to compare
    return (
      compareToLineHeight(e.offsetHeight) < compareToLineHeight(e.scrollHeight)
    )
  }, [])

  const windowSize = useWindowSize({})

  useEffect(() => {
    if (text) {
      isEllipsisActive(spanRef.current)
      setOverflowActive(isEllipsisActive(spanRef.current))
      const numLines = Math.round(
        parseInt(
          window
            .getComputedStyle(spanRef.current, null)
            .getPropertyValue('height')
        ) /
          parseInt(
            window
              .getComputedStyle(spanRef.current, null)
              .getPropertyValue('line-height')
          )
      )
      setNumberOfLines(numLines)
      if (numLines < 3) setExpanded(false)
    }
  }, [windowSize.width, windowSize.height, isEllipsisActive, text])

  const moreInfoStyle = expanded
    ? cx('w-11/12 type-subhead', styles.fontSize)
    : cx(
        'type-subhead w-11/12 overflow-hidden overflow-ellipsis  line-clamp-2 break-words',
        styles.moreInfoText,
        styles.fontSize
      )
  const moreInfoContainerStyle = expanded
    ? 'h-auto flex items-center'
    : 'h-auto flex items-center'
  const viewMoreButton =
    overflowActive && !expanded ? (
      <button
        className={cx(
          'mt-5 h-4 font-bold ml-2',
          styles.transparent,
          styles.primaryImportant
        )}
        onClick={() => setExpanded(true)}
      >
        More
      </button>
    ) : null
  const viewLessButton =
    numberOfLines > 2 || expanded ? (
      <button
        className={cx(
          'h-4 font-bold ml-2',
          styles.transparent,
          styles.primaryImportant
        )}
        onClick={() => setExpanded(false)}
      >
        {' '}
        Less
      </button>
    ) : null
  return text ? (
    <div className={moreInfoContainerStyle}>
      <p className={moreInfoStyle} ref={spanRef}>
        {text}
        {viewLessButton}
      </p>
      {viewMoreButton}
    </div>
  ) : null
}

MoreInfo.propTypes = { text: PropTypes.string }
