import React, { useMemo } from 'react'
import { FutureAvailability } from '../../types/menu'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  getIntervalsDescription,
  renderDatesDescription
} from '../../utils/availability-util'
import { toShortDateString } from '../../utils/time-utils'

type DiningBehaviorDisplay = 'TAKE_OUT' | 'DELIVERY' | 'BOTH'

const getDininingBehavior = (
  futureAvailability: FutureAvailability
): DiningBehaviorDisplay => {
  if (futureAvailability.diningBehaviors.length > 1) {
    return 'BOTH'
  }
  if (futureAvailability.diningBehaviors[0] === 'DELIVERY') {
    return 'DELIVERY'
  }
  return 'TAKE_OUT'
}

const getDininingBehaviorDescription = (key: DiningBehaviorDisplay) => {
  switch (key) {
    case 'BOTH':
      return 'pickup & delivery'
    case 'DELIVERY':
      return 'delivery'
    default:
      return 'pickup'
  }
}

interface Props {
  futureAvailability: FutureAvailability[]
  containerClassName?: string
}

export const MenuFutureAvailability = ({
  futureAvailability,
  containerClassName
}: Props) => {
  const { restaurantInfo } = useRestaurant()

  const dayOfAvailabilities = futureAvailability.filter(
    (availability) =>
      availability.intervals && availability.intervals.length > 0
  )

  const sortedFutureAvailability = useMemo(() => {
    return futureAvailability.reduce((acc, availability) => {
      if (availability.intervals?.length) {
        return acc
      }

      const diningBehavior = getDininingBehavior(availability)
      if (!acc[diningBehavior]) {
        acc[diningBehavior] = [availability]
      } else {
        acc[diningBehavior].push(availability)
      }
      return acc
    }, {} as Record<DiningBehaviorDisplay, FutureAvailability[]>)
  }, [futureAvailability])

  const tz = restaurantInfo?.timeZoneId ?? undefined

  return (
    <div className={containerClassName}>
      {dayOfAvailabilities.map((dayOfAvailability) => {
        const dateStr = toShortDateString(dayOfAvailability.start!!, tz)
        const behavior = getDininingBehavior(dayOfAvailability)
        return (
          <div key={`availability-${dateStr}-${behavior}}`}>
            {dayOfAvailability.intervals!! && (
              <div className='text-coo-primary-text'>
                On <span className='font-semibold'>{dateStr}</span>, available
                for{' '}
                <span className='font-semibold'>
                  {getDininingBehaviorDescription(behavior)}
                </span>{' '}
                {getIntervalsDescription(dayOfAvailability.intervals)}.
              </div>
            )}
          </div>
        )
      })}
      {(Object.keys(sortedFutureAvailability) as DiningBehaviorDisplay[]).map(
        (behavior) => {
          const availabilities = sortedFutureAvailability[behavior]
          const dateRangeStr = renderDatesDescription(availabilities, tz)
          return (
            <div
              className='text-coo-primary-text'
              key={`availability-${behavior}`}
            >
              {dayOfAvailabilities.length > 0
                ? 'Also available for '
                : 'Available for '}
              <span className='font-semibold'>
                {getDininingBehaviorDescription(behavior)}
              </span>
              {' orders'}
              {dateRangeStr}.{' '}
            </div>
          )
        }
      )}
    </div>
  )
}
