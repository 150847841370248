import React, { useState } from 'react'
import { NavigationDrawer } from '../NavigationDrawer/NavigationDrawer'
import styles from './Hamburger.module.css'
import { onEnterOrSpace } from '@local/do-secundo-key-handler'
import { MenuIcon } from '@toasttab/buffet-pui-icons'

export const Hamburger = () => {
  const [isOpen, setOpen] = useState<boolean>()
  const keyHandler = onEnterOrSpace(() => setOpen(true))
  return (
    <>
      <div
        data-testid='hamburger'
        onClick={() => setOpen(true)}
        onKeyPress={keyHandler}
        className={styles.hamburger}
        tabIndex={0}
        role='button'
        aria-label='Options'
        id='hamburger'
      >
        <MenuIcon
          className='text-coo-white-contrasting'
          accessibility='decorative'
        />
      </div>
      {isOpen && <NavigationDrawer onClose={() => setOpen(false)} />}
    </>
  )
}
