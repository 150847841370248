import React, { useContext, createContext, useReducer, useMemo } from 'react'
import PropTypes from 'prop-types'
import { logError as logErrorUtil } from '@local/do-secundo-error'

const logError = (message) =>
  setTimeout(() => {
    logErrorUtil(message)
  }, 0)

export const SERVICE_AVAILABILITY = {
  AVAILABLE: 'AVAILABLE',
  DEGRADED: 'DEGRADED',
  UNAVAILABLE: 'UNAVAILABLE'
}

export const SERVICES = {
  PLACE_ORDER: 'PLACE_ORDER'
}

export const SERVICE_OFFLINE = 'SERVICE_OFFLINE'
export const SERVICE_DEGRADED = 'SERVICE_DEGRADED'
export const SERVICE_ONLINE = 'SERVICE_ONLINE'
export const SERVICE_ERROR = 'SERVICE_ERROR'

const availableService = Object.freeze({
  status: SERVICE_AVAILABILITY.AVAILABLE,
  errorCount: 0
})

const initialContext = {
  [SERVICES.PLACE_ORDER]: { ...availableService }
}

const serviceReducer = (state, action) => {
  const _service = findService(action.service)
  if (_service === null) {
    return state
  }
  try {
    switch (action.type) {
      case SERVICE_ONLINE:
        return {
          ...state,
          [_service]: { ...availableService }
        }
      case SERVICE_DEGRADED:
        // Sentry log offline mode after reduction
        logError({
          message: `Service ${_service} is DEGRADED`
        })
        return {
          ...state,
          [_service]: {
            ...state[_service],
            status: SERVICE_AVAILABILITY.DEGRADED
          }
        }
      case SERVICE_ERROR:
        if (state[_service].errorCount >= 1) {
          // Go offline if we have more than 1 error
          return serviceReducer(state, {
            ...action,
            type: SERVICE_DEGRADED
          })
        }
        return {
          ...state,
          [_service]: {
            ...state[_service],
            errorCount: state[_service].errorCount + 1
          }
        }
      case SERVICE_OFFLINE:
        // Sentry log offline mode after reduction
        logError({
          message: `Service ${_service} is OFFLINE`
        })
        return {
          ...state,
          [_service]: {
            ...state[_service],
            status: SERVICE_AVAILABILITY.UNAVAILABLE
          }
        }
      default:
        return state
    }
  } catch (e) {
    logError(e)
    return state
  }
}

const findService = (service) =>
  (service && SERVICES[service.toString().toUpperCase()]) || null

const ServiceAvailabilityContext = createContext(initialContext)

export const ServiceAvailabilityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(serviceReducer, initialContext)
  const setOffline = (service) => dispatch({ type: SERVICE_OFFLINE, service })
  const setOnline = (service) => dispatch({ type: SERVICE_ONLINE, service })
  const setDegraded = (service) => dispatch({ type: SERVICE_DEGRADED, service })
  const logCriticalError = (service, errorType) =>
    dispatch({ type: SERVICE_ERROR, service, errorType })

  const context = useMemo(
    () => ({
      ...state,
      setOffline,
      setOnline,
      setDegraded,
      logCriticalError
    }),
    [state]
  )

  return (
    <ServiceAvailabilityContext.Provider value={context}>
      {children}
    </ServiceAvailabilityContext.Provider>
  )
}

ServiceAvailabilityProvider.propTypes = {
  children: PropTypes.node
}

export const useServiceAvailability = () => {
  return useContext(ServiceAvailabilityContext)
}
