import React from 'react'

import styles from './Progress.module.css'

import isEqual from 'lodash/isEqual'

const ProgressBase = () => {
  return (
    <div className={styles.wrapper} role='progressbar' aria-label='loading...'>
      <svg className={styles.svg} viewBox='-200 -200 400 400'>
        <g className={styles.g}>
          <circle className={styles.circle} r='130' pathLength='100' />
        </g>
      </svg>
    </div>
  )
}

/*
  Related posts that describe the issue and explain double displayName
  https://github.com/enzymejs/enzyme/pull/2137
  https://github.com/enzymejs/enzyme/issues/2196
*/

// needs this to change displayName from ProgressInner
ProgressBase.displayName = 'Progress'

export const Progress = React.memo(ProgressBase, isEqual)
// needs this to change displayName from Memo(Progress)
Progress.displayName = 'Progress'
