import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useQuery } from 'react-query'
import { getMenus } from '../api/menus'
import { CooMenu } from '../types/menu'
import { useFulfillment } from '../components/FulfillmentProvider/FulfillmentProvider'
import { ItemQuantityRuleView } from '../types/config'
import { useDiningOptions } from './useDiningOptions'
import { getFirstAvailableDateTime } from '../components/FulfillmentSelectorModal/fulfillment-helpers'

export const useMenus = (): {
  menus?: CooMenu[]
  error: boolean
  loading: boolean
  refetch: () => void
  menuItemQuantityRules: ItemQuantityRuleView[]
} => {
  const { restaurantGuid, ooConfig } = useRestaurant()
  const {
    diningOptionBehavior,
    fulfillmentDateTime,
    loading: fulfillmentLoading,
    fastLinkName
  } = useFulfillment()
  const { data: diningOptionFulfillmentData, isLoading: diningOptionLoading } =
    useDiningOptions()

  // If fulfillment date is not selected yet, use first available date/time
  // to retrieve menu
  const dateTimeRequest =
    fulfillmentDateTime ??
    getFirstAvailableDateTime(diningOptionFulfillmentData, diningOptionBehavior)

  const inputsLoading = fulfillmentLoading || diningOptionLoading

  const {
    data,
    isError: error,
    isLoading: loading,
    refetch
  } = useQuery(
    ['menus', restaurantGuid, diningOptionBehavior, dateTimeRequest],
    () => getMenus(diningOptionBehavior, dateTimeRequest, fastLinkName),
    {
      enabled: !inputsLoading
    }
  )

  return {
    menus: data?.menus,
    error,
    loading: loading || inputsLoading,
    refetch,
    menuItemQuantityRules: ooConfig?.itemQuantityRules ?? []
  }
}
