import lodashGet from 'lodash/get'
import lodashSet from 'lodash/set'
import lodashUnset from 'lodash/unset'

export type StorageKey = string | string[]

export interface NamespacedStorage {
  get: (key: StorageKey) => any
  set: (key: StorageKey, value: any) => void
  remove: (key: StorageKey) => void
}

// Namespaced LocalStorage cache wrapper
export const namespacedStorage = (
  namespace: string,
  storage = localStorage
): NamespacedStorage => {
  if (!namespace) {
    throw new TypeError('namespacedStorage: missing namespace')
  }

  const getMap = () => {
    const namespaceValue = storage.getItem(namespace)
    return (namespaceValue && JSON.parse(namespaceValue)) || {}
  }
  const setMap = (fn: (val: object) => void) => {
    const map = getMap()
    fn(map)
    storage.setItem(namespace, JSON.stringify(map))
  }
  return {
    get: (key) => lodashGet(getMap(), key),
    set: (key, value) =>
      setMap((map) => {
        lodashSet(map, key, value)
      }),
    remove: (key) =>
      setMap((map) => {
        lodashUnset(map, key)
      })
  }
}
