import React from 'react'
import cx from 'classnames'
import { Anchor } from '../../Anchor/Anchor'
import { Progress } from '@local/do-secundo-progress'
import { useGiftCardLinks } from '../../use-gift-card-links/use-gift-card-links'

import styles from '../NavigationDrawer.module.css'

export const GiftCardLinks = () => {
  const { loading, error, purchaseLink, checkValueLink, addValueEnabled } =
    useGiftCardLinks()
  if (error) return null
  if (loading) {
    return <Progress />
  }

  const checkValueLabel =
    'Check ' + (addValueEnabled === true ? ' or Add ' : '') + 'Gift Card Value'
  return (
    <>
      {checkValueLink && (
        <li>
          <Anchor
            data-testid='nav-drawer-check-gift-card-balance'
            className={cx(styles.listItem, 'type-default')}
            href={checkValueLink}
            target='_blank'
          >
            {checkValueLabel}
          </Anchor>
        </li>
      )}
      {purchaseLink && (
        <li>
          <Anchor
            data-testid='nav-drawer-buy-gift-card'
            className={cx(styles.listItem, 'type-default')}
            allowFollow={true}
            href={purchaseLink}
            target='_blank'
          >
            Purchase Gift Cards
          </Anchor>
        </li>
      )}
    </>
  )
}
