import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import React from 'react'
import { Button, ButtonVariant } from '@local/do-secundo-button'
import { useCart } from '@local/do-secundo-cart-provider/src'

export const CartNotFoundModal = ({ onClose }: { onClose: () => void }) => {
  const { initialCartMetadata } = useCart()

  return (
    <Modal isOpen onRequestClose={onClose} size={'xl'} hideCloseIcon>
      <ModalHeader>
        <div className={'text-center w-full'}>
          {initialCartMetadata?.orderGuid ? (
            <>
              Too late!{' '}
              {initialCartMetadata?.metadata?.name ? (
                <>
                  Order has already been placed for{' '}
                  {initialCartMetadata.metadata.name}
                </>
              ) : (
                <>This order has already been placed</>
              )}
            </>
          ) : (
            <>Sorry! This cart is no longer available</>
          )}
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={'pt-4 pb-1'}>
          <Button variant={ButtonVariant.PRIMARY} onClick={onClose}>
            Start new order
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}
