/*
  A helper function that accepts a callback as its argument. If callbacks' first argument is an event object - event.stopPropagation method will be invoked before invoking the callback itself.
  The original behavior of callback is not altered, all the arguments are gonna be passed in.
*/

export const stopPropagationIfExists = (handler) => {
  return (event, ...args) => {
    if (event && event.stopPropagation) {
      event.stopPropagation()
    }
    handler(event, ...args)
  }
}
