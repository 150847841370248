import React from 'react'
import { Hamburger } from '../Hamburger/Hamburger'
import { RestaurantLogo } from '../RestaurantHeader/RestaurantLogo'
import { FulfillmentSelector } from '../FulfillmentSelector/FulfillmentSelector'
import styles from './header.module.css'
import cx from 'classnames'
import { useLocation } from 'react-router-dom'

export const Header = () => {
  const { pathname } = useLocation()

  const showFulfillment =
    !pathname.includes('/checkout') && !pathname.includes('/confirm')

  return (
    <div data-testid='PageHeader' className={styles.header} role='banner'>
      <Hamburger />
      <RestaurantLogo className={cx(styles.logo)} />
      {showFulfillment && (
        <div className={styles.fulfillment}>
          <FulfillmentSelector />
        </div>
      )}
    </div>
  )
}
