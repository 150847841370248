import React from 'react'

import { Notification } from '@local/do-secundo-notification'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { ModificationError, useCart } from '@local/do-secundo-cart-provider'
import { joinFieldByCommas } from '../../../utils/reducer-utils'

import styles from './CartModificationError.module.css'

const actionContent = (
  <div className={styles.cartModificationAction}>Select a new item</div>
)

interface Props {
  modificationErrors: ModificationError[]
}

const DisplayErrors: React.FC<Props> = ({ modificationErrors }) => {
  return (
    <>
      {modificationErrors.map((error, idx) => {
        const { items, message } = error
        const itemList = joinFieldByCommas('name')(items)
        const renderSelectItem = !items || items.length === 1
        return (
          <div key={idx}>
            {message}
            {itemList && (
              <div className={styles.outOfStockItems}>{itemList}</div>
            )}
            {renderSelectItem && (
              <div className={styles.selectNewItem}> Select a new item.</div>
            )}
          </div>
        )
      })}
    </>
  )
}

export const CartModificationError: React.FC = () => {
  const { getRestaurantPath } = useRestaurant()
  const {
    cartState: { modificationErrors },
    dispatch
  } = useCart()

  const onClose = () => dispatch({ type: 'CLEAR_MODIFICATION_ERRORS' })

  return modificationErrors.length > 0 ? (
    <div className={styles.CartModificationError}>
      <Notification
        severity='warning'
        isActionable
        actionLink={getRestaurantPath('/')}
        actionContent={actionContent}
        isCloseable
        onClose={onClose}
      >
        <DisplayErrors modificationErrors={modificationErrors} />
      </Notification>
    </div>
  ) : null
}
