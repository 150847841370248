import { FF, useFlag } from '@local/do-secundo-feature-flag'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import React, { useEffect, useState } from 'react'

export const InstagramWarning = () => {
  const [showBrowserWarning, setShowBrowserWarning] = useState(false)

  useEffect(() => {
    const userAgent = (navigator.userAgent || navigator.vendor).toLowerCase()
    if (/instagram/i.test(userAgent) && /iphone|ipad|ipod/i.test(userAgent)) {
      setShowBrowserWarning(true)
    }
  }, [])

  const reCaptchaFeatureEnabled = useFlag(FF.NVS_COO_RECAPTCHA)
  if (!reCaptchaFeatureEnabled) return <></>

  return (
    <Modal
      isOpen={showBrowserWarning}
      onRequestClose={() => setShowBrowserWarning(false)}
    >
      <ModalHeader>
        <InfoIcon /> Please note
      </ModalHeader>
      <ModalBody>
        Accessing this link from within the Instagram browser will cause your
        payment to be blocked when you try to checkout. We're sorry for the
        inconvenience and we're working on making it easier for you to order. In
        the meanwhile, please:
        <div className='pt-4'>
          Click the triple-dot icon in the upper right corner
        </div>
        <img
          className='object-cover pb-4 rounded-md'
          src={
            'https://cdn.toasttab.com/static/30e1c4ecbc5210c07ce177c178836540736c1735/projects/new-ventures/catering/coo_instagram_popup_1.png'
          }
          alt={'Instagram triple-dot'}
        />
        Choose 'Open in external browser'
        <img
          className='object-cover pb-4 rounded-md'
          src={
            'https://cdn.toasttab.com/static/30e1c4ecbc5210c07ce177c178836540736c1735/projects/new-ventures/catering/coo_instagram_popup_2.png'
          }
          alt={'Open in external browser'}
        />
        <div className='pt-2'>
          You'll be able to place your order. Thank you!
        </div>
      </ModalBody>
    </Modal>
  )
}
