import isEmpty from 'lodash/isEmpty'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'

export const useGiftCardLinks = () => {
  const { restaurantInfo } = useRestaurant()
  const { data } = restaurantInfo
  if (!data) return restaurantInfo
  if (isEmpty(data)) return restaurantInfo
  const {
    restaurant: {
      giftCardLinks: { purchaseLink, checkValueLink, addValueEnabled }
    }
  } = data
  return {
    ...restaurantInfo,
    purchaseLink: purchaseLink && `${purchaseLink}?utmCampaign=onlineOrdering`,
    checkValueLink,
    addValueEnabled
  }
}
