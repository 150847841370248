import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './NavigationDrawerHeader.module.css'

export const NavigationDrawerHeader = () => {
  return (
    <>
      <h2
        data-testid='drawer-header'
        className={cx(styles.header, 'type-headline-4')}
      >
        Welcome!
      </h2>
      <p
        data-testid='drawer-text-after-header'
        className={cx(styles.text, 'type-default')}
      >
        {''}
      </p>
    </>
  )
}

NavigationDrawerHeader.propTypes = {
  onClose: PropTypes.func
}
