import React from 'react'
import { getIn, useFormikContext } from 'formik'
import { isSelected } from '@local/do-secundo-modifier-form-helpers'
import { Badge } from '@toasttab/buffet-pui-badge'

export const Required = ({ name }: { name: string }) => {
  const { errors, values } = useFormikContext()
  const err = getIn(errors, name)
  let value = getIn(values, name)
  if (Array.isArray(value)) {
    value = value.find(isSelected)
  }

  const satisfied = value && !err

  return (
    <Badge color={satisfied ? Badge.Color.success : Badge.Color.neutral}>
      Required
    </Badge>
  )
}
