import React from 'react'
import { Button } from '@local/do-secundo-button'
import { useHandleDelete } from '../../CartMutation'

interface Props {
  selectionGuid: string
  setDeleting: (deleting: boolean) => void
  setError: (error: any) => void
  disabled: boolean
}

export const CartTableRemoveItemButton: React.FC<Props> = ({
  selectionGuid,
  setDeleting,
  setError,
  disabled
}) => {
  const handleDelete = useHandleDelete({
    selectionGuid,
    setError
  })
  return (
    <Button
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation()
        setDeleting(true)
        handleDelete().finally(() => setDeleting(false))
      }}
    >
      Remove
    </Button>
  )
}
