import React from 'react'
import cx from 'classnames'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useGetCart } from '../CartQuery/CartQuery'
import CartUnavailable from '../../assets/cart-unavailable.svg'
import CartImg from '../../assets/cart.svg'
import styles from './CartIcon.module.css'

interface Props {
  variant: 'light' | 'primaryContrast'
  showCount?: boolean
  showModificationErrors?: boolean
  disabled?: boolean
}

export const CartIcon = ({
  variant,
  showCount,
  showModificationErrors,
  disabled = false
}: Props) => {
  const {
    error,
    loading: cartLoading,
    cart,
    cartState: { modificationErrors }
  } = useGetCart()
  const { orderingAvailable, loading: availabilityLoading } = useAvailability()
  const loading = cartLoading || availabilityLoading
  const hasModificationErrors =
    modificationErrors && modificationErrors.length > 0

  if (loading) return null

  const numberOfSelections = cart ? cart.order.checks[0].selections.length : 0
  const cartAvailable = orderingAvailable && !error

  return (
    <div
      className={cx(styles.cart, styles[variant], disabled && styles.disabled)}
    >
      {cartAvailable ? (
        <div className={styles.cartImageWrapper}>
          {showModificationErrors && hasModificationErrors && (
            <div className={styles.cartModificationError} />
          )}
          <CartImg className={styles.cartImage} data-testid='cart-available' />
        </div>
      ) : (
        <CartUnavailable
          className={styles.cartImage}
          data-testid='cart-unavailable'
        />
      )}
      {numberOfSelections > 0 && showCount && (
        <span className={styles.quantity}>{numberOfSelections}</span>
      )}
    </div>
  )
}
