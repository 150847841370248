import React, { useState } from 'react'
import styles from './RestaurantDetails.module.css'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { formatPhoneNumber } from '../../../utils/phone-number'
import PhoneImg from '../../../../client/assets/phone.svg'
import { getImageUrl } from '../RestaurantLogo'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { OrderingAvailabilty } from './OrderingAvailability'
import { RestaurantAndBanner } from './banner/RestaurantAndBanner'
import {
  brandingPublicAssetURL,
  restaurantPublicAssetUrl
} from '../../../api/util'

export const RestaurantDetails = () => {
  const [shouldShowInfo, editShowInfo] = useState(false)

  const {
    restaurantInfo,
    applicableConfigs: { brandingConfig }
  } = useRestaurant()

  if (!restaurantInfo) return null

  const name = restaurantInfo.name
  const phone = restaurantInfo.address.phone

  const bannerUrl = brandingConfig
    ? getImageUrl(brandingPublicAssetURL, brandingConfig.bannerImage?.src)
    : restaurantInfo.bannerImagePath
    ? getImageUrl(restaurantPublicAssetUrl, restaurantInfo.bannerImagePath)
    : undefined

  return (
    <>
      <RestaurantAndBanner
        whiteLabelName={name}
        address={restaurantInfo.address}
        bannerUrl={bannerUrl}
        onClickMoreInfo={() => editShowInfo(!shouldShowInfo)}
      />
      {shouldShowInfo && (
        <Modal isOpen onRequestClose={() => editShowInfo(false)}>
          <ModalHeader>
            <div className='type-headline-5'>{name}</div>
          </ModalHeader>
          <ModalBody>
            <div className='py-2 -ml-1'>
              <OrderingAvailabilty />
            </div>
            <div className={'flex mb-1'}>
              {phone && (
                <a href={`tel:${phone}`} className={styles.phone}>
                  <PhoneImg className={styles.phoneImage} />
                  {formatPhoneNumber(phone)}
                </a>
              )}
            </div>
            {restaurantInfo?.description && <p>{restaurantInfo.description}</p>}
          </ModalBody>
        </Modal>
      )}
    </>
  )
}
