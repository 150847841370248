import React, { RefObject, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import {
  ScrollDirection,
  scrollToRef,
  useHorizontalScroll,
  RefMenu
} from './util'
import style from './MenuNav.module.css'
import cx from 'classnames'
import {
  ArrowDecreaseIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@toasttab/buffet-pui-icons'

export const MenuNav = ({
  menus,
  selectedMenuIndex,
  setSelectedMenuIndex,
  selectedGroupGuid,
  setSelectedGroupGuid
}: {
  menus: RefMenu[]
  selectedMenuIndex: number
  setSelectedMenuIndex: (index: number) => void
  selectedGroupGuid?: string
  setSelectedGroupGuid: (guid: string) => void
}) => {
  const {
    scrollContainerRef,
    showLeftArrow,
    showRightArrow,
    scroll,
    scrollEvent
  } = useHorizontalScroll()

  useEffect(() => {
    scrollEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus.length])

  const hasMenuNav = menus.length > 1

  // There are 3 cases to account for:
  // 1. There is a menuNav and a subMenuNav, in which case we'll render a nav with dropdowns
  // 2. There is only a menuNav and no subMenuNav, in which case we'll render the menus without dropdowns
  // 3. There is only a subMenuNav and no menuNav, in which case we redefine the menus as the submenus and render them without dropdowns
  let topLevelMenus: RefMenu[]
  if (menus.length === 0) {
    topLevelMenus = []
  } else if (hasMenuNav) {
    topLevelMenus = menus
  } else {
    topLevelMenus = menus[0]!.groups.map((group) => {
      return {
        ...group,
        groups: [],
        enabled: menus[0].enabled,
        futureAvailability: menus[0].futureAvailability
      } as RefMenu
    })
  }

  return (
    <nav role='tablist' className={style.topMenuNav} data-testid='menu-nav'>
      {topLevelMenus.length > 0 && (
        <div
          className={style.topMenuNavMenuSelection}
          data-testid='top-menu-nav-menu-selection'
        >
          <div className={style.topMenuNavWrapper}>
            <button
              type='button'
              aria-label='Scroll left'
              onClick={scroll(ScrollDirection.Backwards)}
              className={cx(style.arrow, style.leftArrow, {
                [style.arrowHidden]: !showLeftArrow
              })}
            >
              <ChevronLeftIcon
                className='text-coo-primary-contrasting'
                accessibility='decorative'
              />
            </button>
            <div
              className={style.sections}
              ref={scrollContainerRef}
              onScroll={scrollEvent}
            >
              {topLevelMenus.map((menuItem, index) => (
                <MenuNavItem
                  key={menuItem.name}
                  menuItem={menuItem}
                  selected={
                    hasMenuNav
                      ? selectedMenuIndex === index
                      : selectedGroupGuid === menuItem.guid
                  }
                  setSelected={() => {
                    hasMenuNav
                      ? setSelectedMenuIndex(index)
                      : setSelectedGroupGuid(menuItem.guid)
                  }}
                  scrollContainerRef={scrollContainerRef}
                  hasDropdown={hasMenuNav && menuItem.groups.length > 1}
                />
              ))}
            </div>
            <button
              type='button'
              aria-label='Scroll right'
              onClick={scroll(ScrollDirection.Forwards)}
              className={cx(style.arrow, style.rightArrow, {
                [style.arrowHidden]: !showRightArrow
              })}
            >
              <ChevronRightIcon
                className='text-coo-primary-contrasting'
                accessibility='decorative'
              />
            </button>
          </div>
        </div>
      )}
    </nav>
  )
}

const MenuNavItem = ({
  menuItem,
  selected,
  setSelected,
  scrollContainerRef,
  hasDropdown
}: {
  menuItem: RefMenu
  selected: boolean
  setSelected: () => void
  scrollContainerRef: RefObject<HTMLDivElement>
  hasDropdown: boolean
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const dropdownRef = React.useRef<HTMLDivElement>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [15, 0]
        }
      }
    ]
  })

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const isFullyInView = useCallback(() => {
    if (!referenceElement || !scrollContainerRef.current) {
      return
    }
    const refRect = referenceElement.getBoundingClientRect()
    const containerRect = scrollContainerRef.current.getBoundingClientRect()
    return (
      refRect.left >= containerRect.left && refRect.right <= containerRect.right
    )
  }, [referenceElement, scrollContainerRef])

  useEffect(() => {
    const detectOutsideAction = (event: Event) => {
      const target = event.target as Node | null
      if (
        dropdownIsOpen &&
        !popperElement?.contains(target) &&
        !referenceElement?.contains(target)
      ) {
        setDropdownIsOpen(false)
      }
    }

    // Use a timeout so that we don't call a ton of the element's
    // scrollIntoView function consecutively
    let scrollTimeout: ReturnType<typeof setTimeout> | null = null
    const detectSelectionFromVerticalScroll = () => {
      if (scrollTimeout !== null) {
        clearTimeout(scrollTimeout)
      }
      scrollTimeout = setTimeout(() => {
        if (selected && referenceElement && !isFullyInView()) {
          referenceElement.scrollIntoView({ block: 'nearest' })
        }
        scrollTimeout = null
      }, 50)
    }

    document.addEventListener('click', detectOutsideAction)
    document.addEventListener('scroll', detectOutsideAction)
    document.addEventListener('scroll', detectSelectionFromVerticalScroll)
    scrollContainerRef.current?.addEventListener('scroll', detectOutsideAction)

    return () => {
      document.removeEventListener('click', detectOutsideAction)
      document.removeEventListener('scroll', detectOutsideAction)
      document.removeEventListener('scroll', detectSelectionFromVerticalScroll)
    }
  }, [
    dropdownIsOpen,
    popperElement,
    scrollContainerRef,
    referenceElement,
    selected,
    isFullyInView
  ])

  // Make sure this items closes its dropdown if another menu item
  // becomes selected. The above useEffect for detecting outside click
  // doesn't always work when the click is to select another menu item.
  useEffect(() => {
    if (!selected) setDropdownIsOpen(false)
  }, [selected])

  function handleMenuItemClick(
    e: React.KeyboardEvent | React.MouseEvent,
    item: RefMenu
  ) {
    if (e.type === 'keydown') {
      const keyEvent = e as React.KeyboardEvent
      if (keyEvent.key !== 'Space' && keyEvent.key !== 'Enter') {
        return
      }
      e.stopPropagation()
      e.preventDefault()
    }
    setSelected()
    if (hasDropdown) {
      if (!isFullyInView()) {
        referenceElement?.scrollIntoView({ block: 'nearest' })
        // Set a timeout so the event listener that automatically closes the dropdown on scroll
        // doesn't fire before we can scroll the menu item into view and open the dropdown
        setTimeout(() => setDropdownIsOpen(!dropdownIsOpen), 50)
      } else {
        setDropdownIsOpen(!dropdownIsOpen)
      }
    } else {
      scrollToRef(item.ref)
    }
  }

  useEffect(() => {
    if (dropdownIsOpen) {
      const options = popperElement?.querySelectorAll('[role="option"]')
      if (options && options.length > 0) {
        const elementToFocus = options.item(0) as HTMLElement
        // We have to wait for all of the scroll presses to process before moving focus or else
        // the dropdown will close automatically
        setTimeout(() => elementToFocus.focus(), 50)
      }
    }
  }, [dropdownIsOpen, popperElement])

  const handleTabOnDropdown = useCallback(
    (e: React.KeyboardEvent) => {
      const options = popperElement?.querySelectorAll('[role="option"]')
      const target = e.target as HTMLElement
      if (options && target.id === options[options.length - 1]?.id) {
        setDropdownIsOpen(false)
        e.stopPropagation()
        e.preventDefault()
        setTimeout(() => dropdownRef.current?.focus(), 50)
      }
    },
    [dropdownRef, popperElement]
  )

  function handleSubMenuItemKeyDown(
    e: React.KeyboardEvent,
    ref: React.RefObject<HTMLDivElement>
  ) {
    if (e.key === 'Tab') {
      handleTabOnDropdown(e)
    }
    if (e.key !== 'Escape' && e.key !== 'Space' && e.key !== 'Enter') {
      return
    }
    e.stopPropagation()
    e.preventDefault()
    setDropdownIsOpen(false)
    if (e.key === 'Escape') {
      dropdownRef.current?.focus()
    } else {
      scrollToRef(ref)
    }
  }

  return (
    <div
      key={menuItem.name}
      className={style.menuItem}
      ref={setReferenceElement}
      role='tab'
    >
      <div
        className={cx(style.menuItemTarget, selected && style.selected)}
        onClick={(e: React.MouseEvent) => handleMenuItemClick(e, menuItem)}
        onKeyDown={(e: React.KeyboardEvent) => handleMenuItemClick(e, menuItem)}
        role={hasDropdown ? 'listbox' : 'tab'}
        data-testid={`menu-item-target-${menuItem.name}`}
        ref={dropdownRef}
        tabIndex={0}
        aria-haspopup={hasDropdown}
        aria-expanded={dropdownIsOpen}
        aria-controls='menu-item-dropdown-content'
      >
        <div
          aria-selected={selected}
          data-testid={`menu-tab-${menuItem.name}`}
          className='truncate'
        >
          {menuItem.name}
        </div>
        {hasDropdown && (
          <div
            className={style.dropdownTriangle}
            data-testid='dropdown-triangle'
          >
            <ArrowDecreaseIcon size='xs' />
          </div>
        )}
      </div>
      {hasDropdown &&
        dropdownIsOpen &&
        ReactDOM.createPortal(
          <div
            className={style.dropdown}
            data-testid='menu-item-dropdown'
            id='menu-item-dropdown-content'
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            role='tablist'
          >
            {menuItem.groups.map((groupItem, index) => {
              const scrollRef = index === 0 ? menuItem.ref : groupItem.ref
              return (
                <button
                  key={groupItem.guid}
                  id={groupItem.guid}
                  className={cx(style.subMenuItem, 'hover:bg-darken-4')}
                  onClick={() => {
                    setDropdownIsOpen(false)
                    scrollToRef(scrollRef)
                  }}
                  onKeyDown={(e) => handleSubMenuItemKeyDown(e, scrollRef)}
                  tabIndex={1}
                  role='tab'
                >
                  {groupItem.name}
                </button>
              )
            })}
          </div>,
          document.body
        )}
    </div>
  )
}
