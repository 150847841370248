import React from 'react'
import cx from 'classnames'

import { OrderingAvailableBanner } from './OrderingAvailableBanner'
import { OrderingNotAvailableBanner } from './OrderingNotAvailableBanner'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { CartIcon } from '../CartIcon/CartIcon'

import styles from './EmptyCart.module.css'

interface Props {
  orderingAvailable: boolean
  menuLink?: string
}

export const EmptyCart: React.FC<Props> = ({ orderingAvailable, menuLink }) => {
  return (
    <div>
      <div className={cx(styles.emptyCart, 'type-default')}>
        <div className={styles.cartCircle}>
          <CartIcon variant='light' showModificationErrors={false} />
        </div>
        <div className={'pt-4'}>
          {orderingAvailable ? (
            <OrderingAvailableBanner menuLink={menuLink} />
          ) : (
            <OrderingNotAvailableBanner menuLink={menuLink} />
          )}
        </div>
      </div>

      <a href='https://www.toasttab.com/local' aria-label='Toast discover page'>
        <PoweredByToast className={styles.poweredBy} />
      </a>
    </div>
  )
}
