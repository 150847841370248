import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Order } from '../../client/types/orders'
import { useAdsConversionTags } from './ads'

interface ConfirmationTagProps {
  completedOrder: Order
}

const ConfirmationTag: React.FC<ConfirmationTagProps> = ({
  completedOrder
}) => {
  const { data } = useAdsConversionTags()

  if (data) {
    const tax = completedOrder.checks[0].taxAmount
    const discount = completedOrder.checks[0].totalDiscountAmount
    const total = completedOrder.checks[0].totalAmount + discount - tax

    return (
      <Helmet>
        <script>
          {`gtag('event', 'conversion', {
             'send_to': '${data.conversionTagId}/${data.eventLabel}',
             'value': ${total},
             'tax': ${tax},
             'currency': 'USD',
             'transaction_id': '${completedOrder.guid}'
            });
          `}
        </script>
      </Helmet>
    )
  }

  return null
}

export default ConfirmationTag
