import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import KeyHandler from 'react-key-handler'
import FocusTrap from 'focus-trap-react'
import cx from 'classnames'

import { ErrorComponent } from '@local/do-secundo-error'
import { CloseButton } from '@local/do-secundo-closebutton'

import styles from './Modal.module.css'
let modalCount = 0
let modalRoot = document.getElementById('modal-root')
if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.id = 'modal-root'
  document.body.prepend(modalRoot)
}

const stopEventPropagation = (event) => {
  event.stopPropagation()
}

export class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.modalBodyRef = React.createRef()
    this.el = document.createElement('div')
    this.scrollWidth = window.innerWidth - document.body.clientWidth
    // clickTarget property exists so we can see where a click was initiated in the modal
    this.clickTarget = null
  }

  componentDidMount() {
    modalCount++
    if (modalCount === 1) {
      document.body.classList.add('modal-open')
      document.body.style.margin = `0 ${this.scrollWidth}px 0 0`
    }

    modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    modalCount--
    if (modalCount === 0) {
      document.body.classList.remove('modal-open')
      document.body.style.margin = ''
    }
    modalRoot.removeChild(this.el)
  }

  handleOutsideClick = (e, onClose) => {
    if (e.target === this.clickTarget) {
      onClose && onClose(e)
    }
  }

  handleInsideClick = (e) => {
    this.clickTarget = e.target
  }

  render() {
    const {
      wrapper = (x) => x,
      header,
      onClose,
      footer,
      children,
      error,
      responsive = true,
      focusTrapOptions = {}
    } = this.props
    const contents = wrapper(
      <FocusTrap focusTrapOptions={focusTrapOptions}>
        <div
          className={cx(styles.background, { [styles.responsive]: responsive })}
          onClick={(e) => this.handleOutsideClick(e, onClose)}
          onMouseDown={this.handleInsideClick}
        >
          <div
            role='dialog'
            className={styles.modal}
            onClick={stopEventPropagation}
          >
            <div className={styles.headerWrapper}>
              <div className={cx(styles.header, 'type-headline-4')}>
                {typeof header === 'string' ? (
                  <Modal.Header>{header}</Modal.Header>
                ) : (
                  header
                )}
              </div>
              {onClose && (
                <div className={styles.closeButton}>
                  <CloseButton
                    data-testid='modal-close-button'
                    onClick={onClose}
                  />
                  <KeyHandler
                    keyEventName='keydown'
                    keyValue='Escape'
                    onKeyHandle={onClose}
                  />
                </div>
              )}
            </div>
            <div
              data-testid='modal-body'
              className={styles.body}
              ref={this.modalBodyRef}
            >
              {error && <ErrorComponent error={error} />}
              {children}
            </div>
            {footer && <div className={styles.footer}>{footer}</div>}
          </div>
        </div>
      </FocusTrap>
    )
    return ReactDOM.createPortal(contents, this.el)
  }
}

Modal.propTypes = {
  wrapper: PropTypes.func,
  header: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  error: PropTypes.object,
  responsive: PropTypes.bool
}

Modal.Header = ({ children }) => (
  <h2
    data-testid='modal-default-header'
    className={cx(styles.defaultHeader, 'type-headline-4')}
  >
    {children}
  </h2>
)

Modal.Header.propTypes = {
  children: PropTypes.node
}

Modal.Header.displayName = 'Modal.Header'
