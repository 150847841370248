import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import envFromHostname from '@toasttab/env-from-hostname'
import { ErrorComponent } from '@local/do-secundo-error'

import styles from './ErrorBoundary.module.css'

const DefaultFallback = ({ error, componentStack, resetError = () => {} }) => {
  const showDetails = envFromHostname(window.location.hostname) !== 'prod'
  return (
    <div className={styles.error}>
      <ErrorComponent retry={resetError} />
      {showDetails && (
        <pre className={styles.stack}>
          {error.toString()}
          {componentStack}
        </pre>
      )}
    </div>
  )
}

DefaultFallback.propTypes = {
  error: PropTypes.object,
  componentStack: PropTypes.string,
  resetError: PropTypes.func
}

export const ErrorBoundary = ({
  errorContent: FallbackComponent = DefaultFallback,
  children
}) => (
  <Sentry.ErrorBoundary
    fallback={FallbackComponent}
    beforeCapture={(Scope) => Scope.setLevel('critical')}
    children={children}
  />
)

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  errorContent: PropTypes.func
}
