import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import styles from './CartButton.module.css'

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  type?: 'submit' | 'reset' | 'button'
  to?: string
  left: React.ReactNode
  right: React.ReactNode
  disabled?: boolean
  loading: boolean
  showModificationErrors?: boolean
}

export const CartButton: React.FC<Props> = ({
  to,
  left,
  right,
  type,
  disabled = false,
  loading,
  showModificationErrors,
  ...props
}) => {
  const buttonContent = (
    <>
      <span>{left}</span>
      <span>{right}</span>
    </>
  )

  return (
    <div className='p-2 type-default'>
      {loading ? (
        <button
          className={cx(styles.cartButton, styles.loading)}
          type={type}
          {...props}
          disabled
        >
          <Loading variant={LoadingVariant.PRIMARY} />
        </button>
      ) : type || disabled ? (
        <button
          className={cx(styles.cartButton)}
          type={type}
          disabled={disabled}
          {...props}
        >
          {buttonContent}
        </button>
      ) : (
        <Link className={cx(styles.cartButton)} to={to ?? ''}>
          {buttonContent}
        </Link>
      )}
    </div>
  )
}
