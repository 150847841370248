import React, { Reducer, useEffect, useReducer, useState } from 'react'

import { useCart } from '@local/do-secundo-cart-provider'
import { useRestaurantStorage } from '../../utils/restaurant-storage'
import {
  FulfillmentStateData,
  useUpdateFulfillment
} from '../UseUpdateFulfillment/UseUpdateFulfillment'
import {
  isFulfillmentTimeValid,
  normalizeFulfillment,
  normalizeFulfillmentFromCart
} from '../FulfillmentSelectorModal/fulfillment-helpers'

import { initialReducerState } from './initial-fulfillment-state'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { Loading } from '@toasttab/do-secundo-loading'
import { ErrorMessage } from '../../types/cart'
import { useGetCart } from '../CartQuery/CartQuery'
import { LoadingVariant } from '@local/do-secundo-loading'
import { useGetFulfillmentFromQueryParams } from '../FulfillmentSelectorModal/LocationPicker/navigation'

const FULFILLMENT_WARNING_CODE = 'CART_UNFULFILLABLE'

const fulfillmentKeys: (keyof FulfillmentStateData)[] = [
  'diningOptionBehavior',
  'fulfillmentDateTime',
  'deliveryInfo'
]

const warningsContainsFulfillment = (warnings: ErrorMessage[] = []) => {
  return warnings.some((warning) => warning.code === FULFILLMENT_WARNING_CODE)
}

// @ts-ignore
const FulfillmentContext = React.createContext<FulfillmentContextType>({})

export interface FulfillmentState extends FulfillmentStateData {
  valid: boolean
  loading: boolean
  selected: boolean
}

export interface FulfillmentContextType extends FulfillmentState {
  setFulfillment: (
    value: FulfillmentStateData,
    fastLinkName: string | undefined
  ) => Promise<void>
  error?: any
  updating: boolean
  fastLinkName: string | undefined
}

export const fulfillmentReducer: Reducer<
  FulfillmentState,
  Partial<FulfillmentState>
> = (
  prevState: FulfillmentState,
  action: Partial<FulfillmentState>
): FulfillmentState => {
  return {
    ...prevState,
    ...action
  }
}

const FulfillmentProviderInner: React.FC<{
  initialState: FulfillmentState
  fastLinkName: string | undefined
}> = ({ children, initialState, fastLinkName }) => {
  const fulfillmentStorage = useRestaurantStorage()
  const { cartGuid: guid } = useCart()
  const {
    updateFulfillment,
    fulfillmentState: { error, loading: updating }
  } = useUpdateFulfillment()
  const shouldLoadCart = Boolean(guid)
  const { data, loading: cartQueryLoading } = useGetCart()

  const [cartLoading, setCartLoading] = useState(shouldLoadCart)

  const [fulfillmentState, setFulfillmentState] = useReducer(
    fulfillmentReducer,
    initialState
  )

  // when data is loaded and there is cart fulfillment info set,
  // then clear localStorage and use that instead
  useEffect(() => {
    if (data?.fulfillmentDateTime || data?.diningOptionBehavior) {
      // clear local storage and use cart as canonical source
      fulfillmentKeys.forEach((key) => fulfillmentStorage.remove(key))

      const { fulfillmentDateTime, diningOptionBehavior, deliveryInfo } =
        normalizeFulfillmentFromCart(data)

      const valid =
        !warningsContainsFulfillment(data.warnings) &&
        isFulfillmentTimeValid(fulfillmentDateTime)

      setFulfillmentState({
        fulfillmentDateTime,
        diningOptionBehavior,
        deliveryInfo,
        customLocationName: data.metadata?.customLocationName,
        standardFulfillment: data.metadata?.standardFulfillment,
        valid,
        selected: true
      })
    } else {
      setFulfillmentState(initialState)
    }

    // We need cartLoading to lag behind cartQueryLoading by one tick so that we will
    // have set fulfillmentState by the time we return loading=true to the consumer.
    setCartLoading(cartQueryLoading)
  }, [cartQueryLoading, data, fulfillmentStorage, fastLinkName])

  // any time the state changes and differs from cart values, updateAndClear cart
  // if there's no cart use local storage instead
  const setFulfillment = async (
    requestedFulfillment: FulfillmentStateData,
    fastLinkName: string | undefined
  ) => {
    let newFulfillment = normalizeFulfillment(requestedFulfillment)

    // set state locally if no cart
    if (!data) {
      fulfillmentKeys.forEach((key) =>
        fulfillmentStorage.set(key, newFulfillment[key])
      )
      setFulfillmentState({
        ...newFulfillment,
        valid: true,
        selected: true
      })
      return
    }

    const { error } = await updateFulfillment(
      data,
      newFulfillment,
      fastLinkName
    )
    if (error) {
      throw error
    }
  }

  const context = {
    ...fulfillmentState,
    savedAddressGuid: '',
    /**
     * Indicates if the cart is loading, which must happen first so we know whether it exists or
     * not in order to pull the fulfillment state from the cart vs. local storage.
     */
    loading: cartLoading || cartQueryLoading,
    error,
    /**
     * Indicates if either setDeliveryInfo() or setFulfillment() have been called
     * and are in the midst of updating.
     */
    updating,
    setFulfillment,
    fastLinkName: fastLinkName
  }

  return (
    // @ts-ignore
    <FulfillmentContext.Provider value={context}>
      {children}
    </FulfillmentContext.Provider>
  )
}

export const FulfillmentProvider: React.FC<{
  fastLinkName: string | undefined
  children: React.ReactNode
}> = ({ children, fastLinkName }) => {
  const { orderingAvailable, availability, loading, error } = useAvailability()
  const fulfillmentStorage = useRestaurantStorage()
  const fulfillmentQueryParams = useGetFulfillmentFromQueryParams()

  if (loading) {
    return (
      <div className='flex items-center justify-center h-24'>
        {/* @ts-ignore */}
        <Loading variant={LoadingVariant.SECONDARY} />
      </div>
    )
  }

  const initialState = initialReducerState({
    fulfillmentStorage,
    availability: availability!!,
    orderingAvailable,
    fulfillmentQueryParams
  })

  if (error || 'error' in initialState) {
    return (
      <FulfillmentContext.Provider
        //@ts-ignore
        value={{
          error,
          fastLinkName: fastLinkName
        }}
      >
        {children}
      </FulfillmentContext.Provider>
    )
  }

  return (
    <FulfillmentProviderInner
      children={children}
      initialState={initialState}
      fastLinkName={fastLinkName}
    />
  )
}

export const useFulfillment = () => React.useContext(FulfillmentContext)
