import { RestLink } from 'apollo-link-rest'
import { ApolloLink } from '@apollo/client'

import { GetCheckoutResponse } from '../checkout.graphql'

const getQueryMap = {
  Checkout: (response) => {
    const { checkoutResponse } = response.data
    const { restaurantGuid } = checkoutResponse
    return {
      query: GetCheckoutResponse,
      data: { checkoutResponse },
      variables: { restaurantGuid }
    }
  }
}

const errorMap = {
  400: (json) => json.message,
  422: (json) => json.errorMessage
}

const customFetch = async (uri, options) => {
  const resp = await fetch(uri, options)
  // Create client readable messaging for "Bad Request" or "Unprocessable Entity" errors
  const getMessage = errorMap[resp.status]
  if (getMessage) {
    let message
    try {
      const json = await resp.json()
      message = getMessage(json)
    } catch (err) {
      message = 'An unknown error occurred'
    }
    throw new Error(message)
  }
  return resp
}

const saveRestLink = (config) => (operation, forward) => {
  const getQuery = getQueryMap[operation.operationName]
  if (getQuery) {
    return forward(operation).map((resp) => {
      config.client.writeQuery(getQuery(resp))
      return resp
    })
  }
  return forward(operation)
}

const getRestLink = (config) =>
  ApolloLink.from([
    new ApolloLink(saveRestLink(config)),
    new RestLink({
      uri: `${config.OO_BASE_URI}/carts/enlightened/`,
      customFetch,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  ])

// Exported for testing
export { customFetch, saveRestLink, getRestLink }
